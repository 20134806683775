<template ref="section">
  <div class="overall_color">
    <vue-title title="Overall plan"></vue-title>
    <lock-dialog></lock-dialog>
    <div v-if="!viewPathwaySection && readOnly">
      <div class="planTitle text-center">
        <span>TASKS FOR THIS SECTION</span>
      </div>
      <div class="path_task_holder">
        <tasks :render-title="false" :show-task-assign-btn="false" :pp-section="'overall'" :pp="pathwayPlan[$route.meta.tense]"></tasks>
      </div>
      <div class="current_path_footer overall_footer">
        <q-btn @click="viewPathwaySection = !viewPathwaySection" class="tab-type-button" unelevated icon="stream"
               icon-right="arrow_forward_ios" label="Pathway Plan - overall"/>
        <div class="overall_bg"></div>
      </div>
    </div>
    <step v-else @stepChanged="stepChanged" :tabs="tabs" @prev="prev" @validate="validate" :completed="completed"
          :change-tab-to="tab" :loading="loading" :read-only="readOnly" category="overall">

      <template v-slot:1>
        <q-form class="overall-plan-one" ref="1">
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label class="strong-label" for="Name">How are you feeling about your overall plan?</label>
              <div class="feelings-box radio">
                <q-radio title="awful" class="feelings-item" name="feelings"
                         v-if="readOnly ? overallPlan.step_1.overallPlanFeeling === '1' : true"
                         v-model="overallPlan.step_1.overallPlanFeeling" val="1"
                         :class="[{ selected: overallPlan.step_1.overallPlanFeeling === '1' && !readOnly }]">
                  <span class="f-icon">😭</span>
                  <span class="f-text">awful</span>
                </q-radio>
                <q-radio title="bad" class="feelings-item" name="feelings"
                         v-if="readOnly ? overallPlan.step_1.overallPlanFeeling === '2' : true"
                         v-model="overallPlan.step_1.overallPlanFeeling" val="2"
                         :class="[{ selected: overallPlan.step_1.overallPlanFeeling === '2' && !readOnly }]">
                  <span class="f-icon">🙁</span>
                  <span class="f-text">bad</span>
                </q-radio>
                <q-radio title="so so" class="feelings-item" name="feelings"
                         v-if="readOnly ? overallPlan.step_1.overallPlanFeeling === '3' : true"
                         v-model="overallPlan.step_1.overallPlanFeeling" val="3"
                         :class="[{ selected: overallPlan.step_1.overallPlanFeeling === '3' && !readOnly }]">
                  <span class="f-icon">😐</span>
                  <span class="f-text">so so</span>
                </q-radio>
                <q-radio title="great" class="feelings-item" name="feelings"
                         v-if="readOnly ? overallPlan.step_1.overallPlanFeeling === '4' : true"
                         v-model="overallPlan.step_1.overallPlanFeeling" val="4"
                         :class="[{ selected: overallPlan.step_1.overallPlanFeeling === '4' && !readOnly }]">
                  <span class="f-icon">🙂</span>
                  <span class="f-text">good</span>
                </q-radio>
                <q-radio title="great" class="feelings-item" name="feelings"
                         v-if="readOnly ? overallPlan.step_1.overallPlanFeeling === '5' : true"
                         v-model="overallPlan.step_1.overallPlanFeeling" val="5"
                         :class="[{ selected: overallPlan.step_1.overallPlanFeeling === '5' && !readOnly }]">
                  <span class="f-icon">😀</span>
                  <span class="f-text">great</span>
                </q-radio>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="overallCarePlan">What is your overall care plan?</label>
              <q-input
                v-model="overallPlan.step_1.overallCarePlan"
                outlined
                :placeholder="readOnly ? '' : 'Tell us more'"
                :readonly="loading || isOffline || isLocked"
                for="overallCarePlan"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="attempts">What attempts have been made to arrange for you to live with a relative or a close family member as an alternative to care if you're not already in a family or friends placement?</label>
              <q-input
                v-model="overallPlan.step_1.attemptsForLiving"
                outlined
                :placeholder="readOnly ? '' : 'Tell us more'"
                :readonly="loading || isOffline || isLocked"
                for="attempts"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="relationships">My family and social relationships</label>
              <q-input
                v-model="overallPlan.step_1.familyRelationships"
                outlined
                :placeholder="readOnly ? '' : 'Tell us more'"
                :readonly="loading || isOffline || isLocked"
                for="relationships"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="assessment">Worker's assessment</label>
              <q-input
                v-model="overallPlan.step_1.workersAssessment"
                outlined
                :readonly="['SW', 'PA'].includes(currentUser.userType) ? loading || isOffline || isLocked : true"
                for="assessment"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="arrangements">Contact arrangements</label>
              <q-input
                v-model="overallPlan.step_1.contactArrangements"
                outlined
                :placeholder="readOnly ? '' : 'Tell us more'"
                :readonly="loading || isOffline || isLocked"
                for="arrangements"
              />
            </div>
          </div>
        </q-form>
      </template>

      <template v-slot:2>
        <div class="planTitle">
          <span>VISITS & SIGNIFICANT EVENTS</span>
        </div>
        <q-form class="overall-plan-two" ref="2">
          <q-list class="pathList">
            <q-expansion-item
              group="somegroup"
              label="Visit details"
              icon="check_circle"
              default-opened
              :header-class="['pathListHeader', { compleated: ypVisitedCompleted }]"
            >
              <q-card>
                <q-card-section style="padding-top: 6px">
                  <div class="form-row" style="padding-bottom: 2.3vh;">
                    <div class="row">
                      <div class="col-7">
                        <label for="dov" style="display: block">Date visited</label>
                        <modal-date class="date-input" :read-only="loading || isOffline || isLocked" :required-input="false" :max-date="maxDate" v-model="overallPlan.step_2.date" placeholder="Date" label-id="dov" :disable-input="true"></modal-date>
                      </div>
                      <div class="col-5"></div>
                    </div>
                  </div>

                  <div class="form-row form-row-radio">
                    <div class="column">
                      <div class="">
                        <q-field
                          v-model="overallPlan.step_2.wasYpAlone"
                          borderless
                          square
                          class="q-field-radio"
                          :disable="loading || isOffline || isLocked"
                        >
                          <label class="title-label" for="seenAlone">Was {{ ypName }} seen alone?</label>
                          <q-radio title="yes" for="seenAlone" v-model="overallPlan.step_2.wasYpAlone" val="yes" label="Yes" :disable="(loading || isOffline || isLocked) && (overallPlan.step_2.wasYpAlone !== 'yes')"/>
                          <q-radio title="no" for="seenAlone" v-model="overallPlan.step_2.wasYpAlone" val="no" label="No" :disable="(loading || isOffline || isLocked) && (overallPlan.step_2.wasYpAlone !== 'no')"/>
                        </q-field>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="briefComments">Brief comments</label>
                      <q-input
                        v-model="overallPlan.step_2.briefComments"
                        outlined
                        :placeholder="readOnly ? '' : 'Comment'"
                        :readonly="loading || isOffline || isLocked"
                        for="briefComments"
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="outsideStatutory">If these visits are outside statuory timescales, or {{ ypName }}
                        not seen alone, please explain why?</label>
                      <q-input
                        v-model="overallPlan.step_2.outsideStatutory"
                        outlined
                        :placeholder="readOnly ? '' : 'Tell us more'"
                        :readonly="loading || isOffline || isLocked"
                        for="outsideStatutory"
                      />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>

            <q-separator/>

            <q-expansion-item group="somegroup" icon="check_circle"
                              label="Dates and details of other significant visits and meetings with family and / or professionals"
                              :header-class="['pathListHeader', { compleated: significantVisitsCompleted }]">
              <q-card>
                <q-card-section>

                  <div class="visits-box" v-for="(visit, index) in overallPlan.step_2.visits" :key="index">
                    <div class="row visit-row">
                      <div class="col-6 text-left">
                        <span><h2>Visit #{{ index + 1 }}</h2></span>
                      </div>
                      <div class="col-6 text-right" v-if="!readOnly">
                        <a class="delete-item brown" @click.prevent="removeVisit(index)" @keyup.enter="removeVisit(index)" tabindex="0"><span class="delete-icon"></span>
                          Delete
                        </a>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="q-gutter-y-md column">
                        <label :for="'meetingDetails' + index">Visit / meeting details</label>
                        <q-input
                          v-model="visit.visitDetails"
                          outlined
                          :placeholder="readOnly ? '' : 'Tell us more'"
                          :readonly="loading || isOffline || isLocked"
                          :for="'meetingDetails' + index"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="q-gutter-y-md column">
                        <label :for="'memberInvolved' + index">Name of family member involved in the meeting</label>
                        <q-input
                          v-model="visit.familyMemberInvolved"
                          outlined
                          :placeholder="readOnly ? '' : 'Tell us more'"
                          :readonly="loading || isOffline || isLocked"
                          :for="'memberInvolved' + index"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="q-gutter-y-md column">
                        <label :for="'professionalName' + index">Involved professional's name</label>
                        <q-input
                          v-model="visit.professionalInvolved"
                          outlined
                          :placeholder="readOnly ? '' : 'Tell us more'"
                          :readonly="loading || isOffline || isLocked"
                          :for="'professionalName' + index"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="row">
                        <div class="col-7" style="margin-top: -2vh">
                          <label :for="'dovAdditional' + index" style="display: block">Date of visiting</label>
                          <modal-date class="date-input" :read-only="loading || isOffline || isLocked" :max-date="maxDate" v-model="visit.date"
                                      placeholder="Date" :required-input="false" :label-id="'dovAdditional' + index"></modal-date>
                        </div>
                        <div class="col-5"></div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="q-gutter-y-md column">
                        <label :for="'events' + index">Significant events - brief update since last visit</label>
                        <q-input
                          v-model="visit.events"
                          outlined
                          :placeholder="readOnly ? '' : 'Tell us more'"
                          :readonly="loading || isOffline || isLocked"
                          :for="'events' + index"
                        />
                      </div>
                    </div>

                  </div>

                  <q-btn v-if="!readOnly" :readonly="loading || isOffline || isLocked" @click="addVisits()" class="add custom-add brown" style="margin-bottom: 2vh;" flat label="">
                    <span class="plus-icon material-icons-outlined">add</span> Add visit
                  </q-btn>

                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-form>
      </template>

      <template v-slot:3>
        <div class="planTitle text-center">
          <span>TASKS FOR THIS SECTION</span>
        </div>
        <div v-if="pathwayPlanTasks[getTense].overall.total.length" class="overall-plan-three">
          <div class="plan-task" v-for="(task, index) in pathwayPlanTasks[getTense].overall.total" :key="index">
            <task :task="task" :read-only="readOnly ? false : true"></task>
          </div>
        </div>
        <div v-if="!readOnly">
          <q-btn :disable="isLocked || isOffline" style="margin-bottom: 4vh" @click="assignTaskModalOpen = true" class="add blue custom-add" flat><span
            class="plus-icon material-icons-outlined">add</span> Add task
          </q-btn>
          <assign-task :section="'overall'" :pp-id="getPpId" :emit="false"
                       v-model="assignTaskModalOpen"></assign-task>
        </div>
      </template>

    </step>
  </div>

</template>

<script>
import moment from 'moment'
import Step from '../components/Step.vue'
import ModalDate from '../components/Utils/ModalDate'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import scrollToTarget from '../mixins/scrollToTarget'
import Task from '../components/Tasks/Task'
import AssignTask from '../components/Tasks/AssignTask'
import Tasks from './Tasks.vue'
import addLocks from '@/mixins/addLocks'
import removeLocks from '@/mixins/removeLocks'
import LockDialog from '@/components/Utils/LockDialog'
import showLock from '@/mixins/showLock'
import focusLabel from '@/mixins/focusLabel'

let updatePathwayPlanInterval = null

export default {
  name: 'OverAllPlan',
  components: {
    LockDialog,
    Step,
    ModalDate,
    Task,
    AssignTask,
    Tasks
  },
  mixins: [scrollToTarget, addLocks, removeLocks, showLock, focusLabel],
  data () {
    return {
      viewPathwaySection: false,
      readOnly: false,
      feelings: 'good',
      tab: 1,
      tabs: 3,
      loading: false,
      assignTaskModalOpen: false,
      maxDate: moment().format('YYYY/MM/DD'),
      overallPlan: {
        step_1: {
          overallPlanFeeling: '',
          overallCarePlan: '',
          attemptsForLiving: '',
          familyRelationships: '',
          workersAssessment: '',
          contactArrangements: ''
        },
        step_2: {
          date: '',
          wasYpAlone: '',
          briefComments: '',
          outsideStatutory: '',
          visits: []
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'tasks', 'pathwayPlan', 'pathwayPlanTasks', 'isOffline']),
    completed () {
      const steps = [2]
      if (this.overallPlan.step_1.overallPlanFeeling && this.overallPlan.step_1.overallCarePlan && this.overallPlan.step_1.attemptsForLiving && this.overallPlan.step_1.familyRelationships && this.overallPlan.step_1.contactArrangements) {
        steps.push(1)
      }
      return steps
    },
    ypVisitedCompleted () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        if (this.pathwayPlan[this.$route.meta.tense].date_of_visiting && this.pathwayPlan[this.$route.meta.tense].date_of_visiting !== null && this.pathwayPlan[this.$route.meta.tense].seen_alone && this.pathwayPlan[this.$route.meta.tense].seen_alone !== null && this.pathwayPlan[this.$route.meta.tense].comments && this.pathwayPlan[this.$route.meta.tense].comments !== null && this.pathwayPlan[this.$route.meta.tense].outside_statutory && this.pathwayPlan[this.$route.meta.tense].outside_statutory !== null) {
          return true
        }
      }
      return false
    },
    significantVisitsCompleted () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        if (this.pathwayPlan[this.$route.meta.tense].visits && this.pathwayPlan[this.$route.meta.tense].visits.length) {
          let otherVisitsCompleted = true
          this.pathwayPlan[this.$route.meta.tense].visits.forEach((visit) => {
            for (const key in visit) {
              if (!visit[key] || visit[key] === null) {
                otherVisitsCompleted = false
                break
              }
            }
          })
          return otherVisitsCompleted
        }
      }
      return false
    },
    getPpId () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        return this.pathwayPlan[this.$route.meta.tense].id
      }
      return null
    },
    getTense () {
      if (this.$route.meta.tense) {
        return this.$route.meta.tense
      }
      return 'next'
    },
    ypName () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        return this.pathwayPlan[this.$route.meta.tense].first_name + ' ' + this.pathwayPlan[this.$route.meta.tense].last_name
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setPathwayPlan']),
    ...mapMutations(['setppDueDate']),
    changeTab (tab) {
      this.tab = tab
    },
    prev () {
      this.tab = this.tab - 1
    },
    validate (goToNextStep = false) {
      if (this.$refs[this.tab]) {
        this.$refs[this.tab].validate().then(success => {
          if (success) {
            this.loading = true
            this.scroll(this.$refs.saveBtn)
            const userInfo = Object.assign({}, this.overallPlan['step_' + this.tab])
            let requestData = null
            if (this.tab === 1) {
              requestData = {
                overall_plan_feeling: userInfo.overallPlanFeeling,
                overall_care_plan: userInfo.overallCarePlan,
                attempts: userInfo.attemptsForLiving,
                family_relationship: userInfo.familyRelationships,
                contact_arrangements: userInfo.contactArrangements
              }
              if (['SW', 'PA'].includes(this.currentUser.userType)) {
                requestData.workers_assessment = userInfo.workersAssessment
              }
            } else if (this.tab === 2) {
              requestData = {
                date_of_visiting: userInfo.date,
                seen_alone: userInfo.wasYpAlone,
                comments: userInfo.briefComments,
                outside_statutory: userInfo.outsideStatutory,
                visits: []
              }
              if (userInfo.visits.length) {
                userInfo.visits.forEach(item => {
                  const visit = {
                    id: item.id ? item.id : null,
                    visit: item.visitDetails,
                    member_name: item.familyMemberInvolved,
                    professionals_name: item.professionalInvolved,
                    date: item.date,
                    update_visit: item.events
                  }
                  requestData.visits.push(visit)
                })
              }
            } else if (this.tab === 3 && goToNextStep) {
              console.log('3')
              return this.$router.push({
                name: 'PathwayPlan',
                query: {
                  tab: this.getTense
                }
              })
            }
            requestData.pathway_id = this.pathwayPlan[this.$route.meta.tense].id
            requestData.token = this.currentUser.user_token
            this.setPathwayPlan(requestData).then((data) => {
              this.loading = false
              if (data.status === 200) {
                if (goToNextStep && this.tab !== this.tabs) {
                  this.tab = this.tab + 1
                }
                this.updatePathwayData()
                if (data.message) {
                  return this.$q.notify({
                    message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                    html: true,
                    color: 'green',
                    position: 'top-right'
                  })
                }
              } else {
                return this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'red',
                  position: 'top-right'
                })
              }
            })
          }
        })
      } else {
        if (goToNextStep) {
          this.$router.push({
            name: 'PathwayPlan',
            query: {
              tab: this.getTense
            }
          })
        }
      }
    },
    stepChanged (e) {
      this.tab = e
      this.loading = false
    },
    updatePathwayData () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        clearInterval(updatePathwayPlanInterval)
        this.overallPlan.step_1.overallPlanFeeling = this.pathwayPlan[this.$route.meta.tense].overall_plan_feeling
        this.overallPlan.step_1.overallCarePlan = this.pathwayPlan[this.$route.meta.tense].overall_care_plan
        this.overallPlan.step_1.attemptsForLiving = this.pathwayPlan[this.$route.meta.tense].attempts
        this.overallPlan.step_1.familyRelationships = this.pathwayPlan[this.$route.meta.tense].family_relationship
        this.overallPlan.step_1.workersAssessment = this.pathwayPlan[this.$route.meta.tense].workers_assessment
        this.overallPlan.step_1.contactArrangements = this.pathwayPlan[this.$route.meta.tense].contact_arrangements

        this.overallPlan.step_2.date = this.pathwayPlan[this.$route.meta.tense].date_of_visiting
        this.overallPlan.step_2.wasYpAlone = this.pathwayPlan[this.$route.meta.tense].seen_alone
        this.overallPlan.step_2.briefComments = this.pathwayPlan[this.$route.meta.tense].comments
        this.overallPlan.step_2.outsideStatutory = this.pathwayPlan[this.$route.meta.tense].outside_statutory
        this.overallPlan.step_2.visits = []

        if (this.pathwayPlan[this.$route.meta.tense].visits) {
          this.pathwayPlan[this.$route.meta.tense].visits.forEach(visit => {
            this.overallPlan.step_2.visits.push({
              id: visit.id ? visit.id : null,
              visitDetails: visit.visit,
              familyMemberInvolved: visit.member_name,
              professionalInvolved: visit.professionals_name,
              date: visit.date,
              events: visit.update_visit
            })
          })
        }
      }
    },
    addVisits () {
      const visit = {
        visitDetails: '',
        familyMemberInvolved: '',
        professionalInvolved: '',
        date: '',
        events: ''
      }
      this.overallPlan.step_2.visits.push(visit)
      this.$nextTick(function () {
        this.addFocusEvent()
      })
    },
    removeVisit (index) {
      this.overallPlan.step_2.visits.splice(index, 1)
    },
    goback (route) {
      if (this.viewPathwaySection) {
        this.viewPathwaySection = false
      } else {
        if (this.currentYp) {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab, ypId: this.currentYp.id } })
        } else {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab } })
        }
      }
    }
  },
  watch: {
    pathwayPlan: function (newVal, oldVal) {
      if (this.$route.meta.tense === 'current') {
        if (this.pathwayPlan.current) {
          this.setppDueDate({
            dueDate: this.pathwayPlan.current.expiry_date,
            tense: 'current'
          })
        }
      }
    },
    'pathwayPlan.next': function (newVal, oldVal) {
      this.updatePathwayData()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$route.meta.edit === false) {
      this.loading = true
      this.readOnly = true
    }
    updatePathwayPlanInterval = setInterval(this.updatePathwayData, 500)
    this.$root.$on('save:pathwayPlanSection', this.validate)
    if (this.$route.meta.tense === 'current') {
      if (this.pathwayPlan.current) {
        console.log('call 8')
        this.setppDueDate({
          dueDate: this.pathwayPlan.current.expiry_date,
          tense: 'current'
        })
      }
    }
    this.$root.$on('ppSection:back', this.goback)
  },
  beforeDestroy () {
    this.$root.$off('save:pathwayPlanSection')
    this.$root.$off('ppSection:back', this.goback)
  }
}
</script>

<style>
  .profile-tabs .q-focus-helper {
    display: none;
  }
  button.brown, a.brown {
    color: #92353b !important;
  }
</style>
