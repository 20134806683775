<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VueTitle',
  props: ['title'],
  mounted () {
    document.title = this.title ? this.title + this.userType : ''
  },
  computed: {
    ...mapGetters(['currentUser']),
    userType () {
      return ' ' + (typeof this.currentUser.userType === 'string' ? this.currentUser.userType : '')
    }
  },
  watch: {
    title: {
      immediate: true,
      handler () {
        document.title = this.title ? this.title + this.userType : ''
      }
    }
  },
  render () {
    return ''
  }
}
</script>
