<template>
  <div>
    <vue-title :title="currentYp ? 'Caseload profile view' : 'Profile view'"></vue-title>
    <div class="profile-details-holder" v-if="user.userBio">
      <q-list>
        <q-expansion-item v-if="user.userBio"
          group="somegroup"
          label="About me"
          default-opened
          header-class="text-primary"
        >
          <q-card tabindex="0">
            <q-card-section>
              {{ user.userBio.aboutMe }}
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>

        <q-expansion-item v-if="user.userContactDetails"
          group="somegroup"
          label="Contact details"
          header-class="text-primary"
          >
          <q-card>
            <q-card-section>
              <q-markup-table>
                <tbody>
                <tr tabindex="0">
                  <td class="text-left">Phone</td>
                  <td class="text-right">{{ user.userContactDetails.mainPhone }}</td>
                </tr>
                <tr tabindex="0" v-for="(phone, idx) in user.userContactDetails.phoneNumbers" :key="'phone' + idx">
                  <td class="text-left">Phone</td>
                  <td class="text-right">{{ phone }}</td>
                </tr>
                <tr tabindex="0" v-if="user.userContactDetails.email">
                  <td class="text-left">Email</td>
                  <td class="text-right">{{ user.userContactDetails.email }}</td>
                </tr>
                <tr tabindex="0" v-for="(emails, idx) in user.userContactDetails.emails" :key="'email' + idx">
                  <td class="text-left">Email</td>
                  <td class="text-right">{{ emails }}</td>
                </tr>
                </tbody>
              </q-markup-table>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>

        <q-expansion-item v-if="user.userBio"
          group="somegroup"
          label="Allergies & medical conditions"
          header-class="text-primary"
          >
          <q-card tabindex="0">
            <q-card-section>
              {{ user.userBio.medical }}
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>

        <q-expansion-item
          group="somegroup"
          label="Achieved badges"
          header-class="text-primary"
          expand-icon-class="text-white"
          class="achievements-box"
          v-if="userBadges && userBadges.length"
        >
          <q-card>
            <q-card-section>
              <div>
                <achieved-badges :static-badges="userBadges"></achieved-badges>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>

        <q-expansion-item
          group="somegroup"
          label="Personal achievements"
          header-class="text-primary"
          expand-icon-class="text-white"
          class="achievements-box"
          v-if="user.userBio"
        >
          <q-card tabindex="0">
            <q-card-section>
              <div v-if="Array.isArray(user.userBio.achievements)">
                <div class="row" v-for="(achievement, idx) in sortedAchievements" :key="idx">
                  <div class="col-2 vertical-middle achievements-year">
                    {{ achievement.year }}
                  </div>
                  <div class="col-10 vertical-middle achievements-details">
                    {{ achievement.description }}
                  </div>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>
        <q-expansion-item v-if="!currentYp"
          group="somegroup"
          label="People supporting me"
          header-class="text-primary"
          >
          <q-card>
            <q-card-section>
              <supporting-people></supporting-people>
              <div class="clear"></div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-list>
    </div>
  </div>
</template>

<script>
import avatar from '../assets/av.png'
import { mapActions, mapGetters } from 'vuex'
import SupportingPeople from './SupportingPeople'
import AchievedBadges from '@/components/Profile/AchievedBadges'

export default {
  name: 'ViewProfile',
  components: {
    SupportingPeople,
    AchievedBadges
  },
  data () {
    return {
      avatar: avatar,
      user: {
        userBio: false,
        userContactDetails: false,
        userGeneralInfo: false
      }
    }
  },
  computed: {
    ...mapGetters(['userGeneralInfo', 'userContactDetails', 'userBio', 'currentUser', 'currentYp', 'backgroundLoading', 'userBadges']),
    sortedAchievements () {
      if (Array.isArray(this.user.userBio.achievements)) {
        return [...this.user.userBio.achievements].map(achievement => {
          achievement.year = parseInt(achievement.year)
          return achievement
        }).sort((a, b) => {
          if (a.year > b.year) {
            return 1
          }
          if (a.year < b.year) {
            return -1
          }
          return 0
        })
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions(['getUserInfo']),
    updateData () {
      if (this.currentYp) {
        this.user.userBio = this.currentYp.bio
        this.user.userGeneralInfo = this.currentYp.generalInfo
        this.user.userContactDetails = this.currentYp.contactDetails
      } else {
        this.user.userBio = this.userBio
        this.user.userGeneralInfo = this.userGeneralInfo
        this.user.userContactDetails = this.userContactDetails
      }
    }
  },
  mounted () {
    this.updateData()
  },
  watch: {
    backgroundLoading (state) {
      if (!state) {
        this.updateData()
      }
    }
  }
}
</script>

<style scoped>

</style>
