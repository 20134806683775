import api from '@/api'
import { groupServices, groupContacts } from '@/store/utils/groupings'

import Loading from 'quasar/src/plugins/Loading.js';

const state = {
  contacts: [],
  currentContact: false,
  ypContacts: []
}

const getters = {
  currentContact (state) {
    return state.currentContact
  },
  allContacts (state, getters) {
    let contacts = []
    if (getters.currentYp) {
      contacts = state.ypContacts
    } else {
      contacts = state.contacts
    }

    if (getters.searchText.length) {
      return groupContacts(contacts.filter(contact => (contact.firstName + contact.lastName).toLowerCase().replace(/s*/i, '').indexOf(getters.searchText.toLowerCase().replace(/s*/i, '')) > -1))
    }
    return groupContacts(contacts)
  },
  myContacts (state, getters) {
    let contacts = []
    if (getters.currentYp) {
      contacts = state.ypContacts
    } else {
      contacts = state.contacts
    }

    if (getters.searchText.length) {
      return groupContacts(contacts.filter(contact => contact.isMyContact).filter(contact => (contact.firstName + contact.lastName).toLowerCase().indexOf(getters.searchText.toLowerCase().replace(/s*/g, '')) > -1))
    }
    return groupContacts(contacts.filter(contact => contact.isMyContact))
  },
  shareContacts (state, getters) {
    if (getters.searchText.length) {
      return groupContacts(state.contacts.filter(contact => contact.isMyContact).filter(contact => (contact.firstName + contact.lastName).toLowerCase().indexOf(getters.searchText.toLowerCase().replace(/s*/g, '')) > -1))
    }
    return groupContacts(state.contacts.filter(contact => contact.isMyContact))
  },
  canBeSharedContacts (state, getters) {
    return state.contacts.filter(contact => contact.type !== 'service')
  },
  ypContactIDs (state) {
    return state.ypContacts.map(contact => contact.id)
  },
  services (state, getters) {
    let contacts = []
    if (getters.currentYp) {
      contacts = state.ypContacts
    } else {
      contacts = state.contacts
    }

    if (getters.searchText.length) {
      return groupServices(contacts.filter(contact => (contact.firstName + contact.lastName).toLowerCase().indexOf(getters.searchText.toLowerCase().replace(/s*/g, '')) > -1).filter(contact => contact.type === 'service'))
    }
    return groupServices(contacts.filter(contact => contact.type === 'service'))
  },
  quickContacts (state, getters) {
    let contacts = []
    if (getters.currentYp) {
      contacts = state.ypContacts
    } else {
      contacts = state.contacts
    }
    return contacts.filter(contact => contact.isQuickContact)
  },
  allContactsWithoutGroup (state, getters) {
    let contacts = []
    if (getters.currentYp) {
      contacts = state.ypContacts
    } else {
      contacts = state.contacts
    }

    if (getters.searchText.length) {
      return contacts.filter(contact => (contact.firstName + contact.lastName).toLowerCase().replace(/s*/i, '').indexOf(getters.searchText.toLowerCase().replace(/s*/i, '')) > -1)
    }
    return contacts
  }
}

const actions = {
  saveContact (context, updateContactData) {
    const req = Object.assign({
      token: context.getters.currentUser.user_token
    }, updateContactData)
    if (context.getters.currentYp) {
      req.yp_id = context.getters.currentYp.yp_id
    }
    return api.addEditContact(req).then(data => {
      if (data.status === 200) {
        const contact = Object.assign(updateContactData, data.data)
        context.commit('saveContact', {
          contact,
          currentYp: context.getters.currentYp
        })
      } else {
        Loading.hide()
      }
    })
  },
  deleteContact (context, contact) {
    const req = {
      id: contact.id,
      token: context.getters.currentUser.user_token
    }
    if (context.getters.currentYp) {
      req.yp_id = context.getters.currentYp.yp_id
    }

    return api.deleteMyContact(req).then(() => {
      context.commit('deleteContact', {
        contact,
        currentYp: context.getters.currentYp
      })
      context.commit('setCurrentContact', false)
      return context.dispatch('setContacts', true)
    })
  },
  toggleQuickContact (context, contact = false) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        Loading.show()
        const toggle = typeof contact === 'object' ? contact : context.state.currentContact
        const req = {
          token: context.getters.currentUser.user_token,
          id: toggle.id,
          isQuickContact: !toggle.isQuickContact,
          type: toggle.type
        }
        if (context.getters.currentYp) {
          req.yp_id = context.getters.currentYp.yp_id
        }
        api.setQuickContact(req).then(() => {
          context.commit('toggleQuickContact', {
            toggle,
            currentYp: context.getters.currentYp
          })
          resolve()
          Loading.hide()
        }).catch(() => {
          resolve()
          Loading.hide()
        })
      } else {
        Loading.hide()
        reject(Error('offline'))
      }
    })
  },
  setContacts (context, forceLoad = false) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        if (context.getters.allContacts.length === 0) {
          Loading.show()
        }
        let load = true
        const req = {
          token: context.getters.currentUser.user_token
        }
        if (context.getters.currentYp) {
          req.yp_id = context.getters.currentYp.yp_id
          if (!forceLoad) {
            load = !(context.state.ypContacts.length !== 0)
          } else {
            load = true
          }
        }

        if (context.getters.backgroundLoading) {
          load = true
        }

        if (load) {
          return api.getAllContacts(req).then(data => {
            if (data.status === 200) {
              context.commit('setContacts', {
                data: data.data,
                currentYp: context.getters.currentYp
              })
              resolve()
              Loading.hide()
            } else {
              Loading.hide()
            }
          })
        } else {
          Loading.hide()
          resolve()
        }
      } else {
        Loading.hide()
        reject(Error('offline'))
      }
    })
  },
  setCurrentContact (context, data) {
    context.commit('setCurrentContact', data)
  }
}

const mutations = {
  saveContact (state, data) {
    const contactsStorage = data.currentYp ? 'ypContacts' : 'contacts'
    const existingContact = state[contactsStorage].findIndex(contact => contact.id === data.contact.id)
    if (existingContact !== -1) {
      state[contactsStorage] = state[contactsStorage].map((contact, idx) => {
        if (idx === existingContact) {
          contact = data.contact
        }
        return contact
      })
      state.currentContact = data.contact
    } else {
      state[contactsStorage].push(data.contact)
    }
    return existingContact !== -1
  },
  toggleQuickContact (state, data) {
    const contactsStorage = data.currentYp ? 'ypContacts' : 'contacts'
    state[contactsStorage] = state[contactsStorage].map(contact => {
      if (data.toggle.id === contact.id) {
        contact.isQuickContact = !contact.isQuickContact
        if (state.currentContact && state.currentContact.id === contact.id) {
          state.currentContact.isQuickContact = contact.isQuickContact
        }
      }
      return contact
    })
  },
  setContacts (state, data) {
    if (data.currentYp) {
      state.ypContacts = data.data
    } else {
      state.contacts = data.data
    }
  },
  setYpContacts (state, data) {
    state.ypContacts = data
  },
  pushYpContacts (state, data) {
    data.forEach(sharedContact => {
      if (!state.ypContacts.map(contact => contact.id).includes(sharedContact.id)) {
        state.ypContacts.push(sharedContact)
      }
    })
  },
  deleteContact (state, data) {
    const contactsStorage = data.currentYp ? 'ypContacts' : 'contacts'
    state[contactsStorage] = state[contactsStorage].filter((_, idx) => idx !== state.contacts.indexOf(data.contact))
  },
  setCurrentContact (state, contact) {
    state.currentContact = contact
  },
  clearContacts (state) {
    state.contacts = []
    state.ypContacts = []
    state.currentContact = false
  },
  clearYpContacts (state) {
    state.ypContacts = []
    state.currentContact = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
