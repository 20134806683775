<template>
  <div>
    <div class="options-top">
      <div class="q-gutter-y-md column">
        <h2 class="options-title">Building your future:18+</h2>
        <div class="options-bg"></div>
      </div>
    </div>

    <q-list class="options-list">
      <q-expansion-item
        group="somegroup"
        :label="article.title"
        icon="check_circle"
        default-opened
        header-class="options-tab-header"
        v-for="(article, index) in articlesAbove18" :key="index"
      >
        <q-card>
          <q-card-section>
            <div class="option-content">
              <p v-html="article.content" tabindex="0"></p>
              <router-link to="/my-options" class="finde-more">Find out more</router-link>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>

  </div>
</template>

<script>
export default {
  name: 'AgeB',
  props: {
    articlesAbove18: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
