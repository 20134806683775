<template>
  <div ref="notificationBox" :class="{ 'floating-notification': floating }">
    <transition appear enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="in-out">
      <div v-if="show && message" class="notification-box q-ma-sm">
        <div v-html="message"></div>
        <img v-if="image" :src="image.icon" :alt="image.title">
      </div>
    </transition>
  </div>
</template>

<script>
import scrollToTarget from '@/mixins/scrollToTarget'

let timeOut = null
export default {
  name: 'Notification',
  mixins: [scrollToTarget],
  props: {
    section: { // section name for listening on events
      type: String,
      default () {
        return 'task'
      }
    },
    floating: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      show: false,
      message: false,
      image: false
    }
  },
  mounted () {
    this.$root.$on(this.section + '::notification', this.showNotification)
  },
  methods: {
    showNotification (message, image = false) {
      this.message = message
      this.image = image
      this.show = true
      this.scrollQ(this.$refs.notificationBox)
      clearTimeout(timeOut)
      timeOut = setTimeout(() => {
        this.show = false
        this.message = false
      }, 5000)
    }
  },
  beforeDestroy () {
    this.$root.$off(this.section + '::notification', this.showNotification)
  }
}
</script>

<style>
.floating-notification {
  position: absolute;
  top: 10%;
  z-index: 999999;
  left: 0;
  right: 0;
  margin: auto
}
</style>
