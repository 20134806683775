import api from '../../api'
import Loading from 'quasar/src/plugins/Loading.js';
import moment from 'moment'

const state = {
  tasks: [],
  ypTasks: []
}

const getters = {
  tasks (state, getters) {
    try {
      const tasks = (getters.currentYp ? getters.ypTasks : state.tasks).map(task => {
        if (moment().isBefore(task.dueDate, 'day') || moment().isSame(task.dueDate, 'day')) {
          task.status = 'upcoming'
        }
        if (moment().isAfter(task.dueDate, 'day')) {
          task.status = 'overdue'
        }
        if (task.completed) {
          task.status = 'completed'
        }
        return task
      })
      if (getters.searchText.length) {
        return tasks.filter(task => task.description.toLowerCase().includes(getters.searchText.toLowerCase()))
      }
      return tasks
    } catch (e) {
      return []
    }
  },
  pathwayPlanTasks (state, getters) {
    const pathwayPlanTasks = {
      current: [],
      next: []
    }
    const tasks = (getters.currentYp ? getters.ypTasks : state.tasks).map(task => {
      if (moment().isBefore(task.dueDate, 'day') || moment().isSame(task.dueDate, 'day')) {
        task.status = 'upcoming'
      }
      if (moment().isAfter(task.dueDate, 'day')) {
        task.status = 'overdue'
      }
      if (task.completed) {
        task.status = 'completed'
      }
      return task
    })
    tasks.filter(task => {
      if (getters.getCurrentPathwayPlanId === task.pp_id) {
        pathwayPlanTasks.current.push(task)
      }
      if (getters.getNextPathwayPlanId === task.pp_id) {
        pathwayPlanTasks.next.push(task)
      }
    })
    const currentSectionTasks = {
      overall: pathwayPlanTasks.current.filter((task) => task.section === 'overall'),
      education: pathwayPlanTasks.current.filter((task) => task.section === 'education'),
      health: pathwayPlanTasks.current.filter((task) => task.section === 'health'),
      money: pathwayPlanTasks.current.filter((task) => task.section === 'money'),
      managing: pathwayPlanTasks.current.filter((task) => task.section === 'managing')
    }
    const nextSectionTasks = {
      overall: pathwayPlanTasks.next.filter((task) => task.section === 'overall'),
      education: pathwayPlanTasks.next.filter((task) => task.section === 'education'),
      health: pathwayPlanTasks.next.filter((task) => task.section === 'health'),
      money: pathwayPlanTasks.next.filter((task) => task.section === 'money'),
      managing: pathwayPlanTasks.next.filter((task) => task.section === 'managing')
    }
    const data = {
      next: {
        overall: {
          completed: nextSectionTasks.overall.filter((task) => task.completed),
          total: nextSectionTasks.overall
        },
        education: {
          completed: nextSectionTasks.education.filter((task) => task.completed),
          total: nextSectionTasks.education
        },
        managing: {
          completed: nextSectionTasks.managing.filter((task) => task.completed),
          total: nextSectionTasks.managing
        },
        health: {
          completed: nextSectionTasks.health.filter((task) => task.completed),
          total: nextSectionTasks.health
        },
        money: {
          completed: nextSectionTasks.money.filter((task) => task.completed),
          total: nextSectionTasks.money
        }
      },
      current: {
        overall: {
          completed: currentSectionTasks.overall.filter((task) => task.completed),
          total: currentSectionTasks.overall
        },
        education: {
          completed: currentSectionTasks.education.filter((task) => task.completed),
          total: currentSectionTasks.education
        },
        managing: {
          completed: currentSectionTasks.managing.filter((task) => task.completed),
          total: currentSectionTasks.managing
        },
        health: {
          completed: currentSectionTasks.health.filter((task) => task.completed),
          total: currentSectionTasks.health
        },
        money: {
          completed: currentSectionTasks.money.filter((task) => task.completed),
          total: currentSectionTasks.money
        }
      }
    }
    return data
  },
  ypTasks (state) {
    return state.ypTasks
  }
}

const actions = {
  setTaskCompleted (context, taskData) {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'task_' + (taskData.completed ? 'incomplete' : 'completed'), {
        event_category: 'Task',
        event_label: taskData.completed ? 'Task incomplete' : 'Task completed'
      })
    }
    const reqData = {
      token: context.getters.currentUser.user_token,
      user_id: context.getters.currentUser.user_id,
      ticket_id: taskData.id,
      status: !taskData.completed
    }
    context.commit('setTaskCompleted', {
      taskData,
      currentYp: context.getters.currentYp
    })
    return api.setUserTaskCompleted(reqData).then((data) => {
      try {
        if (data.status === 200) {
          context.dispatch('setupUserData')
        } else {
          context.dispatch('setupUserData')
        }
        return data
      } catch (e) {
        console.log(e)
      }
    })
  },
  getUserTasks (context, isAssigned = false) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        let load = true
        const requestData = {
          token: context.getters.currentUser.user_token
        }
        if (context.getters.currentYp) {
          requestData.yp_id = context.getters.currentYp.id
          if (isAssigned) {
            load = true
          } else {
            load = !(context.state.ypTasks.length !== 0)
          }
        }

        if (context.getters.backgroundLoading) {
          load = true
        }

        if (load) {
          return api.getUserTasks(requestData).then((data) => {
            try {
              if (data.status === 200) {
                if (data.data.tasks) {
                  data.data.tasks = data.data.tasks.map(task => {
                    task.completed = task.completed === '1'
                    task.dueDate = task.due_date
                    return task
                  })
                }
                if (context.getters.currentYp) {
                  context.commit('setYpTasks', data.data)
                } else {
                  context.commit('setUserTasks', data.data)
                }
                Loading.hide()
              } else {
                Loading.hide()
              }
            } catch (e) {
              Loading.hide()
              console.log(e)
            }
            resolve()
          }).catch((e) => {})
        } else {
          Loading.hide()
          resolve()
        }
      } else {
        Loading.hide()
        reject(Error('offline'))
      }
    })
  },
  saveTask (context, taskData) {
    const req = Object.assign({ token: context.getters.currentUser.user_token }, taskData)
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'task_created', {
        event_category: 'Task',
        event_label: 'Task created'
      })
    }
    return api.saveTask(req).then((data) => {
      if (data.status === 200) {
        return context.dispatch('getUserTasks', true)
      } else {
        Loading.hide()
      }
    })
  },
  setSelectedTask (context, task) {
    context.commit('setSelectedTask', task)
  },
  deleteTask (context, task) {
    task.token = context.getters.currentUser.user_token
    return api.deleteTask(task).then(data => {
      if ([200, 404].includes(data.status)) {
        context.commit('removeTask', task)
      } else {
        Loading.hide()
      }
      return data.status === 200
    })
  },
  updateTask (context, task) {
    const updatedTasks = Object.assign({}, task)
    delete task.assignedTo
    delete task.pp_id
    delete task.section
    delete task.type
    delete task.dueDate
    task.token = context.getters.currentUser.user_token
    return api.updateTask(task).then(data => {
      if (data.status === 200) {
        context.commit('updateTask', updatedTasks)
      } else {
        Loading.hide()
      }
      return data.status === 200
    })
  }
}

const mutations = {
  setTaskCompleted (state, data) {
    state[data.currentYp ? 'ypTasks' : 'tasks'] = state[data.currentYp ? 'ypTasks' : 'tasks'].map(task => {
      if (task.id === data.taskData.id) {
        task.completed = !data.taskData.completed
        return task
      }
      return task
    })
  },
  setUserTasks (state, data) {
    if (data.tasks) {
      state.tasks = data.tasks
    } else {
      state.tasks = []
    }
  },
  clearTasks (state) {
    state.tasks = []
  },
  setYpTasks (state, data) {
    if (data.tasks) {
      state.ypTasks = data.tasks
    } else {
      state.ypTasks = []
    }
  },
  clearYpTasks (state) {
    state.ypTasks = []
  },
  updateTask (state, updatedTask) {
    const update = task => {
      if (updatedTask.task_id === task.id) {
        task.assignedTo = updatedTask.assignedTo
        task.due_date = updatedTask.due_date
        task.dueDate = updatedTask.dueDate
        task.description = updatedTask.description
        task.badge = {
          id: updatedTask.badge
        }
      }
      return task
    }
    state.tasks = state.tasks.map(update)
    state.ypTasks = state.ypTasks.map(update)
  },
  removeTask (state, removedTask) {
    const removedTaskId = removedTask.task_id
    const remove = task => {
      return task.id !== removedTaskId
    }
    state.tasks = state.tasks.filter(remove)
    state.ypTasks = state.ypTasks.filter(remove)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
