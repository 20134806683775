<template>
  <q-drawer
    v-model="drawerOpen"
    @hide="closeMenu()"
    @show="openMenu()"
    show-if-above
    bordered
    content-class="custom-drawer"
    behavior="mobile"
    :overlay="false"
    aria-label="menu"
    id="app-menu"
  >
    <span ref="menuCloseBtn" class="close-drawer" tabindex="0" aria-label="close" role="button" @click="drawerOpen = false" @keyup.enter="drawerOpen = false"><span class="material-icons-outlined">close</span></span>
    <div class="avatar-box">
      <div class="bg-transparent">
        <div class="row" tabindex="0" @click="$router.push({ name: userType === 'YP' ? 'ViewProfile' : 'SwViewProfile' })" @keyup.enter="$router.push({ name: userType === 'YP' ? 'ViewProfile' : 'SwViewProfile' })">
          <div class="col-4">
            <q-avatar size="72px" class="q-mb-sm">
              <img id="avatar-menu" :src="avatar" alt="avatar">
            </q-avatar>
          </div>
          <div class="col-8">
            <div class="avatar-text">{{ username }}</div>
            <div class="avatar-badge" v-if="profileBadge && currentUser.userType === 'YP'"><i class="av-icon"><img :src="profileBadge.icon" :alt="profileBadge.title"></i> <span>{{ profileBadge.title }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <main-menu-yp v-if="userType === 'YP'"></main-menu-yp>
    <main-menu-sw v-if="['SW', 'PA'].includes(userType)"></main-menu-sw>
  </q-drawer>
</template>

<script>
import avatar from '../assets/av.png'
import SaveIcon from '../assets/save-icon.png'
import { mapGetters, mapMutations } from 'vuex'
import MainMenuYp from '@/components/MainMenuYp'
import MainMenuSw from '@/components/MainMenuSw'

export default {
  name: 'MainMenu',
  components: { MainMenuYp, MainMenuSw },
  computed: {
    ...mapGetters(['appDrawerOpen', 'currentUser', 'profileBadge']),
    username () {
      return this.currentUser && this.currentUser.userInfo.generalInfo && this.currentUser.userInfo.generalInfo.firstName !== '' ? this.currentUser.userInfo.generalInfo.firstName : 'User'
    },
    avatar () {
      try {
        return this.currentUser && this.currentUser.userInfo.generalInfo && typeof this.currentUser.userInfo.generalInfo.avatar === 'string' && this.currentUser.userInfo.generalInfo.avatar.length ? this.currentUser.userInfo.generalInfo.avatar : avatar
      } catch (e) {
        return avatar
      }
    },
    userType () {
      return this.currentUser.userType
    }
  },
  data () {
    return {
      drawerOpen: false,
      SaveIcon: SaveIcon
    }
  },
  methods: {
    ...mapMutations(['closeAppDrawer', 'openAppDrawer']),
    openMenu () {
      this.openAppDrawer()
      window.addEventListener('keydown', this.handleKey)
      this.$refs.menuCloseBtn.focus()
    },
    closeMenu () {
      this.closeAppDrawer()
    },
    handleKey (e) {
      if (e.keyCode === 9) {
        const focusable = document.querySelector('#app-menu').querySelectorAll('a,button,span.close-drawer,img#avatar-menu')
        if (focusable.length) {
          const first = focusable[0]
          const last = focusable[focusable.length - 1]
          const shift = e.shiftKey
          if (shift) {
            if (e.target === first) { // shift-tab pressed on first input in dialog
              last.focus()
              e.preventDefault()
            }
          } else {
            if (e.target === last) { // tab pressed on last input in dialog
              first.focus()
              e.preventDefault()
            }
          }
        }
      }
    }
  },
  watch: {
    appDrawerOpen (open) {
      this.drawerOpen = open
    }
  }
}
</script>
