import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    lockIsOn: {
      type: String,
      default () {
        return 'view'
      }
    }
  },
  data () {
    return {
      modalOpen: false,
      lockedOnMounted: false
    }
  },
  computed: {
    ...mapGetters(['locks', 'pathwayPlan', 'currentUser', 'currentYp', 'selectedTask', 'currentContact']),
    sectionLabel () {
      switch (this.section) {
        case 'pp':
          return 'pathway plan'
        default:
          return this.section
      }
    },
    editingEntity () {
      const roles = { SW: 'social worker', PA: 'personal advisor', YP: 'young person' }
      return this.lockData && roles[this.lockData.lockedBy.userType] ? roles[this.lockData.lockedBy.userType] : ''
    },
    isPathwayPlan () {
      return ['AllAboutMeNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext',
        'SwAllAboutMeNext', 'SwOverallPlanNext', 'SwEducationEmploymentNext', 'SwManagingLivingNext', 'SwHealthDevelopmentNext', 'SwMyMoneyNext'].includes(this.$route.name)
    },
    section () {
      if (this.lockIsOn === 'view') {
        if (['AllAboutMeNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext',
          'SwAllAboutMeNext', 'SwOverallPlanNext', 'SwEducationEmploymentNext', 'SwManagingLivingNext', 'SwHealthDevelopmentNext', 'SwMyMoneyNext'].includes(this.$route.name)) {
          return 'pp'
        }
        if (['YpContacts', 'Contacts'].includes(this.$route.name)) {
          return 'contacts'
        }

        if (['Profile'].includes(this.$route.name)) {
          return 'profile'
        }

        return false
      } else {
        return this.lockIsOn
      }
    },
    resourceId () {
      if (['AllAboutMeNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext'].includes(this.$route.name)) {
        if (this.$route.meta.edit && this.$route.meta.tense) {
          if (this.pathwayPlan && this.pathwayPlan[this.$route.meta.tense]) {
            return this.pathwayPlan[this.$route.meta.tense].id
          }
        }
      }
      if (['Profile'].includes(this.$route.name) && ['SW', 'PA'].includes(this.currentUser.userType) && this.currentYp) {
        return this.currentYp.id
      }
      if (['Profile'].includes(this.$route.name) && this.currentUser.userType === 'YP') {
        return this.currentUser.user_id
      }
      if (this.section === 'task') {
        return this.selectedTask.id
      }

      if (this.section === 'contact') {
        return this.currentContact.id
      }
      return null
    },
    isLocked () {
      return this.locks.filter(lk => lk.resourceId === this.resourceId && lk.section === this.section && lk.lockedBy.id !== this.currentUser.user_id).length > 0
    },
    lockData () {
      if (this.locks.length) {
        return this.locks.filter(lk => lk.resourceId === this.resourceId && lk.section === this.section && lk.lockedBy.id !== this.currentUser.user_id)[0]
      }
      return false
    }
  },
  mounted () {
    this.processLockCheck()
    this.$root.$on('task::assignOpen', this.processLockCheck)
    this.$root.$on('task::editMode', this.processLockCheck)
  },
  methods: {
    ...mapActions(['switchLock', 'checkLock']),
    takeOver () {
      if (this.section !== 'task' && this.resourceId && this.currentYp) {
        this.switchLock({
          resourceId: this.resourceId,
          section: this.section
        })
      }

      if (this.section === 'task' && this.resourceId && this.selectedTask) {
        this.switchLock({
          resourceId: this.resourceId,
          section: this.section
        })
        this.$root.$emit('task::takeOver')
      }
    },
    saveAndExit () {
      this.modalOpen = false
      if (this.section === 'pp') {
        this.$root.$emit('save:pathwayPlanSection')
        this.$emit('closeModal')
      }
      if (this.section === 'profile') {
        this.$root.$emit('save:profile')
      }
      if (this.section === 'contacts') {
        this.$root.$emit('save:contact')
      }
      if (!this.isPathwayPlan) {
        this.$router.go(-1)
      }
    },
    exit () {
      this.modalOpen = false
      if (!['task', 'contact'].includes(this.section) && !this.isPathwayPlan) {
        this.$router.go(-1)
      } else {
        if (!this.isPathwayPlan) {
          this.$root.$emit('rmLocks')
        }
        this.$emit('closeModal')
      }
    },
    processLockCheck () {
      if (this.currentUser.user_id && this.section && this.resourceId) {
        this.checkLock({ id: this.currentUser.user_id, section: this.section, resourceId: this.resourceId })
        if (this.isLocked) {
          if (this.parentLockOnMounted !== null) {
            this.lockedOnMounted = this.parentLockOnMounted
          } else {
            this.lockedOnMounted = true
          }
          this.modalOpen = true
          this.$emit('input', this.isLocked)
        }
      }
    }
  },
  watch: {
    isLocked (locked) {
      this.$emit('input', locked)
      this.modalOpen = locked
    }
  },
  beforeDestroy () {
    this.$root.$off('task::assignOpen', this.setLocks)
    this.$root.$off('task::editMode', this.setLocks)
  }
}
