<template>
  <div>
    <div id="pdfDocumentContainer" role="document">
      <canvas v-in-viewport class="pdfContainer" :id="'pdfContainer' + '_' + index" v-for="(page, index) in totalPages" :key="index">
      </canvas>
    </div>
    <q-scroll-observer scroll-target="pdfDocumentContainer" @scroll="setPageInView"></q-scroll-observer>
  </div>
</template>

<script>
import {
  Loading
} from 'quasar'
import * as pdfjs from 'pdfjs-dist'
import { mapMutations } from 'vuex'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

export default {
  name: 'DocumentViewer',
  data () {
    return {
      totalPages: null,
      pageInView: null,
      notification: null
    }
  },
  methods: {
    ...mapMutations(['setDocumentTitle']),
    preview (url) {
      Loading.show()
      const loadingTask = pdfjs.getDocument(url)
      loadingTask.promise.then((pdfDocument) => {
        this.totalPages = pdfDocument.numPages
        const pagePromise = []
        for (let i = 1; i <= pdfDocument.numPages; i++) {
          pagePromise.push(pdfDocument.getPage(i))
        }
        Promise.all(pagePromise).then((pdfPages) => {
          pdfPages.forEach((pdfPage, i) => {
            const viewport = pdfPage.getViewport({ scale: 0.9 })
            const canvas = document.getElementById('pdfContainer' + '_' + i)
            canvas.width = viewport.width
            canvas.height = viewport.height
            const ctx = canvas.getContext('2d')
            pdfPage.render({
              canvasContext: ctx,
              viewport
            })
          })
          Loading.hide()
        }).catch(e => {
          Loading.hide()
        })
      })
    },
    setPageInView (e) {
      try {
        const els = document.querySelectorAll('.pdfContainer.in-viewport')
        if (els.length) {
          const inView = [...els].filter(el => [...el.classList].includes('in-viewport')).map(el => {
            return {
              page: parseInt(el.id.replace('pdfContainer_', '')) + 1,
              classList: el.className
            }
          })
          const fullView = inView.filter(p => p.classList === 'pdfContainer in-viewport')
          if (fullView.length) {
            this.pageInView = fullView[0].page
          } else {
            if (e.direction === 'up') {
              this.pageInView = Math.min(...inView.map(p => p.page))
            } else {
              this.pageInView = Math.max(...inView.map(p => p.page))
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    if (this.$route.query.doc) {
      this.setDocumentTitle()
      if (this.$route.query.title) {
        this.setDocumentTitle(window.atob(this.$route.query.title))
      } else {
        this.setDocumentTitle(null)
      }
      this.preview(window.atob(this.$route.query.doc))
    } else {
      this.$router.push({ name: 'Home' })
    }
  },
  watch: {
    pageInView (page) {
      if (page && this.totalPages) {
        this.notification = this.$q.notify({
          message: page + ' / ' + this.totalPages,
          timeout: 10000,
          position: 'top-left',
          group: 'pageNotification',
          badgeStyle: 'display: none',
          classes: 'currentPageBadge',
          attrs: {
            'aria-label': page + ' / ' + this.totalPages
          }
        })
      }
    }
  },
  beforeDestroy () {
    if (this.notification) {
      this.notification()
    }
  }
}
</script>

<style>
.pdfContainer {
  width: 100vw;
  float: left;
}

.currentPageBadge {
  margin-top: 70px !important;
}
</style>
