<template>
  <div>
    <div v-if="!readOnly" class="next-pathway">

      <q-tabs
        v-model="tab"
        active-color="primary"
        class="text-grey circle-tabs"
        dense
        indicator-color="primary"
        narrow-indicator
        role="tablist"
      >
        <q-tab v-for="tab in tabs" :key="tab" :class="[{ completed: completed.includes(tab) }, color]" :label="tab"
               :name="tab" role="tab">
          <span class="compleated-icon material-icons-outlined">done</span>
        </q-tab>
      </q-tabs>

      <div>
        <q-tab-panels
          v-model="tab"
          class="pathwayTabs"
          transition-next="scale"
          transition-prev="scale"
        >
          <q-tab-panel v-for="tab in tabs" :key="tab" :name="tab" role="tabpanel">
            <div class="slot-holder">
              <slot ref="stepForm" :name="tab"></slot>
            </div>

            <div v-if="tab === 1" ref="saveBtn" class="row saveBtn" style="margin-bottom: 1vh">
              <div class="col-7"></div>
              <div class="col-5 text-right">
                <q-btn :disable="isOffline || isLocked" :loading="loading"
                       class="no-outline no-shadow full-width q-btn--standard q-btn--rectangle text-white" label="Next"
                       rounded unelevated @click="$emit('validate', true)"/>
              </div>
            </div>

            <div v-else>
              <div ref="saveBtn" class="row saveBtn">
                <div class="col-5">
                  <q-btn class="float-left q-btn--standard" label="Previous" outline rounded
                         @click="$emit('prev')"/>
                </div>
                <div class="col-7">
                  <q-btn :class="completeClass" :disable="isOffline || isLocked" :label="nextBtnLabel"
                         :loading="loading" class="float-right q-btn--standard text-white" rounded
                         unelevated @click="validate"/>
                </div>
              </div>
            </div>

          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>

    <div v-else class="current-pathway">
      <div v-for="tab in tabs" :key="tab">
        <slot ref="stepForm" :name="tab"></slot>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import showLock from '@/mixins/showLock'
import { calculatePp } from '@/store/utils/ppCalculation'

export default {
  name: 'Step',
  mixins: [showLock],
  props: {
    tabs: Number,
    changeTabTo: Number,
    completed: Array,
    color: {
      type: String,
      default () {
        return ''
      }
    },
    loading: {
      type: Boolean,
      default () {
        return false
      }
    },
    readOnly: {
      type: Boolean,
      default () {
        return false
      }
    },
    tense: {
      type: String,
      default () {
        return ''
      }
    },
    category: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      tab: this.changeTabTo
    }
  },
  computed: {
    ...mapGetters(['isOffline', 'pathwayPlan']),
    nextBtnLabel () {
      return this.tab === this.tabs ? 'Complete section' : 'Next'
    },
    completeClass () {
      return this.tab === this.tabs ? 'complete-tab' : ''
    },
    completeBtn () {
      return this.tab === this.tabs
    }
  },
  methods: {
    validate () {
      if (this.completeBtn && this.$parent.getTense === 'next') {
        const pp = this.pathwayPlan[this.$parent.getTense]
        if (typeof window.gtag === 'function') {
          if (calculatePp(pp).ppCalculation[this.category].percentage === 100) {
            window.gtag('event', this.$parent.$options.name + '_complete', {
              event_category: 'PathwayPlan',
              event_label: this.$parent.$options.name + ' complete'
            })
          }
        }
      }
      this.$emit('validate', true)
    }
  },
  watch: {
    tab (tab) {
      this.$emit('stepChanged', tab)
    },
    changeTabTo (tab) {
      this.tab = tab
    }
  }
}
</script>

<style>
.profile-tabs .q-focus-helper {
  display: none;
}
</style>
