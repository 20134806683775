<template>
  <q-dialog v-model="modalOpen" role="region" aria-label="Notification" @show="showModal()" @hide="hideModal()">
    <q-card class="round-dialog">
      <q-card-section class="text-center popup-title">
        <span class="q-ml-sm"><i class="fas fa-bell" style="color: #fe892e"></i> Notifications</span>
      </q-card-section>
      <q-card-section class="row items-center">
        <span class="q-ml-sm text-center">Turn on notifications to receive important messages</span>
      </q-card-section>

      <q-card-actions class="text-center" style="padding-top: 2.5vh">
        <q-btn flat label="Cancel" class="blue" @click="dismiss()" v-close-popup/>
        <q-btn label="Turn on" class="q-btn--standard text-white push-notification-ok" unelevated rounded @click="ok()"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import trapModalFocus from '@/mixins/trapModalFocus'

export default {
  name: 'PushNotificationsPrompt',
  computed: {
    ...mapGetters(['pushNotification', 'currentUser'])
  },
  mixins: [trapModalFocus],
  data () {
    return {
      modalOpen: false
    }
  },
  mounted () {
    this.checkPushNotificationsStatus()
  },
  methods: {
    ...mapActions(['setWebPush']),
    ...mapMutations(['dismissPushNotification']),
    ok () {
      this.modalOpen = false
      this.setWebPush(true)
    },
    dismiss () {
      this.modalOpen = false
      this.dismissPushNotification()
    },
    checkPushNotificationsStatus () {
      setTimeout(() => {
        if (typeof this.pushNotification === 'object') {
          if (!this.pushNotification.endpoint && !this.pushNotification.dismissed) {
            this.modalOpen = true
          }
        }
      }, 1000)
    }
  },
  watch: {
    pushNotification: {
      handler () {
        this.checkPushNotificationsStatus()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
