<template>
  <div>
    <list-contacts :contacts="shareContacts" :chevron="false">
      <template v-slot="{ contact }">
        <q-item-section side>
          <div class="bookmark-icon" v-if="!ypContactIDs.includes(contact.id)" @click.stop="addToShareList(contact)" @keyup.enter.stop="addToShareList(contact)">
            <i :class="{ fas: shareList.includes(contact.id), far: !shareList.includes(contact.id), 'fa-check-circle': shareList.includes(contact.id), 'fa-circle': !shareList.includes(contact.id) }"></i>
          </div>
          <div v-else class="bookmark-icon bookmark-icon-grey">
            <i class="fas fa-check-circle"></i>
          </div>
        </q-item-section>
      </template>
    </list-contacts>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import ListContacts from '@/components/Utils/ListContacts'
import api from '@/api'
import { Loading } from 'quasar'

export default {
  name: 'ShareContacts',
  components: { ListContacts },
  computed: {
    ...mapGetters(['shareContacts', 'currentYp', 'currentUser', 'canBeSharedContacts', 'ypContactIDs'])
  },
  data () {
    return {
      shareList: []
    }
  },
  methods: {
    ...mapMutations(['setCntSelected', 'clearSearch', 'pushYpContacts']),
    addToShareList (contact) {
      if (this.shareList.includes(contact.id)) {
        this.shareList.splice(this.shareList.indexOf(contact.id), 1)
      } else {
        this.shareList.push(contact.id)
      }
    },
    shareSelected () {
      if (this.shareList.length) {
        const data = {
          token: this.currentUser.user_token,
          yp_ids: this.currentYp.id,
          contact_id: this.shareList,
          type: 'contact'
        }
        Loading.show()
        const sharedContacts = this.canBeSharedContacts.filter(contact => this.shareList.includes(contact.id))
        api.shareContact(data).then(() => {
          this.clearSearch()
          this.setCntSelected(0)
          this.pushYpContacts(sharedContacts)
          this.$router.push({ name: 'YpContacts' })
        })
      }
    }
  },
  mounted () {
    this.$root.$on('contacts::share', this.shareSelected)
  },
  watch: {
    shareList (selected) {
      this.setCntSelected(selected.length)
    }
  },
  beforeDestroy () {
    this.$root.$off('contacts::share', this.shareSelected)
    this.setCntSelected(0)
    this.clearSearch()
  }
}
</script>

<style scoped>

</style>
