<template>
  <div class="cards-holder" v-if="filteredQuickContacts.length">
    <div class="q-pa-md row items-start q-gutter-md cards-holder-padding-bottom">
      <q-virtual-scroll virtual-scroll-horizontal :items="filteredQuickContacts">
        <template v-slot="{ item, index }">
          <q-card :key="index" class="text-center scrolling-card">
            <div @click="goToContact(item)" @keyup.enter="goToContact(item)" tabindex="0">
              <q-card-actions align="right">
                <div @click.stop="!isOffline && toggleQuickContact(item)" @keyup.enter.stop="!isOffline && toggleQuickContact(item)" class="bookmark-icon" tabindex="0" aria-label="toggle bookmark" role="button"><i class="fas fa-bookmark"></i>
                </div>
              </q-card-actions>
              <q-card-section>
                <div class="card-avatar">
                  <q-avatar size="6.41vh">
                    <img v-if="typeof item.avatar === 'string' && item.avatar.length" :src="item.avatar" alt="avatar">
                    <img v-else :src="avatar" alt="avatar">
                  </q-avatar>
                </div>
                <div class="card-text">
                  <h2>{{ item.firstName }} {{ item.lastName }}</h2>
                  <p>{{ item.phone }}</p>
                </div>
              </q-card-section>
            </div>
          </q-card>
        </template>
      </q-virtual-scroll>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import avatar from '@/assets/av.png'

export default {
  name: 'QuickContacts',
  props: {
    filterCount: {
      type: Number,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      avatar
    }
  },
  computed: {
    ...mapGetters(['quickContacts', 'isOffline']),
    filteredQuickContacts () {
      if (this.filterCount && this.quickContacts) {
        return this.quickContacts.slice(0, this.filterCount)
      }
      return this.quickContacts
    }
  },
  methods: {
    ...mapActions(['toggleQuickContact']),
    ...mapMutations(['setCurrentContact']),
    goToContact (contact) {
      if (['Contacts', 'YpContacts'].includes(this.$route.name)) {
        this.setCurrentContact(contact)
        this.$router.push({ name: this.$route.name, query: { id: contact.id } })
      } else {
        this.$router.push({ name: 'Contacts', query: { id: contact.id } })
      }
    }
  }
}
</script>

<style scoped>
  .cards-holder .q-card {
    margin-top: 4px;
    margin-left: 4px;
  }
</style>
