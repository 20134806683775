<template>
  <q-header class="contacts-header tasks-header" v-if="['Contacts'].includes($route.name)">
    <q-toolbar v-if="currentContact">
      <div class="col-3">
        <q-btn @click="goBack()" aria-label="back" role="button" flat round dense icon="arrow_back_ios"/>
      </div>
      <div class="col-6 text-center">
        <q-toolbar-title class="text-large" role="heading" aria-level="1">Contact info</q-toolbar-title>
      </div>
      <div class="col-3 text-right">
        <q-btn :disable="isOffline" v-if="currentContact.isMyContact" @click="$root.$emit('contact::edit')" class="delete" flat round dense
               icon="edit" role="button" aria-label="edit contact"/>
        <q-btn :disable="isOffline" v-if="currentContact.isMyContact" @click="$root.$emit('contact::delete')" class="delete" flat round
               dense icon="delete" role="button" aria-label="delete contact"/>
      </div>
    </q-toolbar>
    <q-toolbar v-else>
      <div class="col-4">
        <q-btn
          flat
          dense
          round
          @click="toggleAppDrawer()"
          aria-label="Menu"
          icon="fas fa-bars"
          style="margin-left: -0.5vh"
        />
      </div>
      <div class="col-4 text-center">
        <q-toolbar-title class="text-large" role="heading" aria-level="1">Contacts</q-toolbar-title>
      </div>
      <div class="col-4 text-right header-right">
        <q-btn @click="$root.$emit('contact::showSearch')" flat dense icon="search" role="button" aria-label="search"/>
        <q-btn :disable="isOffline" flat dense icon="person_add" @click="$root.$emit('contact::add')" role="button" aria-label="add contact"/>
        <!-- <q-btn class="search-item" flat dense icon="fas fa-search"/> -->
      </div>
    </q-toolbar>
  </q-header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppHeaderContacts',
  computed: {
    ...mapGetters(['appDrawerOpen', 'currentUser', 'currentContact', 'isOffline'])
  },
  methods: {
    ...mapMutations(['toggleAppDrawer', 'setCurrentContact']),
    goBack () {
      this.setCurrentContact(false)
      this.$router.push({ name: 'Contacts' })
    }
  },
  beforeDestroy () {
    this.$root.$off('contact::showInfo')
  }
}
</script>
