<template>
  <div class="yp-select">
    <q-select
      ref="ypMultiSelect"
      v-model="model"
      outlined
      use-input
      use-chips
      multiple
      input-debounce="0"
      :options="options"
      @filter="filterFn"
      behavior="menu"
      class="chips-select"
      :option-label="(item) => item === null ? 'Null value' : item.name"
      :option-value="(item) => item === null ? null : item.id"
      placeholder="@Type Name"
      :label="labelId"
      @keyup.enter="triggerDropdown"
      @keydown.tab="switchFocusedToMenu"
      @keyup.tab="switchFocusedToMenu"
      @add="switchFocused"
      @remove="switchFocused"
    >
      <template v-slot:after>
        <q-icon class="cursor-pointer" @click.stop="triggerDropdown" tabindex="0"
                @keyup.enter="triggerDropdown()"/>
      </template>
      <template v-slot:selected-item="scope">
        <q-chip
          removable
          dense
          @remove="scope.removeAtIndex(scope.index)"
          :tabindex="scope.tabindex"
          color="white"
          text-color="secondary"
          class="q-ma-none"
        >
          <q-avatar v-if="scope.opt.avatar.length" color="secondary" text-color="white">
            <img :src="scope.opt.avatar" alt="avatar">
          </q-avatar>
          <q-avatar v-else color="primary" text-color="white" icon="person"/>
          {{ scope.opt.name }}
        </q-chip>
      </template>
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps"
                v-on="scope.itemEvents"
                @keyup.esc="closeMenu"
        >
          <q-item-section>{{ scope.opt.name }}</q-item-section>
          <q-item-section avatar>
            <q-icon v-if="scope.selected" name="checked"></q-icon>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<style>
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'YpMultiSelect',
  props: {
    value: {
      default () {
        return []
      },
      type: Array
    },
    labelId: {
      default () {
        return ''
      },
      type: String
    }
  },
  data () {
    return {
      options: [],
      model: null,
      allOptions: []
    }
  },
  computed: {
    ...mapGetters(['myCaseLoad'])
  },
  methods: {
    filterFn (val, update, abort) {
      if (val.length < 1) {
        abort()
        return
      }
      update(() => {
        if (val === '') {
          this.options = this.allOptions
        } else {
          const needle = val.toLowerCase().replace(/^@/g, '')
          this.options = this.allOptions.filter(
            v => v.name.toLowerCase().indexOf(needle) > -1
          )
        }
      })
    },
    triggerDropdown (e) {
      if (e) {
        e.preventDefault()
      }
      if (!this.$refs.ypMultiSelect.menu) {
        this.$refs.ypMultiSelect.filter('@')
        this.$refs.ypMultiSelect.showPopup()
        setTimeout(() => {
          this.$refs.ypMultiSelect.$refs.content.setAttribute('tabindex', 0)
        }, 300)
      } else {
        this.$refs.ypMultiSelect.hidePopup()
      }
    },
    switchFocused (item) {
      try {
        if (this.$refs.ypMultiSelect.$refs.content) {
          const idx = this.allOptions.map(o => o.id).indexOf(item.value.id)
          if (this.$refs.ypMultiSelect.$refs.content.children) {
            this.$refs.ypMultiSelect.$refs.content.children.item(idx).focus()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    switchFocusedToMenu (e) {
      try {
        if (document.activeElement.tagName.toLowerCase() === 'input' && document.activeElement.value.length) {
          e.preventDefault()
          this.$refs.ypMultiSelect.showPopup()
          if (this.$refs.ypMultiSelect.$refs.content.children) {
            this.$refs.ypMultiSelect.$refs.content.children.item(0).focus()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    closeMenu () {
      this.$refs.ypMultiSelect.filter('')
      this.$refs.ypMultiSelect.focus()
      setTimeout(() => {
        this.$refs.ypMultiSelect.hidePopup()
      }, 100)
    }
  },
  watch: {
    myCaseLoad (value) {
      this.options = value.map(yp => {
        return {
          id: yp.id,
          name: yp.firstName + ' ' + yp.lastName,
          avatar: yp.avatar
        }
      })
      this.allOptions = this.options
    },
    model (value) {
      this.$emit('input', value)
    },
    value (value) {
      this.model = value
    }
  },
  mounted () {
    this.options = this.myCaseLoad.map(yp => {
      return {
        id: yp.id,
        name: yp.firstName + ' ' + yp.lastName,
        avatar: yp.avatar
      }
    })
    this.allOptions = this.options
    this.model = this.value
  },
  updated () {
    if (document.querySelectorAll('.q-icon.cursor-pointer').length) {
      document.querySelectorAll('.q-icon.cursor-pointer').forEach(element => {
        element.removeAttribute('aria-hidden')
        element.removeAttribute('role')
      })
    }
    if (document.querySelector('.q-menu[role="listbox"]')) {
      document.querySelector('.q-menu[role="listbox"]').setAttribute('aria-label', 'options')
    }
    if (document.querySelector('.yp-select .q-select input[type="search"]')) {
      document.querySelector('.yp-select .q-select input[type="search"]').setAttribute('title', 'young persons')
    }
  }
}
</script>

<style>
.yp-select .q-select .q-field__input--padding {
  opacity: 1 !important;
}
</style>
