<template>
  <div>
    <vue-title title="App settings"></vue-title>
    <div class="contacts-holder list-view settings-list">
      <div class="list-contacts-holder">
        <q-list separator>
          <q-item :to="{ name: link.to }" clickable v-ripple v-for="(link, idx) in links" :key="idx">
            <q-item-section style="text-transform: uppercase">
              {{ link.label }}
            </q-item-section>
            <q-item-section side><span class="material-icons-outlined" aria-label="forward arrow" role="button">arrow_forward_ios</span></q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppSetting',
  data () {
    return {
      links: [
        /* {
          label: 'Profile settings',
          to: 'ProfileSettings'
        }, */
        {
          label: 'Notification settings',
          to: 'NotificationSettings'
        }
        /* ,
        {
          label: 'Language',
          to: 'Language'
        },
        {
          label: 'Accessibility',
          to: 'Accessibility'
        } */
      ]
    }
  },
  computed: {
    ...mapGetters(['currentYp'])
  },
  methods: {
    ...mapMutations(['clearYp'])
  },
  mounted () {
    console.log('mounted')
    // if (!this.currentYp) {
    //   this.$router.push({ name: 'Home' })
    // } else {
    //   this.$q.loading.hide()
    // }
  }
}
</script>

<style scoped>

</style>
