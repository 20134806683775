<template>
  <div>
    <div class="tasks-content" :class="color" v-if="tasks.length">
      <div class="q-gutter-y-md">
        <q-tabs
          v-model="tab"
          dense
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
          role="tablist"
        >
          <q-tab name="othersTasks" label="Caseload tasks" aria-label="Caseload tasks" role="tab"/>
          <q-tab name="myTasks" label="My tasks" aria-label="My tasks" role="tab"/>
        </q-tabs>

        <notification></notification>
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel role="tabpanel" name="othersTasks" :keep-alive="true" :keep-alive-include="['FilterTasks', 'YpMultiSelect', 'QSelect']">
            <filter-tasks @filterTasks="filterCaseloadTasks = $event" type="othersTasks" :tasks="caseloadTasks" :show-assigned-from="false" :show-assigned-to="true"></filter-tasks>
            <q-btn :disable="isOffline" @click="assignModalOpen = true" class="add blue custom-add" flat><span class="plus-icon material-icons-outlined">add</span> Assign Task</q-btn>
          </q-tab-panel>

          <q-tab-panel role="tabpanel" name="myTasks" :keep-alive="true" :keep-alive-include="['FilterTasks', 'YpMultiSelect', 'QSelect']">
            <filter-tasks @filterTasks="filterMyTasks = $event" type="myTasks" :tasks="myTasks"></filter-tasks>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
    <div class="empty-task" v-else>
      <div class="q-pa-md">
      <p class="text-center" aria-label="Here are the tasks that you have to assigned to your young people. You can also see any tasks assigned to you.">Here are the tasks that you have to assigned to your young people. You can also see any tasks assigned to you.</p>
      <q-btn :disable="isOffline" @click="assignModalOpen = true" class="add blue custom-add" flat aria-label="assign task" role="button"><span class="plus-icon material-icons-outlined">add</span> Assign Task</q-btn>
      <div class="no-tasks-bg"></div>
      </div>
    </div>
    <assign-task v-model="assignModalOpen" :emit="false"></assign-task>
  </div>
</template>

<script>
import Notification from '@/components/Utils/Notification'
import AssignTask from '@/components/Tasks/AssignTask'
import FilterTasks from './FilterTasks'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'TasksTabsSw',
  props: {
    tasks: {
      default () {
        return []
      },
      type: Array
    },
    color: { // the color for the left margin, checkbox etc, tabs.
      type: String,
      default () {
        return ''
      }
    }
  },
  components: {
    Notification,
    AssignTask,
    FilterTasks
  },
  data () {
    return {
      tab: 'myTasks',
      notifyTaskCompletion: false,
      notifyAssignedTask: false,
      assignModalOpen: false,
      filterCaseloadTasks: false,
      filterMyTasks: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'caseloadIds', 'myCaseLoad', 'isOffline']),
    caseloadTasks () {
      let tasks = []
      if (this.tasks) {
        tasks = this.tasks.filter(task => {
          return task.type !== 'event' && this.caseloadIds.includes(task.assignedTo.id)
        })
      }
      return this.filterSortTasks(tasks, this.filterCaseloadTasks, 'caseloadTasks')
    },
    myTasks () {
      let tasks = []
      if (this.tasks) {
        tasks = this.tasks.filter(task => {
          return task.type !== 'event' && task.assignedTo.id === this.currentUser.user_id
        })
      }
      return this.filterSortTasks(tasks, this.filterMyTasks, 'filterMyTasks')
    }
  },
  methods: {
    filterSortTasks (tasks, options, type = null) {
      if (options) {
        if (options.status && options.status !== 'all') {
          tasks = tasks.filter(task => task.status === options.status)
        }
        if (options.yps.length) {
          if (type === 'filterMyTasks') {
            tasks = tasks.filter(task => options.yps.includes(task.assignedBy.id))
          } else {
            tasks = tasks.filter(task => options.yps.includes(task.assignedTo.id))
          }
        }
        if (options.sortby && options.sortby !== 'Default') {
          tasks = tasks.sort((a, b) => options.sortby === 'Newest first' ? moment(b.dueDate).unix() - moment(a.dueDate).unix() : moment(a.dueDate).unix() - moment(b.dueDate).unix())
        } else {
          tasks = [].concat(tasks.filter(task => task.status === 'overdue').sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix()), tasks.filter(task => task.status === 'upcoming').sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix()), tasks.filter(task => task.status === 'completed').sort((a, b) => moment(b.dueDate).unix() - moment(a.dueDate).unix()))
        }
      } else {
        tasks = [].concat(tasks.filter(task => task.status === 'overdue').sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix()), tasks.filter(task => task.status === 'upcoming').sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix()), tasks.filter(task => task.status === 'completed').sort((a, b) => moment(b.dueDate).unix() - moment(a.dueDate).unix()))
      }
      return tasks
    }
  }
}
</script>
