/*eslint-disable */
import QField from 'quasar/src/components/field/QField.js';

export default {
  extends: QField,
  name: 'QField',
  computed: {
    attrs () {
      const attrs = {}
      if (this.fieldClass !== 'q-input') {
        attrs.for = this.targetUid
      }
      if (this.disable === true) {
        attrs['aria-disabled'] = 'true'
      }
      else if (this.readonly === true) {
        attrs['aria-readonly'] = 'true'
      }
      return attrs
    }
  },
  render (h) {
    this.__onPreRender !== void 0 && this.__onPreRender()
    this.__onPostRender !== void 0 && this.$nextTick(this.__onPostRender)

    return h('div', {
      staticClass: 'q-field row no-wrap items-start',
      class: this.classes,
      attrs: this.attrs
    }, [
      this.$scopedSlots.before !== void 0 ? h('div', {
        staticClass: 'q-field__before q-field__marginal row no-wrap items-center',
        on: this.slotsEvents
      }, this.$scopedSlots.before()) : null,

      h('div', {
        staticClass: 'q-field__inner relative-position col self-stretch'
      }, [
        h('div', {
          ref: 'control',
          staticClass: 'q-field__control relative-position row no-wrap',
          class: this.contentClass,
          attrs: { tabindex: -1 },
          on: this.controlEvents
        }, this.__getContent(h)),

        this.shouldRenderBottom === true
          ? this.__getBottom(h)
          : null
      ]),

      this.$scopedSlots.after !== void 0 ? h('div', {
        staticClass: 'q-field__after q-field__marginal row no-wrap items-center',
        on: this.slotsEvents
      }, this.$scopedSlots.after()) : null
    ])
  },
  mounted () {
    if (this.$attrs.title) {
      this.$el.querySelectorAll('input[type="file"]').forEach(el => {
        el.title = this.$attrs.title
      })

      if (this.$el.querySelector('.q-file__filler')) {
        this.$el.querySelector('.q-file__filler').setAttribute('tabindex', '-1')
        this.$el.querySelector('.q-file__filler').setAttribute('aria-labelledby', this.$attrs.title)
      }
    }
  }
}
