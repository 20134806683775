<template>
  <div>
    <q-dialog id="assign-task-modal" v-model="modalOpen" position="bottom" class="brent-dialog" @hide="closeModal()" role="region" :aria-label="!viewTask && selectedTask ? 'Edit task' : (viewTask ? 'View task' : (toType === 'someone' ? 'Assign task' : 'Assign task to me'))" @show="showModal()">
      <lock v-model="isLocked" @closeModal="modalOpen = false" :lock-is-on="'task'"></lock>
      <q-card v-if="!isLocked" :class="['brent-dialog-content assign-task-modal', { 'hide': hide }]">
        <q-card-actions align="left" class="popup-header">
          <q-btn flat label="Cancel" v-close-popup aria-label="cancel" id="cancelAssignTask"/>
          <div v-if="toType !== false" class="text-h6 text-center">
            {{ !viewTask && selectedTask ? 'Edit task' : (viewTask ? 'View task' : (toType === 'someone' ? 'Assign task' : 'Assign task to me')) }}
          </div>
          <q-btn v-if="showEdit && !isOffline" class="right-button" flat label="Edit" @click="switchToEditMode(true)" :ripple="false"/>
          <q-btn v-if="!viewTask && selectedTask" class="right-button text-color-red" flat label="Delete" @click="deleteConfirm = true" :ripple="false"/>
        </q-card-actions>
        <q-card-section class="q-pt-none">
          <q-form @submit="false" :class="{ 'disabled-form': loading }" v-if="toType" ref="assignTaskForm">
            <div>
              <div v-if="toType === 'someone'" class="form-row">
                <div class="row">
                  <div class="col-3 text-left">
                    <q-avatar class="edit-profile-avatar" size="8.88vh">
                      <img
                        v-if="assignedUser !== null && typeof assignedUser.avatar === 'string' && assignedUser.avatar.length" :src="assignedUser.avatar" alt="avatar">
                      <img v-else :src="someoneAvatar" alt="avatar">
                    </q-avatar>
                  </div>
                  <div class="col-9">
                    <div class="input-wrapp">
                      <label for="assignee" class="custom-l">Assignee</label>
                      <q-select
                        v-if="!currentYp && !(loading || isOffline)"
                        use-input
                        v-model="assignUserName"
                        outlined
                        input-debounce="0"
                        placeholder="@Type name"
                        :options="options"
                        @filter="filterFn"
                        :rules="[val => val !== null && val !== '' || 'Please select the assignee']"
                        :disable="loading"
                        behavior="menu"
                        label="assignee"
                        ref="assigneeDropdown"
                        @popup-hide="resetAssigneeDropdown"
                        @popup-show="changeCarretStatus"
                        @keyup.enter="triggerDropdown"
                        @keydown.tab="switchFocusedToMenu"
                        @keyup.tab="switchFocusedToMenu"
                        @add="$refs.assigneeDropdown.hidePopup()"
                        class="assignUserSelect"
                        for="assignee"
                      >
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps"
                                  v-on="scope.itemEvents"
                                  @keyup.esc="closeMenu"
                          >
                            {{ scope.opt }}
                          </q-item>
                        </template>
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">
                              No results
                            </q-item-section>
                          </q-item>
                        </template>
                        <template v-slot:after>
                          <q-icon class="cursor-pointer" @click="triggerDropdown" tabindex="0"
                                  @keyup.enter="triggerDropdown()"/>
                        </template>
                      </q-select>
                      <div style="padding-left: 20px" v-else tabindex="0">{{ assignUserName }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="row">
                  <div class="col-7">
                    <div class="input-wrapp">
                      <label for="dueDate" class="custom-l">Due date</label>
                      <modal-date
                        v-model="dueDate"
                        error-msg="Please select the due date"
                        :min-date="selectedTask.status !== 'completed' ? minDate : false"
                        placeholder="Set task date"
                        class="date-input due-date"
                        :read-only="loading || isOffline"
                        label-id="dueDate"
                        @triggerBehindModalShow="triggerBehindModalShow"
                      >
                      </modal-date>
                    </div>
                  </div>
                  <div class="col-5" v-if="viewTask && selectedTask.status">
                    <div class="status-box">
                      <span :class="[ selectedTask.status ]" class="card-label">{{ selectedTask.status }}</span>
                      <span v-if="selectedTask.status !== 'completed'" class="text">{{ taskTimeDiff.days === 0 ? 'Today' : taskTimeDiff.days + ' days' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row textarea-row">
                <div class="q-gutter-y-md column">
                  <div class="input-wrapp">
                    <label for="description" class="custom-l">Description</label>
                    <q-input
                      type="textarea"
                      outlined
                      placeholder="Add description"
                      v-model.trim="description"
                      :readonly="loading"
                      :rules="[val => val.trim() !== null && val.trim() !== '' || 'Please add a description']"
                      for="description"
                    />
                  </div>
                </div>
              </div>
              <div class="assign-badges-holder" v-if="['SW', 'PA'].includes(currentUser.userType) && toType === 'someone'">
                <assign-badges v-model="assignedBadges" :single="true" :disabled="loading" :emit="true" @triggerBehindModalShow="triggerBehindModalShow"></assign-badges>
              </div>
              <div class="pupup-button">
                <q-btn v-if="!viewTask && !selectedTask && !isOffline" :disable="disableAssign" @click="assignTask" class="float-right q-btn--standard full-width"
                       unelevated rounded
                       :loading="loading"
                       color="primary"
                       label="Assign task"/>
                <q-btn v-if="showMarkCompletedBtn && !isOffline" :disable="disableAssign" class="float-right q-btn--standard full-width"
                       unelevated rounded
                       color="primary"
                       :label="'Mark as ' + (selectedTask.status === 'completed' ? 'incompleted' : 'completed')"
                       @click="markCompleted"
                />
                <q-btn v-if="!viewTask && selectedTask && !isOffline" :disable="disableAssign" @click="editTask()"
                       class="float-right q-btn--standard full-width"
                       unelevated rounded
                       color="primary"
                       :loading="loading"
                       label="Save changes"/>
              </div>
            </div>
          </q-form>
          <div v-if="!toType" class="toType-box">
            <div class="form-row">
              <div class="text-h6 text-center">Who do you want to assign this task to?</div>
              <div class="row avatar-row">
                <div class="col text-center">
                  <q-avatar @click="toType = 'me'" class="task-avatar" size="8.88vh">
                    <img :src="avatar" alt="avatar">
                  </q-avatar>
                </div>
                <div class="col-1"></div>
                <div class="col text-center">
                  <q-avatar @click="toType = 'someone'" class="task-avatar" size="8.88vh">
                    <img :src="someoneAvatar" alt="avatar">
                  </q-avatar>
                </div>
              </div>
              <div class="row btns-row" ref="saveBtn">
                <div class="col">
                  <q-btn @click="toType = 'me'" class="float-left q-btn--standard green" outline rounded label="Me"/>
                </div>
                <div class="col-1"></div>
                <div class="col">
                  <q-btn @click="toType = 'someone'" class="float-right q-btn--standard" unelevated rounded
                         color="primary"
                         label="Someone else"/>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="deleteConfirm" @hide="deleteConfirm = false" >
      <q-card class="round-dialog">
        <q-card-section v-if="value">
          <div class="text-h6 text-center">Are you sure you want to remove this task?</div>
        </q-card-section>
        <q-card-actions class="text-center">
          <q-btn class="blue" flat label="Yes" v-close-popup @click="deleteSelectedTask"/>
          <q-btn color="primary" rounded label="No" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import ModalDate from '../Utils/ModalDate'
import moment from 'moment'
import avatar from '@/assets/av.png'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AssignBadges from './AssignBadges'
import { Loading } from 'quasar'
import addLocks from '@/mixins/addLocks'
import removeLocks from '@/mixins/removeLocks'
import Lock from '@/components/Utils/Lock'
import focusLabel from '@/mixins/focusLabel'

export default {
  name: 'AssignTask',
  components: { Lock, ModalDate, AssignBadges },
  mixins: [addLocks, removeLocks, focusLabel],
  props: {
    value: {
      default: false,
      type: Boolean
    },
    emit: {
      default: true,
      type: Boolean
    },
    type: {
      default: 'task',
      type: String
    },
    ppId: {
      default: null,
      type: String
    },
    section: {
      default: null,
      type: String
    },
    yp: {
      default: false
    },
    taskAssignedMsg: {
      default: null,
      type: String
    }
  },
  data () {
    return {
      modalOpen: false,
      minDate: moment().format('YYYY/MM/DD'),
      toType: false,
      someoneAvatar: avatar,
      assignUserName: null,
      options: [],
      description: '',
      dueDate: '',
      loading: false,
      assignBadgesOpen: false,
      assignedBadges: [],
      viewTask: false,
      deleteConfirm: false,
      editMode: false,
      isLocked: false,
      caretStatus: 'down',
      hide: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'userGeneralInfo', 'assigned', 'currentYp', 'allMyCaseLoad', 'selectedTask', 'isOffline']),
    disableAssign () {
      return this.description.trim().length === 0 || this.dueDate.length === 0 || (this.toType === 'someone' ? this.assignUserName === null : false) || this.isOffline
    },
    avatar () {
      return typeof this.userGeneralInfo.avatar === 'string' && this.userGeneralInfo.avatar.length ? this.userGeneralInfo.avatar : avatar
    },
    assignedUserNames () {
      if (['SW', 'PA'].includes(this.currentUser.userType)) {
        if (this.allMyCaseLoad) {
          return this.allMyCaseLoad.map(user => (user.firstName + ' ' + user.lastName))
        }
      } else {
        if (this.assigned) {
          return this.assigned.map(user => user.user_name)
        }
      }
      return []
    },
    assignedUser () {
      const getUser = () => {
        if (['SW', 'PA'].includes(this.currentUser.userType)) {
          const user = this.allMyCaseLoad.filter(user => (user.firstName + ' ' + user.lastName) === this.assignUserName)
          if (user.length) {
            const u = Object.assign({}, user[0])
            u.name = u.firstName + ' ' + u.lastName
            u.user_id = u.id
            u.role = 'YP'
            return u
          }
        } else {
          const user = this.assigned.filter(user => user.user_name === this.assignUserName)
          if (user.length) {
            const u = Object.assign({}, user[0])
            u.name = u.user_name
            if (!u.user_id && u.id) {
              u.user_id = u.id
            }
            return u
          }
        }
        return null
      }
      if (this.selectedTask) {
        if (this.assignUserName !== this.selectedTask.assignedTo) {
          return getUser()
        }
        if (typeof this.selectedTask.assignedTo === 'object') {
          return this.selectedTask.assignedTo
        }
        return null
      } else {
        if (this.currentYp) {
          return Object.assign({ user_id: this.currentYp.id, user_name: this.currentYp.firstName + ' ' + this.currentYp.lastName }, this.currentYp)
        } else {
          if (this.toType === 'someone' && this.assignedUserName !== null) {
            return getUser()
          }
          return null
        }
      }
    },
    taskTimeDiff () {
      if (this.selectedTask && ['overdue', 'upcoming'].includes(this.selectedTask.status)) {
        const diff = moment.duration(this.selectedTask.status === 'overdue' ? moment().diff(moment(this.selectedTask.due_date, 'YYYY-MM-DD').endOf('day')) : moment(this.selectedTask.due_date, 'YYYY-MM-DD').endOf('day').diff(moment()))
        return {
          days: diff.days(),
          hours: diff.hours()
        }
      } else {
        return false
      }
    },
    showEdit () {
      if (this.viewTask && this.selectedTask && this.selectedTask.type === 'task' && this.selectedTask.status !== 'completed') {
        if (this.currentUser.userType === 'YP') {
          return this.selectedTask.assignedBy.id === this.currentUser.user_id
        } else {
          if (['SW', 'PA'].includes(this.selectedTask.assignedBy.role)) {
            return this.selectedTask.assignedBy.id === this.currentUser.user_id
          } else {
            return this.selectedTask.assignedTo.id === this.currentUser.user_id
          }
        }
      }
      return false
    },
    showMarkCompletedBtn () {
      if (this.viewTask && this.selectedTask && this.selectedTask.type === 'task') {
        if (this.currentUser.userType === 'YP') {
          return this.selectedTask.assignedTo.id === this.currentUser.user_id
        } else {
          if (this.selectedTask.assignedTo.role === 'YP') {
            return true
          } else if (this.selectedTask.assignedTo.id === this.currentUser.user_id) {
            return true
          }
        }
      }
      return false
    }
  },
  watch: {
    value (value) {
      this.modalOpen = value
      if (!value) {
        this.editMode = false
        this.isLocked = false
      }
    },
    modalOpen (value) {
      if (!value) {
        this.toType = this.currentYp ? 'someone' : false
        this.editMode = false
        this.isLocked = false
      } else {
        if (this.currentYp) {
          this.assignUserName = this.selectedTask ? this.selectedTask.assignedTo.name : this.currentYp.firstName + ' ' + this.currentYp.lastName
        }
      }
      this.$emit('input', value)
    },
    selectedTask (task) {
      if (task) {
        this.loadTask(task)
      }
    },
    toType (value) {
      if (value === 'me' || value === 'someone') {
        window.addEventListener('keydown', this.handleKey)
        if (document.querySelector('#assign-task-modal .q-dialog__inner[tabindex="-1"]')) {
          document.querySelector('#assign-task-modal .q-dialog__inner[tabindex="-1"]').focus()
        }
      }
    }
  },
  methods: {
    ...mapActions(['saveTask', 'deleteTask', 'updateTask', 'setTaskCompleted']),
    ...mapMutations(['clearSelectedTask']),
    filterFn (val, update, abort) {
      const fnVal = this.assignUserName || val
      if (fnVal.length < 1) {
        abort()
        return
      }
      if (fnVal === '') {
        update(() => {
          this.options = this.assignedUserNames
        })
        return
      }
      update(() => {
        const needle = fnVal.toLowerCase().replace(/^@/g, '')
        this.options = this.assignedUserNames.filter(v => v.toLowerCase().indexOf(needle) > -1)
      })
    },
    getTaskData () {
      const assigneeId = this.toType === 'someone' ? this.assignedUser.user_id : this.currentUser.user_id
      const taskData = {
        assignee_id: assigneeId,
        user_id: this.currentUser.user_id,
        due_date: moment(this.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        description: this.description,
        badge: '',
        type: this.type,
        pp_id: this.ppId,
        section: this.section
      }
      if (this.assignedBadges.length) {
        taskData.badge = this.assignedBadges.join(',')
      }
      return taskData
    },
    assignTask () {
      this.$refs.assignTaskForm.validate().then(success => {
        if (success) {
          const taskData = this.getTaskData()
          const emit = () => {
            this.$root.$emit('task::assigned', taskData)
            this.$emit('task::assigned', taskData)
            this.$emit('input', false)
            if (this.taskAssignedMsg) {
              this.$root.$emit('task::notification', this.taskAssignedMsg)
            } else {
              this.$root.$emit('task::notification',
                this.toType === 'someone'
                  ? ('The task was successfully assigned to ' + this.assignUserName)
                  : ('&#128198; New Task! <br />The task was successfully assigned to you'))
            }
            this.resetData()
          }
          if (this.emit) {
            emit()
          } else {
            this.loading = true
            this.saveTask(taskData).then(() => {
              emit()
            })
          }
        }
      })
    },
    resetData () {
      this.dueDate = ''
      this.assignUserName = null
      this.description = ''
      if (!this.currentYp) {
        this.toType = false
      } else {
        this.toType = 'someone'
      }

      if (this.ppId) {
        this.toType = false
      }
      // put a condition to allow me inside pp
      this.loading = false
      this.assignedBadges = []
      this.clearSelectedTask()
      this.viewTask = false
      this.minDate = moment().format('YYYY/MM/DD')
    },
    closeModal () {
      this.modalOpen = false
      this.resetData()
      if (document.querySelector('#assign-task-modal')) {
        document.querySelector('#assign-task-modal').removeEventListener('keydown', this.handleKey)
      }
    },
    loadTask (task) {
      if (typeof task.assignedTo === 'object') {
        this.toType = task.assignedTo.id === this.currentUser.user_id ? 'me' : 'someone'
        if (this.toType === 'someone') {
          this.assignUserName = task.assignedTo.name
        }
        this.dueDate = moment(this.selectedTask.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        this.description = this.selectedTask.description
        this.minDate = moment().format('YYYY/MM/DD')
        this.viewTask = true
        this.modalOpen = true
        this.loading = true
        if (this.selectedTask.badge.id) {
          this.assignedBadges = [this.selectedTask.badge.id]
        }
      }
    },
    switchToEditMode (emitEditMode = true) {
      this.viewTask = false
      this.loading = false
      this.editMode = true
      if (emitEditMode) {
        this.$root.$emit('task::editMode')
      }
    },
    editTask () {
      this.$refs.assignTaskForm.validate().then(success => {
        if (success) {
          const taskData = this.getTaskData()
          taskData.task_id = this.selectedTask.id
          taskData.user_id = this.toType === 'someone' ? this.assignedUser.id : (this.currentYp ? this.currentYp.id : this.currentUser.user_id)
          taskData.assignedTo = this.toType === 'someone'
            ? { id: this.assignedUser.id, name: this.assignedUser.name, image: this.assignedUser.avatar, role: this.assignedUser.role }
            : (this.currentYp
              ? { id: this.currentYp.id, name: this.currentYp.firstName + ' ' + this.currentYp.lastName, image: this.currentYp.avatar, role: 'YP' }
              : { id: this.currentUser.user_id, name: this.currentUser.firstName + ' ' + this.currentUser.lastName, image: this.currentUser.avatar, role: this.currentUser.userType }
            )
          taskData.dueDate = moment(this.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          this.loading = true
          this.updateTask(taskData).then(() => {
            this.resetData()
            this.modalOpen = false
          })
        }
      })
    },
    deleteSelectedTask () {
      Loading.show()
      this.deleteTask({ task_id: this.selectedTask.id }).then(() => {
        this.resetData()
        this.modalOpen = false
        Loading.hide()
      })
    },
    markCompleted () {
      Loading.show()
      this.setTaskCompleted(this.selectedTask).then(() => {
        this.resetData()
        this.modalOpen = false
        Loading.hide()
      })
    },
    triggerDropdown () {
      try {
        if (this.$refs.assigneeDropdown.menu) {
          this.$refs.assigneeDropdown.hidePopup()
        } else {
          this.assignUserName = '@'
          this.options = this.assignedUserNames
          this.$refs.assigneeDropdown.filter('@')
          this.$refs.assigneeDropdown.showPopup()
          setTimeout(() => {
            if (this.$refs.assigneeDropdown.$refs.content.children) {
              this.$refs.assigneeDropdown.$refs.content.children.item(0).focus()
            }
          }, 100)
        }
      } catch (e) {
        console.log(e)
      }
    },
    resetAssigneeDropdown () {
      if (this.assignUserName === '@') {
        this.assignUserName = null
      }
      this.caretStatus = 'down'
    },
    changeCarretStatus () {
      this.caretStatus = 'up'
    },
    triggerBehindModalShow (frontModalVisible) {
      this.hide = frontModalVisible
    },
    showModal () {
      try {
        if (document.querySelector('#assign-task-modal')) {
          document.querySelector('#assign-task-modal').addEventListener('keydown', this.handleKey)
          this.$root.$emit('task::assignOpen')
        }
      } catch (e) {
      }
    },
    handleKey (e) {
      try {
        if (e.keyCode === 9 && this.modalOpen) {
          const focusable = document.querySelector('#assign-task-modal').querySelectorAll('input,button,select,textarea,span[tabindex="0"]')
          if (focusable.length) {
            const first = focusable[0]
            const last = (this.disableAssign && (this.toType === 'me' || this.toType === 'someone')) ? focusable[focusable.length - 2] : focusable[focusable.length - 1]
            const shift = e.shiftKey
            if (shift) {
              if (e.target === first) { // shift-tab pressed on first input in dialog
                last.focus()
                e.preventDefault()
              }
            } else {
              if (e.target === last) { // tab pressed on last input in dialog
                first.focus()
                e.preventDefault()
              }
            }
          }
        }
      } catch (e) {
      }
    },
    switchFocused (item) {
      try {
        if (this.$refs.ypMultiSelect.$refs.content) {
          const idx = this.allOptions.map(o => o.id).indexOf(item.value.id)
          if (this.$refs.assigneeDropdown.$refs.content.children) {
            this.$refs.assigneeDropdown.$refs.content.children.item(idx).focus()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    switchFocusedToMenu (e) {
      try {
        if (document.activeElement.tagName.toLowerCase() === 'input' && document.activeElement.value.length) {
          e.preventDefault()
          this.$refs.assigneeDropdown.showPopup()
          if (this.$refs.assigneeDropdown.$refs.content.children) {
            this.$refs.assigneeDropdown.$refs.content.children.item(0).focus()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    closeMenu () {
      this.$refs.assigneeDropdown.hidePopup()
    }
  },
  mounted () {
    this.$root.$on('task::assign', () => {
      this.modalOpen = true
      this.$emit('input', true)
    })
    this.$root.$on('task::takeOver', this.switchToEditMode)
    if (this.currentYp) {
      this.assignUserName = this.currentYp.firstName + ' ' + this.currentYp.lastName
      this.toType = 'someone' // put a condition to allow me inside pp
      if (this.ppId) {
        this.toType = false
      }
    }
    this.options = this.assignedUserNames
  },
  beforeDestroy () {
    this.$root.$off('task::assign')
    this.$root.$off('task::takeOver', this.switchToEditMode)
  },
  updated () {
    if (document.querySelectorAll('.q-dialog--modal .q-select input').length) {
      document.querySelectorAll('.q-dialog--modal .q-select input').forEach(element => {
        element.removeAttribute('aria-multiselectable')
      })
    }
  }
}
</script>

<style>
  .popup-header .right-button {
    position: absolute;
    left: initial;
    right: 0;
  }

  .q-field--disabled .q-field__input {
    display: none;
  }
 .assignUserSelect .fas.fa-exclamation-circle.q-icon.notranslate.text-negative {
    display: none;
  }
  .popup-header .text-color-red {
    color: #e40000;
  }
  .brent-dialog-content .input-wrapp label.custom-l.yp-pre-selected {
    border-bottom: none;
  }
  .brent-dialog-content .input-wrapp label.custom-l.focused {
    color: #03B269;
  }
  .brent-dialog-content .input-wrapp label.custom-l.invalid {
    color: red;
  }
</style>
