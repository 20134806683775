<template>
  <div>
    <div class="text-center">
      <q-btn :disable="isOffline" @click="modalOpen = true" color="primary share-button" rounded outline>Share contact</q-btn>
    </div>
    <q-dialog @before-hide="resetData" v-model="modalOpen" position="bottom" class="brent-dialog">
      <q-card class="date-card brent-dialog-content case-dialog caseLoadContent q-card share-contact-dialog">
        <q-card-actions align="left" class="popup-header">
          <q-btn flat label="Cancel" v-close-popup/>
          <div class="text-h6 text-center">Share Contact</div>
        </q-card-actions>
        <div class="text-center">
          <div class="row">
            <div class="col">
              <q-avatar size="6.41vh">
                <img v-if="typeof currentContact.avatar === 'string' && currentContact.avatar.length" :src="currentContact.avatar" alt="avatar">
                <img v-else :src="avatar" alt="avatar">
              </q-avatar>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span><b>{{ currentContact.firstName + ' ' + currentContact.lastName }}</b></span><br />
              <span style="font-size: 1.73vh">{{ currentContact.phone }}</span>
            </div>
          </div>
        </div>
        <q-form ref="shareContact">
          <div class="form-row">
            <div class="input-wrapp">
            <label class="custom-l custom-l-2" for="shareWith">Share with</label>
              <q-select
                use-input
                outlined
                v-model="shareYp"
                :options="swOption"
                use-chips
                stack-label
                multiple
                color="secondary"
                behavior="menu"
                class="chips-select"
                :disable="loading"
                :option-label="(item) => item === null ? 'Null value' : item.name"
                :rules="[val => val.length || 'Please select young person']"
                placeholder="@Type name"
                @filter="filterFn"
                for="shareWith"
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    removable
                    dense
                    @remove="scope.removeAtIndex(scope.index)"
                    :tabindex="scope.tabindex"
                    color="white"
                    text-color="secondary"
                    class="q-ma-none"
                  >
                    <q-avatar v-if="scope.opt.avatar.length" color="secondary" text-color="white">
                      <img :src="scope.opt.avatar">
                    </q-avatar>
                    <q-avatar v-else color="primary" text-color="white" icon="person"/>
                    {{ scope.opt.name }}
                  </q-chip>
                </template>
              </q-select>
            </div>
            <div class="pupup-button">
              <q-btn :disable="shareYp.length === 0" class="float-right q-btn--standard full-width" unelevated rounded color="primary" label="Share contact" :loading="loading" @click="validate()" />
            </div>
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import avatar from '@/assets/av.png'
import api from '@/api'

export default {
  name: 'ShareContact',
  data () {
    return {
      modalOpen: false,
      shareYp: [],
      avatar,
      loading: false,
      swOption: []
    }
  },
  computed: {
    ...mapGetters(['myCaseLoad', 'currentContact', 'currentUser', 'isOffline']),
    options () {
      return this.myCaseLoad.map(yp => {
        return {
          id: yp.id,
          name: yp.firstName + ' ' + yp.lastName,
          avatar: yp.avatar
        }
      })
    }
  },
  methods: {
    ...mapMutations(['setCurrentContact']),
    validate () {
      this.$refs.shareContact.validate().then(success => {
        if (success) {
          this.loading = true
          const data = {
            token: this.currentUser.user_token,
            yp_ids: this.shareYp.map(yp => yp.id).join(','),
            contact_id: this.currentContact.id,
            type: this.currentContact.type === 'service' ? 'service' : 'contact'
          }
          api.shareContact(data).then(() => {
            this.modalOpen = false
            this.$q.loading.hide()
            const contactName = this.currentContact.firstName + ' ' + this.currentContact.lastName
            const totalContacts = this.shareYp.length
            setTimeout(() => {
              this.$root.$emit('contacts::notification', 'Contact ' + contactName + ' was shared with ' + totalContacts + ' Young Person' + (totalContacts > 1 ? 's' : ''))
            }, 200)
            this.resetData()
          })
        }
      })
    },
    resetData () {
      this.shareYp = []
      this.loading = false
      this.setCurrentContact(false)
    },
    filterFn (val, update, abort) {
      console.log('filterFn', val)
      if (val === '') {
        update(() => {
          this.swOption = []
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase().replace(/^@/g, '')
        console.log('needle', needle)
        this.swOption = this.options.filter(option => option.name.toLowerCase().indexOf(needle) > -1)
      })
    }
  }
}
</script>

<style scoped>

</style>
