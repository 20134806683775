// this module is for volatile data

const state = {
  appDrawerOpen: false,
  search: '',
  cntSelected: 0,
  selectedTask: false,
  ppDueDate: null,
  documentTitle: null,
  backgroundLoading: false,
  isOffline: false
}

const getters = {
  appDrawerOpen (state) {
    return state.appDrawerOpen
  },
  searchText (state) {
    return state.search
  },
  cntSelected (state) {
    return state.cntSelected
  },
  ppDueDate (state) {
    return state.ppDueDate
  },
  selectedTask (state) {
    return state.selectedTask
  },
  documentTitle (state) {
    return state.documentTitle
  },
  backgroundLoading (state) {
    return state.backgroundLoading
  },
  isOffline (state) {
    return state.isOffline
  }
}

const actions = {
  setOffline (context, status) {
    context.commit('setOffline', status)
    if (!status) {
      context.dispatch('setupUserData')
    }
  }
}

const mutations = {
  toggleAppDrawer (state) {
    state.appDrawerOpen = !state.appDrawerOpen
  },
  closeAppDrawer (state) {
    state.appDrawerOpen = false
  },
  openAppDrawer (state) {
    state.appDrawerOpen = true
  },
  setSearch (state, data) {
    state.search = data
  },
  clearSearch (state) {
    state.search = ''
  },
  setCntSelected (state, cnt) {
    state.cntSelected = cnt
  },
  setppDueDate (state, data) {
    state.ppDueDate = data
  },
  setSelectedTask (state, task) {
    state.selectedTask = task
  },
  clearSelectedTask (state) {
    state.selectedTask = false
  },
  setDocumentTitle (state, data) {
    state.documentTitle = data
  },
  setBackgroundLoading (state, data) {
    state.backgroundLoading = data
  },
  setOffline (state, data) {
    state.isOffline = data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
