export default {
  methods: {
    handleKey (e) {
      if (e.keyCode === 9) {
        if (document.querySelector('.q-dialog.q-dialog--modal')) {
          const focusable = document.querySelector('.q-dialog.q-dialog--modal').querySelectorAll('input,button,select,textarea,span[tabindex="0"]')
          if (focusable.length) {
            const first = focusable[0]
            const last = focusable[focusable.length - 1]
            const shift = e.shiftKey
            if (shift) {
              if (e.target === first) { // shift-tab pressed on first input in dialog
                last.focus()
                e.preventDefault()
              }
            } else {
              if (e.target === last) { // tab pressed on last input in dialog
                first.focus()
                e.preventDefault()
              }
            }
          }
        }
      }
    },
    showModal () {
      document.querySelector('.q-dialog.q-dialog--modal').addEventListener('keydown', this.handleKey)
    },
    hideModal () {
      if (document.querySelector('.q-dialog.q-dialog--modal')) {
        document.querySelector('.q-dialog.q-dialog--modal').removeEventListener('keydown', this.handleKey)
      }
    }
  }
}
