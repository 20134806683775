<template>
  <div>
    <div class="text-center q-pa-md start-page-content">
      <p class="text-icons">🤗 🙌</p>
      <p class="welcome-text">Welcome onboard {{ username }}. Let's get your profile complete!</p>
      <q-btn :to="{ name: 'Profile' }" class="no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white" unelevated
             rounded color="primary" label="Complete profile"/>
      <h4 v-if="assignedSocialWorker">Meet your social worker</h4>

      <q-card class="my-card text-left" v-if="assignedSocialWorker">
        <q-card-section  >
          <div class="row">
            <div class="col-3">
              <q-avatar class="edit-profile-avatar" size="7.88vh">
                <img v-if="typeof assignedSocialWorker.avatar === 'string' && assignedSocialWorker.avatar.length" :src="assignedSocialWorker.avatar">
                <img v-else :src="avatar">
              </q-avatar>
            </div>
            <div class="col-9">
              <div class="card-title">{{ assignedSocialWorker.user_name }}</div>
              <div class="card-text">Hello {{ username }}! don’t hesitate to ask me any questions. I'm looking forward to working with you to complete your Pathway Plan.
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import avatar from '../../assets/av.png'
import { mapGetters } from 'vuex'

export default {
  name: 'YPStartPage',
  data () {
    return {
      avatar
    }
  },
  computed: {
    ...mapGetters(['userGeneralInfo', 'currentUser']),
    username () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.firstName : 'User'
    },
    assignedSocialWorker () {
      const sw = this.currentUser.assigned.filter(sw => sw.type === 'SW')
      return sw.length ? sw[0] : false
    }
  }
}
</script>

<style scoped>

</style>
