<template>
  <div>
    <q-header class="tasks-header" v-if="['PathwayPlan'].includes($route.name)">
      <q-toolbar>
        <div v-if="currentYp" class="col-2">
          <q-btn class="back-pp" flat dense round @click="goBack()" aria-label="Menu" icon="arrow_back_ios"/>
        </div>
        <div class="col-3" v-else>
          <q-btn flat dense round @click="toggleAppDrawer(currentYp)" aria-label="Menu" icon="fas fa-bars" style="margin-left: -0.5vh"/>
        </div>
        <div v-if="currentYp" class="col-10" role="heading" aria-level="1">
          <div class="user-details pp-header-details">
            <div class="avatar-text" style="margin-top: 0; font-weight: 600;">{{ currentYp.firstName + ' ' + currentYp.lastName }}</div>
            <p class="subtitle" v-if="ppDueDate">{{ ppDueDate.tense !== 'past' ? (ppDueDate.tense === 'next' ? 'needs signing by: ' + formatDate(ppDueDate.dueDate, 'YYYY-MM-DD', 'DD/MM/YYYY') : 'expires on: ' + formatDate(ppDueDate.dueDate, 'YYYY-MM-DD', 'DD/MM/YYYY')) : 'Past Plans' }}</p>
            <p class="subtitle" v-else>Pathway Plans</p>
          </div>
        </div>
        <div class="col-6 text-center" v-else role="heading" aria-level="1">
          <q-toolbar-title class="text-large">Pathway Plan</q-toolbar-title>
        </div>
        <!--<div v-if="currentUser.userType !== 'YP'" class="col-4 text-right header-right pp-header-r-details">
          <q-btn @click="$root.$emit('pp::add')" outline rounded>
            <span class="material-icons-outlined">add</span> Add Plan
          </q-btn>
        </div>-->
        <!-- <div class="col-2 text-right header-right pp-header-r-details" v-if="currentYp">
          <q-avatar class="edit-profile-avatar" size="6.4vh">
            <img v-if="currentYp.avatar.length" :src="currentYp.avatar">
            <img v-else :src="avatar">
          </q-avatar>
        </div>
        <div class="col-3 text-right header-right" v-else></div> -->
      </q-toolbar>
    </q-header>
    <q-header class="edit-allAboutMe-header about_color" v-if="['AllAboutMeNext', 'AllAboutMeCurrent'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back-pp" @click="goBack()" flat round dense icon="chevron_left" aria-label="back" role="button"/>
        </div>
        <div :class="[!['AllAboutMeCurrent'].includes($route.name) ? 'col-7' : 'col-10' ]" role="heading" aria-level="1">
          <q-toolbar-title>All about me</q-toolbar-title>
          <p class="subtitle" v-if="currentYp">{{ currentYp.firstName + ' ' + currentYp.lastName }}</p>
          <p class="subtitle" v-if="ppExpiryDate">Pathway Plan ends {{ ppExpiryDate }}</p>
        </div>
        <div class="col-3 text-right" v-if="!['AllAboutMeCurrent'].includes($route.name)">
          <q-btn :disable="isOffline || isLocked" @click="$root.$emit('save:pathwayPlanSection')" outline rounded>
            Save
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="edit-overallPlan-header overall_color" v-if="['OverallPlanNext', 'OverallPlanCurrent'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back-pp" @click="goBack()" flat round dense icon="chevron_left" aria-label="back" role="button"/>
        </div>
        <div class="text-left" :class="[!['OverallPlanCurrent'].includes($route.name) ? 'col-7' : 'col-10' ]" role="heading" aria-level="1">
          <q-toolbar-title>Overall plan</q-toolbar-title>
          <p class="subtitle" v-if="currentYp">{{ currentYp.firstName + ' ' + currentYp.lastName }}</p>
          <p class="subtitle" v-if="ppExpiryDate">Pathway Plan ends {{ ppExpiryDate }}</p>
        </div>
        <div class="col-3 text-right" v-if="!['OverallPlanCurrent'].includes($route.name)">
          <q-btn :disable="isOffline || isLocked" @click="$root.$emit('save:pathwayPlanSection')" outline rounded>
            Save
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="edit-educationEmployment-header education_color" v-if="['EducationEmploymentNext', 'EducationEmploymentCurrent'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back-pp" @click="goBack()" flat round dense icon="chevron_left" aria-label="back" role="button"/>
        </div>
        <div class="text-left" :class="[!['EducationEmploymentCurrent'].includes($route.name) ? 'col-7' : 'col-10' ]" role="heading" aria-level="1">
          <q-toolbar-title>Education, employment & training</q-toolbar-title>
          <p class="subtitle" v-if="currentYp">{{ currentYp.firstName + ' ' + currentYp.lastName }}</p>
          <p class="subtitle" v-if="ppExpiryDate">Pathway Plan ends {{ ppExpiryDate }}</p>
        </div>
        <div class="col-3 text-right" v-if="!['EducationEmploymentCurrent'].includes($route.name)">
          <q-btn :disable="isOffline || isLocked" @click="$root.$emit('save:pathwayPlanSection')" outline rounded>
            Save
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="edit-managingLiving-header managing_color" v-if="['ManagingLivingNext', 'ManagingLivingCurrent'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back-pp" @click="goBack()" flat round dense icon="chevron_left" aria-label="back" role="button"/>
        </div>
        <div class="text-left" :class="[!['ManagingLivingCurrent'].includes($route.name) ? 'col-7' : 'col-10' ]" role="heading" aria-level="1">
          <q-toolbar-title>Managing and living independently</q-toolbar-title>
          <p class="subtitle" v-if="currentYp">{{ currentYp.firstName + ' ' + currentYp.lastName }}</p>
          <p class="subtitle" v-if="ppExpiryDate">Pathway Plan ends {{ ppExpiryDate }}</p>
        </div>
        <div class="col-3 text-right" v-if="!['ManagingLivingCurrent'].includes($route.name)">
          <q-btn :disable="isOffline || isLocked" @click="$root.$emit('save:pathwayPlanSection')" outline rounded>
            Save
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="edit-healthDevelopment-header health_color" v-if="['HealthDevelopmentNext', 'HealthDevelopmentCurrent'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back-pp" @click="goBack()" flat round dense icon="chevron_left" aria-label="back" role="button"/>
        </div>
        <div class="text-left" :class="[!['HealthDevelopmentCurrent'].includes($route.name) ? 'col-7' : 'col-10' ]" role="heading" aria-level="1">
          <q-toolbar-title>Health & development</q-toolbar-title>
          <p class="subtitle" v-if="currentYp">{{ currentYp.firstName + ' ' + currentYp.lastName }}</p>
          <p class="subtitle" v-if="ppExpiryDate">Pathway Plan ends {{ ppExpiryDate }}</p>
        </div>
        <div class="col-3 text-right" v-if="!['HealthDevelopmentCurrent'].includes($route.name)">
          <q-btn :disable="isOffline || isLocked" @click="$root.$emit('save:pathwayPlanSection')" outline rounded>
            Save
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="edit-myMoney-header money_color" v-if="['MyMoneyNext', 'MyMoneyCurrent'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back-pp" @click="goBack()" flat round dense icon="chevron_left" aria-label="back" role="button"/>
        </div>
        <div class="text-left" :class="[!['MyMoneyCurrent'].includes($route.name) ? 'col-7' : 'col-10' ]" role="heading" aria-level="1">
          <q-toolbar-title>My money</q-toolbar-title>
          <p class="subtitle" v-if="currentYp">{{ currentYp.firstName + ' ' + currentYp.lastName }}</p>
          <p class="subtitle" v-if="ppExpiryDate">Pathway Plan ends {{ ppExpiryDate }}</p>
        </div>
        <div class="col-3 text-right" v-if="!['MyMoneyCurrent'].includes($route.name)">
          <q-btn :disable="isOffline || isLocked" @click="$root.$emit('save:pathwayPlanSection')" outline rounded>
            Save
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="pp-header" v-if="['PpDashboard'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn
            flat
            dense
            round
            @click="toggleAppDrawer()"
            aria-label="Menu"
            icon="fas fa-bars"
            style="margin-left: -0.5vh"
          />
        </div>
        <div class="col-8 text-center" role="heading" aria-level="1">
          <q-toolbar-title class="text-large">Pathway Plans</q-toolbar-title>
        </div>
        <!--<div class="col-2 text-right header-right">
          <q-btn @click="$root.$emit('pp::add')" flat dense icon="add"/>
        </div>-->
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from 'vuex'
import avatar from '../../assets/av.png'
import moment from 'moment'
import showLock from '@/mixins/showLock'

export default {
  name: 'AppHeaderPathwayPlan',
  mixins: [showLock],
  data () {
    return {
      avatar: avatar,
      prevIsCaseload: false
    }
  },
  computed: {
    ...mapGetters(['appDrawerOpen', 'currentYp', 'currentUser', 'ppDueDate', 'pathwayPlan', 'isOffline']),
    ppExpiryDate () {
      if (this.pathwayPlan && this.ppDueDate) {
        if (this.pathwayPlan[this.ppDueDate.tense] && this.ppDueDate.tense === 'current') {
          return this.formatDate(this.pathwayPlan[this.ppDueDate.tense].expiry_date, 'YYYY-MM-DD', 'DD/MM/YYYY')
        }
      }
      return null
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer']),
    ...mapActions(['clearYp']),
    goBack () {
      // this.clearYp()
      if (['PathwayPlan'].includes(this.$route.name) && this.currentYp) {
        const previousRoute = this.$route.params.previousRoute ? this.$route.params.previousRoute : (this.currentUser.userType === 'YP' ? 'PathwayPlan' : 'PpDashboard')
        this.$router.push({ name: previousRoute })
      } else if (['AllAboutMeNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext', 'AllAboutMeCurrent', 'OverallPlanCurrent', 'EducationEmploymentCurrent', 'ManagingLivingCurrent', 'HealthDevelopmentCurrent', 'MyMoneyCurrent'].includes(this.$route.name)) {
        const previousRoute = this.$route.params.previousRoute ? this.$route.params.previousRoute : null
        const tab = this.$route.meta.edit ? 'next' : 'current'
        this.$root.$emit('ppSection:back', { previousRoute, tab })
      }
    },
    formatDate (date, fromFormat, toFormat) {
      return moment(date, fromFormat).format(toFormat)
    }
  }
}
</script>
