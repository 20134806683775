<template>
  <div>
    <vue-title title="My options"></vue-title>
    <div class="options-template">
      <div class="tasks-content">
        <div class="q-gutter-y-md">
          <q-tabs
            v-model="tab"
            dense
            active-color="primary"
            indicator-color="primary"
            narrow-indicator
            role="tablist"
          >
            <q-tab v-if="age === null || age &lt;= 18" name="ageA" label="16-18" tabindex="0" aria-label="16-18" role="tab"/>
            <q-tab name="ageB" label="18+" tabindex="0" aria-label="18+" role="tab"/>
          </q-tabs>
          <q-tab-panels style="padding: 0" v-model="tab" animated>
            <q-tab-panel v-if="age === null || age &lt;= 18" name="ageA" style="padding: 0" role="tabpanel">
              <age-a :articles16-to18="articles16To18"></age-a>
            </q-tab-panel>
            <q-tab-panel name="ageB" style="padding: 0" role="tabpanel">
              <age-b :articles-above18="articlesAbove18"></age-b>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgeA from '../components/MyOptions/AgeA'
import AgeB from '../components/MyOptions/AgeB'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'MyOptions',
  components: {
    AgeA,
    AgeB
  },
  data () {
    return {
      tab: 'ageA'
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'articles']),
    articles16To18 () {
      if (this.articles) {
        return this.articles.filter(article => {
          if (article.tags) {
            return article.tags.includes('16-18')
          }
        })
      }
      return []
    },
    articlesAbove18 () {
      if (this.articles) {
        return this.articles.filter(article => {
          if (article.tags) {
            return article.tags.includes('18+')
          }
        })
      }
      return []
    },
    age () {
      if (this.currentUser.userInfo.generalInfo.dob) {
        return Math.floor(moment(new Date()).diff(moment(this.currentUser.userInfo.generalInfo.dob, 'DD/MM/YYYY'), 'years', true))
      }
      return null
    }
  },
  methods: {
    ...mapActions(['getArticles'])
  },
  mounted () {
    this.getArticles().then((data) => {
      if (data.status !== 200) {
        this.$q.notify({
          message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
          html: true,
          color: 'red',
          position: 'top-right'
        })
      }
    })
    if (this.age > 18) {
      this.tab = 'ageB'
    }
    if (this.age >= 16 && this.age < 18) {
      this.tab = 'ageA'
    }
  }
}
</script>
