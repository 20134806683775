<template>
  <div role="region" aria-label="next pathway plan">
    <div v-if="currentUser.userType !== 'YP'">
      <div class="set-next">
        <div class="q-pa-md title-text-box text-center">
          <p for="nextPpDuedate">Next Pathway Plan is due on {{ ppDueDate }}. You can override this date below. </p>
        </div>
        <div class="title-text-box text-center">
          <div class="form-row" style="padding-bottom: 2.3vh">
            <div class="row">
              <div class="col-12">
                <modal-date class="date-input" :max-date="maxDate" :min-date="minDate" v-model="ppDueDate" placeholder="Due date" :date-selectable="true" :read-only="true" label-id="overrideDate"></modal-date>
              </div>
            </div>
          </div>
        </div>
        <div class="q-pa-md" style="padding-top: 2.5vh">
          <div class="text-center">
            <q-btn  class="no-outline no-shadow full-width q-btn--standard q-btn-full q-btn--rectangle bg-primary text-white" unelevated rounded color="primary" @click="pathwayPlan.next ? updatePp() : createNextPp()" :disable="!ppDueDate && !isOffline">
              <span>Override next Pathway Plan date</span>
            </q-btn>
          </div>
          <div class="set-next-illustration-bg"></div>
        </div>
        <div class="q-pa-md">
          <div class="button-section">
            <q-btn
              :class="['no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white q-btn-full', { disabled: nextPpBtnDisabled }]"
              unelevated
              rounded color="primary" :disable="nextPpBtnDisabled && !isOffline" @click="startNextPp()">
              <div>
                <span v-if="nextPpBtnDisabled">Next Pathway Plan</span>
                <span v-else>Click to begin next Pathway Plan</span>
                <span class="txt">due date {{ ppDueDateObj ? ppDueDateObj.format('DD MMMM YYYY') : '' }}</span>
              </div>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="showNoPpPage">
        <div :class="{ 'custom-pp-title-box' : showRegistrationEmotican }">
          <div class="q-pa-md title-text-box text-center">
            <div class="emoji" v-if="showRegistrationEmotican">
              <div class="emoji-box">
                <img :src="emoji1" alt="icon">
              </div>
              <div class="emoji-box">
                <img :src="emoji2" alt="icon">
              </div>
            </div>
            <div v-if="fromPpDashboard">
              <p v-if="nextPpBtnDisabled">Your next Pathway Plan will be <br> opened on
                {{ nextPpOpenDate ? nextPpOpenDate.format('DD/MM/YYYY') : '' }}</p>
              <p v-else>Start filling in your next <br> Pathway Plan </p>
            </div>
            <div v-else>
              <p v-if="nextPpBtnDisabled">You can start filling out your next <br> Pathway Plan on
                {{ nextPpOpenDate ? nextPpOpenDate.format('DD/MM/YYYY') : '' }}</p>
              <p class="next-pp-text" v-else>Your next Pathway Plan is open.</p>
            </div>
          </div>
          <div class="q-pa-md">
            <div class="button-section">
              <q-btn
                :class="['no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white q-btn-full', { disabled: nextPpBtnDisabled }]"
                unelevated
                rounded color="primary" :disable="nextPpBtnDisabled && !isOffline" @click="startNextPp()">
                <div>
                  <div v-if="fromPpDashboard">
                    <span v-if="nextPpBtnDisabled">Next Pathway Plan</span>
                    <span v-else>Click to begin next Pathway Plan</span>
                    <span class="txt">due date {{ nextPpDueDate ? nextPpDueDate.format('DD MMMM YYYY') : '' }}</span>
                  </div>
                  <div v-else>
                    <span v-if="nextPpBtnDisabled">Next Pathway Plan</span>
                    <span v-else>Start your next Pathway Plan</span>
                    <span class="txt">due date {{ nextPpDueDate ? nextPpDueDate.format('DD MMMM YYYY') : '' }}</span>
                  </div>
                </div>
              </q-btn>
            </div>
          </div>
        </div>
        <div v-if="showBg" class="start-section" style="padding-top: 7vh">
          <div class="img-box">
            <img :src="illustration" alt="image">
          </div>
        </div>
      </div>
    </div>
    <add-pp v-model="openPpAdd" :emit="false" :pp="pathwayPlan.next"></add-pp>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import illustration from '../../assets/start-illustration.png'
import emoji1 from '../../assets/emoji1.png'
import emoji2 from '../../assets/emoji2.png'
import { Loading } from 'quasar'
import ModalDate from '../Utils/ModalDate'
import AddPp from '@/components/PathwayPlan/AddPp'

export default {
  name: 'NoNextPp',
  components: {
    ModalDate,
    AddPp
  },
  props: {
    showBg: {
      type: Boolean,
      default () {
        return true
      }
    },
    fromPpDashboard: {
      type: Boolean,
      default () {
        return false
      }
    },
    showRegistrationEmotican: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      illustration: illustration,
      emoji1: emoji1,
      emoji2: emoji2,
      maxDate: false,
      ppDueDate: '',
      openPpAdd: false,
      minDate: moment().format('YYYY/MM/DD')
    }
  },
  computed: {
    ...mapGetters(['pathwayPlan', 'currentUser', 'currentYp', 'isOffline']),
    showNoPpPage () {
      if (this.pathwayPlan.next) {
        if (this.pathwayPlan.next.opened === 'no' || this.pathwayPlan.next.started === 'no') {
          return true
        }
      }
      return false
    },
    nextPpBtnDisabled () {
      if (this.pathwayPlan.next) {
        if (this.pathwayPlan.next.opened === 'yes' && this.pathwayPlan.next.started === 'no') {
          return false
        }
      }
      return true
    },
    nextPpDueDate () {
      return this.pathwayPlan.next ? moment(this.pathwayPlan.next.due_date, 'YYYY-MM-DD') : null
    },
    nextPpOpenDate () {
      return this.pathwayPlan.next ? moment(this.pathwayPlan.next.open_date, 'YYYY-MM-DD') : null
    },
    ppDueDateObj () {
      if (this.ppDueDate) {
        return moment(this.ppDueDate, 'DD/MM/YYYY')
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setPathwayPlan', 'getPreviewPp', 'createPp']),
    startNextPp () {
      Loading.show()
      this.setPathwayPlan({
        token: this.currentUser.user_token,
        pathway_id: this.pathwayPlan.next.id,
        started: 'yes'
      }).then(() => {
        Loading.hide()
        this.$root.$emit('triggerPpDashboard')
      }, err => {
        Loading.hide()
        this.$q.notify({
          message: err.message,
          color: 'red',
          position: 'top-right'
        })
      })
    },
    updatePp () {
      console.log('updatePp')
      Loading.show()
      this.setPathwayPlan({
        token: this.currentUser.user_token,
        pathway_id: this.pathwayPlan.next.id,
        due_date: this.ppDueDate
      }).then(() => {
        Loading.hide()
        this.$root.$emit('triggerPpDashboard')
      }, err => {
        Loading.hide()
        this.$q.notify({
          message: err.message,
          color: 'red',
          position: 'top-right'
        })
      })
    },
    createNextPp () {
      Loading.show()
      this.createPp({
        token: this.currentUser.user_token,
        user_id: this.currentYp.id,
        due_date: this.ppDueDate
      }).then(() => {
        Loading.hide()
        this.$root.$emit('triggerPpDashboard')
        this.$root.$emit('pp::notification', 'Next Pathway Plan for ' + this.currentYp.firstName + ' ' + this.currentYp.lastName + ' was successfully added')
      }, err => {
        Loading.hide()
        this.$q.notify({
          message: err.message,
          color: 'red',
          position: 'top-right'
        })
      })
    }
  },
  watch: {
    pathwayPlan: {
      deep: true,
      handler: function (val) {
        this.ppDueDate = val.next ? moment(val.next.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : moment().add(8, 'week').format('DD/MM/YYYY')
      }
    }
  },
  mounted () {
    if (!this.pathwayPlan.next) {
      this.ppDueDate = this.pathwayPlan.current ? moment(this.pathwayPlan.current.expiry_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : moment().add(8, 'week').format('DD/MM/YYYY')
      this.maxDate = this.pathwayPlan.current ? moment(this.pathwayPlan.current.expiry_date, 'YYYY-MM-DD').format('YYYY/MM/DD') : moment().add(8, 'week').format('YYYY/MM/DD')
    } else {
      this.ppDueDate = moment(this.pathwayPlan.next.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.maxDate = this.pathwayPlan.current ? moment(this.pathwayPlan.current.expiry_date, 'YYYY-MM-DD').format('YYYY/MM/DD') : moment().add(8, 'week').format('YYYY/MM/DD')
    }
  }
}
</script>

<style scoped>

</style>
