<template>
  <div>
    <q-header class="view-profile-header" v-if="['YpMainView'].includes($route.name)">
      <q-toolbar class="items-start">
        <div class="col-4">
          <q-btn class="back-pp" flat dense round @click="goBack()" aria-label="back" icon="arrow_back_ios"/>
        </div>
        <div class="col-4">
          <q-avatar class="edit-profile-avatar" size="8.87vh">
            <img :src="avatar" alt="avatar">
          </q-avatar>
        </div>
        <div class="col-4 text-right blue bookmark-icon">
          <q-btn @click="$root.$emit('caseload:removeYp')" aria-label="bookmark">
            <img :src="bookmarkIcon" alt="icon">
          </q-btn>
        </div>
      </q-toolbar>
      <div class="avatar-details">
        <div class="avatar-text" role="heading" aria-level="1">{{ username }}</div>
        <p>{{ gender ? gender : '' }} <span v-if="age" class="text-divider"></span> {{ age ? age : '' }}
          {{ dob ? '( ' + dob + ' )' : '' }} </p>
        <p>{{ firstLanguage ? firstLanguage + ' speaker' : '' }} <!--<span class="text-divider"></span> Unaccompanied minor-->
        </p>
      </div>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import avatar from '../../assets/av.png'
import bookmarkIcon from '../../assets/bookmark_outline.svg'

export default {
  name: 'AppHeaderYpMainView',
  data () {
    return {
      bookmarkIcon: bookmarkIcon
    }
  },
  computed: {
    ...mapGetters(['appDrawerOpen', 'currentYp']),
    username () {
      return this.currentYp.firstName !== '' ? this.currentYp.firstName + ' ' + this.currentYp.lastName : 'User'
    },
    avatar () {
      return typeof this.currentYp.avatar === 'string' && this.currentYp.avatar.length ? this.currentYp.avatar : avatar
    },
    gender () {
      try {
        let gender = false
        switch (this.currentYp.generalInfo.gender) {
          case 'm':
            gender = 'Male'
            break
          case 'f':
            gender = 'Female'
            break
        }
        return gender
      } catch (e) {
      }
      return false
    },
    age () {
      try {
        if (moment(this.currentYp.generalInfo.dob, 'DD/MM/YYYY').isValid()) {
          return moment().diff(moment(this.currentYp.generalInfo.dob, 'DD/MM/YYYY'), 'years', false)
        }
      } catch (e) {
      }
      return false
    },
    dob () {
      try {
        if (moment(this.currentYp.generalInfo.dob, 'DD/MM/YYYY').isValid()) {
          return moment(this.currentYp.generalInfo.dob, 'DD/MM/YYYY').format('Do MMMM YYYY')
        }
      } catch (e) {
      }
      return false
    },
    firstLanguage () {
      try {
        if (typeof this.currentYp.generalInfo.firstLanguage === 'string' && this.currentYp.generalInfo.firstLanguage.length) {
          return this.currentYp.generalInfo.firstLanguage
        }
      } catch (e) {
      }
      return false
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer']),
    goBack () {
      this.$router.push({ name: this.$route.params.previousRoute ? this.$route.params.previousRoute : 'Home' })
    }
  }
}
</script>
