import api from '../../api'
import Loading from 'quasar/src/plugins/Loading.js';

const state = {
  badges: [],
  assignedBadges: []
}

const getters = {
  badges (state) {
    return state.badges
  },
  ypAssignedBadges (state) {
    return state.assignedBadges.map(badge => badge.id)
  }
}

const actions = {
  setAppBadges (context) {
    if (context.getters.currentUser.active) {
      return api.getBadges({
        token: context.getters.currentUser.user_token,
        type: 'all'
      }).then(data => {
        if (data.status === 200) {
          if (Array.isArray(data.data)) {
            context.commit('setBadges', data.data)
          }
        } else {
          Loading.hide()
        }
      })
    }
  },
  setUserBadges (context) {
    if (context.getters.currentUser.active && !context.getters.currentYp) {
      return api.getBadges({
        token: context.getters.currentUser.user_token,
        type: 'own'
      }).then(data => {
        if (data.status === 200) {
          if (Array.isArray(data.data)) {
            console.log(data.data)
            context.commit('setUserBadges', data.data)
          }
        } else {
          Loading.hide()
        }
      })
    }
  },
  assignBadge (context, badge) {
    Loading.show()
    return api.assignBadge({ token: context.getters.currentUser.user_token, yp_id: context.getters.currentYp.id, badge_ids: badge }).then(data => {
      if (data.status === 200) {
        badge = context.getters.badges.filter(b => b.id === badge)[0]
        context.commit('addBadge', badge)
        Loading.hide()
        return true
      }
      Loading.hide()
      return false
    }).catch(e => {
      Loading.hide()
      return false
    })
  },
  removeAssignedBadge (context, badge) {
    Loading.show()
    return api.removeBadge({ token: context.getters.currentUser.user_token, yp_id: context.getters.currentYp.id, badge_id: badge }).then(data => {
      if (data.status === 200) {
        context.commit('removeBadge', badge)
        Loading.hide()
        return true
      } else {
        Loading.hide()
        return false
      }
    }).catch(e => {
      Loading.hide()
      return false
    })
  }
}

const mutations = {
  setBadges (state, data) {
    state.badges = data
  },
  setYpBadges (state, data) {
    state.assignedBadges = data
  },
  addBadge (state, badge) {
    if (!state.assignedBadges.map(b => b.id).includes(badge.id)) {
      state.assignedBadges.push(badge)
    }
  },
  removeBadge (state, badge) {
    state.assignedBadges = state.assignedBadges.filter(b => b.id !== badge)
  },
  clearYpBadges (state, data) {
    state.assignedBadges = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
