import Vue from 'vue'
import Vuex from 'vuex'
import data from './modules/data'
import user from './modules/user'
import tasks from './modules/tasks'
import contacts from './modules/contacts'
import VuexPersist from 'vuex-persist'
import localforage from 'localforage'
import pathwayPlans from './modules/pathwayPlans'
import youngPersons from './modules/youngPersons'
import badges from './modules/badges'
import socket from './modules/socket'

const localStorageData = (new VuexPersist({ // localStorage
  modules: ['user']
})).plugin

const indexedDBStorage = module => {
  return (new VuexPersist({ // indexedDb storage for tasks key
    key: module,
    modules: [module],
    storage: localforage,
    asyncStorage: true
  })).plugin
}

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    data,
    user,
    tasks,
    contacts,
    pathwayPlans,
    youngPersons,
    badges,
    socket
  },
  plugins: [
    localStorageData,
    indexedDBStorage('tasks'),
    indexedDBStorage('contacts'),
    indexedDBStorage('pathwayPlans'),
    indexedDBStorage('youngPersons'),
    indexedDBStorage('badges')
  ]
})
