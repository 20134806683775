var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-md",staticStyle:{"padding-top":"0","padding-bottom":"0"}},[_c('vue-title',{attrs:{"title":"My profile"}}),_c('div',{staticClass:"profile-content"},[_c('q-form',{ref:"generalInfo",staticClass:"general-info-form"},[_c('div',{staticClass:"form-row avatar-form-row"},[_c('div',{staticClass:"q-gutter-y-md column text-center"},[_c('q-avatar',{staticClass:"profile-avatar",attrs:{"size":"14.8vh"}},[(!_vm.uploadedAvatarImg)?_c('img',{attrs:{"src":_vm.avatar,"alt":"avatar"}}):_c('img',{attrs:{"src":_vm.uploadedAvatarImg,"alt":"avatar"}})]),(!_vm.viewMode)?_c('q-file',{attrs:{"title":"photo","disable":_vm.isOffline,"borderless":"","input-class":"add blue custom-add text-center","label":"Add photo","for":"photo"},on:{"input":_vm.getAvatarPreview},model:{value:(_vm.userInfo.avatar),callback:function ($$v) {_vm.$set(_vm.userInfo, "avatar", $$v)},expression:"userInfo.avatar"}},[_c('div',{staticClass:"add blue custom-add text-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(_vm.uploadedAvatarImg.length ? 'Change' : 'Add')+" photo")]),_c('div',{attrs:{"slot":"file"},slot:"file"})]):_vm._e()],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name")]),_c('q-input',{attrs:{"outlined":"","placeholder":"Enter your first name","rules":[
            function (val) { return val !== null && val !== '' || 'Please type your name'; },
            function (val) { return val.length > 2 || 'Please type at least 3 characters'; }
            ],"readonly":_vm.loading || _vm.viewMode || _vm.isOffline,"for":"name"},model:{value:(_vm.userInfo.firstName),callback:function ($$v) {_vm.$set(_vm.userInfo, "firstName", $$v)},expression:"userInfo.firstName"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"lastname"}},[_vm._v("Last name")]),_c('q-input',{attrs:{"outlined":"","placeholder":"Enter your last name","rules":[
            function (val) { return val !== null && val !== '' || 'Please type your first name'; },
            function (val) { return val.length > 2 || 'Please type at least 3 characters'; }
            ],"readonly":_vm.loading || _vm.viewMode || _vm.isOffline,"for":"lastname"},model:{value:(_vm.userInfo.lastName),callback:function ($$v) {_vm.$set(_vm.userInfo, "lastName", $$v)},expression:"userInfo.lastName"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"phone_number"}},[_vm._v("Phone Number")]),_c('q-input',{attrs:{"outlined":"","placeholder":"Enter your phone number","rules":[
            function (val) { return val !== null && val !== '' || 'Please type your phone number'; },
            function (val) { return val.length > 2 || 'Please type at least 3 characters'; }
            ],"readonly":_vm.loading || _vm.viewMode || _vm.isOffline,"for":"phone_number"},model:{value:(_vm.userInfo.phone_number),callback:function ($$v) {_vm.$set(_vm.userInfo, "phone_number", $$v)},expression:"userInfo.phone_number"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-pt-md"},[_c('div',{staticClass:"column"},[_c('label',{attrs:{"for":"jobtitle"}},[_vm._v("Job title")]),_c('q-select',{class:[{ 'select-box-disabled' : _vm.loading || _vm.viewMode || _vm.isOffline }],attrs:{"rules":[function (val) { return val !== null && val !== '' || 'Please select your job title'; }],"outlined":"","options":(_vm.loading || _vm.viewMode || _vm.isOffline) ? [] : _vm.jobTitles,"disabled":_vm.loading || _vm.viewMode || _vm.isOffline,"option-label":function (item) { return item === null ? null : item.name; },"icon":"expand_more","for":"jobtitle","behavior":"menu","hide-dropdown-icon":_vm.loading || _vm.viewMode || _vm.isOffline},model:{value:(_vm.userInfo.jobTitle),callback:function ($$v) {_vm.$set(_vm.userInfo, "jobTitle", $$v)},expression:"userInfo.jobTitle"}})],1)])]),(!_vm.viewMode)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"password"}},[_vm._v("New password")]),_c('q-input',{attrs:{"outlined":"","type":_vm.pwdType ? 'password' : 'text',"placeholder":"Enter new password","rules":[
              function (val) { return _vm.validatePassword || 'Your password should be at least 6 characters and include letters and numbers'; }
            ],"readonly":_vm.loading || _vm.viewMode || _vm.isOffline,"for":"password"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",class:_vm.pwdType ? 'fas fa-eye' : 'far fa-eye-slash',attrs:{"aria-label":"toggle password","role":"button","tabindex":"0","aria-hidden":false},on:{"click":function($event){_vm.pwdType = !_vm.pwdType},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.pwdType = !_vm.pwdType}}})]},proxy:true}],null,false,2266006768),model:{value:(_vm.userInfo.password),callback:function ($$v) {_vm.$set(_vm.userInfo, "password", $$v)},expression:"userInfo.password"}})],1)]):_vm._e(),(!_vm.viewMode)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v("Confirm password")]),_c('q-input',{attrs:{"outlined":"","type":_vm.repeatPwdType ? 'password' : 'text',"placeholder":"Enter new password","rules":[
              function (val) { return _vm.validateConfirmPassword || 'Confirm password must be same'; }
            ],"readonly":_vm.loading || _vm.viewMode || _vm.isOffline,"for":"password"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",class:_vm.repeatPwdType ? 'fas fa-eye' : 'far fa-eye-slash',attrs:{"aria-label":"toggle password","role":"button","tabindex":"0","aria-hidden":false},on:{"click":function($event){_vm.repeatPwdType = !_vm.repeatPwdType},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.repeatPwdType = !_vm.repeatPwdType}}})]},proxy:true}],null,false,1186839815),model:{value:(_vm.userInfo.confirmPassword),callback:function ($$v) {_vm.$set(_vm.userInfo, "confirmPassword", $$v)},expression:"userInfo.confirmPassword"}})],1)]):_vm._e(),_c('div',{ref:"saveBtn"},[(!_vm.viewMode)?_c('q-btn',{staticClass:"no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white",attrs:{"disable":_vm.isOffline,"unelevated":"","rounded":"","color":"primary","label":"Save","loading":_vm.loading},on:{"click":_vm.validate}}):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }