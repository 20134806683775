<template>
  <div>
    <div class="text-h6 text-center" role="heading" aria-level="2">Your Bio</div>
    <q-form class="contact-details-form" ref="bio">
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="about">About me</label>
          <q-input
            :readonly="loading || isOffline"
            v-model="bio.aboutMe"
            type="textarea"
            outlined
            placeholder="Tell us more about you including interest and hobbies"
            for="about"/>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="medical">Known allergies and / or medical conditions</label>
          <q-input
            :readonly="loading || isOffline"
            v-model="bio.medical"
            outlined placeholder="Enter medical information "
            for="medical"/>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="myAchivements">My achievements</label>
          <div class="row" v-for="(achievement, idx) in bio.achievements" :key="idx" style="padding-bottom: 10px">
            <div class="col-4">
              <q-select
                :options="(loading || isOffline) ? [] : years"
                :hide-dropdown-icon="loading || isOffline"
                :disabled="loading || isOffline"
                :class="[{ 'select-box-disabled' : loading || isOffline }]"
                outlined v-model="bio.achievements[idx].year" label="My achievements" behavior="menu">
                <span slot="label" v-if="bio.achievements[idx].year.length === 0">
                  YYYY
                </span>
              </q-select>
            </div>
            <div class="col-8" style="padding-left: 1vh">
              <q-input title="What did you achieve?" :readonly="loading || isOffline" outlined placeholder="What did you achieve?" v-model="bio.achievements[idx].description" />
            </div>
            <q-btn
              v-if="idx !== 0 && !(loading || isOffline)" @click="removeAchievements(idx)" class="add add-phone custom-add blue" flat>
              <span class="plus-icon material-icons-outlined">remove</span> Remove achievement</q-btn>
          </div>
        </div>
        <q-btn v-if="!(loading || isOffline)"
               @click="addAchievements()" class="add add-phone custom-add blue" flat><span class="plus-icon material-icons-outlined">add</span> Add more</q-btn>
      </div>
      <div>
        <div class="saveBtn row" ref="saveBtn">
          <div class="col">
            <q-btn @click="$emit('prev')" class="float-left q-btn--standard" outline rounded color="primary"
                   label="Previous" role="button" aria-label="previous"/>
          </div>
          <div class="col">
            <q-btn class="float-right q-btn--standard" @click="validate(true)" unelevated rounded color="primary"
                   label="Save" :disable="isOffline" :loading="loading" role="button" aria-label="save"/>
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import scrollToTarget from '../../mixins/scrollToTarget'

export default {
  name: 'Bio',
  mixins: [scrollToTarget],
  data () {
    return {
      bio: {
        aboutMe: '',
        medical: '',
        achievements: [{
          year: moment().format('YYYY'),
          description: ''
        }]
      },
      expanded: false,
      loading: false,
      year: moment().format('YYYY')
    }
  },
  mounted () {
    this.updateLocalData()
    this.$root.$on('save:profile', this.validate)
  },
  computed: {
    ...mapGetters(['userBio', 'currentYp', 'currentUser', 'backgroundLoading', 'isOffline']),
    years () {
      let minYear = 1980
      const getDobYear = (user) => {
        const dob = moment(user.generalInfo.dob, 'DD/MM/YYYY')
        if (dob.isValid()) {
          minYear = Number.parseInt(dob.format('YYYY'))
        }
      }
      if (this.currentYp) {
        getDobYear(this.currentYp)
      } else {
        getDobYear(this.currentUser.userInfo)
      }
      return [...Array(Number.parseInt(moment().format('YYYY')) - minYear + 1).keys()].map(i => i + minYear)
    }
  },
  methods: {
    ...mapActions(['setBio']),
    addAchievements () {
      this.bio.achievements.push({
        year: moment().format('YYYY'),
        description: ''
      })
    },
    removeAchievements (idx) {
      this.bio.achievements.splice(idx, 1)
    },
    updateLocalData () {
      if (this.currentYp) {
        this.bio = Object.assign({}, this.currentYp.bio)
      } else if (this.userBio !== null) {
        this.bio = Object.assign({}, this.userBio)
      }
      if (!Array.isArray(this.bio.achievements)) {
        this.bio.achievements = []
      }
      if (this.bio.achievements.length === 0) {
        this.bio.achievements = [{
          year: moment().format('YYYY'),
          description: ''
        }]
      }
    },
    validate (event = false) {
      this.$refs.bio.validate().then(success => {
        if (success) {
          this.loading = true
          this.setBio(this.bio).then((data) => {
            this.loading = false
            if (data.status === 200) {
              if (data.message) {
                this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'green',
                  position: 'top-right'
                })
              }
              this.updateLocalData()
              if (event) {
                this.$emit('valid')
              }
            } else {
              this.$q.notify({
                message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                html: true,
                color: 'red',
                position: 'top-right'
              })
            }
          })
        }
      })
    }
  },
  beforeDestroy () {
    this.$root.$off('save:profile')
  },
  watch: {
    backgroundLoading (status) {
      if (!status) {
        this.updateLocalData()
      }
    }
  },
  updated () {
    if (document.querySelectorAll('.q-select [role="combobox"]').length) {
      document.querySelectorAll('.q-select [role="combobox"]').forEach(element => {
        element.removeAttribute('aria-multiselectable')
        element.setAttribute('aria-label', 'year')
      })
    }
  }
}
</script>

<style scoped>

</style>
