// remove locks with route navigation

import { mapActions, mapGetters } from 'vuex'

export default {
  mounted () {
    this.$root.$on('rmLocks', this.rmLocks)
  },
  beforeDestroy () {
    this.$root.$off('rmLocks', this.rmLocks)
  },
  methods: {
    ...mapActions(['removeLock']),
    rmLocks (lkParams = false) {
      if (!lkParams && this.removeLockParams) {
        lkParams = this.removeLockParams
      }
      if (lkParams) {
        this.removeLock({
          id: this.currentUser.user_id,
          resourceId: lkParams.resourceId,
          section: lkParams.section
        })
      }
    }
  },
  data () {
    return {
      tempLockParams: [] // add lag for removing the task locks
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentYp', 'locks', 'pathwayPlan', 'currentContact']),
    removeLockParams () {
      let section = false
      if (['AllAboutMeNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext'].includes(this.$route.name)) {
        if (this.$route.meta.edit && this.$route.meta.tense) {
          if (this.pathwayPlan && this.pathwayPlan[this.$route.meta.tense]) {
            section = 'pp'
            return {
              resourceId: this.pathwayPlan[this.$route.meta.tense].id,
              section,
              yp: this.currentYp ? this.currentYp.id : this.currentUser.user_id
            }
          }
        }
      }
      if (['Profile'].includes(this.$route.name)) {
        section = 'profile'
      }

      if (section) {
        if (['SW', 'PA'].includes(this.currentUser.userType) && this.currentYp) {
          return {
            resourceId: this.currentYp.id,
            section: section
          }
        }
        if (this.currentUser.userType === 'YP') {
          return {
            resourceId: this.currentUser.user_id,
            section: section
          }
        }
      }
      return false
    },
    removeTaskLockParams () {
      const lk = []
      if (this.$options.name === 'AssignTask' && this.selectedTask) {
        lk.push({
          resourceId: this.selectedTask.id,
          section: 'task'
        })
      }
      return lk
    },
    removeContactLockParams () {
      if (this.$options.name === 'AddEditContact' && this.currentContact && this.modalOpen === false) {
        return {
          resourceId: this.currentContact.id,
          section: 'contact'
        }
      }
      return false
    }
  },
  beforeRouteLeave (to, from, next) {
    this.rmLocks(this.removeLockParams)
    next()
  },
  watch: {
    removeTaskLockParams (locks) {
      if (locks.length) {
        this.tempLockParams = this.removeTaskLockParams
      } else {
        this.tempLockParams.forEach(lk => {
          this.rmLocks(lk)
        })
        this.tempLockParams = []
      }
    },
    removeContactLockParams (lock) {
      if (lock) {
        this.rmLocks(lock)
      }
    }
  }
}
