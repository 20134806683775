<template>
  <div>
    <div class="tasks-content" :class="color">
      <div class="q-gutter-y-md">
        <q-tabs
          v-model="tab"
          dense
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
          role="tablist"
        >
          <q-tab name="upcoming" :label="'Upcoming ' + upcomingTasks.length" tabindex="0" :aria-label="'Upcoming ' + upcomingTasks.length" role="tab"/>
          <q-tab name="overdue" :label="'Overdue ' + overdueTasks.length" tabindex="0" :aria-label="'Overdue ' + overdueTasks.length" role="tab"/>
          <q-tab name="completed" :label="'Completed ' + completedTasks.length" tabindex="0" :aria-label="'Completed ' + completedTasks.length" role="tab"/>
        </q-tabs>

        <notification></notification>
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="upcoming" role="tabpanel">
            <task-list :pp-section="ppSection" type="upcoming" label="upcoming" :tasks="upcomingTasks" :color="color"></task-list>
            <q-btn :disable="isOffline" v-if="showTaskAssignBtn" @click="assignModalOpen = true" class="add blue custom-add" flat><span class="plus-icon material-icons-outlined">add</span> Assign task</q-btn>
          </q-tab-panel>

          <q-tab-panel name="overdue" role="tabpanel">
            <task-list :pp-section="ppSection" type="overdue" label="overdue" :tasks="overdueTasks" :color="color"></task-list>
          </q-tab-panel>

          <q-tab-panel name="completed" role="tabpanel">
            <task-list :pp-section="ppSection" type="completed" label="completed" :tasks="completedTasks" :color="color"></task-list>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
    <assign-task v-model="assignModalOpen" :emit="false"></assign-task>
  </div>
</template>

<script>
import moment from 'moment'
import TaskList from '@/components/Tasks/TaskList'
import AssignTask from '@/components/Tasks/AssignTask'
import Notification from '@/components/Utils/Notification'
import { mapGetters } from 'vuex'

export default {
  name: 'TasksTabs',
  props: {
    tasks: {
      default () {
        return []
      },
      type: Array
    },
    color: { // the color for the left margin, checkbox etc, tabs.
      type: String,
      default () {
        return ''
      }
    },
    showTaskAssignBtn: {
      type: Boolean,
      default () {
        return true
      }
    },
    ppSection: {
      type: String,
      default () {
        return null
      }
    }
  },
  components: {
    Notification,
    TaskList,
    AssignTask
  },
  data () {
    return {
      tab: 'upcoming',
      notifyTaskCompletion: false,
      notifyAssignedTask: false,
      assignModalOpen: false
    }
  },
  computed: {
    ...mapGetters(['currentYp', 'currentUser', 'isOffline']),
    upcomingTasks () {
      if (this.tasks) {
        return this.tasks.filter(task => {
          if (task.type === 'event' && task.status === 'upcoming') {
            return true
          }
          if (['OverallPlanNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext', 'OverallPlanCurrent', 'OverallPlanCurrent', 'EducationEmploymentCurrent', 'ManagingLivingCurrent', 'HealthDevelopmentCurrent', 'MyMoneyCurrent'].includes(this.$route.name)) {
            return task.status === 'upcoming'
          } else {
            if (this.currentYp) {
              return task.status === 'upcoming' && (task.assignedTo.id === this.currentYp.id || task.assignedBy.id === this.currentYp.id)
            } else {
              return task.status === 'upcoming'
            }
          }
        }).sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix())
      }
      return []
    },
    completedTasks () {
      if (this.tasks) {
        return this.tasks.filter(task => {
          if (task.type === 'event' && task.status !== 'upcoming') {
            return true
          }
          if (['OverallPlanNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext', 'OverallPlanCurrent', 'OverallPlanCurrent', 'EducationEmploymentCurrent', 'ManagingLivingCurrent', 'HealthDevelopmentCurrent', 'MyMoneyCurrent'].includes(this.$route.name)) {
            return task.status === 'completed'
          } else {
            if (this.currentYp) {
              return task.status === 'completed' && (task.assignedTo.id === this.currentYp.id || task.assignedBy.id === this.currentYp.id)
            } else {
              return task.status === 'completed'
            }
          }
        })
      }
      return []
    },
    overdueTasks () {
      if (this.tasks) {
        return this.tasks.filter(task => {
          if (['OverallPlanNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext', 'OverallPlanCurrent', 'OverallPlanCurrent', 'EducationEmploymentCurrent', 'ManagingLivingCurrent', 'HealthDevelopmentCurrent', 'MyMoneyCurrent'].includes(this.$route.name)) {
            return task.status === 'overdue' && task.type !== 'event'
          } else {
            if (this.currentYp) {
              return task.status === 'overdue' && task.type !== 'event' && (task.assignedTo.id === this.currentYp.id || task.assignedBy.id === this.currentYp.id)
            } else {
              return task.status === 'overdue' && task.type !== 'event'
            }
          }
        })
      }
      return []
    }
  }
}
</script>
