<template>
  <div :class="[type + '-task-holder']">
    <q-form class="general-info-form" v-if="tasks.length">
      <div v-for="(tasks, month) in tasksInMonths" :key="month">
        <div class="month first"><span>{{ month }}</span></div>
        <div v-for="(task, idx) in tasks" :key="idx">
          <task :color="color" :task="task" :show-assigned-to="currentUser.userType === 'YP'"></task>
        </div>
      </div>
    </q-form>
    <div class="empty-task" v-else tabindex="0">
      <div v-if="ppSection" class="pp-tasks-badges">
        <div class="badges-section text-center">
          <h2>You have no {{ label }} tasks</h2>
          <!--<div class="no-badges">
            <img :src="badges" alt="badges">
          </div>
          <p aria-label="For each completed task you will receive a badge">For each completed task you will receive a badge</p>-->
        </div>
      </div>
      <div v-else>
        <div v-if="ypHomeNoTaskTemplate">
          <p class="text-center" v-if="date.format('DD-MM-YYYY') === todayDate.format('DD-MM-YYYY')" aria-label="No tasks for Today">No tasks for today</p>
          <p class="text-center" v-else aria-label="No tasks">No tasks</p>
        </div>
        <div v-else>
          <p v-if="label.length" class="text-center">No {{ label }} tasks </p>
          <div class="no-tasks-bg"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Task from '@/components/Tasks/Task'
import { mapGetters } from 'vuex'
import badges from '../../assets/badges-illust.png'

export default {
  name: 'TaskList',
  components: {
    Task
  },
  props: {
    type: {
      default () {
        return ''
      },
      type: String
    },
    tasks: {
      default () {
        return []
      },
      type: Array
    },
    label: {
      default () {
        return ''
      },
      type: String
    },
    color: { // the color for the left margin, checkbox etc.
      type: String,
      default () {
        return ''
      }
    },
    date: {
      type: Object,
      default () {
        return null
      }
    },
    ypHomeNoTaskTemplate: {
      type: Boolean,
      default () {
        return false
      }
    },
    ppSection: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      todayDate: moment(),
      badges: badges
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tasksInMonths () {
      if (this.tasks.length) {
        let dueDate = ''
        return this.tasks.reduce((acc, overdueTasks) => {
          dueDate = moment(overdueTasks.dueDate)
          if (!acc[dueDate.format('MMMM YYYY')]) acc[dueDate.format('MMMM YYYY')] = []
          acc[dueDate.format('MMMM YYYY')].push(overdueTasks)
          return acc
        }, {})
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>
