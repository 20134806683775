<template>
  <div>
    <div class="doc-list pp-doc-list" v-if="ppList.length">
      <div class="doc-row" v-for="(pp, key) in ppList" :key="key">
        <div class="doc">
          <div class="row">
            <div class="col-3">
              <img :src="docImgPrev" alt="document image">
            </div>
            <div class="col-9 position-relative">
              <div class="doc-details">
                <div>
                  <p class="title">{{ pp.fileTitle }}</p>
                  <p class="list-date">{{ pp.fileDate }}</p>
                  <p class="size">{{ pp.file.size }}</p>
                </div>
                <p class="action">
                  <a v-if="!isOffline" @click="downloadPP" target="_blank" :download="pp.fileTitle" :href="pp.file.fileurl" style="margin-left: 10px" class="add blue custom-add" aria-label="download" role="button"><q-icon name="get_app"></q-icon> Download</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="q-pa-md" v-else>
      <div class="empty-task">
        <p class="text-center">No past plans here yet</p>
      </div>
      <div class="set-next-illustration-bg2" style="margin-top: 30%"></div>
    </div>
  </div>
</template>

<script>
import docImgPrev from '../../assets/doc_prev.png'
import { mapGetters } from 'vuex'

export default {
  name: 'Past',
  props: {
    ppList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      value: 100,
      progress: 1,
      docImgPrev: docImgPrev
    }
  },
  computed: {
    ...mapGetters(['isOffline'])
  },
  methods: {
    downloadPP () {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'download_pp', {
          event_category: 'PathwayPlan',
          event_label: 'Download Pathway Plan'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
