<template>
  <div>
    <q-header class="tasks-header" v-if="['AppSetting'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back" :to="{ name: 'Home' }" flat round dense icon="arrow_back_ios" aria-label="back"/>
        </div>
        <div class="col-10">
          <q-toolbar-title role="heading" aria-level="1">App settings</q-toolbar-title>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="tasks-header" v-if="['ProfileSettings', 'NotificationSettings', 'Language', 'Accessibility'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back" :to="{ name: 'AppSetting' }" flat round dense icon="arrow_back_ios" aria-label="back"/>
        </div>
        <div class="col-10">
          <q-toolbar-title v-if="appSetingTitle" role="heading" aria-level="1">{{ appSetingTitle }}</q-toolbar-title>
        </div>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppHeaderAppSetting',
  computed: {
    ...mapGetters(['appDrawerOpen']),
    appSetingTitle () {
      if (this.$route.name) {
        if (this.$route.name === 'ProfileSettings') {
          return 'Profile Settings'
        } else if (this.$route.name === 'NotificationSettings') {
          return 'Notification Settings'
        } else if (this.$route.name === 'Language') {
          return 'Language'
        } else if (this.$route.name === 'Accessibility') {
          return 'Accessibility'
        }
      }
      return null
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>
