<template>
  <div class="about_color">
    <vue-title title="All about me"></vue-title>
    <lock-dialog></lock-dialog>
    <div class="current_path_footer" v-if="!viewPathwaySection && readOnly">
      <q-btn @click="viewPathwaySection = !viewPathwaySection" class="tab-type-button" unelevated icon="account_circle"
             icon-right="arrow_forward_ios" label="Pathway Plan - all about me"/>
      <div class="about-background"></div>
    </div>
    <step v-else @stepChanged="stepChanged" :tabs="tabs" @prev="prev" @validate="validate" :completed="completed"
          :change-tab-to="tab" :loading="loading" :read-only="readOnly" category="about">

      <template v-slot:1>
        <q-form class="all-about-me-one" ref="1">
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="Name">First name</label>
              <q-input
                v-model="allAboutMe.step_1.firstName"
                outlined
                :placeholder="readOnly ? '' : 'Enter your first name'"
                :readonly="loading || isOffline || isLocked"
                for="Name"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="lastName">Last name</label>
              <q-input
                v-model="allAboutMe.step_1.lastName"
                outlined
                :placeholder="readOnly ? '' : 'Enter your last name'"
                :readonly="loading || isOffline || isLocked"
                for="lastName"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="dateOfBirth">Date of birth</label>
              <modal-date :required-input="false" :read-only="loading || isOffline || isLocked" :max-date="maxDate" v-model="allAboutMe.step_1.dob" placeholder="Enter your date of birth" label-id="dateOfBirth" :disable-input="true"></modal-date>
            </div>
          </div>
          <div class="form-row form-row-radio">
            <div class="q-gutter-y-md column">
              <div>
                <q-field
                  v-model="allAboutMe.step_1.gender"
                  borderless
                  square
                  class="q-field-radio"
                  :disable="loading || isOffline || isLocked"
                >
                  <label class="title-label">What is your sex?</label>
                  <q-radio title="male" v-model="allAboutMe.step_1.gender" val="m" label="Male" :disable="(loading || isOffline || isLocked) && (allAboutMe.step_1.gender !== 'm')"/>
                  <q-radio title="female" v-model="allAboutMe.step_1.gender" val="f" label="Female" :disable="(loading || isOffline || isLocked) && (allAboutMe.step_1.gender !== 'f')"/>
                </q-field>
              </div>
            </div>
          </div>
          <div class="form-row form-row-radio">
            <div class="q-gutter-y-md column">
              <div class="row">
                <q-field
                  v-model="allAboutMe.step_1.showGenderIdentity"
                  borderless
                  square
                  class="q-field-radio"
                  :disable="loading || isOffline || isLocked"
                  for="genderIdentity"
                >
                  <label class="title-label" for="genderIdentity">Is the gender you identify with the same as your sex registered at birth?</label>
                  <span class="details">This question is voluntary</span>
                  <q-radio title="yes" v-model="allAboutMe.step_1.showGenderIdentity" val="yes" label="Yes" :disable="(loading || isOffline || isLocked) && (allAboutMe.step_1.showGenderIdentity !== 'yes')"/>
                  <q-radio title="no" v-model="allAboutMe.step_1.showGenderIdentity" val="no" label="No" :disable="(loading || isOffline || isLocked) && (allAboutMe.step_1.showGenderIdentity !== 'no')"/>
                </q-field>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="disabled">Disabled</label>
              <!-- <span class="custom-readonly-field" for="firstLanguage" tabindex="0" v-if="loading || isOffline || isLocked">{{ allAboutMe.step_1.disabled }}</span> -->
              <q-select
                label-slot outlined v-model="allAboutMe.step_1.disabled"
                :options="(loading || isOffline || isLocked) ? [] : staticOptions"
                :hide-dropdown-icon="loading || isOffline || isLocked"
                :disabled="loading || isOffline || isLocked"
                for="disabled"
                :class="[{ 'select-box-disabled' : loading || isOffline || isLocked }]"
                behavior="menu"
              >
                <span slot="label" v-if="!allAboutMe.step_1.disabled || allAboutMe.step_1.disabled.length === 0">
                  {{ readOnly ? '' : 'Choose...' }}
                </span>
              </q-select>
            </div>
          </div>
        </q-form>
      </template>

      <template v-slot:2>
        <q-form class="all-about-me-two" ref="2">
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="communicationNeeds">Communication needs</label>
              <q-input
                v-model="allAboutMe.step_2.communicationNeeds"
                outlined
                :placeholder="readOnly ? '' : 'Specify your communication needs'"
                :readonly="loading || isOffline || isLocked"
                for="communicationNeeds"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="legalStatus">Legal status</label>
              <q-select
                outlined label-slot v-model="allAboutMe.step_2.legalStatus"
                :options="(loading || isOffline || isLocked) ? [] : legalStatusOptions"
                :hide-dropdown-icon="loading || isOffline || isLocked"
                :disabled="loading || isOffline || isLocked"
                :class="[{ 'select-box-disabled' : loading || isOffline || isLocked }]"
                for="legalStatus"
                behavior="menu"
              >
                <span slot="label" v-if="!allAboutMe.step_2.legalStatus || allAboutMe.step_2.legalStatus.length === 0">
                  {{ readOnly ? '' : 'Choose...' }}
                </span>
              </q-select>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="leavingCareStatus">Leaving care status</label>
              <q-select
                outlined label-slot v-model="allAboutMe.step_2.leavingCareStatus"
                :options="(loading || isOffline || isLocked) ? [] : leavingCareOptions"
                :hide-dropdown-icon="loading || isOffline || isLocked"
                :disabled="loading || isOffline || isLocked"
                :class="[{ 'select-box-disabled' : loading || isOffline || isLocked }]"
                for="leavingCareStatus"
                behavior="menu"
              >
              <span slot="label" v-if="!allAboutMe.step_2.leavingCareStatus || allAboutMe.step_2.leavingCareStatus.length === 0">
                  {{ readOnly ? '' : 'Choose...' }}
                </span>
              </q-select>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="immigrationStatus">Immigration status</label>
              <q-select
                outlined label-slot v-model="allAboutMe.step_2.immigrationStatus"
                :options="(loading || isOffline || isLocked) ? [] : immigrationStatusOptions"
                :hide-dropdown-icon="loading || isOffline || isLocked"
                :disabled="loading || isOffline || isLocked"
                :class="[{ 'select-box-disabled' : loading || isOffline || isLocked }]"
                for="immigrationStatus"
                behavior="menu"
              >
                <span slot="label" v-if="!allAboutMe.step_2.immigrationStatus || allAboutMe.step_2.immigrationStatus.length === 0">
                  {{ readOnly ? '' : 'Choose...' }}
                </span>
              </q-select>
            </div>
          </div>
        </q-form>
      </template>

      <template v-slot:3>
        <q-form class="all-about-me-three" ref="3">
          <div class="form-row" style="padding-bottom: 3vh">
            <div class="q-gutter-y-md column">
              <label for="birthCertificateName">Who has got my birth certificate</label>
              <q-input
                v-model="allAboutMe.step_3.whoHasBirthCertificate"
                outlined
                :placeholder="readOnly ? '' : 'Full name'"
                :readonly="loading || isOffline || isLocked"
                for="birthCertificateName"
              />
            </div>
            <div v-if="!readOnly">
              <q-file v-if="!allAboutMe.step_3.birthCertificate" @input="getAvatarPreview($event, 'birthCertificate')"
                      max-file-size="2048000" borderless
                      input-class="add blue custom-add text-center" v-model="allAboutMe.step_3.birthCertificateArray"
                      label="+ Add your birth certificate">
                <div slot="label" class="add blue custom-add"><span class="plus-icon material-icons-outlined">add</span>
                  Add your birth certificate (pdf)
                </div>
              </q-file>
              <span class="file-box" v-if="allAboutMe.step_3.birthCertificate">
                <span @click="removeFile('birthCertificate')" @keyup.enter="removeFile('birthCertificate')" class="material-icons-outlined" tabindex="0">close</span>
                <a :href="allAboutMe.step_3.birthCertificate"
                   @click.prevent="downloadItem({ url: allAboutMe.step_3.birthCertificate, label: allAboutMe.step_3.birthCertificateFilename })">
                  {{ allAboutMe.step_3.birthCertificateFilename }}
                </a>
              </span>
            </div>
          </div>
          <div class="form-row" style="padding-bottom: 3vh;">
            <div class="q-gutter-y-md column">
              <label for="passportCertificateName">Who has got my passport</label>
              <q-input
                v-model="allAboutMe.step_3.whoHasPassport"
                outlined
                :placeholder="readOnly ? '' : 'Full name'"
                :readonly="loading || isOffline || isLocked"
                for="passportCertificateName"
              />
            </div>
            <div v-if="!readOnly">
              <q-file v-if="!allAboutMe.step_3.passport" @input="getAvatarPreview($event, 'passport')"
                      max-file-size="2048000" borderless
                      input-class="add blue custom-add text-center" v-model="allAboutMe.step_3.passportArray"
                      label="+ Add passport">
                <div slot="label" class="add blue custom-add"><span class="plus-icon material-icons-outlined">add</span>
                  Add passport (pdf)
                </div>
              </q-file>
              <span class="file-box" v-if="allAboutMe.step_3.passport">
                <span @click="removeFile('passport')" @keyup.enter="removeFile('passport')" class="material-icons-outlined" tabindex="0">close</span>
                <a :href="allAboutMe.step_3.passport"
                   @click.prevent="downloadItem({ url: allAboutMe.step_3.passport, label: allAboutMe.step_3.passportFilename })">
                  {{ allAboutMe.step_3.passportFilename }}
                </a>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="niNumber">What is my NI number</label>
              <q-input
                v-model="allAboutMe.step_3.niNumber"
                outlined
                :placeholder="readOnly ? '' : 'NI number'"
                :readonly="loading || isOffline || isLocked"
                for="niNumber"
              />
            </div>
          </div>
        </q-form>
      </template>

      <template v-slot:4>
        <div class="all-about-me-four">
          <!--<div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="Plan date">This plan needs completing by:</label>
              <q-input
                v-model="allAboutMe.step_4.planDate"
                outlined
                placeholder="Plan date"
                :disable="true"
              />
            </div>
          </div>-->
          <div class="form-row" v-if="readOnly">
            <div class="q-gutter-y-md column">
              <label for="Next / Current plan expiry date">Next plan due date:</label>
              <span class="custom-readonly-field">{{ allAboutMe.step_4.nextPlanDate }}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="Date of Birth">Name of social worker / personal advisor</label>
              <span class="label-details">People involved in preparing the needs assessment, developing the plan, review and people supporting me</span>
              <div v-if="assigned.length" class="pathway-cards">
                <q-card class="my-card info-card" v-for="(user, index) in assigned" :key="index">
                  <q-card-section>
                    <div class="q-gutter-sm">
                      <div class="row">
                        <div class="col-12">
                          <div class="bg-transparent">
                            <div class="row">
                              <div class="card-avatar">
                                <q-avatar size="7.9vh">
                                  <img :src="user.avatar ? user.avatar : avatar" alt="avatar">
                                </q-avatar>
                              </div>
                              <div class="card-avatar-details">
                                <div class="due-date assigned"><span class="card-info-title">{{ user.user_name }}</span><span
                                  class="card-info-desc">{{
                                    user.type === 'PA' ? '(Personal Advisor)' : '(Social Worker)'
                                  }}</span>
                                </div>
                                <div class="clear"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </div>

            </div>
          </div>

        </div>
      </template>

    </step>
  </div>

</template>

<script>
import moment from 'moment'
import Step from '../components/Step.vue'
import ModalDate from '../components/Utils/ModalDate'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import scrollToTarget from '../mixins/scrollToTarget'
import axios from 'axios/index'
import avatar from '../assets/av.png'
import addLocks from '@/mixins/addLocks'
import removeLocks from '@/mixins/removeLocks'
import LockDialog from '@/components/Utils/LockDialog'
import showLock from '@/mixins/showLock'
import focusLabel from '@/mixins/focusLabel'
let updatePathwayPlanInterval = null

export default {
  name: 'AllAboutMe',
  components: {
    LockDialog,
    Step,
    ModalDate
  },
  mixins: [scrollToTarget, addLocks, removeLocks, showLock, focusLabel],
  data () {
    return {
      viewPathwaySection: false,
      readOnly: false,
      tab: 1,
      tabs: 4,
      loading: false,
      maxDate: moment().subtract(10, 'years').format('YYYY/MM/DD'),
      birthCertificateUploaded: false,
      passportUploaded: false,
      allAboutMe: {
        step_1: {
          firstName: '',
          lastName: '',
          dob: '',
          gender: false,
          showGenderIdentity: false,
          disabled: ''
        },
        step_2: {
          communicationNeeds: '',
          legalStatus: '',
          leavingCareStatus: '',
          immigrationStatus: ''
        },
        step_3: {
          whoHasBirthCertificate: '',
          birthCertificate: '',
          whoHasPassport: '',
          passport: '',
          niNumber: '',
          birthCertificateArray: [],
          passportArray: [],
          birthCertificateFilename: '',
          passportFilename: ''
        },
        step_4: {
          planDate: '',
          nextPlanDate: ''
        }
      },
      staticOptions: [
        'yes',
        'no'
      ],
      legalStatusOptions: [],
      leavingCareOptions: [],
      immigrationStatusOptions: [],
      avatar: avatar
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'pathwayPlan', 'assigned', 'currentYp', 'isOffline']),
    completed () {
      const steps = [4]
      if (this.allAboutMe.step_1.firstName && this.allAboutMe.step_1.lastName && this.allAboutMe.step_1.dob && this.allAboutMe.step_1.gender && this.allAboutMe.step_1.disabled) {
        steps.push(1)
      }
      if (this.allAboutMe.step_2.legalStatus && this.allAboutMe.step_2.leavingCareStatus && this.allAboutMe.step_2.immigrationStatus) {
        steps.push(2)
      }
      if (this.allAboutMe.step_3.whoHasBirthCertificate && this.allAboutMe.step_3.niNumber) {
        steps.push(3)
      }
      return steps
    },
    getTense () {
      if (this.$route.meta.tense) {
        return this.$route.meta.tense
      }
      return 'next'
    }
  },
  methods: {
    ...mapActions(['getPathwayPlan', 'setPathwayPlan', 'getAssignedUsers']),
    ...mapMutations(['setppDueDate']),
    downloadItem ({ url, label }) {
      axios.get(url, { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },
    changeTab (tab) {
      this.tab = tab
    },
    prev () {
      this.tab = this.tab - 1
    },
    validate (goToNextStep = false) {
      if (this.$refs[this.tab]) {
        this.$refs[this.tab].validate().then(success => {
          if (success) {
            this.loading = true
            this.scroll(this.$refs.saveBtn)
            const userInfo = Object.assign({}, this.allAboutMe['step_' + this.tab])
            let requestData = null
            if (this.tab === 1) {
              requestData = {
                first_name: userInfo.firstName,
                last_name: userInfo.lastName,
                gender: userInfo.gender,
                dob: userInfo.dob,
                gender_birth: userInfo.showGenderIdentity,
                disabled: userInfo.disabled
              }
            } else if (this.tab === 2) {
              requestData = {
                communication_needs: userInfo.communicationNeeds,
                legal_status: userInfo.legalStatus,
                leaving_care_status: userInfo.leavingCareStatus,
                Immigration_status: userInfo.immigrationStatus
              }
            } else if (this.tab === 3) {
              requestData = {
                who_has_got_my_birth_certificate: userInfo.whoHasBirthCertificate,
                who_has_got_my_passport: userInfo.whoHasPassport,
                ni_number: userInfo.niNumber
              }
              if (this.birthCertificateUploaded) {
                requestData.birth_certificate = userInfo.birthCertificate
                requestData.birth_certificate_filename = userInfo.birthCertificateFilename
              }
              if (this.passportUploaded) {
                requestData.passport = userInfo.passport
                requestData.passport_filename = userInfo.passportFilename
              }
            } else if (this.tab === 4 && goToNextStep) {
              return this.$router.push({
                name: 'PathwayPlan',
                query: {
                  tab: this.getTense
                }
              })
            }
            requestData.pathway_id = this.pathwayPlan[this.$route.meta.tense].id
            requestData.token = this.currentUser.user_token
            console.log('requestData', requestData)
            this.setPathwayPlan(requestData).then((data) => {
              this.loading = false
              if (data.status === 200) {
                if (goToNextStep && this.tab !== this.tabs) {
                  this.tab = this.tab + 1
                }
                if (data.message) {
                  return this.$q.notify({
                    message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                    html: true,
                    color: 'green',
                    position: 'top-right'
                  })
                }
              } else {
                return this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'red',
                  position: 'top-right'
                })
              }
            })
          }
        })
      } else {
        if (goToNextStep) {
          this.$router.push({
            name: 'PathwayPlan',
            query: {
              tab: this.getTense
            }
          })
        }
      }
    },
    stepChanged (e) {
      this.tab = e
      this.loading = false
    },
    updatePathwayData () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        clearInterval(updatePathwayPlanInterval)
        this.allAboutMe.step_1.firstName = this.pathwayPlan[this.$route.meta.tense].first_name
        this.allAboutMe.step_1.lastName = this.pathwayPlan[this.$route.meta.tense].last_name
        this.allAboutMe.step_1.dob = this.pathwayPlan[this.$route.meta.tense].dob
        this.allAboutMe.step_1.gender = this.pathwayPlan[this.$route.meta.tense].gender
        this.allAboutMe.step_1.showGenderIdentity = this.pathwayPlan[this.$route.meta.tense].gender_birth
        this.allAboutMe.step_1.disabled = this.pathwayPlan[this.$route.meta.tense].disabled

        this.allAboutMe.step_2.communicationNeeds = this.pathwayPlan[this.$route.meta.tense].communication_needs
        this.allAboutMe.step_2.legalStatus = this.pathwayPlan[this.$route.meta.tense].legal_status
        this.allAboutMe.step_2.leavingCareStatus = this.pathwayPlan[this.$route.meta.tense].leaving_care_status
        this.allAboutMe.step_2.immigrationStatus = this.pathwayPlan[this.$route.meta.tense].Immigration_status

        this.allAboutMe.step_3.whoHasBirthCertificate = this.pathwayPlan[this.$route.meta.tense].who_has_got_my_birth_certificate
        this.allAboutMe.step_3.birthCertificate = this.pathwayPlan[this.$route.meta.tense].birth_certificate ? this.pathwayPlan[this.$route.meta.tense].birth_certificate : ''
        this.allAboutMe.step_3.whoHasPassport = this.pathwayPlan[this.$route.meta.tense].who_has_got_my_passport
        this.allAboutMe.step_3.passport = this.pathwayPlan[this.$route.meta.tense].passport ? this.pathwayPlan[this.$route.meta.tense].passport : ''
        this.allAboutMe.step_3.niNumber = this.pathwayPlan[this.$route.meta.tense].ni_number
        this.allAboutMe.step_3.birthCertificateFilename = this.pathwayPlan[this.$route.meta.tense].birth_certificate_filename
        this.allAboutMe.step_3.passportFilename = this.pathwayPlan[this.$route.meta.tense].passport_filename
        this.allAboutMe.step_3.birthCertificateArray = []
        this.allAboutMe.step_3.passportArray = []

        this.allAboutMe.step_4.planDate = this.pathwayPlan[this.$route.meta.tense].created_date ? moment(this.pathwayPlan[this.$route.meta.tense].created_date).format('DD/MM/YYYY') : ''
        this.allAboutMe.step_4.nextPlanDate = this.pathwayPlan[this.$route.meta.tense].expiry_date ? moment(this.pathwayPlan[this.$route.meta.tense].expiry_date).format('DD/MM/YYYY') : ''

        this.setupStepTwoOptions()
      }
    },
    getAvatarPreview (e, property) {
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = () => {
        this.allAboutMe.step_3[property] = reader.result
        this.allAboutMe.step_3[property + 'Filename'] = e.name
        this[property + 'Uploaded'] = true
      }
    },
    removeFile (file) {
      if (this.allAboutMe.step_3) {
        this.allAboutMe.step_3[file] = ''
        this.allAboutMe.step_3[file + 'Filename'] = ''
        this.allAboutMe.step_3[file + 'Array'] = []
        this[file + 'Uploaded'] = true
      }
    },
    setupStepTwoOptions () {
      if (this.allAboutMe.step_1.dob) {
        if (moment(this.allAboutMe.step_1.dob, 'DD/MM/YYYY').add(18, 'years') < moment().hours(0).minutes(0).seconds(0)) {
          // Is adult
          this.legalStatusOptions = [
            'Not relevant'
          ]
          this.leavingCareOptions = [
            'Former relevant'
          ]
          this.immigrationStatusOptions = [
            'N/A',
            'Refugee status',
            'Humanitarian protection',
            'Limited leave',
            'Further leave to remain application',
            'Appeals rights exhausted',
            'No recourse to public funds',
            'Deportation notice issues'
          ]
        } else {
          // Is minor
          this.legalStatusOptions = [
            'Section 20',
            'Section 31'
          ]
          this.leavingCareOptions = [
            'Eligible',
            'Relevant'
          ]
          this.immigrationStatusOptions = [
            'N/A',
            'Refugee status',
            'Humanitarian protection',
            'Limited leave'
          ]
        }

        if (!this.legalStatusOptions.includes(this.allAboutMe.step_2.legalStatus)) {
          this.allAboutMe.step_2.legalStatus = ''
        }
        if (!this.leavingCareOptions.includes(this.allAboutMe.step_2.leavingCareStatus)) {
          this.allAboutMe.step_2.leavingCareStatus = ''
        }
        if (!this.immigrationStatusOptions.includes(this.allAboutMe.step_2.immigrationStatus)) {
          this.allAboutMe.step_2.immigrationStatus = ''
        }
      }
    },
    goback (route) {
      if (this.viewPathwaySection) {
        this.viewPathwaySection = false
      } else {
        if (this.currentYp) {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab, ypId: this.currentYp.id } })
        } else {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab } })
        }
      }
    }
  },
  watch: {
    'allAboutMe.step_1.dob': function (newVal, oldVal) {
      this.setupStepTwoOptions()
    },
    pathwayPlan: function (newVal, oldVal) {
      if (this.$route.meta.tense === 'current') {
        if (this.pathwayPlan.current) {
          this.setppDueDate({
            dueDate: this.pathwayPlan.current.expiry_date,
            tense: 'current'
          })
        }
      }
    },
    'pathwayPlan.next': function (newVal, oldVal) {
      this.updatePathwayData()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$route.meta.edit === false) {
      this.loading = true
      this.readOnly = true
    }
    this.updatePathwayData()
    this.$root.$on('save:pathwayPlanSection', this.validate)
    updatePathwayPlanInterval = setInterval(this.updatePathwayData, 500)
    this.$root.$on('save:allAboutMe', this.validate)
    if (this.$route.meta.tense === 'current') {
      if (this.pathwayPlan.current) {
        this.setppDueDate({
          dueDate: this.pathwayPlan.current.expiry_date,
          tense: 'current'
        })
      }
    }
    if (this.currentYp) {
      this.getAssignedUsers()
    }
    this.$root.$on('ppSection:back', this.goback)
  },
  beforeDestroy () {
    this.$root.$off('save:pathwayPlanSection')
    this.$root.$off('ppSection:back', this.goback)
  },
  updated () {
    if (document.querySelectorAll('.q-select [role="combobox"]').length) {
      document.querySelectorAll('.q-select [role="combobox"]').forEach(element => {
        element.removeAttribute('aria-multiselectable')
        element.setAttribute('aria-label', element.getAttribute('id'))
      })
    }
  }
}
</script>

<style>
.profile-tabs .q-focus-helper {
  display: none;
}
</style>
