<template>
  <div class="education_color">
    <vue-title title="Education, employment & training"></vue-title>
    <lock-dialog></lock-dialog>
    <div v-if="!viewPathwaySection && readOnly">
      <div class="planTitle text-center">
        <span>TASKS FOR THIS SECTION</span>
      </div>
      <div class="path_task_holder">
        <tasks :render-title="false" :show-task-assign-btn="false" :pp-section="'education'" :pp="pathwayPlan[$route.meta.tense]"></tasks>
      </div>
      <div class="current_path_footer education_footer">
        <q-btn @click="viewPathwaySection = !viewPathwaySection" class="tab-type-button" unelevated icon="school" icon-right="arrow_forward_ios" label="Pathway Plan - Education, Employment & Training"/>
      </div>
    </div>

    <step v-else @stepChanged="stepChanged" :tabs="tabs" @prev="prev" @validate="validate" :completed="completed" :change-tab-to="tab" :loading="loading" :read-only="readOnly" category="education">
      <template v-slot:1>
        <q-form class="education-employment-plan-one" ref="1">
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label class="strong-label" for="Name">How are you feeling about education, employment & training</label>
              <div class="feelings-box radio">
                <q-radio title="awful" class="feelings-item" v-if="readOnly ? educationEmployment.step_1.educationFeeling === '1' : true" name="feelings" v-model="educationEmployment.step_1.educationFeeling" val="1" :class="[{ selected: educationEmployment.step_1.educationFeeling === '1' & !readOnly }]">
                  <span class="f-icon">😭</span>
                  <span class="f-text">awful</span>
                </q-radio>
                <q-radio title="bad" class="feelings-item" v-if="readOnly ? educationEmployment.step_1.educationFeeling === '2' : true" name="feelings" v-model="educationEmployment.step_1.educationFeeling" val="2" :class="[{ selected: educationEmployment.step_1.educationFeeling === '2' & !readOnly }]">
                  <span class="f-icon">🙁</span>
                  <span class="f-text">bad</span>
                </q-radio>
                <q-radio title="so so" class="feelings-item" v-if="readOnly ? educationEmployment.step_1.educationFeeling === '3' : true" name="feelings" v-model="educationEmployment.step_1.educationFeeling" val="3" :class="[{ selected: educationEmployment.step_1.educationFeeling === '3' & !readOnly }]">
                  <span class="f-icon">😐</span>
                  <span class="f-text">so so</span>
                </q-radio>
                <q-radio title="good" class="feelings-item" v-if="readOnly ? educationEmployment.step_1.educationFeeling === '4' : true" name="feelings" v-model="educationEmployment.step_1.educationFeeling" val="4" :class="[{ selected: educationEmployment.step_1.educationFeeling === '4' & !readOnly }]">
                  <span class="f-icon">🙂</span>
                  <span class="f-text">good</span>
                </q-radio>
                <q-radio title="great" class="feelings-item" v-if="readOnly ? educationEmployment.step_1.educationFeeling === '5' : true" name="feelings" v-model="educationEmployment.step_1.educationFeeling" val="5" :class="[{ selected: educationEmployment.step_1.educationFeeling === '5' & !readOnly }]">
                  <span class="f-icon">😀</span>
                  <span class="f-text">great</span>
                </q-radio>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="educationWorkingWell">What's working well</label>
              <q-input
                v-model="educationEmployment.step_1.educationWorkingWell"
                outlined
                :placeholder="readOnly ? '' : 'Tell us what is working well'"
                :readonly="loading || isOffline || isLocked"
                for="educationWorkingWell"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="educationWorriedAbout">What are we worried about</label>
              <q-input
                v-model="educationEmployment.step_1.educationWorriedAbout"
                outlined
                :placeholder="readOnly ? '' : 'Add description'"
                :readonly="loading || isOffline || isLocked"
                for="educationWorriedAbout"
              />
            </div>
          </div>
          <q-list class="pathList">
            <q-expansion-item
              group="somegroup"
              label="Education, employment and training history"
              icon="check_circle"
              default-opened
              :header-class="['pathListHeader', { compleated: historyCompleted }]"
              class="pathList educationPathList"
            >
              <q-card>
                <q-card-section>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="educationCurrentEstablishment">Current establishment</label>
                      <q-input
                        v-model="educationEmployment.step_1.educationCurrentEstablishment"
                        outlined
                        placeholder="Name of current establishment"
                        :readonly="loading || isOffline || isLocked"
                        for="educationCurrentEstablishment"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="educationAddress">Address</label>
                      <q-input
                        v-model="educationEmployment.step_1.educationAddress"
                        outlined
                        :placeholder="readOnly ? '' : 'Add address'"
                        :readonly="loading || isOffline || isLocked"
                        for="educationAddress"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="telephone">Telephone number</label>
                      <q-input
                        :readonly="loading || isOffline || isLocked"
                        :rules="[
                          val => val.match(/^([0-9]\s*|-*|\+*|\(-*|\)*|\.*)*$/g) !== null || 'Please type a valid phone number'
                          ]"
                        v-model="educationEmployment.step_1.educationPhone" name="telephone" outlined :placeholder="readOnly ? '' : 'Enter telephone number'" for="telephone"/>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="educationSupportContact">Support contact</label>
                      <q-input
                        v-model="educationEmployment.step_1.educationSupportContact"
                        outlined
                        :placeholder="readOnly ? '' : 'Full name'"
                        :readonly="loading || isOffline || isLocked"
                        for="educationSupportContact"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="row">
                      <div class="col-7" style="margin-top: -2vh">
                        <label for="date" style="display: block">Expected end date</label>
                        <modal-date class="date-input" :required-input="false" :read-only="loading || isOffline || isLocked" :min-date="minDate" v-model="educationEmployment.step_1.educationDate" placeholder="Date" label-id="date" :disable-input="true"></modal-date>
                      </div>
                      <div class="col-5"></div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="educationResponsibleLa">Responsible LA</label>
                      <q-input
                        v-model="educationEmployment.step_1.educationResponsibleLa"
                        outlined
                        :placeholder="readOnly ? '' : 'Full name'"
                        :readonly="loading || isOffline || isLocked"
                        for="educationResponsibleLa"
                      />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-form>
      </template>

      <template v-slot:2>
        <q-form class="education-employment-plan-two" ref="2">
          <q-list class="pathList">
            <q-expansion-item
              group="somegroup"
              label="What needs to happen"
              icon="check_circle"
              default-opened
              :header-class="['pathListHeader', { compleated: needsToHappenCompleted }]"
            >
              <q-card>
                <q-card-section>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="educationNextSteps">Next steps</label>
                      <q-input
                        v-model="educationEmployment.step_2.educationNextSteps"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="educationNextSteps"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="educationLongTermGoals">Long term goals</label>
                      <q-input
                        v-model="educationEmployment.step_2.educationLongTermGoals"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="educationLongTermGoals"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="educationContingency">Contingency if anything goes wrong</label>
                      <q-input
                        v-model="educationEmployment.step_2.educationContingency"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="educationContingency"
                      />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>

          </q-list>
        </q-form>
      </template>

      <template v-slot:3>
        <div class="planTitle text-center">
          <span>TASKS FOR THIS SECTION</span>
        </div>
        <div v-if="pathwayPlanTasks[getTense].education.total.length" class="education-employment-plan-three">
          <div v-for="(task, index) in pathwayPlanTasks[getTense].education.total" :key="index">
            <task :task="task" :read-only="readOnly ? false : true"></task>
          </div>
        </div>
        <div v-if="!readOnly">
          <q-btn :disable="isLocked || isOffline" @click="assignTaskModalOpen = true" class="add blue custom-add" flat><span class="plus-icon material-icons-outlined">add</span> Add task</q-btn>
          <assign-task :section="'education'" :pp-id="getPpId" :emit="false" v-model="assignTaskModalOpen"></assign-task>
        </div>
      </template>

    </step>
  </div>
</template>

<script>
import Step from '../components/Step.vue'
import ModalDate from '../components/Utils/ModalDate'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import scrollToTarget from '../mixins/scrollToTarget'
import Task from '../components/Tasks/Task'
import AssignTask from '../components/Tasks/AssignTask'
import moment from 'moment'
import Tasks from './Tasks.vue'
import addLocks from '@/mixins/addLocks'
import removeLocks from '@/mixins/removeLocks'
import LockDialog from '@/components/Utils/LockDialog'
import showLock from '@/mixins/showLock'
import focusLabel from '@/mixins/focusLabel'
let updatePathwayPlanInterval = null

export default {
  name: 'EducationEmployment',
  components: {
    LockDialog,
    Step,
    ModalDate,
    Task,
    AssignTask,
    Tasks
  },
  mixins: [scrollToTarget, addLocks, removeLocks, showLock, focusLabel],
  data () {
    return {
      viewPathwaySection: false,
      readOnly: false,
      tab: 1,
      tabs: 3,
      loading: false,
      assignTaskModalOpen: false,
      educationEmployment: {
        step_1: {
          educationFeeling: '',
          educationWorkingWell: '',
          educationWorriedAbout: '',
          educationCurrentEstablishment: '',
          educationAddress: '',
          educationPhone: '',
          educationSupportContact: '',
          educationDate: '',
          educationResponsibleLa: ''
        },
        step_2: {
          educationNextSteps: '',
          educationLongTermGoals: '',
          educationContingency: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['tasks', 'pathwayPlan', 'pathwayPlanTasks', 'currentUser', 'isOffline']),
    completed () {
      const steps = []
      if (this.educationEmployment.step_1.educationFeeling && this.educationEmployment.step_1.educationWorkingWell && this.educationEmployment.step_1.educationWorriedAbout) {
        steps.push(1)
      }
      if (this.educationEmployment.step_2.educationNextSteps && this.educationEmployment.step_2.educationLongTermGoals && this.educationEmployment.step_2.educationContingency) {
        steps.push(2)
      }
      return steps
    },
    historyCompleted () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        if (this.pathwayPlan[this.$route.meta.tense].education_current_establishment && this.pathwayPlan[this.$route.meta.tense].education_current_establishment !== null && this.pathwayPlan[this.$route.meta.tense].education_address && this.pathwayPlan[this.$route.meta.tense].education_address !== null && this.pathwayPlan[this.$route.meta.tense].education_phone && this.pathwayPlan[this.$route.meta.tense].education_phone !== null && this.pathwayPlan[this.$route.meta.tense].education_support_contact && this.pathwayPlan[this.$route.meta.tense].education_support_contact !== null && this.pathwayPlan[this.$route.meta.tense].education_date && this.pathwayPlan[this.$route.meta.tense].education_date !== null && this.pathwayPlan[this.$route.meta.tense].education_responsible_la && this.pathwayPlan[this.$route.meta.tense].education_responsible_la !== null) {
          return true
        }
      }
      return false
    },
    needsToHappenCompleted () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        if (this.pathwayPlan[this.$route.meta.tense].education_next_steps && this.pathwayPlan[this.$route.meta.tense].education_next_steps !== null && this.pathwayPlan[this.$route.meta.tense].education_long_term_goals && this.pathwayPlan[this.$route.meta.tense].education_long_term_goals !== null && this.pathwayPlan[this.$route.meta.tense].education_contingency && this.pathwayPlan[this.$route.meta.tense].education_contingency !== null) {
          return true
        }
      }
      return false
    },
    getPpId () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        return this.pathwayPlan[this.$route.meta.tense].id
      }
      return null
    },
    getTense () {
      if (this.$route.meta.tense) {
        return this.$route.meta.tense
      }
      return 'next'
    },
    minDate () {
      if (this.currentYp && this.currentYp.dob) {
        return moment(this.currentYp.dob, 'DD/MM/YYYY').format('YYYY/MM/DD')
      } else if (this.currentUser.userInfo.generalInfo.dob) {
        return moment(this.currentUser.userInfo.generalInfo.dob, 'DD/MM/YYYY').format('YYYY/MM/DD')
      }
      return false
    }
  },
  methods: {
    ...mapActions(['setPathwayPlan']),
    ...mapMutations(['setppDueDate']),
    changeTab (tab) {
      this.tab = tab
    },
    prev () {
      this.tab = this.tab - 1
    },
    validate (goToNextStep = false) {
      if (this.$refs[this.tab]) {
        this.$refs[this.tab].validate().then(success => {
          if (success) {
            this.loading = true
            this.scroll(this.$refs.saveBtn)
            const userInfo = Object.assign({}, this.educationEmployment['step_' + this.tab])
            let requestData = null
            if (this.tab === 1) {
              requestData = {
                education_feeling: userInfo.educationFeeling,
                education_working_well: userInfo.educationWorkingWell,
                education_worried_about: userInfo.educationWorriedAbout,
                education_current_establishment: userInfo.educationCurrentEstablishment,
                education_address: userInfo.educationAddress,
                education_phone: userInfo.educationPhone,
                education_support_contact: userInfo.educationSupportContact,
                education_date: userInfo.educationDate,
                education_responsible_la: userInfo.educationResponsibleLa
              }
            } else if (this.tab === 2) {
              requestData = {
                education_next_steps: userInfo.educationNextSteps,
                education_long_term_goals: userInfo.educationLongTermGoals,
                education_contingency: userInfo.educationContingency
              }
            } else if (this.tab === 3 && goToNextStep) {
              return this.$router.push({
                name: 'PathwayPlan',
                query: {
                  tab: this.getTense
                }
              })
            }
            requestData.pathway_id = this.pathwayPlan[this.$route.meta.tense].id
            requestData.token = this.currentUser.user_token
            this.setPathwayPlan(requestData).then((data) => {
              this.loading = false
              if (data.status === 200) {
                if (goToNextStep && this.tab !== this.tabs) {
                  this.tab = this.tab + 1
                }
                if (data.message) {
                  return this.$q.notify({
                    message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                    html: true,
                    color: 'green',
                    position: 'top-right'
                  })
                }
              } else {
                return this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'red',
                  position: 'top-right'
                })
              }
            })
          }
        })
      } else {
        if (goToNextStep) {
          this.$router.push({
            name: 'PathwayPlan',
            query: {
              tab: this.getTense
            }
          })
        }
      }
    },
    stepChanged (e) {
      this.tab = e
      this.loading = false
    },
    updatePathwayData () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        clearInterval(updatePathwayPlanInterval)
        this.educationEmployment.step_1.educationFeeling = this.pathwayPlan[this.$route.meta.tense].education_feeling
        this.educationEmployment.step_1.educationWorkingWell = this.pathwayPlan[this.$route.meta.tense].education_working_well
        this.educationEmployment.step_1.educationWorriedAbout = this.pathwayPlan[this.$route.meta.tense].education_worried_about
        this.educationEmployment.step_1.educationCurrentEstablishment = this.pathwayPlan[this.$route.meta.tense].education_current_establishment
        this.educationEmployment.step_1.educationAddress = this.pathwayPlan[this.$route.meta.tense].education_address
        this.educationEmployment.step_1.educationPhone = this.pathwayPlan[this.$route.meta.tense].education_phone ? this.pathwayPlan[this.$route.meta.tense].education_phone : ''
        this.educationEmployment.step_1.educationSupportContact = this.pathwayPlan[this.$route.meta.tense].education_support_contact
        this.educationEmployment.step_1.educationDate = this.pathwayPlan[this.$route.meta.tense].education_date
        this.educationEmployment.step_1.educationResponsibleLa = this.pathwayPlan[this.$route.meta.tense].education_responsible_la

        this.educationEmployment.step_2.educationNextSteps = this.pathwayPlan[this.$route.meta.tense].education_next_steps
        this.educationEmployment.step_2.educationLongTermGoals = this.pathwayPlan[this.$route.meta.tense].education_long_term_goals
        this.educationEmployment.step_2.educationContingency = this.pathwayPlan[this.$route.meta.tense].education_contingency
      }
    },
    goback (route) {
      if (this.viewPathwaySection) {
        this.viewPathwaySection = false
      } else {
        if (this.currentYp) {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab, ypId: this.currentYp.id } })
        } else {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab } })
        }
      }
    }
  },
  watch: {
    'allAboutMe.step_1.dob': function (newVal, oldVal) {
      this.setupStepTwoOptions()
    },
    pathwayPlan: function (newVal, oldVal) {
      if (this.$route.meta.tense === 'current') {
        if (this.pathwayPlan.current) {
          this.setppDueDate({
            dueDate: this.pathwayPlan.current.expiry_date,
            tense: 'current'
          })
        }
      }
    },
    'pathwayPlan.next': function (newVal, oldVal) {
      this.updatePathwayData()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$route.meta.edit === false) {
      this.loading = true
      this.readOnly = true
    }
    updatePathwayPlanInterval = setInterval(this.updatePathwayData, 500)
    this.updatePathwayData()
    this.$root.$on('save:pathwayPlanSection', this.validate)
    if (this.$route.meta.tense === 'current') {
      if (this.pathwayPlan.current) {
        this.setppDueDate({
          dueDate: this.pathwayPlan.current.expiry_date,
          tense: 'current'
        })
      }
    }
    this.$root.$on('ppSection:back', this.goback)
  },
  beforeDestroy () {
    this.$root.$off('save:pathwayPlanSection')
    this.$root.$off('ppSection:back', this.goback)
  }
}
</script>

<style >
  .profile-tabs .q-focus-helper {
    display: none;
  }
</style>
