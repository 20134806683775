<template>
  <q-header :class="[userType === 'YP' ? 'home-header' : 'tasks-header']" v-if="['Home', 'YPStartPage', 'SWPAStartPage'].includes($route.name)">
    <q-toolbar v-if="['SW', 'PA'].includes(userType)" class="items-start">
      <div class="col-9">
        <q-btn
          flat
          dense
          round
          @click="toggleAppDrawer()"
          aria-label="Menu"
          icon="fas fa-bars"
          style="margin-left: -0.5vh"
        />
        <div class="user-details">
          <div class="avatar-text" role="heading" aria-level="1">Hello, {{ username }}</div>
          <p class="message">Welcome to the Plan your Pathway app!</p>
        </div>
      </div>
      <div class="col-3 text-right header-right">
        <q-avatar class="edit-profile-avatar" size="8.87vh">
          <img :src="avatar" alt="avatar" tabindex="0" @click="$router.push({ name: userType === 'YP' ? 'ViewProfile' : 'SwViewProfile' })" @keyup.enter="$router.push({ name: userType === 'YP' ? 'ViewProfile' : 'SwViewProfile' })">
        </q-avatar>
      </div>
    </q-toolbar>
    <q-toolbar v-else class="items-start">
      <div class="col-7">
        <q-btn
          flat
          dense
          round
          @click="toggleAppDrawer()"
          aria-label="Menu"
          icon="fas fa-bars"
          style="margin-left: -0.5vh"
        />
        <div class="user-details">
          <div class="avatar-text" role="heading" aria-level="1">Hello {{ username }}</div>
          <p class="message" style="margin-bottom: 0">{{ $route.name === 'YPStartPage' ? 'Welcome toPathway!' : 'Great work this week!' }}</p>
        </div>
      </div>
      <div class="col-5 text-center">
        <q-avatar class="edit-profile-avatar" size="8.87vh" style="margin-bottom: -0.7vh;">
          <img :src="avatar" alt="avatar" tabindex="0" @click="$router.push({ name: userType === 'YP' ? 'ViewProfile' : 'SwViewProfile' })" @keyup.enter="$router.push({ name: userType === 'YP' ? 'ViewProfile' : 'SwViewProfile' })">
        </q-avatar>
        <div class="avatar-badge" style="position: relative; z-index: 99" aria-label="Super Saver" v-if="profileBadge"><i class="av-icon"><img :src="profileBadge.icon" :alt="profileBadge.title">{{ profileBadge.title }}</i></div>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import avatar from '../../assets/av.png'

export default {
  name: 'AppHeaderHome',
  computed: {
    ...mapGetters(['appDrawerOpen', 'currentUser', 'userGeneralInfo', 'profileBadge']),
    userType () {
      return this.currentUser.userType
    },
    username () {
      return this.userGeneralInfo && this.userGeneralInfo.firstName !== '' ? this.userGeneralInfo.firstName : 'User'
    },
    avatar () {
      return this.userGeneralInfo && typeof this.userGeneralInfo.avatar === 'string' && this.userGeneralInfo.avatar.length ? this.userGeneralInfo.avatar : avatar
    },
    gender () {
      let gender = false
      switch (this.userGeneralInfo && this.userGeneralInfo.gender) {
        case 'm':
          gender = 'Male'
          break
        case 'f':
          gender = 'Female'
          break
      }
      return gender
    },
    age () {
      if (this.userGeneralInfo && moment(this.userGeneralInfo.dob, 'DD/MM/YYYY').isValid()) {
        return moment().diff(moment(this.userGeneralInfo.dob, 'DD/MM/YYYY'), 'years', false)
      }
      return false
    },
    dob () {
      if (this.userGeneralInfo && moment(this.userGeneralInfo.dob, 'DD/MM/YYYY').isValid()) {
        return moment(this.userGeneralInfo.dob, 'DD/MM/YYYY').format('Do MMMM YYYY')
      }
      return false
    },
    firstLanguage () {
      if (this.userGeneralInfo && typeof this.userGeneralInfo.firstLanguage === 'string' && this.userGeneralInfo.firstLanguage.length) {
        return this.userGeneralInfo.firstLanguage
      }
      return false
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>
