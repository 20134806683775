import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Register from '../views/Auth/Register.vue'
import Login from '../views/Auth/Login.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import Profile from '../views/Profile'
import ResetPassword from '../views/Auth/ResetPassword.vue'
import ViewProfile from '../views/ViewProfile.vue'
import YPStartPage from '../views/YP/YPStartPage.vue'
import SWPAStartPage from '../views/SWPA/SWPAStartPage'
import Tasks from '../views/Tasks.vue'
import Contacts from '../views/Contacts.vue'
import store from '../store/index'
import PathwayPlan from '../views/PathwayPlan'
import AllAboutMe from '../views/AllAboutMe.vue'
import OverallPlan from '../views/OverallPlan.vue'
import EducationEmployment from '../views/EducationEmployment.vue'
import ManagingLiving from '../views/ManagingLiving.vue'
import HealthDevelopment from '../views/HealthDevelopment.vue'
import MyMoney from '../views/MyMoney.vue'
import MyOptions from '../views/MyOptions.vue'
import MyCaseLoad from '../views/SWPA/MyCaseLoad'
import AddYoungPersons from '@/views/SWPA/AddYoungPersons'
import AllYoungPersons from '@/views/SWPA/AllYoungPersons'
import SWGeneralInfo from '@/components/Profile/SWGeneralInfo'
import YpMainView from '@/views/SWPA/YpMainView'
import SupportingPeople from '@/views/SupportingPeople'
import AchievementBadges from '@/views/AchievementBadges'
import PpDashboard from '@/views/PpDashboard'
import ShareContacts from '@/views/SWPA/ShareContacts'
import DocumentViewer from '@/views/DocumentViewer'
import AppSetting from '@/views/AppSetting.vue'
import Setting from '@/views/Setting.vue'
import Info from '@/views/Info.vue'
import TestInput from '@/views/TestInput.vue'

Vue.use(VueRouter)

const auth = [ // auth routes without login auth requirement
  {
    path: '/register/:hash',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword
  }
]

const global = [ // routes for the all user types
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/pathway-plan',
    name: 'PathwayPlan',
    component: PathwayPlan
  },
  {
    path: '/pathway/all-about-me/edit',
    name: 'AllAboutMeNext',
    component: AllAboutMe,
    meta: {
      edit: true,
      tense: 'next'
    }
  },
  {
    path: '/pathway/overall-plan/edit',
    name: 'OverallPlanNext',
    component: OverallPlan,
    meta: {
      edit: true,
      tense: 'next'
    }
  },
  {
    path: '/pathway/education-employment/edit',
    name: 'EducationEmploymentNext',
    component: EducationEmployment,
    meta: {
      edit: true,
      tense: 'next'
    }
  },
  {
    path: '/pathway/managing-living/edit',
    name: 'ManagingLivingNext',
    component: ManagingLiving,
    meta: {
      edit: true,
      tense: 'next'
    }
  },
  {
    path: '/pathway/health-development/edit',
    name: 'HealthDevelopmentNext',
    component: HealthDevelopment,
    meta: {
      edit: true,
      tense: 'next'
    }
  },
  {
    path: '/pathway/my-money/edit',
    name: 'MyMoneyNext',
    component: MyMoney,
    meta: {
      edit: true,
      tense: 'next'
    }
  },
  {
    path: '/pathway/all-about-me/view',
    name: 'AllAboutMeCurrent',
    component: AllAboutMe,
    meta: {
      edit: false,
      tense: 'current'
    }
  },
  {
    path: '/pathway/overall-plan/view',
    name: 'OverallPlanCurrent',
    component: OverallPlan,
    meta: {
      edit: false,
      tense: 'current'
    }
  },
  {
    path: '/pathway/education-employment/view',
    name: 'EducationEmploymentCurrent',
    component: EducationEmployment,
    meta: {
      edit: false,
      tense: 'current'
    }
  },
  {
    path: '/pathway/managing-living/view',
    name: 'ManagingLivingCurrent',
    component: ManagingLiving,
    meta: {
      edit: false,
      tense: 'current'
    }
  },
  {
    path: '/pathway/health-development/view',
    name: 'HealthDevelopmentCurrent',
    component: HealthDevelopment,
    meta: {
      edit: false,
      tense: 'current'
    }
  },
  {
    path: '/pathway/my-money/view',
    name: 'MyMoneyCurrent',
    component: MyMoney,
    meta: {
      edit: false,
      tense: 'current'
    }
  },
  {
    path: '/profile/view',
    name: 'ViewProfile',
    component: ViewProfile
  },
  {
    path: '/profile/edit',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/pp-dashboard',
    name: 'PpDashboard',
    component: PpDashboard
  },
  {
    path: '/document-viewer',
    name: 'DocumentViewer',
    component: DocumentViewer
  },
  {
    path: '/app-setting',
    name: 'AppSetting',
    component: AppSetting
  },
  {
    path: '/app-setting/profile-setting',
    name: 'ProfileSettings',
    component: Setting
  },
  {
    path: '/app-setting/notification-setting',
    name: 'NotificationSettings',
    component: Setting
  },
  {
    path: '/app-setting/language',
    name: 'Language',
    component: Setting
  },
  {
    path: '/app-setting/accessibility',
    name: 'Accessibility',
    component: Setting
  },
  {
    path: '/info/:pageSlug',
    name: 'Info',
    component: Info
  },
  {
    path: '/test',
    name: 'TestInput',
    component: TestInput
  }
]

const yp = [ // routes for the YP user type
  {
    path: '/yps-start-page',
    name: 'YPStartPage',
    component: YPStartPage
  },
  {
    path: '/my-options',
    name: 'MyOptions',
    component: MyOptions
  }
]

const swPa = [ // routes for the SW/PA user types
  {
    path: '/sw-pa-start-page',
    name: 'SWPAStartPage',
    component: SWPAStartPage
  },
  {
    path: '/my-caseload',
    name: 'MyCaseLoad',
    component: MyCaseLoad
  },
  {
    path: '/add-young-persons',
    name: 'AddYoungPersons',
    component: AddYoungPersons
  },
  {
    path: '/all-young-persons',
    name: 'AllYoungPersons',
    component: AllYoungPersons
  },
  {
    path: '/sw-profile/view',
    name: 'SwViewProfile',
    component: SWGeneralInfo
  },
  {
    path: '/sw-profile/edit',
    name: 'SwEditProfile',
    component: SWGeneralInfo
  },
  {
    path: '/my-caseload/young-person/view',
    name: 'YpMainView',
    component: YpMainView
  },
  {
    path: '/my-caseload/yp-tasks',
    name: 'YpTasks',
    component: Tasks
  },
  {
    path: '/my-caseload/yp-contacts',
    name: 'YpContacts',
    component: Contacts
  },
  {
    path: '/my-caseload/yp-supporting-people',
    name: 'YpSupportingPeople',
    component: SupportingPeople
  },
  {
    path: '/my-caseload/yp-achievements',
    name: 'YpAchievementBadges',
    component: AchievementBadges
  },
  {
    path: '/my-caseload/share-contacts',
    name: 'ShareContacts',
    component: ShareContacts
  }
]

const routes = [].concat(auth.map(route => {
  if (route.meta === undefined) {
    route.meta = {}
  }
  route.meta.auth = false
  return route
}), global.map(route => {
  if (route.meta === undefined) {
    route.meta = {}
  }
  route.meta.auth = true
  return route
}), yp.map(route => {
  if (route.meta === undefined) {
    route.meta = {}
  }
  route.meta.auth = ['YP']
  return route
}), swPa.map(route => {
  if (route.meta === undefined) {
    route.meta = {}
  }
  route.meta.auth = ['SW', 'PA']
  return route
}))

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (from.name !== 'Login') {
    if (to.meta.auth && !store.getters.currentUser.active) {
      next('/login')
    }
  }
  if (Array.isArray(to.meta.auth) && !to.meta.auth.includes(store.getters.currentUser.userType)) {
    console.log('not allowed')
    next('/')
  }
  next()
})

export default router
