<template>
  <q-layout :class="[$route.name]" view="lHh Lpr lFf">
    <div role="region" aria-label="skip to main content">
      <a :href="$route.path + '#mainContent'" class="skipTo">Skip to content</a>
    </div>
    <app-header></app-header>
    <main-menu v-if="currentUser.active"></main-menu>
    <q-page-container role="main">
      <background-loading></background-loading>
      <q-banner class="bg-primary text-white" v-if="updateAvailable">
        New version available!
        <template v-slot:action>
          <q-btn flat color="white" label="Dismiss" @click="dismissBanner()"/>
          <q-btn flat color="white" label="Refresh" @click="refresh()"/>
        </template>
      </q-banner>
      <router-view id="mainContent" role="main"/>

      <!-- Add to home screen dialog -->
      <q-dialog v-model="addToHomeDialog" persistent role="region" aria-label="Add to home screen" @hide="hideModal()" @show="showModal()">
        <q-card class="round-dialog">
          <q-card-section class="text-center popup-title">
            <span class="q-ml-sm">Add to Home Screen</span>
          </q-card-section>

          <q-card-section class="items-center">
            <!-- <q-avatar icon="signal_wifi_off" color="primary" text-color="white" /> -->
            <div class="row text-row">
              <div class="col-3">
                <q-img
                  v-if="logo"
                  :src="logo"
                  spinner-color="white"
                  style="height: 6.5vh; width: 6.5vh"
                  alt="icon"
                />
              </div>
              <div class="col-9">
                <span class="text">Pathway App</span>
                <span class="sub-text">Pathway.co.uk</span>
              </div>
            </div>
          </q-card-section>

          <q-card-actions class="text-center" style="padding-top: 2.5vh">
            <q-btn flat label="Cancel" class="blue" @click="dismiss()" v-close-popup/>
            <q-btn label="Add" class="q-btn--standard text-white addto-homescreen-add" unelevated rounded @click="addToHomescreen()" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>
      <push-notifications-prompt></push-notifications-prompt>
    </q-page-container>
  </q-layout>
</template>

<script>

import moment from 'moment'
import wb from './registerServiceWorker'
import MainMenu from './components/MainMenu'
import AppHeader from './components/AppHeader'
import { mapGetters, mapActions } from 'vuex'
import BackgroundLoading from '@/components/AppHeaders/BackgroundLoading'
import PushNotificationsPrompt from '@/components/PushNotificationsPrompt'
import trapModalFocus from '@/mixins/trapModalFocus'
import { subscribe, isSupported } from 'on-screen-keyboard-detector'

export default {
  name: 'App',
  components: {
    PushNotificationsPrompt,
    BackgroundLoading,
    MainMenu,
    AppHeader
  },
  mixins: [trapModalFocus],
  data () {
    return {
      deferredPrompt: null,
      beforeinstallprompt: false,
      addToHomeDialog: false,
      today: new Date(),
      logo: window.logo,
      updateAvailable: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'nonce'])
  },
  methods: {
    ...mapActions(['setSocket', 'setWebPush']),
    ...mapActions(['setOffline']),
    addToHomescreen () {
      this.deferredPrompt.prompt()
      this.deferredPrompt = null
    },
    async dismiss () {
      localStorage.addToHomescreen = moment()
      this.deferredPrompt = null
    },
    refresh () {
      wb.addEventListener('controlling', (event) => {
        window.location.reload()
      })
      wb.messageSW({ type: 'SKIP_WAITING' })
      this.updateAvailable = false
    },
    dismissBanner () {
      this.updateAvailable = false
    },
    showIosNotification () {
      if (window.navigator.standalone === false && this.currentUser.active) {
        this.$q.notify({
          message: '<div class="ios-box"><span class="add-info"><i class="fas fa-plus"></i></span> <div class="text">Install this webapp on your iPhone: tap <span class="add-icon"></span> and then Add to homescreen</div></div>',
          html: true,
          classes: 'notifcatoin-holder',
          timeout: 20000
        })
      }
    },
    openDialog () {
      if (this.deferredPrompt && (localStorage.addToHomescreen === undefined || moment() > moment(localStorage.addToHomescreen).add(14, 'days')) && !this.beforeinstallprompt && this.$q.platform.is.mobile && this.currentUser.active) {
        this.addToHomeDialog = true
        this.beforeinstallprompt = true
      }
      this.showIosNotification()
    },
    setScript () {
      if (this.currentUser.user_id) {
        const customScript = document.createElement('script')
        customScript.setAttribute('nonce', this.nonce)
        if (this.currentUser.userType === 'SW' || this.currentUser.userType === 'PA') {
          customScript.setAttribute('src', 'https://desk.zoho.eu/portal/api/web/inapp/98445000000192315?orgId=20081423958')
        } else {
          customScript.setAttribute('src', 'https://desk.zoho.eu/portal/api/web/inapp/98445000000192299?orgId=20081423958')
        }
        document.head.appendChild(customScript)
      }
    }
  },
  mounted () {
    document.addEventListener('update-nonce', () => {
      this.setScript()
    })
    this.setScript()
    window.quasar = this.$q

    this.$root.$on('addToHomeScreen', this.openDialog)

    if (wb) {
      wb.addEventListener('waiting', (event) => {
        this.updateAvailable = true
      })
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.deferredPrompt = e
      if ((localStorage.addToHomescreen === undefined || moment() > moment(localStorage.addToHomescreen).add(14, 'days')) && !this.beforeinstallprompt && this.$q.platform.is.mobile && this.currentUser.active) {
        this.addToHomeDialog = true // This opens the Add to home screen dialog
        this.beforeinstallprompt = true
      }
    })
    this.showIosNotification()
    this.setSocket()

    window.addEventListener('online', () => {
      this.$q.notify({
        message: 'You are back online',
        color: 'green'
      })
      this.setOffline(false)
    })

    window.addEventListener('offline', () => {
      this.$q.notify({
        message: 'You are offline',
        color: 'red'
      })
      this.setOffline(true)
    })

    window.addEventListener('load', () => {
      var viewport = document.querySelector('meta[name=viewport]')
      viewport.setAttribute('content', viewport.content + ', height=' + window.innerHeight)
    })
    console.log('platform is ios and mobile', this.$q.platform.is.ios, this.$q.platform.is.mobile)
    if (!this.$q.platform.is.ios && this.$q.platform.is.mobile) {
      if (isSupported()) {
        subscribe(visibility => {
          document.querySelectorAll('.q-dialog--modal').forEach(el => {
            if (el.id !== 'date-modal') {
              visibility === 'visible' ? el.querySelector('.q-card').classList.add('resize-modal') : el.querySelector('.q-card').classList.remove('resize-modal')
            }
          })
        })
      }
    }
  }
}
</script>

<style>
</style>
