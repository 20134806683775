<template>
  <div>
    <vue-title title="Pathway Plan dashboard"></vue-title>
    <pathway-plan v-if="currentUser.userType === 'YP'"></pathway-plan>
    <sw-pp-dashboard v-else></sw-pp-dashboard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PathwayPlan from './PathwayPlan'
import SwPpDashboard from './SWPA/SwPpDashboard'

export default {
  name: 'PpDashboard',
  components: {
    PathwayPlan,
    SwPpDashboard
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>
