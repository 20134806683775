<template>
  <div>
    <notification></notification>

    <!-- Calendar -->
    <div class="calendar-holder text-center" style="padding-top: 10px;">
      <div class="month-year" :aria-label="selectedDate.format('MMMM YYYY')">{{ selectedDate.format('MMMM YYYY') }}</div>
      <q-virtual-scroll virtual-scroll-horizontal :items="calendarItems">
        <template v-slot="{ item, index }">
          <div tabindex="0" role="button" :key="index" @keyup.enter="changeDate(item.dateObj)"
                :class="['date-box text-center scrolling-card', { 'active': item.dateObj.format('DD-MM-YYYY') === todayDate.format('DD-MM-YYYY') }, { 'selected': item.dateObj.format('DD-MM-YYYY') === selectedDate.format('DD-MM-YYYY') }, { 'bullet': item.tasks.length }]"
                @click="changeDate(item.dateObj)"> <!-- add active and selected classes -->
            <div class="date-box-content">
              <span class="dot"></span>
              <span class="monthDay">{{ item.dateObj.format('DD') }}</span>
              <span class="weekDay" :aria-label="item.dateObj.format('dddd')">{{ item.dateObj.format('ddd') }}</span>
            </div>
          </div>
        </template>
      </q-virtual-scroll>
    </div>

    <div class="home-taks">
      <div class="q-pa-md">
        <h2 aria-label="Upcoming Tasks">Upcoming tasks</h2>
        <div class="tasks-content">
          <task-list type="upcoming" label="upcoming" :tasks="upcomingTasks"></task-list>
        </div>
        <div class="text-center view-btn">
          <q-btn class="add custom-add" flat :to="{ name: 'Tasks' }" aria-label="View All tasks">View all </q-btn>
        </div>
      </div>
    </div>

    <!-- Open Pathway Plans -->
    <div class="q-pa-md q-gutter-md open-p" style="padding-top: 0; margin-top: 0.8vh" v-if="openPp">
      <p class="title text-center" aria-label="Open Pathway Plans">Open Pathway Plans</p>
      <div>
        <div class="next-box-holder" tabindex="0" @click="goToYpPp(openPp)" @keyup.enter="goToYpPp(openPp)">
          <div class="ph-title-box">
            <div class="row">
              <div class="col-2">
                <div class="card-avatar">
                  <q-avatar size="52x">
                    <img v-if="openPp.avatar && openPp.avatar.length" :src="openPp.avatar" alt="avatar">
                    <img v-else :src="avatar" alt="avatar">
                  </q-avatar>
                </div>
              </div>
              <div class="col-10">
                <h2>{{ openPp.first_name + ' ' + openPp.last_name }} <span class="on-right material-icons-outlined">navigate_next</span>
                </h2>
                <p>It needs signing by: {{ openPp.due_date ? formatDate(openPp.due_date, 'YYYY-MM-DD', 'DD/MM/YYYY') : '' }}</p>
              </div>
            </div>
          </div>
          <pp-linear-progress :pp-calculation="openPp.ppCalculation"></pp-linear-progress>
        </div>
        <div class="text-center view-btn">
          <q-btn class="add custom-add" flat :to="{ name: 'PpDashboard' }" aria-label="View Pathway Plans">View Pathway Plans</q-btn>
        </div>
      </div>
    </div>
    <!-- <div class="badges-section text-center">
      <h3>Badges You've Earned</h3>
      <div class="no-badges">
        <img :src="badges">
      </div>
      <p>As you complete tasks, any badges you are rewarded will appear here. </p>
      <div class="text-center view-btn">
        <q-btn class="add custom-add" flat label="View Tasks"/>
      </div>
    </div> -->
    <!-- <div class="start-section">
      <div class="img-box">
        <img :src="illustration">
      </div>
      <div class="q-pa-md text-box text-center">
        <p>Start filling in your next <br> Pathway Plan </p>
      </div>
    </div> -->
    <!-- <div class="badges-section text-center">
      <h3>Badges You've Earned</h3>
      <div class="badges-list">
        <span class="badge-item">🎓</span>
        <span class="badge-item">🏗</span>
        <span class="badge-item">🚩</span>
        <span class="badge-item">🥑</span>
        <span class="badge-item">💰</span>
        <span class="badge-item">🏗</span>
        <span class="badge-item">🎓</span>
        <span class="badge-item">🥑</span>
      </div>
      <p class="continue">Keep going! For each done task you will get badges! </p>
      <div class="text-center view-btn">
        <q-btn class="add custom-add" flat label="View Tasks"/>
      </div>
    </div> -->
    <div class="home-contacts recent-caseload">
      <recent-caseload></recent-caseload>
    </div>
  </div>
</template>

<script>
import badges from '../../assets/badges-illust.png'
import illustration from '../../assets/start-illustration.png'
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import TaskList from '@/components/Tasks/TaskList'
import Notification from '@/components/Utils/Notification'
import avatar from '../../assets/av.png'
import PpLinearProgress from '@/components/PathwayPlan/PpLinearProgress'
import RecentCaseload from '@/components/MyCaseLoad/RecentCaseload'

export default {
  name: 'SWPAHome',
  components: {
    TaskList,
    Notification,
    PpLinearProgress,
    RecentCaseload
  },
  data () {
    return {
      badges: badges,
      illustration: illustration,
      right: null,
      showNoPpPage: false,
      nextPpBtnDisabled: true,
      nextPpDueDate: null,
      nextPpOpenDate: null,
      selectedDate: moment(),
      todayDate: moment(),
      avatar: avatar
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'pathwayPlan', 'tasks', 'myCaseLoad', 'isOffline']),
    upcomingTasks () {
      const calendarItemIndex = this.calendarItems.findIndex(item => {
        return item.dateObj.format('DD-MM-YYYY') === this.selectedDate.format('DD-MM-YYYY')
      })
      if (calendarItemIndex > -1) {
        return this.calendarItems[calendarItemIndex].tasks
      }
      return []
    },
    calendarItems () {
      const startDate = moment()
      const endDate = moment().add(14, 'days')
      const currDate = moment(startDate)
      const dateTasks = []
      let obj = {
        dateObj: currDate.clone(),
        tasks: this.tasks.filter(task => {
          return task.due_date === currDate.clone().format('YYYY-MM-DD') && (task.assignedTo.id === this.currentUser.user_id)
        })
      }
      dateTasks.push(obj)
      while (currDate.add(1, 'days').diff(endDate) < 0) {
        obj = {
          dateObj: currDate.clone(),
          tasks: this.tasks.filter(task => {
            return task.due_date === currDate.clone().format('YYYY-MM-DD') && (task.assignedTo.id === this.currentUser.user_id)
          })
        }
        dateTasks.push(obj)
      }
      return dateTasks
    },
    openPp () {
      if (this.pathwayPlan.next && this.pathwayPlan.next.length) {
        return this.sortOpenPp(this.pathwayPlan.next)
      }
      return null
    }
  },
  methods: {
    ...mapMutations(['setYp']),
    changeDate (item) {
      this.selectedDate = item
    },
    formatDate (date, fromFormat, toFormat) {
      return moment(date, fromFormat).format(toFormat)
    },
    sortOpenPp (pp) {
      return pp.sort((a, b) => moment(a.due_date).unix() - moment(b.due_date).unix())[0]
    },
    goToYpPp (pp) {
      if (this.myCaseLoad && pp && !this.isOffline) {
        const yp = this.myCaseLoad.find(yp => yp.id === pp.user_id)
        console.log('yp', yp)
        if (yp) {
          this.setYp(yp)
          this.$router.push({ name: 'PathwayPlan', query: { tab: 'next', ypId: yp.id } })
        }
      }
    }
  },
  mounted () {
    if (!this.currentUser.active) {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
