<template>
    <q-card v-if="modalOpen">
      <q-card-section>
        <div class="text-h6 text-center">Currently the {{ editingEntity }} is editing the {{ sectionLabel }}.</div>
        <div class="text-center content-lock">
          <q-icon name="lock" class="text-red" style="font-size: 3rem;"/>
        </div>
      </q-card-section>
      <q-card-actions class="text-center" v-if="['SW', 'PA'].includes(currentUser.userType)">
        <q-btn v-if="!isSpecialLock && ['pp', 'profile', 'contact', 'task'].includes(section)" class="blue" flat
               label="Take Over" @click="takeOver"/>
        <q-btn color="primary" rounded @click="exit">
          {{ !isPathwayPlan ? 'Exit' : 'OK' }}
        </q-btn>
      </q-card-actions>
      <q-card-actions class="text-center" v-else>
        <q-btn v-if="!lockedOnMounted && !['task', 'contact'].includes(section)" class="blue" flat @click="saveAndExit">
          Save {{ !isPathwayPlan ? 'and Exit' : '' }}
        </q-btn>
        <q-btn color="primary" rounded @click="exit">
          {{ !isPathwayPlan ? 'Exit' : 'OK' }}
        </q-btn>
      </q-card-actions>
    </q-card>
</template>

<script>
import showLock from '@/mixins/showLock'

export default {
  name: 'Lock',
  mixins: [showLock],
  props: {
    display: {
      type: Boolean,
      default: true
    },
    parentLockOnMounted: {
      default: null
    }
  },
  computed: {
    isSpecialLock () {
      if (this.currentYp) {
        const assignedIds = this.currentYp.assigned.map(user => user.id)
        return this.locks.filter(lk => lk.by !== this.currentUser.user_id && assignedIds.includes(lk.by)).length > 0
      }
      return false
    }
  }
}
</script>
