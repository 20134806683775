<template>
  <div class="q-pa-md">
    <div style="font-weight: bold" class="text-center badge-titile" role="heading" aria-level="2">Achieved Badges</div>
    <assign-badges></assign-badges>
  </div>
</template>

<script>
import AssignBadges from '@/components/Tasks/AssignBadges'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AchievementBadges',
  components: { AssignBadges },
  computed: {
    ...mapGetters(['badges'])
  },
  methods: {
    ...mapActions(['setAppBadges', 'getYpBadges'])
  }
}
</script>

<style scoped>

</style>
