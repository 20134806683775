<template>
  <div>
    <div class="text-h6 text-center" role="heading" aria-level="2">Contact Details</div>
    <q-form class="contact-details-form" ref="contactDetails">
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="mainPhone">Mobile Phone</label>
          <q-input :readonly="loading || isOffline" :rules="[val => val.match(/^([0-9]\s*|-*|\+*|\(-*|\)*|\.*)*$/g) !== null || 'Please type a valid phone number']" v-model="contactDetails.mainPhone" name="mainPhone" outlined placeholder="Enter your phone number" for="mainPhone"/>
        </div>
        <q-btn v-if="!(loading || isOffline)" @click="addInfo('phoneNumbers')" class="add add-phone custom-add blue" flat><span class="plus-icon material-icons-outlined">add</span> Add phone</q-btn>
      </div>
      <div class="form-row" v-for="(_, idx) in contactDetails.phoneNumbers" :key="'phoneNumbers' + idx">
        <div class="q-gutter-y-md column">
          <q-input
            :readonly="loading || isOffline"
            :rules="[
              val => val !== null && val !== '' || 'Please type your phone number',
              val => val.match(/^([0-9]\s*|-*|\+*|\(-*|\)*|\.*)*$/g) !== null || 'Please type a valid phone number'
              ]"
            v-model="contactDetails.phoneNumbers[idx]" outlined placeholder="Enter your phone number" :title="'mainPhone' + idx"/>
        </div>
        <q-btn v-if="!(loading || isOffline)" @click="removeInfo('phoneNumbers', idx)" class="add add-phone custom-add blue" flat><span class="plus-icon material-icons-outlined">remove</span> Remove phone</q-btn>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="email">Email</label>
          <q-input
            :value="contactDetails.email" type="email" outlined readonly placeholder="Enter your email" for="email"/>
        </div>
        <q-btn v-if="!(loading || isOffline)"
               @click="addInfo('emails')" class="add add-email custom-add blue" flat><span class="plus-icon material-icons-outlined">add</span> Add email</q-btn>
      </div>
      <div class="form-row" v-for="(_, idx) in contactDetails.emails" :key="'emails' + idx">
        <div class="q-gutter-y-md column">
          <q-input type="email" :rules="[val => validEmail(val) || emailErrMsg]" :readonly="loading || isOffline" v-model="contactDetails.emails[idx]" outlined placeholder="Enter your email" :title="'email' + idx"/>
        </div>
        <q-btn v-if="!(loading || isOffline)" @click="removeInfo('emails')" class="add add-phone custom-add blue" flat><span class="plus-icon material-icons-outlined">remove</span> Remove email</q-btn>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="address">Address</label>
          <q-input
            :readonly="loading || isOffline"
            type="address"
            outlined placeholder="Your current address"
            v-model="contactDetails.address"
            for="address"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="city">City</label>
          <q-input
            :readonly="loading || isOffline"
            outlined placeholder="City"
            v-model="contactDetails.city"
            for="city"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="postcode">Postcode</label>
          <q-input
            :readonly="loading || isOffline"
            outlined placeholder="Postcode"
            v-model="contactDetails.postcode"
            for="postcode"
          />
        </div>
      </div>
      <div>
        <div class="saveBtn row" ref="saveBtn">
          <div class="col">
            <q-btn @click="$emit('prev')" class="float-left q-btn--standard" outline rounded color="primary"
                   label="Previous" aria-label="previous" role="button"/>
          </div>
          <div class="col">
            <q-btn @click="validate(true)" class="float-right q-btn--standard" unelevated rounded color="primary"
                   label="Next" :disable="isOffline" :loading="loading" aria-label="next" role="button"/>
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContactDetails',
  props: {
    valid: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contactDetails: {
        mainPhone: '',
        phoneNumbers: [],
        emails: [],
        address: '',
        city: '',
        postcode: ''
      },
      loading: false,
      emailErrMsg: ''
    }
  },
  computed: {
    ...mapGetters(['userContactDetails', 'currentUser', 'currentYp', 'backgroundLoading', 'isOffline'])
  },
  mounted () {
    this.updateLocalData()
    this.$root.$on('save:profile', this.validate)
  },
  methods: {
    ...mapActions(['setContactDetails']),
    updateLocalData () {
      if (this.currentYp) {
        this.contactDetails = Object.assign({}, this.currentYp.contactDetails)
      } else {
        if (this.userContactDetails !== null) {
          this.contactDetails = JSON.parse(JSON.stringify(Object.assign({}, this.userContactDetails)))
        }
      }
    },
    addInfo (type) {
      this.contactDetails[type].push('')
    },
    removeInfo (type, idx) {
      this.contactDetails[type].splice(idx, 1)
    },
    validEmail (email) {
      if (email === '' || email === null) {
        this.emailErrMsg = 'Enter email'
        return false
      } else if (email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g) === null) {
        this.emailErrMsg = 'Enter valid email'
        return false
      } else if (this.contactDetails.email === email || this.countOccurrences(this.contactDetails.emails, email) > 1) {
        this.emailErrMsg = 'email already added'
        return false
      }
      return true
    },
    validate (event = false) {
      this.$refs.contactDetails.validate().then(success => {
        if (success) {
          this.loading = true
          console.log('this.contactDetails', this.contactDetails)
          this.setContactDetails(this.contactDetails).then((data) => {
            this.loading = false
            if (data.status === 200) {
              if (data.message) {
                this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'green',
                  position: 'top-right'
                })
              }
              this.updateLocalData()
              if (event) {
                this.$emit('valid')
              }
            } else {
              this.$q.notify({
                message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                html: true,
                color: 'red',
                position: 'top-right'
              })
            }
          })
        }
      })
    },
    countOccurrences (arr, val) {
      return arr.reduce((a, v) => (v === val ? a + 1 : a), 0)
    }
  },
  beforeDestroy () {
    this.$root.$off('save:profile')
  },
  watch: {
    backgroundLoading (status) {
      if (!status) {
        this.updateLocalData()
      }
    }
  }
}
</script>

<style scoped>

</style>
