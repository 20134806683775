<template>
  <div>
    <q-header class="edit-profile-header" v-if="['SwViewProfile', 'SwEditProfile'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back back-profile" @click="$router.go(-1)" flat round dense icon="arrow_back_ios" aria-label="back"/>
        </div>
        <div class="col-7 text-left" v-if="$route.name === 'SwEditProfile'">
          <q-toolbar-title role="heading" aria-level="1">Editing My Profile</q-toolbar-title>
        </div>
        <div class="col-7 text-center" v-if="$route.name === 'SwViewProfile'">
          <q-toolbar-title class="text-large" role="heading" aria-level="1">My Profile</q-toolbar-title>
        </div>
        <div class="col-3 text-right">
          <q-btn :disable="isOffline" v-if="$route.name === 'SwEditProfile'" @click="!isOffline &&$root.$emit('save:profile')" outline rounded
                 label="Save"/>
          <q-btn :disable="isOffline" class="edit-style" :to="{ name: 'SwEditProfile' }" v-if="$route.name === 'SwViewProfile'" outline rounded
                 ><span class="material-icons-outlined">edit</span> Edit</q-btn>
        </div>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import avatar from '../../assets/av.png'

export default {
  name: 'AppHeaderSwProfile',
  computed: {
    ...mapGetters(['appDrawerOpen', 'userGeneralInfo', 'currentContact', 'currentUser', 'isOffline']),
    username () {
      return this.userGeneralInfo.firstName !== '' ? this.userGeneralInfo.firstName : 'User'
    },
    avatar () {
      return typeof this.userGeneralInfo.avatar === 'string' && this.userGeneralInfo.avatar.length ? this.userGeneralInfo.avatar : avatar
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>
