export function groupContacts (data, groupBy = 'firstName') {
  try {
    return Object.values(data.sort((a, b) => a[groupBy].localeCompare(b[groupBy])).reduce((r, e) => {
      // get first letter of name of current element
      const group = e[groupBy].toUpperCase()[0]
      // if there is no property in accumulator with this letter create it
      if (!r[group]) {
        r[group] = {
          group,
          children: [e]
        } // if there is push current element to children array for that letter
      } else {
        r[group].children.push(e)
      }
      // return accumulator
      return r
    }, {}))
  } catch (e) {
    console.log(e)
    return []
  }
}
export function groupServices (data, groupBy = 'serviceCategory') {
  try {
    return Object.values(data.sort((a, b) => a[groupBy].localeCompare(b[groupBy])).reduce((r, e) => {
      // get first letter of name of current element
      const group = e[groupBy]
      const groupIcon = e[groupBy + 'Icon']
      // if there is no property in accumulator with this letter create it
      if (!r[group]) {
        r[group] = {
          group,
          groupIcon,
          children: [e]
        } // if there is push current element to children array for that letter
      } else {
        r[group].children.push(e)
      }
      // return accumulator
      return r
    }, {}))
  } catch (e) {
    console.log(e)
    return []
  }
}
