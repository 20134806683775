<template>
  <div>
    <vue-title title="Caseload profile"></vue-title>
    <notification floating></notification>
    <div class="contact-actions text-center contact-actions-custom left-btns">
      <q-btn v-if="currentYp.contactDetails && currentYp.contactDetails.mainPhone" type="a" round outline icon="call" :href="'tel:' + currentYp.contactDetails.mainPhone" aria-label="dial" role="button"/>
      <q-btn v-if="currentYp.contactDetails && currentYp.contactDetails.email" type="a" round outline icon="email" :href="'mailto:' + currentYp.contactDetails.email" aria-label="email" role="button"/>
      <q-btn v-if="currentYp.contactDetails && currentYp.contactDetails.mainPhone" type="a" round outline icon="chat" :href="'sms:' + currentYp.contactDetails.mainPhone" aria-label="sms" role="button"/>
      <q-btn class="share-button " outline rounded @click="assignModalOpen = true">Add Task</q-btn>
      <div class="clear"></div>
    </div>
    <div class="contacts-holder list-view">
      <div class="list-contacts-holder">
        <q-list separator>
          <q-item @click="goTo(link)" clickable v-ripple v-for="(link, idx) in links" :key="idx">
            <q-item-section style="text-transform: uppercase">
              {{ link.label }}
            </q-item-section>
            <q-item-section side><span class="material-icons-outlined">arrow_forward_ios</span></q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
    <assign-task :task-assigned-msg="taskAssignedMsg" v-model="assignModalOpen" :emit="false" :yp="currentYp"></assign-task>
    <remove-caseload @removed="$router.push({ name: 'MyCaseLoad' })" v-model="toRemoveYp"></remove-caseload>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import AssignTask from '@/components/Tasks/AssignTask'
import Notification from '@/components/Utils/Notification'
import RemoveCaseload from '@/components/MyCaseLoad/CaseloadConfirmation'

export default {
  name: 'YpMainView',
  components: {
    Notification,
    AssignTask,
    RemoveCaseload
  },
  data () {
    return {
      links: [
        {
          label: 'General Information',
          to: 'ViewProfile'
        },
        {
          label: 'Pathway Plans',
          to: 'PathwayPlan'
        },
        {
          label: 'Tasks',
          to: 'YpTasks'
        },
        {
          label: 'People Supporting Me',
          to: 'YpSupportingPeople'
        },
        {
          label: 'Contacts',
          to: 'YpContacts'
        },
        {
          label: 'Achievement Badges',
          to: 'YpAchievementBadges'
        }
      ],
      assignModalOpen: false,
      toRemoveYp: false
    }
  },
  computed: {
    ...mapGetters(['currentYp']),
    taskAssignedMsg () {
      if (this.currentYp) {
        return 'Task for ' + this.currentYp.firstName + ' ' + this.currentYp.lastName + ' was added successfully.'
      }
      return null
    }
  },
  methods: {
    ...mapMutations(['clearYp']),
    removeYp () {
      this.toRemoveYp = Object.assign({}, this.currentYp)
      this.toRemoveYp.isMyCaseLoad = true
    },
    goTo (link) {
      if (this.currentYp) {
        this.$router.push({ name: link.to, params: { previousRoute: this.$route.name }, query: { tab: 'current', ypId: this.currentYp.id } })
      }
    }
  },
  mounted () {
    if (!this.currentYp) {
      this.$router.push({ name: 'Home' })
    } else {
      this.$q.loading.hide()
    }
    this.$root.$on('caseload:removeYp', this.removeYp)
  },
  beforeDestroy () {
    this.$root.$off('caseload:removeYp', this.removeYp)
  }
}
</script>

<style scoped>

</style>
