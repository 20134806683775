<template>
  <div v-if="ppCalculation">
    <div class="liniar-progrress-holder">
      <div class="row q-pa-md">
        <div class="col-2" :aria-label="ppCalculation.about.percentage + ' % completed all about me'">
          <q-linear-progress rounded size="8px" role="progressbar" aria-label="about me" :aria-valuenow="ppCalculation.about.linearProgress" :value="ppCalculation.about.linearProgress" color="warning" class="about_color q-mt-sm"/>
          <span class="label">About me</span>
        </div>
        <div class="col-2" :aria-label="ppCalculation.overall.percentage + ' % completed overall'">
          <q-linear-progress rounded size="8px" role="progressbar" aria-label="overall" :aria-valuenow="ppCalculation.overall.linearProgress" :value="ppCalculation.overall.linearProgress" color="warning" class="overall_color q-mt-sm"/>
          <span class="label">Overall</span>
        </div>
        <div class="col-2" :aria-label="ppCalculation.education.percentage + ' % completed education and training'">
          <q-linear-progress rounded size="8px" role="progressbar" aria-label="education and training" :aria-valuenow="ppCalculation.education.linearProgress" :value="ppCalculation.education.linearProgress" color="warning" class="education_color q-mt-sm"/>
          <span class="label">Education /training</span>
        </div>
        <div class="col-2" :aria-label="ppCalculation.managing.percentage + ' % completed living'">
          <q-linear-progress rounded size="8px" role="progressbar" aria-label="living" :aria-valuenow="ppCalculation.managing.linearProgress" :value="ppCalculation.managing.linearProgress" color="warning" class="managing_color q-mt-sm"/>
          <span class="label">Living</span>
        </div>
        <div class="col-2" :aria-label="ppCalculation.health.percentage + ' % completed health'">
          <q-linear-progress rounded size="8px" role="progressbar" aria-label="health" :aria-valuenow="ppCalculation.health.linearProgress" :value="ppCalculation.health.linearProgress" color="warning" class="health_color q-mt-sm"/>
          <span class="label">Health</span>
        </div>
        <div class="col-2" :aria-label="ppCalculation.money.percentage + ' % completed my money'">
          <q-linear-progress rounded size="8px" role="progressbar" aria-label="money" :aria-valuenow="ppCalculation.money.linearProgress" :value="ppCalculation.money.linearProgress" color="warning" class="money_color q-mt-sm"/>
          <span class="label">Money</span>
        </div>
      </div>
      <div class="liniar-progrress-text">{{ ppCalculation.totalPercent }}% <span>Total</span></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PpLinearProgress',
  props: {
    ppCalculation: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>
