<template>
  <div>
    <vue-title v-if="renderTitle" :title="currentYp ? 'Caseload tasks' : 'Tasks'"></vue-title>
    <div v-if="search" class="search-holder">
      <search-bar></search-bar>
    </div>
    <task-tabs :pp-section="ppSection" :tasks="filteredTasks" color="c-purple" v-if="(currentUser.userType === 'YP') || currentYp" :show-task-assign-btn="showTaskAssignBtn"></task-tabs>
    <tasks-tabs-sw v-if="['SW', 'PA'].includes(currentUser.userType) && !currentYp" :tasks="filteredTasks" :pp-section="ppSection"></tasks-tabs-sw>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TaskTabs from '@/components/Tasks/TaskTabs'
import TasksTabsSw from '../components/Tasks/TaskTabsSw'
import SearchBar from '@/components/Utils/SearchBar'

export default {
  name: 'Tasks',
  components: {
    TasksTabsSw,
    TaskTabs,
    SearchBar
  },
  props: {
    showTaskAssignBtn: {
      type: Boolean,
      default () {
        return true
      }
    },
    ppSection: {
      type: String,
      default () {
        return null
      }
    },
    pp: {
      type: Object,
      default () {
        return null
      }
    },
    renderTitle: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      search: false
    }
  },
  computed: {
    ...mapGetters(['tasks', 'currentUser', 'currentYp']),
    filteredTasks () {
      if (this.ppSection && this.pp) {
        return this.tasks.filter(task => {
          return task.section === this.ppSection && task.pp_id === this.pp.id
        })
      }
      return this.tasks
    }
  },
  methods: {
    ...mapActions(['getUserTasks', 'getAssignedUsers', 'clearYp']),
    ...mapMutations(['clearSearch']),
    showSearch () {
      this.search = !this.search
      if (!this.search) {
        this.clearSearch()
      }
    }
  },
  mounted () {
    if (this.$route.name === 'Tasks') {
      this.clearYp()
    }
    if (!this.currentUser.active) {
      return this.$router.push({
        name: 'Login'
      })
    }
    this.$root.$on('task::showSearch', this.showSearch)
    this.getUserTasks().catch(e => {
    })
    this.getAssignedUsers().catch(e => {
    })
  },
  beforeDestroy () {
    this.clearSearch()
    this.$root.$off('task::showSearch', this.showSearch)
  }
}
</script>
