import api from '../../api'
import userHelper from '../utils/userHelpers'
import Loading from 'quasar/src/plugins/Loading.js';
import toUint8Array from 'urlb64touint8array'

let webPushServiceWorkerInterval = null

const state = {
  currentUser: {
    user_id: '',
    user_email: '',
    user_token: null,
    expiry: false,
    active: false,
    userType: null,
    userInfo: {
      generalInfo: null,
      contactDetails: null,
      bio: null,
      supportingPeople: null,
      settings: null,
      profileBadge: null
    },
    assigned: [],
    userBadges: [],
    articles: []
  },
  yp: false,
  recentCaseload: [],
  showToolTip: true,
  skipInfoFullCall: true,
  pushNotification: {
    endpoint: false, // for safari deviceToken, for rest web push endpoint
    dismissed: false,
    credentials: false // full credentials for Web Push
  },
  activeLock: false,
  nonce: null
}

const getters = {
  currentUser (state) {
    return state.currentUser
  },
  userGeneralInfo (state) {
    return state.currentUser.userInfo.generalInfo
  },
  userContactDetails (state) {
    return state.currentUser.userInfo.contactDetails
  },
  userBio (state) {
    return state.currentUser.userInfo.bio
  },
  assigned (state, getters) {
    if (typeof getters.currentYp === 'object') {
      return typeof getters.currentYp === 'object' && Array.isArray(getters.currentYp.assigned) ? getters.currentYp.assigned : []
    } else {
      return state.currentUser.assigned
    }
  },
  currentYp (state) {
    return state.yp
  },
  recentCaseload (state) {
    return state.recentCaseload
  },
  userBadges (state) {
    return state.currentUser.userBadges
  },
  supportingPeople (state) {
    return state.currentUser.userInfo.supportingPeople
  },
  showToolTip (state) {
    return state.showToolTip
  },
  appSettings (state) {
    return state.currentUser.userInfo.settings
  },
  articles (state) {
    return state.currentUser.articles
  },
  skipInfoFullCall (state) {
    return state.skipInfoFullCall
  },
  pushEndpoint (state) {
    return state.pushNotification.endpoint
  },
  profileBadge (state, getters) {
    if (getters.currentYp) {
      return state.yp.profileBadge
    } else {
      return state.currentUser.userInfo.profileBadge
    }
  },
  pushNotification (state, getters) {
    if (getters.currentUser.active) {
      return state.pushNotification
    } else {
      return false
    }
  },
  webPushCredentials (state) {
    return state.pushNotification.credentials
  },
  activeLock (state) {
    return state.activeLock
  },
  nonce (state) {
    return state.nonce
  }
}

const actions = {
  register (context, userData) {
    context.commit('setSkipInfoFullCall')
    return api.register(userData).then((data) => {
      return data
    })
  },
  setUser (context, data) {
    context.commit('setUser', data)
    context.dispatch('handleCredentialsChange')
  },
  loginPassword ({ commit }, userData) {
    return api.loginPassword(userData).then((data) => {
      return data
    })
  },
  login (context, userData) {
    return api.login(userData).then((data) => {
      if (data.status === 200) {
        context.commit('setUser', data)
        context.dispatch('handleCredentialsChange')
        userHelper.setUpUser({ data: data.data.info_full }, context, { userType: data.data.user_type })
        context.dispatch('registerUserPushNotifications')
        if (['93a76ff9114669c3e110efca725b5bdf', '6a46d24dcd427e1f390627ee8ec7bb62'].includes(data.data.user_id)) {
          setTimeout(() => {
            window.location.reload() // reload to remove the analytics tag
          }, 100)
        }
      }
      return data
    })
  },
  forgotPassword ({ commit }, userData) {
    return api.forgotPassword(userData).then((data) => {
      return data
    })
  },
  resetPassword ({ commit }, userData) {
    return api.resetPassword(userData).then((data) => {
      return data
    })
  },
  logOut (context) {
    const userId = context.getters.currentUser.user_id
    context.commit('setUser', null)
    context.commit('clearContacts')
    context.commit('closeAppDrawer')
    context.commit('clearTasks')
    context.commit('clearPathwayPlans')
    context.commit('clearYoungPersons')
    context.commit('clearRecentCaseload')
    context.dispatch('handleCredentialsChange', userId)
    context.commit('setNonce', null)
    api.logout()
  },
  setupUserData (context) {
    return userHelper.setUserFull(context, { userType: context.getters.currentUser.userType })
  },
  setGeneralInfo (context, userData) {
    let generalInfo = {
      token: context.state.currentUser.user_token,
      avatar: userData.avatar,
      first_name: userData.firstName,
      last_name: userData.lastName
    }
    if (context.getters.currentYp || context.getters.currentUser.userType === 'YP') {
      if (context.getters.currentYp) {
        generalInfo.yp_id = context.getters.currentYp.id
      }
      generalInfo = Object.assign(generalInfo, {
        dob: userData.dob,
        gender: userData.gender,
        gender_birth: userData.genderIdentity ? 'yes' : 'no',
        first_language: userData.firstLanguage,
        other_languages: userData.languages.join(',')
      })
    }

    if (['SW', 'PA'].includes(context.getters.currentUser.userType)) {
      generalInfo.jobTitle = userData.jobTitle
      generalInfo.phone_number = userData.phone_number
    }

    if (userData.password) {
      generalInfo.password = userData.password
    }
    return api.setUserGeneralInfo(generalInfo).then((data) => {
      if (userData.password) {
        delete userData.password
      }
      if (userData.confirmPassword) {
        delete userData.confirmPassword
      }
      if (!context.getters.currentYp) {
        context.commit('setUserGeneralInfo', userData)
      } else {
        context.commit('updateCurrentYp', {
          ...userData,
          generalInfo: userData
        })
      }
      return data
    })
  },
  setContactDetails (context, userData) {
    if (Array.isArray(userData.emails)) {
      userData.emails = userData.emails.filter(email => email.trim() !== '')
    }
    const contactDetails = {
      token: context.state.currentUser.user_token,
      mobile: userData.mainPhone,
      phone_numbers: userData.phoneNumbers.join(','),
      emails: userData.emails.join(','),
      address: userData.address,
      city: userData.city,
      postcode: userData.postcode
    }

    if (context.getters.currentYp) {
      contactDetails.yp_id = context.getters.currentYp.id
    }

    return api.setUserContactDetails(contactDetails).then((data) => {
      if (!context.getters.currentYp) {
        context.commit('setUserContactDetails', userData)
      } else {
        context.commit('updateCurrentYp', { contactDetails: userData })
      }
      return data
    })
  },
  setBio (context, userData) {
    if (Array.isArray(userData.achievements)) {
      userData.achievements = userData.achievements.filter(achievement => achievement.description.trim() !== '')
    }
    const userBio = {
      token: context.state.currentUser.user_token,
      about_me: userData.aboutMe,
      allergies: userData.medical,
      achievements: JSON.stringify(userData.achievements),
      care_history: JSON.stringify({})
    }

    if (context.getters.currentYp) {
      userBio.yp_id = context.getters.currentYp.id
    }

    return api.setUserBio(userBio).then((data) => {
      if (!context.getters.currentYp) {
        context.commit('setUserBio', userData)
      } else {
        context.commit('updateCurrentYp', { bio: userData })
      }
      return data
    })
  },
  getUserInfo (context) {
    return api.getUserInfo({ token: context.state.currentUser.user_token }).then(userData => {
      userData = userData.data
      try {
        if (context.getters.currentUser.userType === 'YP') {
          const generalInfo = {
            firstName: userData.first_name !== null ? userData.first_name : '',
            lastName: userData.last_name !== null ? userData.last_name : '',
            dob: userData.dob !== null ? userData.dob : '',
            gender: userData.gender !== null ? userData.gender : '',
            showGenderIdentity: userData.gender_birth === 'yes',
            firstLanguage: userData.first_language !== null ? userData.first_language : '',
            languages: Array.isArray(userData.other_languages) ? userData.other_languages : [],
            avatar: userData.avatar !== null ? userData.avatar : null
          }
          context.commit('setUserGeneralInfo', generalInfo)
        } else {
          const generalInfo = {
            firstName: userData.first_name !== null ? userData.first_name : '',
            lastName: userData.last_name !== null ? userData.last_name : '',
            phone_number: userData.phone_number !== null ? userData.phone_number : '',
            avatar: userData.avatar !== null ? userData.avatar : null
          }
          if (userData.type) {
            generalInfo.type = userData.type
          }
          context.commit('setUserGeneralInfo', generalInfo)
        }
      } catch (e) {
        console.log(e)
      }
      try {
        if (userData.phone_numbers.length) {
          userData.phone_numbers = userData.phone_numbers.filter(number => number.length > 0)
        }
        if (userData.emails.length) {
          userData.emails = userData.emails.filter(email => email.length > 0)
        }
        const contactDetails = {
          mainPhone: !['', null].includes(userData.mobile) ? userData.mobile : '',
          phoneNumbers: Array.isArray(userData.phone_numbers) ? userData.phone_numbers : [],
          emails: Array.isArray(userData.emails) ? userData.emails : [],
          address: userData.address !== null ? userData.address : '',
          city: userData.city !== null ? userData.city : '',
          postcode: userData.postcode !== null ? userData.postcode : ''
        }
        context.commit('setUserContactDetails', contactDetails)
      } catch (e) {
        console.log(e)
      }
      try {
        userData.achievements = JSON.parse(userData.achievements)
      } catch (e) {
        userData.achievements = []
      }
      try {
        const bio = {
          aboutMe: userData.about_me !== null ? userData.about_me : '',
          medical: userData.allergies !== null ? userData.allergies : '',
          achievements: userData.achievements
        }
        context.commit('setUserBio', bio)
      } catch (e) {
        console.log(e)
      }
    })
  },
  getAssignedUsers (context) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        let load = true
        const req = {
          token: context.getters.currentUser.user_token
        }
        if (context.getters.currentYp) {
          req.user_id = context.getters.currentYp.id
          load = context.getters.currentYp.assigned.length === 0
        } else {
          load = context.getters.currentUser.userType === 'YP' && context.getters.currentUser.assigned.length === 0
        }

        if (context.getters.backgroundLoading) {
          load = true
        }
        if (load) {
          return api.getAssignedUsers(req).then(data => {
            if (Array.isArray(data.data)) {
              if (context.getters.currentYp) {
                context.commit('setYpAssignedUsers', data.data)
              } else {
                context.commit('setAssignedUsers', data.data)
              }
            }
            resolve()
          })
        } else {
          Loading.hide()
          resolve()
        }
      } else {
        Loading.hide()
        resolve()
        // reject(Error('offline'))
      }
    })
  },
  check_link (context, hash) {
    return api.check_link(hash).then((data) => {
      return data
    })
  },
  clearYp (context, state) {
    context.commit('clearYpTasks')
    context.commit('clearYp')
    context.commit('clearYpContacts')
    context.commit('clearYpPps')
    context.commit('clearYpBadges')
  },
  setAppSettings (context, userData) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        return api.setAppSettings(userData).then((data) => {
          try {
            if (data.status === 200) {
              if (data.data) {
                context.commit('setAppSettings', data.data)
                if (window.wb) {
                  try {
                    navigator.serviceWorker.controller.postMessage({ settingsUpdate: userData })
                  } catch (e) {
                  }
                }
              }
            }
            resolve(data)
          } catch (e) {
            console.log(e)
          }
        })
      } else {
        Loading.hide()
        reject(Error('offline'))
      }
    })
  },
  setPushNotificationsSettings (context) {
    return api.setAppSettings({
      token: context.getters.currentUser.user_token,
      notifications_push: true
    }).then((data) => {
      try {
        if (data.status === 200 && data.data) {
          context.commit('setAppSettings', data.data)
        }
      } catch (e) {
        console.log(e)
      }
    })
  },
  getArticles (context) {
    const req = {
      token: context.state.currentUser.user_token
    }
    return api.getArticles(req).then(data => {
      try {
        if (data.status === 200) {
          context.commit('setArticles', data.data)
        }
        return data
      } catch (e) {
        console.log(e)
      }
    })
  },
  editProfile (context, userData) {
    const requestData = {
      token: context.state.currentUser.user_token,
      badge: userData.id
    }
    return api.setUserGeneralInfo(requestData).then((data) => {
      if (data.status === 200) {
        context.commit('setProfileBadge', {
          id: userData.id,
          icon: userData.icon,
          title: userData.title
        })
      }
      return data
    })
  },
  setWebPush (context, fromAction = false) { // sets the web push notifications
    if (context.getters.currentUser.active) {
      if ('safari' in window && 'pushNotification' in window.safari) {
        if (fromAction) {
          const checkRemotePermission = permissionData => {
            console.log(permissionData)
            if (permissionData.permission === 'default') {
              window.safari.pushNotification.requestPermission(
                process.env.VUE_APP_SAFARI_PUSH_ENDPOINT,
                process.env.VUE_APP_SAFARI_PUSH_ID,
                {
                  token: context.getters.currentUser.user_token
                },
                checkRemotePermission
              )
            } else if (permissionData.permission === 'denied') {
              context.commit('dismissPushNotification')
              context.commit('setPushEndpoint', false)
            } else if (permissionData.permission === 'granted') {
              api.registerSafariPushDeviceToken({
                token: context.getters.currentUser.user_token,
                device_token: permissionData.deviceToken
              }).then((data) => {
                if (data.status === 200) {
                  context.commit('setPushEndpoint', permissionData.deviceToken)
                  context.commit('removeDismissPushNotification')
                  context.dispatch('setPushNotificationsSettings')
                }
              })
            }
          }

          // Ensure that the user can receive Safari Push Notifications.
          const permissionData = window.safari.pushNotification.permission(process.env.VUE_APP_SAFARI_PUSH_ID)
          checkRemotePermission(permissionData)
        }
      } else if ('Notification' in window) {
        const setSubscriptionCredential = () => {
          if (!context.getters.pushEndpoint) {
            webPushServiceWorkerInterval = setInterval(() => {
              if (window.wb && window.wb.pushManager) {
                window.wb.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: toUint8Array(process.env.VUE_APP_VAPID)
                }).then(subscription => {
                  window.webPushSub = subscription
                  const p256dh = subscription.getKey('p256dh')
                  const auth = subscription.getKey('auth')
                  const webPushData = {
                    token: context.getters.currentUser.user_token,
                    auth: {
                      p256dh: p256dh ? btoa(String.fromCharCode.apply(null, new Uint8Array(p256dh))) : null,
                      auth: p256dh ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null,
                      endpoint: subscription.endpoint
                    },
                    encoding: (PushManager.supportedContentEncodings || ['aesgcm'])[0]
                  }
                  api.setWebPushNotificationsCredentials(webPushData).then(data => {
                    if (data.status === 200) {
                      context.commit('setPushEndpoint', subscription.endpoint)
                      context.commit('removeDismissPushNotification')
                      context.commit('setWebPushCredentials', {
                        auth: webPushData.auth,
                        encoding: webPushData.encoding
                      })
                      context.dispatch('setPushNotificationsSettings')
                    }
                  })
                  clearInterval(webPushServiceWorkerInterval)
                }).catch(err => {
                  clearInterval(webPushServiceWorkerInterval)
                  console.log('err', err)
                })
              }
            }, 1000)
          }
        }
        if (('Notification' in window)) {
          if (Notification.permission === 'granted') {
            setSubscriptionCredential()
          } else {
            if (fromAction) {
              try {
                Notification.requestPermission().then(permission => {
                  if (permission === 'granted') {
                    setSubscriptionCredential()
                    context.commit('setPushEndpoint', false)
                  } else {
                    if (context.getters.pushEndpoint) {
                      api.deleteWebPushNotificationEndpoint({
                        token: context.getters.currentUser.user_token,
                        endpoint: context.getters.pushEndpoint
                      })
                    }
                    context.commit('dismissPushNotification')
                    context.commit('setPushEndpoint', false)
                  }
                })
              } catch (e) {
                clearInterval(webPushServiceWorkerInterval)
              }
            }
          }
        }
      }
    }
  },
  registerUserPushNotifications (context) {
    if (context.getters.pushEndpoint && context.getters.currentUser.active) {
      if ('safari' in window && 'pushNotification' in window.safari) {
        api.registerSafariPushDeviceToken({
          token: context.getters.currentUser.user_token,
          device_token: context.getters.pushEndpoint
        })
      } else {
        if (context.getters.webPushCredentials) {
          const webPushData = Object.assign({ token: context.getters.currentUser.user_token }, context.getters.webPushCredentials)
          api.setWebPushNotificationsCredentials(webPushData)
        }
      }
    }
  },
  setNonce (context, data) {
    context.commit('setNonce', data)
  }
}

const mutations = {
  setUser (state, data) {
    if (data === null) {
      state.currentUser.user_id = ''
      state.currentUser.user_token = null
      state.currentUser.expiry = false
      state.currentUser.active = false
      state.currentUser.userType = null
      state.currentUser.user_email = null
      state.currentUser.userInfo = {
        generalInfo: null,
        contactDetails: null,
        bio: null,
        supportingPeople: null,
        settings: null,
        profileBadge: null
      }
      state.skipInfoFullCall = true
      state.currentUser.assigned = []
    } else {
      state.currentUser.user_id = data.data.user_id
      state.currentUser.user_token = data.data.user_token
      state.currentUser.expiry = data.data.expiry
      state.currentUser.active = true
      state.currentUser.userType = data.data.user_type
      state.currentUser.user_email = data.data.user_email
    }
  },
  setUserGeneralInfo (state, data) {
    if (data.avatar === '') {
      data.avatar = ''
    }
    state.currentUser.userInfo.generalInfo = data
    if (data.jobTitle) {
      state.currentUser.userType = data.jobTitle
    }
  },
  setUserContactDetails (state, data) {
    state.currentUser.userInfo.contactDetails = data
  },
  setUserBio (state, data) {
    state.currentUser.userInfo.bio = data
  },
  setAssignedUsers (state, data) {
    if (data && data.length) {
      state.currentUser.assigned = data
    }
  },
  setYpAssignedUsers (state, data) {
    if (data && data.length) {
      state.yp.assigned = data
    }
  },
  setYp (state, data) {
    state.yp = data
  },
  clearYp (state) {
    state.yp = false
  },
  setRecentCaseload (state, yp) {
    if (!state.recentCaseload.map(rcl => rcl.id).includes(yp.id)) {
      state.recentCaseload.unshift(yp)
      if (state.recentCaseload.length > 2) {
        state.recentCaseload = state.recentCaseload.slice(0, 2)
      }
    }
  },
  removeFromRecentCaseload (state, ypId) {
    state.recentCaseload = state.recentCaseload.filter(yp => yp.id === ypId)
  },
  updateCurrentYp (state, data) {
    state.yp = Object.assign(state.yp, data)
  },
  setUserBadges (state, data) {
    state.currentUser.userBadges = data
  },
  setSupportingPeople (state, data) {
    state.currentUser.userInfo.supportingPeople = data
  },
  hideToolTip (state) {
    state.showToolTip = false
  },
  clearRecentCaseload (state) {
    state.recentCaseload = []
  },
  setAppSettings (state, data) {
    if (data) {
      state.currentUser.userInfo.settings = data
    }
  },
  setArticles (state, data) {
    state.currentUser.articles = data
  },
  setSkipInfoFullCall (state) {
    state.skipInfoFullCall = false
  },
  setPushEndpoint (state, data) {
    state.pushNotification.endpoint = data
  },
  setWebPushCredentials (state, data) {
    state.pushNotification.credentials = data
  },
  setProfileBadge (state, data) {
    state.currentUser.userInfo.profileBadge = data
  },
  dismissPushNotification (state) {
    state.pushNotification.dismissed = true
  },
  removeDismissPushNotification (state) {
    state.pushNotification.dismissed = false
  },
  resetPushNotificationsData (state) {
    if (state.pushNotification.dismissed) {
      state.pushNotification = {
        endpoint: false, // for safari deviceToken, for rest web push endpoint
        dismissed: false,
        credentials: false // full credentials for Web Push
      }
    }
  },
  setActiveLock (state, data) {
    state.activeLock = data
  },
  setNonce (state, data) {
    state.nonce = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
