<template>
  <div class="forgotpass hidden-header human-background">
    <vue-title title="Forgot password"></vue-title>
    <q-img
      v-if="logo"
      :src="logo"
      spinner-color="white"
      style="max-width: 88px; margin: 0 auto; display: block"
      class="relative-position login-logo"
      alt="logo"
    />
    <h4 class="logo-text text-center" role="heading" aria-level="1">{{ title }}</h4>
    <p class="subtitle text-center">Forgot your password?</p>
    <q-form class="register-form">
      <div class="q-pa-md">
        <div class="q-gutter-y-md column">
          <label for="email">Email</label>
          <q-input v-model="email" type="email" outlined placeholder="Enter your email address" @keyup="resetErrors" for="email"/>
          <span class="error" v-if="emailError">{{ emailError }}</span>
          <q-btn @click="submitForm()" flat class="no-outline no-shadow q-btn--standard q-btn--rectangle bg-primary text-white" v-bind:class="{ disabled: isDisabled }" :disabled='isDisabled' rounded label="Reset password" aria-label="Reset password"/>
          <p class="forgot-text text-center"><router-link class="green form-text-link" :to="{ name: 'Login' }" aria-label="Log in">Log in</router-link></p>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import focusLabel from '@/mixins/focusLabel'

export default {
  name: 'ForgotPassword',
  mixins: [focusLabel],
  data () {
    return {
      title: window.title,
      logo: window.logo,
      email: '',
      emailError: ''
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isDisabled () {
      if (this.email.length) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    checkForm () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(this.email)) {
        this.emailError = 'Invalid email'
        return false
      }
      return true
    },
    submitForm () {
      if (this.checkForm()) {
        this.$q.loading.show()
        this.forgotPassword({
          email: this.email
        }).then((data) => {
          this.$q.loading.hide()
          console.log('data', data)
          if (data.status === 200) {
            return this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'green',
              position: 'top-right'
            })
          }
          return this.$q.notify({
            message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
            html: true,
            color: 'red',
            position: 'top-right'
          })
        }, err => {
          this.$q.loading.hide()
          return this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        })
      }
    },
    resetErrors () {
      this.emailError = ''
    }
  },
  mounted () {
    if (this.currentUser.active) {
      this.$router.push({
        name: 'Home'
      })
    }
  }
}
</script>

<style scoped>
span.error {
  color: red;
}
</style>
