<template>
  <div class="q-pa-md" style="padding-top: 0; padding-bottom: 0">
    <vue-title title="My profile"></vue-title>
    <div class="profile-content">
      <q-form class="general-info-form" ref="generalInfo">
        <div class="form-row avatar-form-row">
          <div class="q-gutter-y-md column text-center">
            <q-avatar class="profile-avatar" size="14.8vh">
              <img v-if="!uploadedAvatarImg" :src="avatar" alt="avatar">
              <img v-else :src="uploadedAvatarImg" alt="avatar">
            </q-avatar>
            <q-file title="photo" :disable="isOffline" v-if="!viewMode" @input="getAvatarPreview" borderless input-class="add blue custom-add text-center"
                    v-model="userInfo.avatar" label="Add photo" for="photo">
              <div slot="label" class="add blue custom-add text-center">{{ uploadedAvatarImg.length ? 'Change' : 'Add' }} photo</div>
              <div slot="file"></div>
            </q-file>
          </div>
        </div>
        <div class="form-row">
          <div class="q-gutter-y-md column">
            <label for="name">Name</label>
            <q-input
              v-model="userInfo.firstName"
              outlined
              placeholder="Enter your first name"
              :rules="[
              val => val !== null && val !== '' || 'Please type your name',
              val => val.length > 2 || 'Please type at least 3 characters'
              ]"
              :readonly="loading || viewMode || isOffline"
              for="name"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="q-gutter-y-md column">
            <label for="lastname">Last name</label>
            <q-input
              v-model="userInfo.lastName"
              outlined
              placeholder="Enter your last name"
              :rules="[
              val => val !== null && val !== '' || 'Please type your first name',
              val => val.length > 2 || 'Please type at least 3 characters'
              ]"
              :readonly="loading || viewMode || isOffline"
              for="lastname"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="q-gutter-y-md column">
            <label for="phone_number">Phone Number</label>
            <q-input
              v-model="userInfo.phone_number"
              outlined
              placeholder="Enter your phone number"
              :rules="[
              val => val !== null && val !== '' || 'Please type your phone number',
              val => val.length > 2 || 'Please type at least 3 characters'
              ]"
              :readonly="loading || viewMode || isOffline"
              for="phone_number"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="q-pt-md">
            <div class="column">
              <label for="jobtitle">Job title</label>
              <q-select :rules="[val => val !== null && val !== '' || 'Please select your job title']"
                        outlined v-model="userInfo.jobTitle" :options="(loading || viewMode || isOffline) ? [] : jobTitles"
                        :disabled="loading || viewMode || isOffline"
                        :option-label="(item) => item === null ? null : item.name"
                        icon="expand_more"
                        for="jobtitle"
                        behavior="menu"
                        :hide-dropdown-icon="loading || viewMode || isOffline"
                        :class="[{ 'select-box-disabled' : loading || viewMode || isOffline }]"
              />
            </div>
          </div>
        </div>
        <div class="form-row" v-if="!viewMode">
          <div class="q-gutter-y-md column">
            <label for="password">New password</label>
            <q-input
              v-model="userInfo.password"
              outlined
              :type="pwdType ? 'password' : 'text'"
              placeholder="Enter new password"
              :rules="[
                val => validatePassword || 'Your password should be at least 6 characters and include letters and numbers'
              ]"
              :readonly="loading || viewMode || isOffline"
              for="password"
            >
              <template v-slot:append>
                <q-icon
                  :class="pwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                  class="cursor-pointer"
                  @click="pwdType = !pwdType"
                  @keyup.enter="pwdType = !pwdType"
                  aria-label="toggle password"
                  role="button"
                  tabindex="0"
                  :aria-hidden="false"
                />
              </template>
            </q-input>
          </div>
        </div>
        <div class="form-row" v-if="!viewMode">
          <div class="q-gutter-y-md column">
            <label for="confirmPassword">Confirm password</label>
            <q-input
              v-model="userInfo.confirmPassword"
              outlined
              :type="repeatPwdType ? 'password' : 'text'"
              placeholder="Enter new password"
              :rules="[
                val => validateConfirmPassword || 'Confirm password must be same'
              ]"
              :readonly="loading || viewMode || isOffline"
              for="password"
            >
              <template v-slot:append>
                <q-icon
                  :class="repeatPwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                  class="cursor-pointer"
                  @click="repeatPwdType = !repeatPwdType"
                  @keyup.enter="repeatPwdType = !repeatPwdType"
                  aria-label="toggle password"
                  role="button"
                  tabindex="0"
                  :aria-hidden="false"
                />
              </template>
            </q-input>
          </div>
        </div>
        <div ref="saveBtn">
          <q-btn :disable="isOffline" @click="validate"
                 class="no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white"
                 unelevated
                 rounded color="primary" label="Save" :loading="loading" v-if="!viewMode"/>
        </div>
      </q-form>
    </div>
  </div>
</template>
<script>
import avatar from '../../assets/av.png'
import { mapActions, mapGetters } from 'vuex'
import scrollToTarget from '../../mixins/scrollToTarget'
import focusLabel from '@/mixins/focusLabel'

export default {
  name: 'SWGeneralInfo',
  mixins: [scrollToTarget, focusLabel],
  props: {
    valid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['userGeneralInfo', 'currentUser', 'isOffline']),
    viewMode () {
      return this.$route.name === 'SwViewProfile'
    },
    validatePassword () {
      const re = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/
      if (this.userInfo && this.userInfo.password && !re.test(this.userInfo.password)) {
        return false
      }
      return true
    },
    validateConfirmPassword () {
      if (this.userInfo && this.userInfo.password !== this.userInfo.confirmPassword) {
        return false
      }
      return true
    }
  },
  mounted () {
    this.updateLocalData()
    this.$root.$on('save:profile', this.validate)
  },
  data () {
    return {
      loading: false,
      avatar: avatar,
      uploadedAvatarImg: false,
      canNext: true,
      jobTitles: [
        {
          type: 'SW',
          name: 'Social worker'
        },
        {
          type: 'PA',
          name: 'Personal advisor'
        }
      ],
      userInfo: {
        firstName: '',
        lastName: '',
        phone_number: '',
        avatar: [],
        jobTitle: {},
        password: '',
        confirmPassword: ''

      },
      pwdType: true,
      repeatPwdType: true
    }
  },
  methods: {
    ...mapActions(['setGeneralInfo', 'getUserInfo']),
    updateLocalData () {
      if (this.userGeneralInfo !== null) {
        const userInfo = Object.assign({ password: '', confirmPassword: '' }, this.userGeneralInfo)
        if (userInfo.avatar !== null && userInfo.avatar.length) {
          this.uploadedAvatarImg = userInfo.avatar
        }
        delete userInfo.avatar
        userInfo.avatar = []
        userInfo.jobTitle = this.jobTitles.filter(jt => jt.type === this.currentUser.userType)[0]
        this.userInfo = userInfo
      }
    },
    validate () {
      this.$refs.generalInfo.validate().then(success => {
        if (success) {
          this.loading = true
          this.scroll(this.$refs.saveBtn)
          const userInfo = Object.assign({}, this.userInfo)
          if (this.uploadedAvatarImg.length) {
            userInfo.avatar = this.uploadedAvatarImg
          } else {
            userInfo.avatar = ''
          }
          userInfo.jobTitle = userInfo.jobTitle.type
          this.setGeneralInfo(userInfo).then(() => {
            this.updateLocalData()
            this.loading = false
            this.userInfo.password = ''
            this.userInfo.confirmPassword = ''
          })
        }
      })
    },
    addInfo (type) {
      this.userInfo[type].push('')
    },
    removeInfo (type, idx) {
      this.userInfo[type].splice(idx, 1)
    },
    getAvatarPreview (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = () => {
        this.uploadedAvatarImg = reader.result
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('save:profile')
  }
}
</script>
<style>
.general-info-form .q-field-radio .q-field__control {
  padding-left: 0 !important;
  padding-right: 0;
}

.general-info-form .q-field-radio .q-radio__label {
  color: #1D1D1D;
}
</style>
