<template>
  <div>
    <vue-title :title="currentYp ? 'Caseload Pathway Plan dashboard' : 'Pathway Plan dashboard'"></vue-title>
    <notification :section="'pp'"></notification>
    <div>
      <!-- <no-next-pp :from-pp-dashboard="true" v-if="showNoPpPage"></no-next-pp> -->
      <div class="tasks-content">
        <!-- <div class="success-popup text-center">
          <span class="icon"><img :src="successIcon"></span>
          <p>Pathway Plan was signed off <br> successfully! Let's get started</p>
          <q-btn class="add green custom-add" flat label="Got It!" />
        </div> -->
        <div class="q-gutter-y-md">
          <!-- <q-card> -->
          <q-tabs
            v-model="tab"
            dense
            active-color="primary"
            indicator-color="primary"
            narrow-indicator
            role="tablist"
          >
            <q-tab name="current" label="Current" role="tab"/>
            <q-tab name="next" label="Next" role="tab"/>
            <q-tab name="past" :label="'Past ' + pathwayPlan.past.length" role="tab"/>
          </q-tabs>
          <q-tab-panels style="padding: 0" v-model="tab" animated>
            <q-tab-panel name="current" style="padding: 0" role="tabpanel">
              <current :previous-route="previousRoute"></current>
            </q-tab-panel>

            <q-tab-panel name="next" style="padding: 0" role="tabpanel">
              <next :previous-route="previousRoute"></next>
            </q-tab-panel>

            <q-tab-panel name="past" style="padding: 0" role="tabpanel">
              <past :pp-list="pathwayPlan.past"></past>
            </q-tab-panel>
          </q-tab-panels>
          <!-- </q-card> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Current from '../components/PathwayPlan/Current'
import Next from '../components/PathwayPlan/Next'
import Past from '../components/PathwayPlan/Past'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import badges from '../assets/badges-illust.png'
import illustration from '../assets/start-illustration.png'
import { Loading } from 'quasar'
import moment from 'moment'
import successIcon from '../assets/compleated-icon.png'
import Notification from '@/components/Utils/Notification'

export default {
  name: 'PathwayPlan',
  components: {
    Current,
    Next,
    Past,
    Notification
  },
  data () {
    return {
      successIcon: successIcon,
      tab: this.$route.query.tab ? this.$route.query.tab : 'current',
      showPathwayDashboard: false,
      badges: badges,
      illustration: illustration,
      nextPpBtnDisabled: true,
      nextPpDueDate: null,
      nextPpOpenDate: null,
      doneLoading: false,
      openPpAdd: false,
      ppConvertedCurrent: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'pathwayPlan', 'currentYp', 'mainPp', 'backgroundLoading', 'myCaseLoad']),
    showNoPpPage () {
      if (this.currentUser.userType === 'YP') {
        if (!this.pathwayPlan.current && !this.pathwayPlan.past.length && this.pathwayPlan.next) {
          return this.pathwayPlan.next.opened === 'no' || this.pathwayPlan.next.started === 'no'
        }
      } else {
        if (!this.pathwayPlan.current && !this.pathwayPlan.past.length) {
          return (!this.pathwayPlan.next && this.doneLoading) || (this.pathwayPlan.next && (this.pathwayPlan.next.opened === 'no' || this.pathwayPlan.next.started === 'no'))
        }
      }
      return false
    },
    previousRoute () {
      return this.$route.params.previousRoute ? this.$route.params.previousRoute : (this.currentUser.userType === 'YP' ? 'PathwayPlan' : 'PpDashboard')
    }
  },
  methods: {
    ...mapActions(['getPathwayPlan', 'setPathwayPlan', 'getUserTasks', 'getPpSwPa', 'syncYpPp']),
    ...mapMutations(['setppDueDate', 'setYp']),
    triggerDashboardUpdates () {
      if (this.$route.name === 'PathwayPlan') {
        this.showPathwayDashboard = false
        this.setppDueDate(null) // For SW
        if (!this.pathwayPlan.current && !this.pathwayPlan.past.length) {
          if (this.pathwayPlan.next) {
            this.nextPpDueDate = moment(this.pathwayPlan.next.due_date, 'YYYY-MM-DD')
            this.nextPpOpenDate = moment(this.pathwayPlan.next.open_date, 'YYYY-MM-DD')
            if (this.pathwayPlan.next.opened === 'yes' && this.pathwayPlan.next.started === 'yes') {
              this.showPathwayDashboard = true
              this.tab = 'next'
            }
          }
        } else {
          this.showPathwayDashboard = true
          if (this.pathwayPlan.past.length) {
            this.tab = 'past'
          }
          if (this.pathwayPlan.next) {
            this.nextPpDueDate = moment(this.pathwayPlan.next.due_date, 'YYYY-MM-DD')
            this.nextPpOpenDate = moment(this.pathwayPlan.next.open_date, 'YYYY-MM-DD')
            this.tab = 'next'
            if (this.pathwayPlan.next.started === 'no') {
              this.nextPpBtnDisabled = false
            }
          } else {
            if (this.currentUser.userType !== 'YP') {
              this.tab = 'next'
            }
          }
          if (this.pathwayPlan.current) {
            this.tab = 'current'
            if (this.currentYp) {
              this.setppDueDate({
                dueDate: this.pathwayPlan[this.tab].expiry_date,
                tense: this.tab
              })
            }
          }
        }
        if (['past', 'current', 'next'].includes(this.$route.query.tab)) {
          this.tab = this.$route.query.tab
        }
        // if (this.currentUser.userType !== 'YP') {
        if (this.pathwayPlan[this.tab] && this.currentYp) {
          if (this.tab === 'current') {
            this.setppDueDate({
              dueDate: this.pathwayPlan[this.tab].expiry_date,
              tense: this.tab
            })
          } else {
            this.setppDueDate({
              dueDate: this.pathwayPlan[this.tab].due_date,
              tense: this.tab
            })
          }
        }
      }
      // }
    },
    startNextPp () {
      Loading.show()
      // Promise.all([
      //   this.setPathwayPlan({ token: this.currentUser.user_token, pathway_id: this.pathwayPlan.next.id, started: 'yes' }),
      //   this.getPpSwPa()
      // ]).then((values) => {
      //   console.log('values', values)
      //   Loading.hide()
      //   this.triggerDashboardUpdates()
      //   this.tab = 'next'
      // })
      this.setPathwayPlan({
        token: this.currentUser.user_token,
        pathway_id: this.pathwayPlan.next.id,
        started: 'yes'
      }).then(() => {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'PP_started', {
            event_category: 'PathwayPlan',
            event_label: 'Pathway Plan started'
          })
        }
        Loading.hide()
        this.triggerDashboardUpdates()
        this.tab = 'next'
      })
    }
  },
  watch: {
    tab (value) {
      if (this.pathwayPlan[value] && this.currentYp) {
        if (value === 'current') {
          this.setppDueDate({
            dueDate: this.pathwayPlan[value].expiry_date,
            tense: this.tab
          })
        } else {
          this.setppDueDate({
            dueDate: this.pathwayPlan[value].due_date,
            tense: value
          })
        }
      } else {
        this.setppDueDate(null)
      }
      if (this.$route.query.tab !== value) {
        if (this.currentYp) {
          this.$router.push({ name: 'PathwayPlan', query: { tab: value, ypId: this.currentYp.id }, params: { previousRoute: this.$route.params.previousRoute } })
        } else {
          this.$router.push({ name: 'PathwayPlan', query: { tab: value }, params: { previousRoute: this.$route.params.previousRoute } })
        }
      }
    },
    backgroundLoading (status) {
      if (!status) {
        if (this.currentYp) {
          this.syncYpPp()
          this.triggerDashboardUpdates()
        } else {
          this.triggerDashboardUpdates()
        }
        this.ppConvertedCurrent = this.pathwayPlan.next.opened === 'no'
        if (this.ppConvertedCurrent) {
          this.tab = 'current'
          this.ppConvertedCurrent = false
        }
      }
    },
    myCaseLoad (newval, oldval) {
      console.log('myCaseLoad', oldval, newval)
      if (oldval === null && newval && newval.length) {
        if (this.$route.query.ypId && newval && newval.length) {
          const yp = newval.find(yp => yp.id === this.$route.query.ypId)
          if (yp) {
            Loading.show()
            this.getPpSwPa().then(() => {
              this.setYp(yp)
              this.syncYpPp()
              Loading.hide()
            }, err => {
              Loading.hide()
              if (err.message !== 'offline') {
                this.$q.notify({
                  message: '<span role="region" aria-label="' + err.message + '">' + err.message + '</span>',
                  html: true,
                  color: 'red',
                  position: 'top-right'
                })
              }
            })
          }
        }
      }
    }
  },
  mounted () {
    this.triggerDashboardUpdates()
    // this.doneLoading = true
    this.$root.$on('triggerPpDashboard', this.triggerDashboardUpdates)
    if (this.currentUser.userType === 'YP' && !this.currentYp) {
      // Loading.show()
      // YP user is logged in
      this.triggerDashboardUpdates()
      Promise.all([this.getUserTasks(), this.getPathwayPlan({ pathway: 'all' })]).then(() => { // this.getUserTasks(),
        Loading.hide()
        this.doneLoading = true
        this.triggerDashboardUpdates()
      }, err => {
        if (err.message !== 'offline') {
          Loading.hide()
          this.doneLoading = true
          this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        } else {
          Loading.hide()
          this.doneLoading = true
          this.triggerDashboardUpdates()
        }
      })
    } else {
      // SW/PA  logged in and selected YP user
      // Filter YP pps from SW pp data
      if (this.$route.query.ypId) {
        const yp = this.myCaseLoad.find(yp => yp.id === this.$route.query.ypId)
        if (yp) {
          this.setYp(yp)
        }
      }
      if (this.currentYp) {
        Loading.show()
        this.getUserTasks().then(() => {
          this.syncYpPp()
          this.doneLoading = true
          this.triggerDashboardUpdates()
        }).catch(e => {
          if (e.message === 'offline') {
            this.syncYpPp()
            this.doneLoading = true
            this.triggerDashboardUpdates()
          }
        })
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('triggerPpDashboard', this.triggerDashboardUpdates)
  }
}
</script>
