export function calculatePp (pp) {
  try {
    const nextSectionFields = {
      about: ['first_name', 'last_name', 'dob', 'gender', 'disabled', 'legal_status', 'leaving_care_status', 'Immigration_status', 'who_has_got_my_birth_certificate', 'ni_number'],
      overall: ['overall_plan_feeling', 'overall_care_plan', 'attempts', 'family_relationship', 'contact_arrangements'],
      education: ['education_feeling', 'education_working_well', 'education_worried_about', 'education_next_steps', 'education_long_term_goals', 'education_contingency'],
      managing: ['managing_feeling', 'managing_working_well', 'managing_worried_about', 'managing_next_steps', 'managing_long_term_goals', 'managing_contingency'],
      health: ['health_feeling', 'health_working_well', 'health_worried_about', 'mental_health', 'health_next_steps', 'health_long_term_goals', 'health_contingency'],
      money: ['money_feeling', 'money_working_well', 'money_worried_about', 'money_next_steps', 'money_long_term_goals', 'money_contingency']
    }
    const data = {}
    for (const [key, section] of Object.entries(nextSectionFields)) {
      data[key] = {}
      data[key].totalFields = section
      data[key].completedFields = pp.started === 'yes' ? section.filter(field => pp[field] && pp[field] !== null) : []
      data[key].percentage = Math.ceil((data[key].completedFields.length / data[key].totalFields.length) * 100)
      data[key].linearProgress = parseFloat((data[key].completedFields.length / data[key].totalFields.length).toFixed(2))
    }
    pp.ppCalculation = data
    let sectionCount = 0
    let sectionPercentCount = 0
    for (const [section] of Object.entries(pp.ppCalculation)) {
      sectionPercentCount += parseInt(pp.ppCalculation[section].percentage)
      sectionCount++
    }
    pp.ppCalculation.totalPercent = Math.ceil(sectionPercentCount / sectionCount)
    return pp
  } catch (e) {
    console.log(e)
    return []
  }
}
