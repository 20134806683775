<template>
  <div>
    <div class="message-lock" v-if="isLocked && isPathwayPlan">
      <div class="icon-holder">
        <img :src="lockIcon">
      </div>
      <p class="text-center">Currently the {{ editingEntity }} is editing the {{ sectionLabel }}</p>
    </div>
    <q-dialog ref="lockModal" v-model="modalOpen" :persistent="true">
      <lock :parent-lock-on-mounted="lockedOnMounted" class="round-dialog" @closeModal="modalOpen = false"></lock>
    </q-dialog>
  </div>
</template>

<script>

import Lock from '@/components/Utils/Lock'
import showLock from '@/mixins/showLock'
import lockIcon from '@/assets/lock.png'

export default {
  name: 'LockDialog',
  components: { Lock },
  mixins: [showLock],
  data () {
    return {
      lockIcon: lockIcon
    }
  },
  computed: {
    isSpecialLock () {
      if (this.currentYp) {
        const assignedIds = this.currentYp.assigned.map(user => user.id)
        return this.locks.filter(lk => lk.by !== this.currentUser.user_id && assignedIds.includes(lk.by)).length > 0
      }
      return false
    }
  }
}

</script>

<style scoped>

</style>
