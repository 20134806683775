<template>
  <div class="date-template">
    <q-input :disable="disable" outlined v-model="selectedDate" mask="##/##/####"
             :rules="[
              val => !disable && requiredInput ? (validDate(val) || errorMsg) : true,
              ]" :placeholder="placeholder"
             @click="openModal"
             @keydown="evInput"
             :clearable="clearable"
             @keyup.native.enter="openModal"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            :readonly="readOnly"
            :title="labelId"
    >
    </q-input>
    <q-dialog id="date-modal" v-model="modalOpen" position="bottom" class="brent-dialog" role="region" aria-label="datepicker" @show="show()" @hide="hideModal()">
      <q-card class="date-card">
        <q-card-actions align="left" class="popup-header">
          <q-btn flat label="Cancel" v-close-popup aria-label="cancel"/>
          <div class="text-h6 text-center">{{ placeholder }}</div>
        </q-card-actions>
        <q-card-section class="q-pt-none">
          <q-date :options="dateOptions" :flat="true" no-unset v-model="selectedDate" mask="DD/MM/YYYY" minimal/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ModalDate',
  props: {
    value: {
      default: ''
    },
    placeholder: {
      default: ''
    },
    minDate: {
      default: false
    },
    maxDate: {
      default: false
    },
    errorMsg: {
      default: ''
    },
    disable: {
      default: false,
      type: Boolean
    },
    disableInput: {
      default: false,
      type: Boolean
    },
    requiredInput: {
      default: true,
      type: Boolean
    },
    labelId: {
      default: '',
      type: String
    },
    clearable: {
      default: false,
      type: Boolean
    },
    readOnly: {
      default: false
    },
    dateSelectable: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      selectedDate: '',
      modalOpen: false
    }
  },
  mounted () {
    if (this.value && this.value.length) {
      this.selectedDate = this.value
    }
  },
  watch: {
    selectedDate (val) {
      this.modalOpen = false
      this.$emit('input', val)
    },
    value (value) {
      this.selectedDate = value
      this.modalOpen = false
    },
    modalOpen (value) {
      this.$emit('triggerBehindModalShow', value)
    }
  },
  methods: {
    show () {
      document.querySelector('#date-modal').addEventListener('keydown', this.handleKey)
      if (document.querySelectorAll('body .q-dialog--modal .q-date__arrow button').length) {
        document.querySelectorAll('body .q-dialog--modal .q-date__arrow button').forEach(element => {
          element.setAttribute('aria-label', 'arrow')
        })
      }
    },
    evInput (e) {
      if (this.disableInput && e.keyCode !== 9) {
        e.preventDefault()
      }
    },
    validDate (date) {
      if (moment(date, 'DD/MM/YYYY', true).isValid()) {
        return this.dateOptions(date)
      } else {
        return false
      }
    },
    dateOptions (date) {
      if (moment(date, 'DD/MM/YYYY', true).format('YYYY/MM/DD') !== 'Invalid date') {
        date = moment(date, 'DD/MM/YYYY', true).format('YYYY/MM/DD')
      }
      if (this.minDate && this.maxDate) {
        return date >= this.minDate && date <= this.maxDate
      }
      if (this.minDate) {
        return date >= this.minDate
      }
      if (this.maxDate) {
        return date <= this.maxDate
      }
      return true
    },
    openModal () {
      if (!this.readOnly) {
        this.modalOpen = true
      } else if (this.dateSelectable) {
        this.modalOpen = true
      }
    },
    hideModal () {
      if (document.querySelector('#date-modal')) {
        document.querySelector('#date-modal').removeEventListener('keydown', this.handleKey)
      }
    },
    handleKey (e) {
      if (e.keyCode === 9) {
        const focusable = document.querySelector('#date-modal').querySelectorAll('input,button,select,textarea')
        if (focusable.length) {
          const first = focusable[0]
          const last = focusable[focusable.length - 1]
          const shift = e.shiftKey
          if (shift) {
            if (e.target === first) { // shift-tab pressed on first input in dialog
              last.focus()
              e.preventDefault()
            }
          } else {
            if (e.target === last) { // tab pressed on last input in dialog
              first.focus()
              e.preventDefault()
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
