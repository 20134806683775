<template>
  <div>
    <q-header class="document-viewer-header bg-white text-black" v-if="['DocumentViewer'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="done-btn" color="blue" flat @click="$router.go(-1)" aria-label="Done">Done</q-btn>
        </div>
        <div class="col-9">
          <span role="heading" aria-level="1">{{ documentTitle }}</span>
        </div>
        <div class="col-1">
          <q-btn class="done-btn" color="blue" flat @click="$router.go(-1)" aria-label="Close">
            <q-icon name="fas fa-times" class="cursor-pointer doc-close"/>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'AppHeaderDocumentViewer',
  computed: {
    ...mapGetters(['documentTitle'])
  }
}
</script>

<style scoped>
  .doc-close {
    font-size: 1.8em;
    color: #2196f3 !important;
  }
</style>
