<template>
  <div :class="['task', { 'pp-section': task.section !== '' && task.section !== null }, { 'info-card': showShade }, { 'event': task.type === 'event' }]">

    <q-card :class="['my-card', ppSectionClass, { 'border': ppSectionClass !== '' && ppSectionClass !== null }]">
      <q-card-section @click="setTask(task)" @keyup.enter="setTask(task)" tabindex="0">
        <span v-if="showStatusTag" :class="['card-label', task.status]" :aria-label="task.status">{{ task.status }}</span>
        <div class="q-gutter-sm ck-style">
          <q-checkbox tabindex="0" :aria-labelledby="task.id" :readonly="isOffline || isLocked" v-if="showCheckbox" :value="task.completed" v-on:click.native="markCompleted(task)" @keyup.native.enter="markCompleted(task)"/>
          <i v-if="showCicularCheck" class="task-desctiption-icon fas fa-check-circle ok"></i>
          <span class="type" v-if="task.type === 'event'">Event</span>
          <span class="desc" :id="task.id">{{ task.description }}</span>
          <div class="row" style="margin-top: 0.5vh">
            <div class="col-8">
              <div class="">

                <!-- Shows assignedBy -->
                <div v-if="showFrom">
                  <div class="card-avatar">
                    <q-avatar size="32px">
                      <img v-if="task.assignedBy.image.length" :src="task.assignedBy.image" alt="avatar">
                      <img v-else :src="avatar" alt="avatar">
                    </q-avatar>
                  </div>
                  <div class="card-avatar-details">
                    <div class="due-date assigned">from: <span><b>{{ getName(task.assignedBy) }}</b></span></div>
                  </div>
                </div>

                <!-- Shows AssignedTo -->
                <div v-if="showTo">
                  <div class="card-avatar">
                    <q-avatar size="32px">
                      <img v-if="task.assignedTo.image.length" :src="task.assignedTo.image" alt="avatar">
                      <img v-else :src="avatar" alt="avatar">
                    </q-avatar>
                  </div>
                  <div class="card-avatar-details">
                    <div class="due-date assigned">Assigned to: <span><b>{{ getName(task.assignedTo) }}</b></span></div>
                  </div>
                </div>

                <!-- Shows file details -->
                <div v-if="task.type === 'event' && task.file">
                  <div class="card-avatar doc-avatar">
                    <q-avatar size="32px">
                      <img :src="docImg" alt="document image">
                    </q-avatar>
                  </div>
                  <div class="card-avatar-details">
                    <div class="due-date assigned">
                      <a :href="task.file.url" @click.prevent="downloadItem({ url: task.file.url, label: task.file.name })">
                        {{ task.file.name }}
                      </a>
                      <span v-if="task.file.size"><b>{{ task.file.size }}</b></span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4">
              <div class="due-date">Due date: <span><b>{{ dateFormat(task.dueDate, 'DD/MM/YYYY') }}</b></span></div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>

  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import avatar from '../../assets/av.png'
import docImg from '../../assets/document.png'
import axios from 'axios/index'

export default {
  name: 'Task',
  props: {
    task: {
      type: Object,
      default () {
        return null
      }
    },
    color: { // the color for the left margin, checkbox etc.
      type: String,
      default () {
        return ''
      }
    },
    readOnly: {
      type: Boolean,
      default () {
        return true
      }
    },
    showAssignedFrom: {
      type: Boolean,
      default () {
        return true
      }
    },
    showAssignedTo: {
      type: Boolean,
      default () {
        return false
      }
    },
    showStatusTag: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      avatar: avatar,
      docImg: docImg
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentYp', 'isOffline', 'locks']),
    ppSectionClass () {
      if (this.currentUser.userType === 'YP') {
        if (this.task.assignedTo.id === this.currentUser.user_id) {
          return this.task.section
        }
      } else {
        if (this.task.assignedTo.role === 'YP') {
          return this.task.section
        }
        if (!['OverallPlanNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext'].includes(this.$route.name)) {
          return this.task.section
        }
      }
      return null
    },
    showShade () {
      if (this.currentUser.userType === 'YP' && this.task.assignedTo.id !== this.currentUser.user_id) {
        return true
      }
      return false
    },
    showCheckbox () {
      if (this.task && this.task.type === 'task' && this.currentUser) {
        if (this.currentUser.userType === 'YP') {
          if (this.task.assignedTo.id === this.currentUser.user_id) {
            return this.readOnly
          }
        } else {
          if (this.task.assignedTo.role === 'YP' || this.task.assignedTo.id === this.currentUser.user_id) {
            return this.readOnly
          }
        }
      }
      return false
    },
    showCicularCheck () {
      if (this.currentUser.userType === 'YP') {
        return this.task.assignedTo.id !== this.currentUser.user_id && this.task.completed
      } else {
        return this.task.assignedTo.role !== 'YP' && this.task.assignedTo.id !== this.currentUser.user_id && this.task.completed
      }
    },
    showFrom () {
      if (this.task && this.currentUser && this.task.type === 'task') {
        if (this.currentUser.userType === 'YP') {
          // When logged in user is YP
        } else {
          // When logged in user is SW
          if (this.currentYp) {
            return false
          } else {
            if (this.task.assignedTo.id === this.currentUser.user_id) {
              return true
            }
          }
        }
      }
      return false
    },
    showTo () {
      if (this.task && this.currentUser && this.task.type === 'task') {
        if (this.currentUser.userType === 'YP') {
          // When logged in user is YP
          if (this.task.assignedTo.id !== this.currentUser.user_id) {
            return true
          }
        } else {
          // When logged in user is SW
          if (this.currentYp) {
            if (this.task.assignedTo.id !== this.currentYp.id) {
              return true
            }
          } else {
            return this.showAssignedTo
          }
        }
      }
      return false
    },
    isLocked () {
      return this.locks.filter(lk => lk.resourceId === this.task.id &&
        lk.section === 'task' &&
        lk.lockedBy.id !== this.currentUser.user_id).length > 0 ||
        this.locks.filter(lk => lk.resourceId === this.task.pp_id &&
          lk.section === 'pp' &&
          lk.lockedBy.id !== this.currentUser.user_id).length > 0
    }
  },
  methods: {
    ...mapActions(['setTaskCompleted', 'setSelectedTask', 'checkLock']),
    test () {
      console.log('123')
    },
    markCompleted (task) {
      if (!this.isOffline && !this.isLocked) {
        // this.$q.loading.show()
        this.setTaskCompleted(task).then((data) => {
          // this.$q.loading.hide()
          if (data.status !== 200) {
            return this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'red',
              position: 'top-right'
            })
          }
          if (task.completed) {
            if (task.badge && task.badge.id) {
              const notificationText = this.currentUser.userType === task.assignedTo.role ? 'Great Job &#128077;!<br> You have completed a task and earned the following badge' : ('Great Job &#128077;!<br> You have completed a task and ' + task.assignedTo.name + ' earned the following badge')
              this.$root.$emit('task::notification', notificationText, task.badge)
            } else {
              const notificationText = 'Great Job &#128077;!<br> You have completed task!'
              this.$root.$emit('task::notification', notificationText)
            }
          }
        }, err => {
          this.$q.loading.hide()
          return this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        })
      }
    },
    getName (assigned) {
      if (this.currentUser.user_id === assigned.id) {
        return 'Me'
      } else {
        return assigned.name
      }
    },
    dateFormat (date, format) {
      return moment(date, 'YYYY-MM-DD').format(format)
    },
    downloadItem ({ url, label }) {
      axios.get(url, { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },
    setTask (task) {
      this.checkLock({ id: this.currentUser.user_id, section: 'task', resourceId: task.id })
      this.setSelectedTask(task)
    }
  },
  mounted () {
    if (document.querySelectorAll('.q-checkbox input[type="checkbox"]').length) {
      document.querySelectorAll('.q-checkbox input[type="checkbox"]').forEach(element => {
        element.setAttribute('title', 'checkbox')
      })
    }
  }
}
</script>
