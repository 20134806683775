<template>
  <div>
    <div class="selected-badges">
      <div class="badges-list">
        <span :class="['badge-item', { 'badge-active': profileBadge ? badge.id === profileBadge.id : false }]" v-for="(badge, idx) in (staticBadges.length ? staticBadges : assignedBadges)" :key="'assigned_' + idx" :aria-label="badge.title" @click="selectBadge(badge)" @keyup.enter="selectBadge(badge)" tabindex="0">
          <img :src="badge.icon" :alt="badge.title">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Loading } from 'quasar'

export default {
  name: 'AchievedBadges',
  props: {
    staticBadges: {
      default () {
        return []
      }
    }
  },
  data () {
    return {
      assigned: []
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'isOffline', 'backgroundLoading', 'profileBadge'])
  },
  methods: {
    ...mapActions(['editProfile']),
    selectBadge (badge) {
      if (!this.isOffline && !this.backgroundLoading) {
        Loading.show()
        this.editProfile(badge).then(data => {
          Loading.hide()
          if (data.status !== 200) {
            this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'red',
              position: 'top-right'
            })
          }
        }, err => {
          Loading.hide()
          this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
