<template>
  <div class="q-pa-md">
    <div v-show="!loading" v-html="content"></div>
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      title: false,
      content: '',
      loading: true
    }
  },
  methods: {
    ...mapMutations(['setDocumentTitle'])
  },
  mounted () {
    this.loading = true
    this.$q.loading.show()
    api.getInfoPage({
      slug: this.$route.params.pageSlug
    }).then(data => {
      if (data.status === 200) {
        this.setDocumentTitle(data.data.title)
        this.content = data.data.content
        this.loading = false
        this.$q.loading.hide()
      } else {
        this.$router.push({ name: 'Home' })
      }
    })
  },
  watch: {
    '$route.params.pageSlug' (slug) {
      this.loading = true
      this.$q.loading.show()
      api.getInfoPage({ slug: slug }).then(data => {
        if (data.status === 200) {
          this.setDocumentTitle(data.data.title)
          this.content = data.data.content
          this.loading = false
          this.$q.loading.hide()
        } else {
          this.$router.push({ name: 'Home' })
        }
      })
    }
  }
}

</script>

<style scoped>

</style>
