<template>
  <div>
    <q-header class="view-profile-header" v-if="['ViewProfile', 'Profile', 'YpContacts', 'YpTasks', 'YpSupportingPeople', 'YpAchievementBadges'].includes($route.name) && currentYp">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back" v-if="['ViewProfile', 'YpTasks', 'YpSupportingPeople', 'YpAchievementBadges'].includes($route.name)" :to="{ name: 'YpMainView' }" flat round dense icon="arrow_back_ios" aria-label="back" role="button"/>
          <q-btn class="back" v-if="['Profile'].includes($route.name)" :to="{ name: 'ViewProfile' }" flat round dense icon="arrow_back_ios" aria-label="back" role="button"/>
          <q-btn class="back" v-if="['YpContacts'].includes($route.name) && !currentContact" :to="{ name: 'YpMainView' }" flat round dense icon="arrow_back_ios" aria-label="back" role="button"/>
          <q-btn class="back" v-if="['YpContacts'].includes($route.name) && currentContact" @click="goBack()" flat round dense icon="arrow_back_ios" aria-label="back" role="button"/>
        </div>
        <div class="col-6 text-left">
          <q-toolbar-title role="heading" aria-level="1">
            <span></span>
            {{ username }}<br/>
            <span class="headerSubtitle" v-if="['ViewProfile'].includes($route.name)">General Information</span>
            <span class="headerSubtitle" v-if="['Profile'].includes($route.name)">Edit Information</span>
            <span class="headerSubtitle" v-if="['YpSupportingPeople'].includes($route.name)">People Supporting me</span>
            <span class="headerSubtitle" v-if="['YpContacts'].includes($route.name)">Contacts</span>
            <span class="headerSubtitle" v-if="['YpTasks'].includes($route.name)">Tasks</span>
            <span class="headerSubtitle" v-if="['YpAchievementBadges'].includes($route.name)">Achievements</span>
          </q-toolbar-title>
        </div>
        <div class="col-4 text-right">
          <q-btn v-if="['ViewProfile'].includes($route.name)" class="edit-style" outline rounded :to="{ name: 'Profile' }"><span class="material-icons-outlined">edit</span> Edit</q-btn>
          <q-btn @click="$root.$emit('save:profile')" v-if="['Profile'].includes($route.name)" class="edit-style" outline rounded><span class="material-icons-outlined">save</span> Save</q-btn>
          <div v-if="['YpContacts'].includes($route.name) && !currentContact">
            <q-btn @click="$root.$emit('contact::showSearch')" flat dense icon="search" aria-label="search"/>
            <q-btn flat dense icon="person_add" @click="$router.push({ name: 'ShareContacts' })" aria-label="share contact"/>
          </div>
          <div v-if="['YpContacts'].includes($route.name) && currentContact">
            <q-btn v-if="currentContact.isMyContact" @click="$root.$emit('contact::edit')" class="delete" flat round dense
                   icon="edit" aria-label="edit"/>
            <q-btn v-if="currentContact.isMyContact" @click="$root.$emit('contact::delete')" class="delete" flat round
                   dense icon="delete" aria-label="delete"/>
          </div>
          <q-btn :disable="isOffline" style="font-size: 1.7vh" v-if="['YpTasks'].includes($route.name)" @click="$root.$emit('task::assign')" outline rounded
          ><span class="material-icons-outlined">add</span> Add Task</q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="share-contacts-header search-header bg-white text-black" v-if="['ShareContacts'].includes($route.name)">
      <div>
        <div class="row">
          <div class="col-3">
            <q-btn @click="cancel()" class="cancel-btn custom-add" color="blue" flat aria-label="Cancel">Cancel</q-btn>
          </div>
          <div class="col-6 text-center">
            <span class="text-center h-title" aria-level="1" role="heading">Add Contacts</span>
            <br/>
            <span class="text-center h-text">{{ cntSelected }} Selected</span>
          </div>
          <div class="col-3 text-right header-right">
            <q-btn unelevated rounded color="primary" @click="$root.$emit('contacts::share')" aria-label="Add">Add</q-btn>
          </div>
        </div>
        <div class="row">
          <div class="col search-box">
            <search-bar></search-bar>
          </div>
        </div>
      </div>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SearchBar from '@/components/Utils/SearchBar'

export default {
  name: 'AppHeaderSwYpView',
  components: { SearchBar },
  computed: {
    ...mapGetters(['appDrawerOpen', 'currentYp', 'currentContact', 'cntSelected', 'isOffline']),
    username () {
      return this.currentYp.firstName !== '' ? this.currentYp.firstName + ' ' + this.currentYp.lastName : 'User'
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer', 'setCurrentContact', 'clearSearch']),
    cancel () {
      this.clearSearch()
      this.$router.go(-1)
    },
    goBack () {
      this.setCurrentContact(false)
      this.$router.push({ name: this.$route.name })
    }
  }
}
</script>

<style>
  .headerSubtitle {
    font-size: 10px;
  }
</style>
