<template>
  <div>
    <div class="text-h6 text-center" role="heading" aria-level="2">General Information</div>
    <q-form class="general-info-form" ref="generalInfo">
      <div class="form-row avatar-form-row">
        <div class="q-gutter-y-md column text-center">
          <q-avatar class="profile-avatar" size="14.8vh">
            <img v-if="!uploadedAvatarImg" :src="avatar" :alt="userInfo.firstName">
            <img v-else :src="uploadedAvatarImg" :alt="userInfo.firstName">
          </q-avatar>
          <q-file title="upload-avatar-image" v-if="!isOffline" @input="getAvatarPreview" borderless input-class="add blue custom-add text-center"
                  v-model="userInfo.avatar" label-slot label="photo" for="upload-avatar-image">
            <div slot="label" class="add blue2 custom-add text-center">{{ uploadedAvatarImg.length ? 'Change' : 'Add' }} photo</div>
            <div slot="file"></div>
          </q-file>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="firstName">First name</label>
          <q-input
            v-model="userInfo.firstName"
            outlined
            placeholder="Enter your first name"
            :rules="[
              val => val.trim() !== null && val.trim() !== '' || 'Please type your name',
              val => val.trim().length > 2 || 'Please type at least 3 characters'
              ]"
            :readonly="loading || isOffline"
            for="firstName"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="lastName">Last name</label>
          <q-input
            v-model="userInfo.lastName"
            outlined
            placeholder="Enter your last name"
            :rules="[
              val => val.trim() !== null && val.trim() !== '' || 'Please type your first name',
              val => val.trim().length > 2 || 'Please type at least 3 characters'
              ]"
            :readonly="loading || isOffline"
            for="lastName"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="dateOfBirth">Date of birth</label>
          <modal-date :read-only="loading || isOffline" error-msg="Please enter your date of birth"
                      :max-date="maxDate" v-model="userInfo.dob"
                      placeholder="Enter your date of birth" label-id="dateOfBirth" :disable-input="true"></modal-date>
        </div>
      </div>
      <div class="form-row form-row-radio">
        <div class="q-gutter-y-md column">
          <div style="margin-top: 0">
            <q-field
              v-model="userInfo.gender"
              :rules="[val => ['m', 'f'].includes(val) || 'Please select your gender']"
              borderless
              square
              class="q-field-radio"
              :disable="loading || isOffline"
            >
              <label class="title-label" for="whatIsYourSex">What is your sex?</label>
              <q-radio title="male" v-model="userInfo.gender" val="m" label="Male" :disable="(loading || isOffline) && (userInfo.gender !== 'm')"/>
              <q-radio title="female" v-model="userInfo.gender" val="f" label="Female" :disable="(loading || isOffline) && (userInfo.gender !== 'f')"/>
            </q-field>
          </div>
        </div>
      </div>
      <div class="form-row" v-if="!below16">
        <div class="q-gutter-y-md column">
          <label class="title-label" for="genderIdentity">Is the gender you identify with the same as your sex registered at birth?</label>
          <span class="details" tabindex="0">This question is voluntary</span>
          <div class="row">
            <q-field
              v-model="userInfo.showGenderIdentity"
              borderless
              square
              class="q-field-radio"
              :disable="loading || isOffline"
              for="genderIdentity"
            >
              <q-radio title="yes" tabindex="0" v-model="userInfo.showGenderIdentity" :val="false" label="Yes" :disable="(loading || isOffline) && userInfo.showGenderIdentity !== false"/>
              <q-radio title="no" tabindex="0" v-model="userInfo.showGenderIdentity" :val="true" label="No" :disable="(loading || isOffline) && !userInfo.showGenderIdentity !== true"/>
            </q-field>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="firstLanguage">First language</label>
          <q-select :rules="[val => val !== null && val !== '' || 'Please select your language']"
                    outlined v-model="userInfo.firstLanguage"
                    :options="(loading || isOffline) ? [] : languages"
                    :hide-dropdown-icon="loading || isOffline"
                    :disabled="loading || isOffline"
                    :class="[{ 'select-box-disabled' : loading || isOffline }]"
                    for="firstLanguage"
                    label-slot
                    behavior="menu"
          />
        </div>
        <q-btn v-if="!(loading || isOffline)" @click="addInfo('languages')" class="add blue custom-add" flat>
          <span class="plus-icon material-icons-outlined">add</span> Add other languages</q-btn>
      </div>
        <div class="form-row" v-for="(language, idx) in userInfo.languages" :key="idx">
          <q-select :for="'otherLanguage' + idx" :rules="[val => val !== null && val !== '' || 'Please select your language']" outlined
                    v-model="userInfo.languages[idx]"
                    :options="(loading || isOffline) ? [] : languages"
                    :hide-dropdown-icon="loading || isOffline"
                    :disabled="loading || isOffline"
                    :class="[{ 'select-box-disabled' : loading || isOffline }]"
                    behavior="menu"
          />
          <q-btn :disable="loading || isOffline" @click="removeInfo('languages', idx)" class="add add-phone custom-add blue" flat role="button" tabindex="0"><span class="plus-icon material-icons-outlined">remove</span> Remove language</q-btn>
      </div>
      <div ref="saveBtn">
        <q-btn @click="validate(true)"
               class="no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white" unelevated
               rounded color="primary" label="Next" v-if="!isOffline" :loading="loading" aria-label="next" role="button" tabindex="0"/>
      </div>
    </q-form>
  </div>
</template>
<script>
import avatar from '../../assets/av.png'
import languages from '../../assets/languages.json'
import ModalDate from '../Utils/ModalDate'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GeneralInfo',
  components: { ModalDate },
  props: {
    valid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['userGeneralInfo', 'currentYp', 'backgroundLoading', 'isOffline']),
    languages () {
      return this.allLanguages.filter(language => !this.userInfo.languages.includes(language))
        .filter(language => this.userInfo.firstLanguage !== language)
    },
    below16 () {
      if (this.userInfo.dob) {
        return moment(this.userInfo.dob, 'DD/MM/YYYY').add(16, 'years') > moment().hours(0).minutes(0).seconds(0)
      }
      return false
    }
  },
  mounted () {
    this.updateLocalData()
    this.$root.$on('save:profile', this.validate)
  },
  data () {
    return {
      loading: false,
      avatar: avatar,
      uploadedAvatarImg: false,
      maxDate: moment().subtract(10, 'years').format('YYYY/MM/DD'),
      canNext: true,
      allLanguages: Object.values(languages).map(lang => lang.name),
      userInfo: {
        firstName: '',
        lastName: '',
        dob: '',
        gender: false,
        showGenderIdentity: false,
        genderIdentity: '',
        firstLanguage: 'English',
        languages: [],
        avatar: []
      }
    }
  },
  methods: {
    ...mapActions(['setGeneralInfo']),
    updateLocalData () {
      let userInfo = {}
      if (this.currentYp) {
        userInfo = Object.assign({}, this.currentYp.generalInfo)
      } else if (this.userGeneralInfo !== null) {
        userInfo = Object.assign({}, this.userGeneralInfo)
      }
      if (userInfo.avatar !== null && userInfo.avatar.length) {
        this.uploadedAvatarImg = userInfo.avatar
      }
      if (userInfo.firstLanguage === '') {
        userInfo.firstLanguage = 'English'
      }
      delete userInfo.avatar
      userInfo.avatar = []
      this.userInfo = userInfo
    },
    validate (event = false) {
      this.$refs.generalInfo.validate().then(success => {
        if (success) {
          this.loading = true
          const userInfo = Object.assign({}, this.userInfo)
          if (this.uploadedAvatarImg.length) {
            userInfo.avatar = this.uploadedAvatarImg
          } else {
            userInfo.avatar = ''
          }
          this.setGeneralInfo(userInfo).then((data) => {
            this.loading = false
            if (data.status === 200) {
              if (data.message) {
                this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'green',
                  position: 'top-right'
                })
              }
              this.updateLocalData()
              if (event) {
                this.$emit('valid')
              }
            } else {
              this.$q.notify({
                message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                html: true,
                color: 'red',
                position: 'top-right'
              })
            }
          })
        }
      })
    },
    addInfo (type) {
      this.userInfo[type].push('')
    },
    removeInfo (type, idx) {
      this.userInfo[type].splice(idx, 1)
    },
    getAvatarPreview (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = () => {
        this.uploadedAvatarImg = reader.result
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('save:profile')
  },
  watch: {
    backgroundLoading (status) {
      if (!status) {
        this.updateLocalData()
      }
    }
  },
  updated () {
    if (document.querySelectorAll('.q-select [role="combobox"]').length) {
      document.querySelectorAll('.q-select [role="combobox"]').forEach(element => {
        element.removeAttribute('aria-multiselectable')
        element.setAttribute('aria-label', 'language')
      })
    }
  }
}
</script>
<style>
.general-info-form .q-field-radio .q-field__control {
  padding-left: 0 !important;
  padding-right: 0;
}

.general-info-form .q-field-radio .q-radio__label {
  color: #1D1D1D;
}
</style>
