<template>
  <div>
    <div class="contacts-holder list-view notification-settings" v-if="$route.name === 'NotificationSettings'">
      <vue-title title="Notifications settings"></vue-title>
      <div class="list-contacts-holder">
        <q-list separator>
          <div v-for="(item, idx) in notifications" :key="idx">
            <q-item clickable v-ripple v-if="!(['SW', 'PA'].includes(currentUser.userType) && item.name === 'show_group_notification')">
              <q-item-section>
                <label :for="item.name">{{ item.label }}</label>
              </q-item-section>
              <q-toggle v-if="item.name === 'notifications_email' && ['SW', 'PA'].includes(currentUser.userType)" :id="item.name" :disable="true" v-model="default_notification_sw" color="blue" title="toggle switch"/>
              <q-toggle v-else :id="item.name" :disable="isOffline" v-model="item.value" color="blue" @input="saveSetting(item)" title="toggle switch"/>
            </q-item>
          </div>
        </q-list>
        <!--
        <q-list separator>
          <div class="list-title">In-App notifications  </div>
          <q-item clickable v-ripple v-for="(item, idx) in inAppNotifications" :key="idx">
            <q-item-section>
              {{ item.label }}
            </q-item-section>
            <q-toggle :disable="isOffline" v-model="item.value" color="blue" @input="saveSetting(item)" :aria-label="item.value" title="toggle switch"/>
          </q-item>
        </q-list>
        -->
      </div>
    </div>

    <div class="contacts-holder list-view profile-settings" v-if="$route.name === 'ProfileSettings'">
      <vue-title title="Profile settings"></vue-title>
    </div>

    <div class="contacts-holder list-view language" v-if="$route.name === 'Language'">
      <vue-title title="Language"></vue-title>
    </div>

    <div class="contacts-holder list-view accessibility" v-if="$route.name === 'Accessibility'">
      <vue-title title="Accessibility"></vue-title>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Loading } from 'quasar'

export default {
  name: 'Setting',
  data () {
    return {
      default_notification_sw: true,
      notifications: [
        {
          label: 'Receive all notifications',
          name: 'receive_notifications',
          value: false
        },
        {
          label: 'Show group notifications',
          name: 'show_group_notification',
          value: false
        },
        {
          label: 'Receive notifications via email',
          name: 'notifications_email',
          value: false
        },
        {
          label: 'Receive notifications via SMS',
          name: 'notifications_sms',
          value: false
        },
        {
          label: 'Receive push notifications',
          name: 'notifications_push',
          value: false
        },
        {
          label: 'Receive reminder messages',
          name: 'receive_remainder',
          value: false
        }
      ],
      /*
      inAppNotifications: [
        {
          label: 'In-App vibrate',
          name: 'inapp_vibrate',
          value: false
        }
        {
          label: 'In-App Sounds',
          name: 'inapp_sounds',
          value: false
        }
      ],
       */
      value: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'appSettings', 'isOffline'])
  },
  methods: {
    ...mapActions(['setAppSettings']),
    ...mapMutations(['resetPushNotificationsData']),
    saveSetting (item) {
      Loading.show()
      const requestData = {}
      if (item.name === 'receive_notifications') {
        this.notifications = this.notifications.map((notification) => {
          if (!(this.currentUser.userType !== 'YP' && notification.name === 'notifications_email')) {
            notification.value = item.value
          }
          return notification
        })
        /*
        this.inAppNotifications = this.inAppNotifications.map((notification) => {
          notification.value = item.value
          return notification
        })
         */
      } else {
        if (!item.value) {
          this.notifications[0].value = false
        } else {
          let receiveNotifications = true
          this.notifications.forEach((notification) => {
            if (!notification.value && notification.name !== 'receive_notifications') {
              receiveNotifications = false
            }
          })
          /*
          this.inAppNotifications.forEach((notification) => {
            if (!notification.value && notification.name !== 'receive_notifications') {
              receiveNotifications = false
            }
          })
           */
          this.notifications[0].value = receiveNotifications
        }
      }
      this.notifications.forEach((notification) => {
        requestData[notification.name] = notification.value
      })
      /*
      this.inAppNotifications.forEach((notification) => {
        requestData[notification.name] = notification.value
      })
       */
      requestData.token = this.currentUser.user_token
      this.setAppSettings(requestData).then((data) => {
        Loading.hide()
        if (data.status === 200) {
          if (['receive_notifications', 'notifications_push'].includes(item.name) && item.value) {
            this.resetPushNotificationsData()
          }
          this.$q.notify({
            message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
            html: true,
            color: 'green',
            position: 'top-right'
          })
        } else {
          this.$q.notify({
            message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
            html: true,
            color: 'red',
            position: 'top-right'
          })
        }
      })
    },
    updateSettings () {
      if (this.appSettings) {
        this.notifications = this.notifications.map((notification) => {
          notification.value = this.appSettings[notification.name]
          return notification
        })
        /*
        this.inAppNotifications = this.inAppNotifications.map((notification) => {
          notification.value = this.appSettings[notification.name]
          return notification
        })
         */
        let receiveNotifications = true
        Object.keys(this.appSettings).every(index => {
          if (!this.appSettings[index] && index !== 'inapp_sounds' && index !== 'inapp_vibrate') {
            receiveNotifications = false
            return false
          }
          return true
        })
        const index = this.notifications.findIndex((notification) => notification.name === 'receive_notifications')
        if (index > -1) {
          this.notifications[index].value = receiveNotifications
        }
      }
    }
  },
  mounted () {
    this.updateSettings()
    if (document.querySelectorAll('.q-toggle[role="checkbox"]').length) {
      document.querySelectorAll('.q-toggle[role="checkbox"]').forEach(element => {
        if (element.querySelector('input[type="checkbox"]')) {
          element.querySelector('input[type="checkbox"]').setAttribute('id', element.getAttribute('id'))
        }
        element.removeAttribute('id')
      })
    }
  }
}
</script>

<style scoped>

</style>
