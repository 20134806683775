// map incoming update requests to vuex actions

export default {
  get_task: ['getUserTasks', 'setUserBadges'],
  get_pp: ['getPathwayPlan', 'getUserTasks'],
  get_pp_sw_pa: ['getPpSwPa', 'getUserTasks'],
  get_all_contact: ['setContacts'],
  info: ['getUserInfo'],
  info_full: ['setupUserData'],
  yp_info: ['getYpUserInfo'],
  get_badges: ['setUserBadges']
}
