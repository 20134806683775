<template>
  <div>
    <q-header class="tasks-header" v-if="['MyCaseLoad'].includes($route.name)">
      <q-toolbar>
        <div class="col-3">
          <q-btn
            flat
            dense
            round
            @click="toggleAppDrawer()"
            aria-label="Menu"
            icon="fas fa-bars"
            style="margin-left: -0.5vh"
          />
        </div>
        <div class="col-6 text-center">
          <q-toolbar-title class="text-large" style="0" role="heading" aria-level="1">My caseload</q-toolbar-title>
        </div>
        <div class="col-3 text-right header-right">
          <q-btn :disable="isOffline" flat dense icon="person_add" :to="{ name: 'AddYoungPersons' }" aria-label="add young person" role="button"/>
          <q-btn @click="$root.$emit('caseload:showSearch')" flat dense icon="search" aria-label="search"/>
        </div>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppHeaderMyCaseLoad',
  computed: {
    ...mapGetters(['appDrawerOpen', 'isOffline'])
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>
