import axios from 'axios/index'
import { socket } from '@/store/modules/socket'
import store from '@/store'
import router from '@/router'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const getData = function (method, url, requestData = null) {
  return new Promise((resolve, reject) => {
    return http.get('/wpapp/auth/v2/nonce', { 'Content-Type': 'application/json' }).then(resp => {
      requestData.nonce = resp.data.data.nonce
      if (store.getters.nonce !== resp.data.data.nonce && store.getters.currentUser.user_id) {
        store.dispatch('setNonce', resp.data.data.nonce)
        const event = new CustomEvent('update-nonce')
        document.dispatchEvent(event)
      }
      if (socket) {
        requestData.socket_id = socket.id
      }
      if (requestData !== null) {
        return http[method](url, requestData, { headers: { 'Content-Type': 'application/json' } }).then(resp => {
          if (resp.data.status === 540) {
            const userId = store.getters.currentUser.user_id
            store.dispatch('logOut')
            router.push({ name: 'Login' }).catch(() => {})
            store.dispatch('handleCredentialsChange', userId)
            reject(new Error('Token expired'))
          }
          resolve(resp.data)
        }).catch(e => reject(e))
      } else {
        return http[method](url, { headers: { 'Content-Type': 'application/json' } }).then(resp => resolve(resp.data)).catch(e => reject(e))
      }
    })
  })
}

export default {
  register (data) {
    return getData('post', '/wpapp/api/v2/user/register', data)
  },
  loginPassword (data) {
    return getData('post', '/wpapp/api/v2/user/login_password?auth=email', data)
  },
  login (data) {
    return getData('post', '/wpapp/api/v2/user/login?auth=email', data)
  },
  forgotPassword (data) {
    return getData('post', '/wpapp/api/v2/user/forgot_password', data)
  },
  resetPassword (data) {
    return getData('post', '/wpapp/api/v2/user/reset_password', data)
  },
  setUserGeneralInfo (data) {
    return getData('post', '/wpapp/api/v2/user/edit_profile', data)
  },
  setUserContactDetails (data) {
    return getData('post', '/wpapp/api/v2/user/edit_contact', data)
  },
  setUserBio (data) {
    return getData('post', '/wpapp/api/v2/user/edit_bio', data)
  },
  getUserInfo (data) {
    return getData('post', '/wpapp/api/v2/user/info', data)
  },
  getUserInfoFull (data) {
    return getData('post', '/wpapp/api/v2/user/info_full', data)
  },
  getUserTasks (data) {
    return getData('post', '/wpapp/api/v2/task/get_task', data)
  },
  setUserTaskCompleted (data) {
    return getData('post', '/wpapp/api/v2/task/task_completed', data)
  },
  getAssignedUsers (data) {
    return getData('post', '/wpapp/api/v2/task/get_assigned_users', data)
  },
  saveTask (data) {
    return getData('post', '/wpapp/api/v2/task/save_task', data)
  },
  updateTask (data) {
    return getData('post', '/wpapp/api/v2/task/update_task', data)
  },
  deleteTask (data) {
    return getData('post', '/wpapp/api/v2/task/delete_task', data)
  },
  check_link (data) {
    return getData('post', '/wpapp/api/v2/user/check_link', data)
  },
  addEditContact (data) {
    return getData('post', '/wpapp/api/v2/contact/add_contact', data)
  },
  getAllContacts (data) {
    return getData('post', '/wpapp/api/v2/contact/get_all_contact', data)
  },
  setQuickContact (data) {
    return getData('post', '/wpapp/api/v2/contact/set_quick_contact', data)
  },
  deleteMyContact (data) {
    return getData('post', '/wpapp/api/v2/contact/delete_my_contact', data)
  },
  getPathwayPlan (data) {
    return getData('post', '/wpapp/api/v2/pathway/get_pp', data)
  },
  setPathwayPlan (data) {
    return getData('post', '/wpapp/api/v2/pathway/update_pp', data)
  },
  getAllYp (data) {
    return getData('post', '/wpapp/api/v2/user/get_all_yp', data)
  },
  getMyCaseLoad (data) {
    return getData('post', '/wpapp/api/v2/user/get_young_persons', data)
  },
  addToCaseload (data) {
    return getData('post', '/wpapp/api/v2/user/add_yp_ids', data)
  },
  removeFromCaseload (data) {
    return getData('post', '/wpapp/api/v2/user/remove_yp_from_swpa', data)
  },
  sendGroupNotification (data) {
    return getData('post', '/wpapp/api/v2/user/push_notification', data)
  },
  getPreviewPp (data) {
    return getData('post', '/wpapp/api/v2/pathway/get_preview', data)
  },
  reportError (data) {
    return getData('post', '/wpapp/api/v2/contact/report_errors', data)
  },
  shareContact (data) {
    return getData('post', '/wpapp/api/v2/contact/share_contact', data)
  },
  getPpSwPa (data) {
    return getData('post', '/wpapp/api/v2/pathway/get_pp_sw_pa', data)
  },
  getBadges (data) {
    return getData('post', '/wpapp/api/v2/badge/get_badges', data)
  },
  assignBadge (data) {
    return getData('post', '/wpapp/api/v2/badge/assign_badge', data)
  },
  removeBadge (data) {
    return getData('post', '/wpapp/api/v2/badge/delete_badge', data)
  },
  overridePp (data) {
    return getData('post', '/wpapp/api/v2/pathway/override', data)
  },
  deletePp (data) {
    return getData('post', '/wpapp/api/v2/pathway/delete_pp', data)
  },
  createPp (data) {
    return getData('post', '/wpapp/api/v2/pathway/create_pp', data)
  },
  setAppSettings (data) {
    return getData('post', '/wpapp/api/v2/user/app_settings', data)
  },
  getArticles (data) {
    return getData('post', '/wpapp/api/v2/options/list', data)
  },
  getInfoPage (data) {
    return getData('post', '/wpapp/api/v2/options/page', data)
  },
  setWebPushNotificationsCredentials (data) {
    return getData('post', '/wpapp/api/v2/user/web_push_cred', data)
  },
  deleteWebPushNotificationEndpoint (data) {
    return getData('post', '/wpapp/api/v2/user/web_push_delete', data)
  },
  registerSafariPushDeviceToken (data) {
    return getData('post', '/safari_push/registerDevice', data)
  },
  logout (data = {}) {
    return getData('get', '/wpapp/api/v2/user/logout', data)
  }
}
