import api from '../../api'
import { calculatePp } from '../utils/ppCalculation'
import Loading from 'quasar/src/plugins/Loading.js';

const state = {
  next: null,
  current: null,
  past: [],
  ypPps: {
    next: null,
    current: null,
    past: []
  }
}

const getters = {
  pathwayPlan (state, getter) {
    return getter.currentYp ? getter.ypPps : state
  },
  getCurrentPathwayPlanId (state, getter) {
    return getter.currentYp ? (getter.ypPps.current ? getter.ypPps.current.id : null) : (state.current ? state.current.id : null)
  },
  getNextPathwayPlanId (state, getter) {
    return getter.currentYp ? (getter.ypPps.next ? getter.ypPps.next.id : null) : (state.next ? state.next.id : null)
  },
  ypPps (state) {
    return state.ypPps
  },
  mainPp (state) {
    return state
  }
}

const actions = {
  setPathwayPlan (context, userData) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        return api.setPathwayPlan(userData).then((data) => {
          try {
            if (data.status === 200) {
              if (data.data) {
                context.commit('setPathwayPlan', {
                  pathwayPlan: data.data,
                  type: 'next',
                  currentUser: context.getters.currentUser
                })
              }
            }
            resolve(data)
          } catch (e) {
            console.log(e)
            Loading.hide()
            reject(e)
          }
        })
      } else {
        Loading.hide()
        reject(Error('offline'))
      }
    })
  },
  getPathwayPlan (context, userData = { pathway: 'all' }) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        return api.getPathwayPlan({ token: context.getters.currentUser.user_token, ...userData }).then((data) => {
          try {
            if (data.status === 200) {
              if (data.data) {
                context.commit('setPathwayPlan', {
                  pathwayPlan: data.data,
                  type: userData.pathway,
                  currentUser: context.getters.currentUser
                })
                resolve(data.data)
              }
            } else {
              resolve()
              Loading.hide()
            }
          } catch (e) {
            console.log(e)
            Loading.hide()
            reject(e)
          }
        })
      } else {
        Loading.hide()
        reject(Error('offline'))
      }
    })
  },
  getPreviewPp (context, userData) {
    return api.getPreviewPp(userData).then((data) => {
      try {
        if (data.status === 200) {
          return data.data ? data.data : false
        } else {
          Loading.hide()
        }
        return false
      } catch (e) {
        console.log(e)
        Loading.hide()
      }
    })
  },
  getPpSwPa (context) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        return api.getPpSwPa({ token: context.getters.currentUser.user_token }).then((data) => {
          try {
            if (data.status === 200) {
              if (data.data) {
                context.commit('setPathwayPlan', {
                  pathwayPlan: data.data,
                  currentUser: context.getters.currentUser
                })
                if (context.getters.currentYp && typeof data.data === 'object' && Array.isArray(data.data.current) && Array.isArray(data.data.next)) {
                  const pathwayPlan = {
                    current: context.state.current ? context.state.current.filter(pp => pp.user_id === context.getters.currentYp.id) : null,
                    next: context.state.next ? context.state.next.filter(pp => pp.user_id === context.getters.currentYp.id) : null,
                    past: context.state.past.length ? context.state.past.filter(pp => pp.user_id === context.getters.currentYp.id) : []
                  }
                  context.commit('setYpPps', { pathwayPlan })
                }
                resolve(data.data)
              }
            } else {
              Loading.hide()
              resolve(false)
            }
          } catch (e) {
            reject(e)
            console.log(e)
            Loading.hide()
            return false
          }
        })
      } else {
        Loading.hide()
        reject(Error('offline'))
      }
    })
  },
  setYpPps (context, ppdata) {
    if (ppdata) {
      context.commit('setYpPps', { pathwayPlan: ppdata })
    }
  },
  overridePp (context, requestData) {
    return api.overridePp({ token: context.getters.currentUser.user_token, ...requestData }).then((data) => {
      try {
        if (data.status === 200 && data.data) {
          // context.commit('overridePp', data.data)
          return true
        } else {
          Loading.hide()
        }
      } catch (e) {
        Loading.hide()
        console.log(e)
      }
    })
  },
  deletePp (context, userData) {
    return api.deletePp(userData).then((data) => {
      try {
        if (data.status === 200) {
          if (data.data) {
            context.commit('deletePp')
          }
        } else {
          Loading.hide()
        }
      } catch (e) {
        console.log(e)
        Loading.hide()
      }
    })
  },
  createPp (context, userData) {
    return api.createPp(userData).then((data) => {
      try {
        if (data.status === 200) {
          if (data.data) {
            context.commit('createPp', {
              pathwayPlan: data.data,
              currentYp: context.getters.currentYp
            })
            return data
          } else {
            Loading.hide()
            return data
          }
        }
      } catch (e) {
        Loading.hide()
        console.log(e)
      }
    })
  },
  syncYpPp (context) {
    if (context.getters.currentYp && typeof context.getters.mainPp === 'object' && Array.isArray(context.getters.mainPp.current) && Array.isArray(context.getters.mainPp.next)) {
      const pathwayPlan = {
        current: context.state.current ? context.state.current.filter(pp => pp.user_id === context.getters.currentYp.id) : null,
        next: context.state.next ? context.state.next.filter(pp => pp.user_id === context.getters.currentYp.id) : null,
        past: context.state.past.length ? context.state.past.filter(pp => pp.user_id === context.getters.currentYp.id) : []
      }
      context.commit('setYpPps', { pathwayPlan })
    }
  }
}

const mutations = {
  setPathwayPlan (state, data) {
    if (data.currentUser.userType === 'YP') {
      // YP
      if (data.type === 'all') {
        state.current = data.pathwayPlan.current.length ? data.pathwayPlan.current[0] : null
        state.next = data.pathwayPlan.next.length ? calculatePp(data.pathwayPlan.next[0]) : null
        state.past = data.pathwayPlan.past
      } else {
        if (data.pathwayPlan) {
          Object.keys(data.pathwayPlan).forEach(index => {
            state[data.type][index] = data.pathwayPlan[index]
          })
          state[data.type] = calculatePp(state[data.type])
        }
      }
    } else {
      // SW/PA
      // SW/PA updating YP PP data
      if (data.type === 'next') {
        if (data.pathwayPlan) {
          Object.keys(data.pathwayPlan).forEach(index => {
            state.ypPps[data.type][index] = data.pathwayPlan[index]
          })
          if (state.ypPps[data.type]) {
            state.ypPps[data.type] = calculatePp(state.ypPps[data.type])
          }
          state[data.type] = state[data.type].map(pp => {
            if (pp.id === data.pathwayPlan.id) {
              Object.keys(data.pathwayPlan).forEach(index => {
                pp[index] = data.pathwayPlan[index]
              })
            }
            return pp
          })
        }
      } else {
        state.current = data.pathwayPlan.current.length ? data.pathwayPlan.current : []
        const nextPPs = []
        data.pathwayPlan.next.forEach(function (pp, index) {
          nextPPs.push(calculatePp(pp))
        })
        state.next = nextPPs.length ? nextPPs : []
        state.past = data.pathwayPlan.past
      }
    }
  },
  clearPathwayPlans (state) {
    state.next = {}
    state.current = {}
    state.past = []
  },
  clearYpPps (state) {
    state.ypPps.next = null
    state.ypPps.current = null
    state.ypPps.past = []
  },
  setYpPps (state, data) {
    state.ypPps.current = data.pathwayPlan.current.length ? data.pathwayPlan.current[0] : null
    state.ypPps.next = data.pathwayPlan.next.length ? calculatePp(data.pathwayPlan.next[0]) : null
    state.ypPps.past = data.pathwayPlan.past
  },
  overridePp (state, data) {
    if (state.ypPps.current) {
      // Deleted yp current pp and also from SW pp list
      state.current = state.current.filter(pp => pp.id !== state.ypPps.current.id)
      state.ypPps.current = null
    }
    if (state.ypPps.next) {
      // Updates the due date for yp next pp and also in SW pp list
      state.ypPps.next.due_date = data.due_date
      state.ypPps.next.opened = data.opened
      state.ypPps.next.open_date = data.open_date
      state.ypPps.next.started = data.started
      state.next = state.next.map((pp) => {
        if (pp.id === state.ypPps.next.id) {
          pp = state.ypPps.next
        }
        return pp
      })
    }
  },
  deletePp (state) {
    if (state.ypPps.next) {
      // Deleting YP next pp
      state.next = state.next.filter((pp) => pp.id !== state.ypPps.next.id)
      state.ypPps.next = null
    }
  },
  createPp (state, data) {
    if (data.pathwayPlan) {
      // Calculating pp preogress
      const pp = calculatePp(data.pathwayPlan)
      // Adding pp to next section
      state.next.push(pp)
      if (data.currentYp) {
        // Assigning pp to yp next section
        state.ypPps.next = pp
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
