<template>
  <div class="q-pa-md recent-caseload" v-if="recentCaseload.length">
    <h2>Recently opened</h2>
    <case-load-yp :young-person="yp" v-for="(yp, idx) in recentCaseload" :key="idx"></case-load-yp>
    <div class="clear"></div>
    <div class="text-center view-btn">
      <q-btn class="add custom-add" flat :to="{ name: 'MyCaseLoad' }">Go to my caseload</q-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CaseLoadYp from '@/components/MyCaseLoad/CaseLoadYp'

export default {
  name: 'RecentCaseload',
  components: { CaseLoadYp },
  computed: {
    ...mapGetters(['recentCaseload'])
  }
}
</script>

<style scoped>

</style>
