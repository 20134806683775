<template>
  <div>
    <notification></notification>
    <no-next-pp :show-bg="false" v-if="showNoPpPageOnTop" :show-registration-emotican="true"></no-next-pp>

    <div class="success-popup text-center" v-if="pathwayPlan.current && pathwayPlan.current.current_started === 'no'">
      <span class="icon"><img :src="successIcon" alt="successIcon"></span>
      <p aria-label="Pathway Plan was signed off successfully! let's get started">Pathway Plan was signed off <br>
        successfully! let's get started</p>
      <q-btn class="add green custom-add" flat label="Start working on current Pathway Plan"
             :to="{ name: 'PathwayPlan' }" aria-label="Start working on current Pathway Plan"/>
    </div>

    <!-- Calendar -->
    <div class="calendar-holder text-center">
      <div class="month-year" :aria-label="selectedDate.format('MMMM YYYY')">{{
          selectedDate.format('MMMM YYYY')
        }}
      </div>
      <q-virtual-scroll virtual-scroll-horizontal :items="calendarItems"
                        :aria-label="selectedDate.format('DD dddd') + ' ' + 'Selected'" tabindex="0">
        <template v-slot="{ item, index }">
          <div tabindex="0" :aria-label="item.dateObj.format('DD') + ' ' + item.dateObj.format('dddd')"
               @keyup.enter="changeDate(item.dateObj)" :key="index"
               :class="['date-box text-center scrolling-card', { 'active': item.dateObj.format('DD-MM-YYYY') === todayDate.format('DD-MM-YYYY') }, { 'selected': item.dateObj.format('DD-MM-YYYY') === selectedDate.format('DD-MM-YYYY') }, { 'bullet': item.tasks.length }]"
               @click="changeDate(item.dateObj)"> <!-- add active and selected classes -->
            <div class="date-box-content">
              <span class="dot"></span>
              <span class="monthDay">{{ item.dateObj.format('DD') }}</span>
              <span class="weekDay">{{ item.dateObj.format('ddd') }}</span>
            </div>
          </div>
        </template>
      </q-virtual-scroll>
    </div>

    <!-- Upcoming Tasks -->
    <div class="home-taks">
      <div class="q-pa-md">
        <h2>Upcoming tasks</h2>
        <div class="tasks-content">
          <task-list type="upcoming" label="upcoming" :tasks="upcomingTasks" :date="selectedDate"
                     :yp-home-no-task-template="true"></task-list>
        </div>
        <div class="text-center view-btn">
          <q-btn class="add custom-add" flat :to="{ name: 'Tasks' }" aria-label="View All tasks">View all</q-btn>
        </div>
      </div>
    </div>

    <!-- No pp page -->
    <no-next-pp v-if="!showNoPpPageOnTop"></no-next-pp>

    <!-- Next PP section -->
    <div v-if="pathwayPlan.next && pathwayPlan.next.started === 'yes'" class="home-pp-holder">
      <div class="q-pa-md q-gutter-md" style="margin-top: 0;">
        <div class="next-box-holder">
          <div class="ph-title-box">
            <h2>My next Pathway Plan</h2>
            <p>It needs signing by:
              {{ pathwayPlan.next ? formatDate(pathwayPlan.next.due_date, 'YYYY-MM-DD', 'DD/MM/YYYY') : '' }}</p>
          </div>
          <pp-linear-progress :pp-calculation="pathwayPlan.next.ppCalculation"></pp-linear-progress>
        </div>
      </div>
      <div class="success-popup message-popup text-center"
           v-if="pathwayPlan.next.signed_by_yp === 'yes' && pathwayPlan.next.signed_by_sw === 'no' && pathwayPlan.next.ppCalculation.totalPercent === 100">
        <span class="icon"><img :src="elipseIcon" alt="elipseIcon"></span>
        Your social worker or personal advisor will now sign-off your Pathway Plan. If you have any questions please
        contact them directly.
      </div>
      <div class="q-pa-md" style="padding-top: 0" v-else>
        <div class="button-section">
          <q-btn
            :disable="isOffline"
            class="no-outline no-shadow full-width q-btn--standard q-btn-full q-btn--rectangle bg-primary text-white"
            unelevated
            rounded color="primary" :to="{ name: 'PathwayPlan', query: { tab: 'next' } }" role="button">Continue filling your Pathway Plan <span
            class="txt">due date {{
              pathwayPlan.next ? formatDate(pathwayPlan.next.due_date, 'YYYY-MM-DD', 'DD MMMM YYYY') : ''
            }} </span></q-btn>
        </div>
      </div>
    </div>

    <!-- Current PP section -->
    <div class="q-pa-md" v-if="pathwayPlan.current">
      <div class="doc-row">
        <div class="doc">
          <div class="row">
            <div class="col-3">
              <img :src="docImg" alt="document image">
            </div>
            <div class="col-9 position-relative">
              <div class="doc-details">
                <p class="title">My current Pathway Plan</p>
                <p class="size">ends: {{ currentPpExpiryDate ? currentPpExpiryDate.format('DD/MM/YYYY') : '' }}</p>
                <p class="date">
                  <q-btn :disable="isOffline" class="add blue custom-add" flat icon="visibility" label="Preview" @click="goToPreview()" aria-label="Preview"/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-top: 0">
        <div class="start-filling">
          <q-btn :disable="isOffline"
                 :class="['no-outline no-shadow full-width q-btn--standard q-btn-full q-btn--rectangle bg-primary text-white']"
                 unelevated rounded color="primary" :to="{ name: 'PathwayPlan', query: { tab: 'current' } }"
                 aria-label="Start working on Pathway Plan" role="button">
            <div>
              <span>Start working on Pathway Plan</span>
            </div>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Badges -->
    <div class="badges-section text-center" v-if="userBadges && userBadges.length">
      <h2 style="margin-bottom: 0">Badges you've earned</h2>
      <div>
        <assign-badges :disabled="true" :static-badges="userBadges" :emit="true"></assign-badges>
      </div>
      <p class="continue">Keep going! For each done task you will get badges! </p>
      <div class="text-center view-btn">
        <q-btn class="add custom-add" flat label="View tasks" :to="{ name: 'Tasks' }" aria-label="View tasks"/>
      </div>
    </div>
    <div class="badges-section text-center" v-else>
      <h2>Badges You've Earned</h2>
      <div class="no-badges">
        <img :src="badges" alt="badge icon">
      </div>
      <p>As you complete tasks, any badges you are rewarded will appear here. </p>
      <div class="text-center view-btn">
        <q-btn class="add custom-add" flat label="View tasks" :to="{ name: 'Tasks' }" aria-label="View tasks"/>
      </div>
    </div>
    <div class="home-contacts" v-if="quickContacts.length">
      <div class="q-pa-md">
        <div class="row">
          <div class="col-8">
            <h2>Saved contacts</h2>
          </div>
          <div class="col-4 text-right">
            <q-btn class="green custom-add" flat :to="{ name: 'Contacts' }" style="font-size: 1.73vh"
                   aria-label="View All">View all
            </q-btn>
          </div>
        </div>
        <div class="home-cards-holder">
          <quick-contacts :filter-count="10"></quick-contacts>
        </div>
      </div>
    </div>
    <div class="home-contacts" v-if="supportingPeople && supportingPeople.length">
      <div class="q-pa-md" style="padding: 0 16px">
        <div class="col-8">
          <h2>People supporting me</h2>
        </div>
      </div>
      <div class="home-cards-holder">
        <supporting-people></supporting-people>
      </div>
    </div>
  </div>
</template>

<script>
import badges from '../../assets/badges-illust.png'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import TaskList from '@/components/Tasks/TaskList'
import Notification from '@/components/Utils/Notification'
import QuickContacts from '@/components/Contacts/QuickContacts'
import NoNextPp from '@/components/PathwayPlan/NoNextPp'
import PpLinearProgress from '@/components/PathwayPlan/PpLinearProgress'
import elipseIcon from '../../assets/elipse-icon.png'
import successIcon from '../../assets/compleated-icon.png'
import docImg from '../../assets/document.png'
import SupportingPeople from '../SupportingPeople'
import AssignBadges from '@/components/Tasks/AssignBadges'

export default {
  name: 'YPHome',
  components: {
    TaskList,
    Notification,
    QuickContacts,
    NoNextPp,
    PpLinearProgress,
    SupportingPeople,
    AssignBadges
  },
  data () {
    return {
      badges: badges,
      selectedDate: moment(),
      todayDate: moment(),
      elipseIcon: elipseIcon,
      successIcon: successIcon,
      docImg: docImg
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'pathwayPlan', 'tasks', 'quickContacts', 'supportingPeople', 'userBadges', 'isOffline']),
    upcomingTasks () {
      const calendarItemIndex = this.calendarItems.findIndex(item => {
        return item.dateObj.format('DD-MM-YYYY') === this.selectedDate.format('DD-MM-YYYY')
      })
      if (calendarItemIndex > -1) {
        return this.calendarItems[calendarItemIndex].tasks
      }
      return []
    },
    calendarItems () {
      const startDate = moment()
      const endDate = moment().add(14, 'days')
      const currDate = moment(startDate)
      const dateTasks = []
      let obj = {
        dateObj: currDate.clone(),
        tasks: this.tasks.filter(task => {
          return task.due_date === currDate.clone().format('YYYY-MM-DD') && task.assignedTo.id === this.currentUser.user_id
        })
      }
      dateTasks.push(obj)
      while (currDate.add(1, 'days').diff(endDate) < 0) {
        obj = {
          dateObj: currDate.clone(),
          tasks: this.tasks.filter(task => {
            return task.due_date === currDate.clone().format('YYYY-MM-DD') && task.assignedTo.id === this.currentUser.user_id
          })
        }
        dateTasks.push(obj)
      }
      return dateTasks
    },
    showNoPpPageOnTop () {
      if (this.pathwayPlan.next) {
        if (this.pathwayPlan.next.opened === 'yes' && this.pathwayPlan.next.started === 'no') {
          return true
        }
      }
      return false
    },
    currentPpExpiryDate () {
      if (this.pathwayPlan.current) {
        return this.pathwayPlan.current.expiry_date ? moment(this.pathwayPlan.current.expiry_date, 'YYYY-MM-DD') : null
      }
      return null
    }
  },
  methods: {
    ...mapActions(['getPathwayPlan', 'setPathwayPlan']),
    changeDate (item) {
      this.selectedDate = item
    },
    formatDate (date, fromFormat, toFormat) {
      return moment(date, fromFormat).format(toFormat)
    },
    goToPreview () {
      const CurrentPpEndDate = this.pathwayPlan.current ? (this.pathwayPlan.current.expiry_date ? moment(this.pathwayPlan.current.expiry_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '') : ''
      this.$router.push({ name: 'DocumentViewer', query: { doc: window.btoa(this.pathwayPlan.current.file.fileurl), title: window.btoa('Pathway Plan ends: ' + CurrentPpEndDate) } })
    }
  },
  mounted () {
    if (!this.currentUser.active) {
      this.$router.push({ name: 'Login' })
    }
    console.log('test', this.pathwayPlan.current && this.pathwayPlan.current.current_started === 'no')
    if (this.pathwayPlan.current && this.pathwayPlan.current.current_started === 'no') {
      const requestData = {
        pathway_id: this.pathwayPlan.current.id,
        token: this.currentUser.user_token,
        current_started: 'yes'
      }
      this.setPathwayPlan(requestData).then((data) => {})
    }
  }
}
</script>
