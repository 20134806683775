<template>
  <div>
    <vue-title title="My caseload"></vue-title>
    <div class="" v-if="showSearch">
      <div class="search-holder">
        <search-bar></search-bar>
      </div>
    </div>
    <notification section="caseload"></notification>
    <div class="q-pa-md">
      <send-group-notifications v-if="myCaseLoad && myCaseLoad.length"></send-group-notifications>
      <div class="case-box-holder" v-if="myCaseLoad && myCaseLoad.length">
        <div class="row q-gutter-md custom-q-gutter-md" v-if="myCaseLoad && myCaseLoad.length" style="margin: 0">
          <div class="case-box" v-for="(yp, idx) in myCaseLoad" :key="idx">
            <case-load-yp @caseloadRemoveYp="removeYp(yp)" :young-person="yp"></case-load-yp>
          </div>
        </div>
      </div>
    </div>
    <remove-caseload v-model="toRemoveYp"></remove-caseload>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CaseLoadYp from '../../components/MyCaseLoad/CaseLoadYp'
import avatar from '../../assets/av.png'
import SendGroupNotifications from '@/components/MyCaseLoad/SendGroupNotifications'
import RemoveCaseload from '@/components/MyCaseLoad/CaseloadConfirmation'
import SearchBar from '@/components/Utils/SearchBar'
import Notification from '@/components/Utils/Notification'

export default {
  name: 'CaseLoad',
  components: {
    Notification,
    SearchBar,
    RemoveCaseload,
    CaseLoadYp,
    SendGroupNotifications
  },
  data () {
    return {
      toRemoveYp: false,
      avatar,
      showSearch: false
    }
  },
  computed: {
    ...mapGetters(['myCaseLoad', 'allMyCaseLoad'])
  },
  methods: {
    ...mapActions(['setMyCaseLoad', 'clearYp']),
    ...mapMutations(['removeYpFromCaseLoad', 'clearSearch']),
    removeYp (yp) {
      this.toRemoveYp = yp
      this.toRemoveYp.isMyCaseLoad = true
    },
    toggleSearch () {
      this.showSearch = !this.showSearch
      if (!this.showSearch) {
        this.clearSearch()
      }
    }
  },
  watch: {
    modalOpen (isOpen) {
      if (!isOpen) {
        this.toRemoveYp = false
      }
    }
  },
  mounted () {
    this.$root.$on('caseload:showSearch', this.toggleSearch)
    this.clearYp()
  },
  beforeDestroy () {
    this.$root.$off('caseload:showSearch', this.toggleSearch)
    this.clearSearch()
  }
}
</script>
