<template>
  <div>
    <div v-if="pathwayPlan.current">
      <div class="q-pa-md">
        <div class="doc-row">
          <div class="doc">
            <div class="row">
              <div class="col-3">
                <img :src="docImg" alt="document image">
              </div>
              <div class="col-9 position-relative">
                <div class="doc-details">
                  <div>
                    <p class="title">My current Pathway Plan</p>
                    <p class="title">{{ pathwayPlan.current.fileUrl }}</p>
                    <p class="size">ends: {{ endDate }}</p>
                  </div>
                  <p class="date">
                    <q-btn :disable="isOffline" class="add blue custom-add" flat icon="visibility" label="Preview" @click="goToPreview" aria-label="preview"/>
                    <a aria-label="download" @click="download(pathwayPlan.current.file.fileurl)" v-if="!isOffline" href="javascript:void(0)" style="margin-left: 10px" class="q-btn add blue custom-add custom-add-a"><q-icon name="get_app"></q-icon> <span>Download</span></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pathway-section-grid :previous-route="previousRoute" :show-circular-progress="false" :pathway-tense="'current'"></pathway-section-grid>
    </div>
    <div class="q-pa-md" v-else>
      <div class="empty-task">
        <p class="text-center">No current plans here yet</p>
      </div>
      <div class="set-next-illustration-bg2" style="margin-top: 30%"></div>
    </div>
  </div>
</template>

<script>
import docImg from '../../assets/document.png'
import PathwaySectionGrid from './PathwaySectionGrid'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { saveAs } from 'file-saver'

export default {
  name: 'Current',
  components: {
    PathwaySectionGrid
  },
  props: {
    previousRoute: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      docImg: docImg
    }
  },
  computed: {
    ...mapGetters(['pathwayPlan', 'currentUser', 'isOffline']),
    endDate () {
      return this.pathwayPlan.current.expiry_date ? moment(this.pathwayPlan.current.expiry_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
    }
  },
  methods: {
    ...mapActions(['getPreviewPp']),
    ...mapMutations(['setDocumentTitle']),
    goToPreview () {
      this.$router.push({ name: 'DocumentViewer', query: { doc: window.btoa(this.pathwayPlan.current.file.fileurl), title: window.btoa('Pathway Plan ends: ' + this.endDate) } })
    },
    download (url) {
      saveAs(url)
    }
  }
}
</script>

<style scoped>

</style>
