import { register } from 'register-service-worker'

let wb = false

if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready (service) {
        // console.log('service worker ready', service)
        wb = service
        window.wb = service
      },
      updated (service) {
        // console.log('service worker updated', service)
        wb = service
        window.wb = service
      }
    })
  }
}

export default wb
