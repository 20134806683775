<template>
  <div>
    <vue-title title="All young person"></vue-title>
    <div class="search-holder" style="padding-bottom: 2.8vh; padding-top: 2.8vh">
      <search-bar></search-bar>
    </div>
    <q-tooltip v-model="showTT" @hide="hideToolTip" anchor="top left" self="bottom start" v-if="toolTipTarget"
               :target="toolTipTarget">
      <div class="allYoungPeopleToolTip">
        Tap to add a young person to your caseload
      </div>
    </q-tooltip>
    <list-contacts :set-current="goToYoungPerson" :contacts="groupedYoungPersons">
      <template v-slot="{ contact }">
        <q-item-section side>
          <div class="bookmark-icon" @click.stop="changeCaseload(contact)" @keyup.enter.stop="changeCaseload(contact)" v-if="!contact.hasSW" :class="{ toolTipTarget: !contact.isMyCaseLoad && !contact.hasSW }" tabindex="0" aria-label="bookmark" role="button">
            <i :class="{ fas: contact.isMyCaseLoad, far: !contact.isMyCaseLoad }" class="fa-bookmark"></i>
          </div>
          <div class="leadByText text-right" v-else>
            allocated to: <br/>
            {{ contact.leadBy }}
          </div>
        </q-item-section>
      </template>
    </list-contacts>
    <caseload-confirmation v-model="yp"></caseload-confirmation>
  </div>
</template>

<script>
import ListContacts from '@/components/Utils/ListContacts'
import SearchBar from '@/components/Utils/SearchBar'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CaseloadConfirmation from '@/components/MyCaseLoad/CaseloadConfirmation'
import { Loading } from 'quasar'

export default {
  name: 'AllYoungPersons',
  components: {
    CaseloadConfirmation,
    ListContacts,
    SearchBar
  },
  data () {
    return {
      yp: false,
      toolTipTarget: false,
      showTT: true
    }
  },
  computed: {
    ...mapGetters(['groupedYoungPersons', 'showToolTip', 'isOffline'])
  },
  methods: {
    ...mapActions(['setYoungPersons', 'setCurrentYp']),
    ...mapMutations(['clearSearch', 'hideToolTip']),
    changeCaseload (contact) {
      if (!this.isOffline) {
        this.yp = contact
      }
    },
    goToYoungPerson (yp) {
      if (yp.isMyCaseLoad) {
        Loading.show()
        this.setCurrentYp(yp).then(success => {
          if (success) {
            this.$router.push({ name: 'YpMainView', params: { previousRoute: this.$route.name } })
            Loading.hide()
          } else {
            Loading.hide()
          }
        }).catch(e => {
        })
      }
    }
  },
  mounted () {
    this.setYoungPersons().then(() => {
      this.toolTipTarget = document.querySelector('.toolTipTarget')
      this.showTT = this.showToolTip
    })
  },
  beforeDestroy () {
    this.clearSearch()
  }
}
</script>

<style>
.leadByText {
  font-size: 14px;
}
</style>
