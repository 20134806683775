<template>
  <q-list role="menu">
    <q-item clickable @click="clearYp" exact tag="a" :to="{ name: 'Home' }" role="menuitem">
      <q-item-section avatar>
        <q-icon name="home"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>Home</q-item-label>
      </q-item-section>
    </q-item>
    <q-item clickable @click="clearYp" tag="a" :to="{ name: 'MyCaseLoad' }" role="menuitem">
      <q-item-section avatar>
        <q-icon name="bubble_chart"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>My Caseload</q-item-label>
      </q-item-section>
    </q-item>
    <q-item clickable @click="clearYp" tag="a" :to="{ name: 'AllYoungPersons' }" role="menuitem">
      <q-item-section avatar>
        <q-icon name="person"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>All Young People</q-item-label>
      </q-item-section>
    </q-item>
    <q-item clickable @click="clearYp" tag="a" :to="{ name: 'Tasks'}" role="menuitem">
      <q-item-section avatar>
        <q-icon name="task_alt"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>Tasks</q-item-label>
      </q-item-section>
    </q-item>

    <q-item clickable @click="clearYp" tag="a" :to="{ name: 'PpDashboard' }" role="menuitem">
      <q-item-section avatar>
        <q-icon name="view_list"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>Pathway Plans</q-item-label>
      </q-item-section>
    </q-item>
    <q-item clickable @click="clearYp" tag="a" :to="{ name: 'Contacts'}" role="menuitem">
      <q-item-section avatar>
        <q-icon name="people"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>Contacts</q-item-label>
      </q-item-section>
    </q-item>
    <!--
    <q-item clickable @click="clearYp" tag="a">
      <q-item-section avatar>
        <q-icon name="description"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>Documents</q-item-label>
      </q-item-section>
    </q-item>
    -->
    <q-item clickable @click="clearYp" tag="a" :to="{ name: 'SwViewProfile'}" role="menuitem">
      <q-item-section avatar>
        <q-icon name="account_circle"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>My Profile</q-item-label>
      </q-item-section>
    </q-item>
    <q-item clickable @click="clearYp" tag="a" :to="{ name: 'AppSetting'}" role="menuitem">
      <q-item-section avatar>
        <q-icon name="o_settings"/>
      </q-item-section>
      <q-item-section>
        <q-item-label>App Settings</q-item-label>
      </q-item-section>
    </q-item>
    <q-item clickable @click="goToPrivacyPolicy" tag="a" role="menuitem">
      <q-item-section avatar>
      </q-item-section>
      <q-item-section class="privacy-policy">
        <q-item-label>Privacy policy</q-item-label>
      </q-item-section>
    </q-item>
    <q-item @click="logout" :class="{ last: stickToBottom }" clickable tag="a" role="menuitem">
      <q-item-section>
        <q-item-label>Logout</q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-icon name="logout"/>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MainMenuSw',
  computed: {
    stickToBottom () {
      if (window.screen.availHeight <= 500) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['logOut', 'clearYp']),
    logout () {
      this.logOut()
      this.clearYp()
      this.$router.go({ name: 'Login' })
    },
    goToPrivacyPolicy () {
      window.open('https://www.brent.gov.uk/privacy-cookie-policy', '_blank')
    }
  }
}
</script>
