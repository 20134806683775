import api from '@/api'
import { groupContacts } from '@/store/utils/groupings'
import Loading from 'quasar/src/plugins/Loading.js';
import userHelper from '../utils/userHelpers'

const state = {
  youngPersons: [],
  myCaseload: null
}

const getters = {
  myCaseLoad (state, getters) {
    if (getters.searchText.length) {
      return state.myCaseload.filter(youngPerson => (youngPerson.firstName + youngPerson.lastName).toLowerCase().indexOf(getters.searchText.toLowerCase().replace(/s*/g, '')) > -1)
    } else {
      return state.myCaseload
    }
  },
  allMyCaseLoad (state) {
    return state.myCaseload
  },
  notMyCaseLoad (state) {
    return state.youngPersons.filter(yp => !yp.isMyCaseLoad)
  },
  groupedYoungPersons (state, getters) {
    if (getters.searchText.length) {
      return groupContacts(state.youngPersons.filter(youngPerson => (youngPerson.firstName + youngPerson.lastName).toLowerCase().indexOf(getters.searchText.toLowerCase().replace(/s*/g, '')) > -1))
    } else {
      return groupContacts(state.youngPersons)
    }
  },
  groupedNotMyCaseload (state, getters) {
    if (getters.searchText.length) {
      return groupContacts(getters.notMyCaseLoad.filter(youngPerson => (youngPerson.firstName + youngPerson.lastName).toLowerCase().indexOf(getters.searchText.toLowerCase().replace(/s*/g, '')) > -1))
    } else {
      return groupContacts(getters.notMyCaseLoad)
    }
  },
  youngPersons (state) {
    return state.youngPersons
  },
  caseloadIds (state, getters) {
    if (getters.myCaseLoadWithoutFilter && getters.myCaseLoadWithoutFilter.length) {
      return getters.myCaseLoadWithoutFilter.map(cl => cl.id)
    }
    return []
  },
  myCaseLoadWithoutFilter (state) {
    return state.myCaseload
  }
}

const actions = {
  setMyCaseLoad (context) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        return api.getMyCaseLoad({ token: context.getters.currentUser.user_token }).then(data => {
          if (data.status === 200) {
            context.commit('setMyCaseLoad', data.data)
            resolve()
          } else {
            Loading.hide()
            resolve()
          }
        }).catch(e => {
          Loading.hide()
          reject(e)
        })
      } else {
        reject(Error)
      }
    })
  },
  setYoungPersons (context, data) {
    return api.getAllYp({ token: context.getters.currentUser.user_token }).then(data => {
      if (data.status === 200) {
        context.commit('setYoungPersons', data.data)
      } else {
        Loading.hide()
      }
    }).catch(e => {
      Loading.hide()
    })
  },
  addYpToMyCaseload (context, data) {
    Loading.show()
    const yps = data.join(',')
    return api.addToCaseload({
      token: context.getters.currentUser.user_token,
      yp_ids: yps
    }).then(data => {
      if (data.status === 200) {
        context.commit('addToCaseloadSelected', data)
        return Promise.all([context.dispatch('setMyCaseLoad'), context.dispatch('setYoungPersons')]).then(() => {
          Loading.hide()
          return data
        })
      } else {
        Loading.hide()
        return data
      }
    }).catch(e => {
      Loading.hide()
    })
  },
  removeYpFromMyCaseLoad (context, ypId) {
    Loading.show()
    return api.removeFromCaseload({
      token: context.getters.currentUser.user_token,
      user_id: ypId
    }).then(data => {
      if (data.status === 200) {
        context.commit('removeYpFromCaseLoad', ypId)
        context.commit('removeFromRecentCaseload', ypId)
        Loading.hide()
        data.data.caseloadLength = context.getters.myCaseLoad.length
        return data
      } else {
        Loading.hide()
        data.data.caseloadLength = 0
        return data
      }
    }).catch(e => {
      Loading.hide()
      return 0
    })
  },
  setCurrentYp (context, yp) {
    return userHelper.setUserFull(context, Object.assign({ isCaseload: true, yp_id: yp.id }, yp))
  },
  getYpUserInfo (context) {
    if (context.getters.currentYp) {
      return userHelper.setUserFull(context, Object.assign({
        isCaseload: true,
        yp_id: context.getters.currentYp.id
      }, Object.assign({}, context.getters.currentYp)))
    }
  },
  getYpBadges (context) {
    if (context.getters.currentYp) {
      return api.getBadges({
        token: context.getters.currentUser.user_token,
        yp_id: context.getters.currentYp.id
      }).then(data => {
        if (data.status === 200) {
          context.commit('updateCurrentYp', { badges: data.data })
        }
        Loading.hide()
      })
    } else {
      return false
    }
  }
}

const mutations = {
  setYoungPersons (state, data) {
    state.youngPersons = data
  },
  setMyCaseLoad (state, data) {
    state.myCaseload = state.myCaseload ? state.myCaseload : []
    if (data.length) {
      state.myCaseload = data.map(cl => {
        cl.assigned = []
        return cl
      })
    }
  },
  clearYoungPersons (state) {
    state.youngPersons = []
  },
  clearMyCaseload (state) {
    state.myCaseload = []
  },
  addToCaseloadSelected (state, data) {
    if (Array.isArray(data)) {
      state.youngPersons = state.youngPersons.map(yp => {
        if (data.includes(yp.id)) {
          yp.isMyCaseLoad = true
        }
        return yp
      })
    }
  },
  removeYpFromCaseLoad (state, ypId) {
    state.youngPersons = state.youngPersons.map(yp => {
      if (yp.id === ypId) {
        yp.isMyCaseLoad = false
      }
      return yp
    })
    state.myCaseload = state.myCaseload.filter(yp => yp.id !== ypId)
  },
  updateYpPersons (state, data) {
    state.youngPersons = state.youngPersons.map(yp => {
      if (data.includes(yp.id)) {
        yp.isMyCaseLoad = true
      }
      return yp
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
