export default {
  methods: {
    addFocusEvent () {
      try {
        if (document.querySelectorAll('label input,div input,textarea,[role="combobox"]').length) {
          document.querySelectorAll('label input,div input,textarea,[role="combobox"]').forEach(element => {
            element.addEventListener('focus', () => {
              setTimeout(() => {
                if (!element.getAttribute('aria-invalid') || element.getAttribute('aria-invalid') === 'false') {
                  if (document.querySelector('label[for=\'' + element.id + '\']')) {
                    document.querySelector('label[for=\'' + element.id + '\']').classList.remove('labelfocusinvalid')
                    document.querySelector('label[for=\'' + element.id + '\']').classList.add('labelfocusvalid')
                  }
                } else {
                  if (document.querySelector('label[for=\'' + element.id + '\']')) {
                    document.querySelector('label[for=\'' + element.id + '\']').classList.remove('labelfocusvalid')
                    document.querySelector('label[for=\'' + element.id + '\']').classList.add('labelfocusinvalid')
                  }
                }
              }, 10)
            })
            element.addEventListener('keyup', () => {
              if (!element.getAttribute('aria-invalid') || element.getAttribute('aria-invalid') === 'false') {
                if (document.querySelector('label[for=\'' + element.id + '\']')) {
                  document.querySelector('label[for=\'' + element.id + '\']').classList.remove('labelfocusinvalid')
                  document.querySelector('label[for=\'' + element.id + '\']').classList.add('labelfocusvalid')
                }
              } else {
                if (document.querySelector('label[for=\'' + element.id + '\']')) {
                  document.querySelector('label[for=\'' + element.id + '\']').classList.remove('labelfocusvalid')
                  document.querySelector('label[for=\'' + element.id + '\']').classList.add('labelfocusinvalid')
                }
              }
            })
            element.addEventListener('blur', () => {
              if (document.querySelector('label[for=\'' + element.id + '\']')) {
                document.querySelector('label[for=\'' + element.id + '\']').classList.remove('labelfocusvalid')
                document.querySelector('label[for=\'' + element.id + '\']').classList.remove('labelfocusinvalid')
              }
            })
          })
        }
      } catch (e) {
      }
    }
  },
  mounted () {
    this.addFocusEvent()
  },
  updated () {
    this.addFocusEvent()
  }
}
