<template>
  <q-dialog v-model="modalOpen" position="bottom" class="brent-dialog" role="region" aria-label="add contact" @hide="hideModal()" @show="showModal()">
    <lock v-model="isLocked" @closeModal="modalOpen = false" :lock-is-on="'contact'"></lock>
    <q-card v-if="!isLocked" class="brent-dialog-content">
      <q-card-actions align="left" class="popup-header">
        <q-btn flat label="Cancel" v-close-popup/>
        <div class="text-h6 text-center" role="heading" aria-level="2">{{ currentContact ? 'Edit' : 'New' }} contact</div>
      </q-card-actions>
      <q-card-section class="q-pt-none">
        <q-form ref="addContactForm">
          <div>
            <div class="form-row avatar-form-row">
              <div class="q-gutter-y-md column text-center">
                <q-avatar class="profile-avatar" size="14.8vh">
                  <img v-if="!uploadedAvatarImg" :src="avatar" alt="avatar">
                  <img v-else :src="uploadedAvatarImg" alt="avatar">
                </q-avatar>
                <q-file title="photo" @input="getAvatarPreview" borderless input-class="add blue custom-add text-center" v-model="contactInfo.avatar" label="Add photo" for="photo">
                  <div slot="label" class="add blue2 custom-add text-center">{{ uploadedAvatarImg.length ? 'Change' : 'Add' }} photo</div>
                  <div slot="file"></div>
                </q-file>
              </div>
            </div>
            <div class="form-row">
              <div class="input-wrapp">
                <label v-show="contactInfo.firstName.length" for="firstName" class="custom-l">First name</label>
                <q-input
                  :rules="[
                    val => val !== null && val.trim() !== '' || 'Please type the first name',
                    ]"
                  v-model="contactInfo.firstName"
                  outlined placeholder="First name" for="firstName"/>
              </div>
            </div>
            <div class="form-row">
              <div class="input-wrapp">
                <label v-show="contactInfo.lastName.length" for="lastName" class="custom-l">Last name</label>
                <q-input
                  v-model="contactInfo.lastName"
                  outlined placeholder="Last name" for="lastName"/>
              </div>
            </div>
            <div class="form-row">
              <div class="input-wrapp">
                <label v-show="contactInfo.organization.length" for="organization" class="custom-l">Organization</label>
                <q-input
                  v-model="contactInfo.organization"
                  outlined placeholder="Organization" for="organization"/>
              </div>
            </div>
            <div class="form-row">
              <div class="input-wrapp">
                <label v-show="typeof contactInfo.phone === 'string' && contactInfo.phone.length" for="phone" class="custom-l">Phone</label>
                <q-input
                  :rules="[
                    val => val !== null && val.trim() !== '' || 'Please type the phone number',
                    val => val.match(/^([0-9]\s*|-*|\+*|\(-*|\)*|\.*)*$/g) !== null || 'Please type a valid phone number'
                   ]"
                  v-model="contactInfo.phone"
                  outlined placeholder="Phone" for="phone"/>
              </div>
            </div>
            <div class="form-row">
              <q-btn :disable="loading" @click="addInfo('phoneNumbers')" class="add add-phone custom-add blue" flat role="button">
                <span class="plus-icon material-icons-outlined">add</span> Add phone
              </q-btn>
            </div>
            <div class="form-row" v-for="(_, idx) in contactInfo.phoneNumbers" :key="'phoneNumbers' + idx">
              <div class="q-gutter-y-md column">
                <q-input :disable="loading" :rules="[val => val !== null && val.trim() !== '' || 'Please type your phone number', val => val.match(/^([0-9]\s*|-*|\+*|\(-*|\)*|\.*)*$/g) !== null || 'Please type a valid phone number' ]" v-model="contactInfo.phoneNumbers[idx]" outlined placeholder="Enter your phone number" :title="'phone' + idx"/>
              </div>
              <q-btn :disable="loading" @click="removeInfo('phoneNumbers', idx)" class="add add-phone custom-add blue" flat role="button">
                <span class="plus-icon material-icons-outlined">remove</span> Remove phone
              </q-btn>
            </div>

            <div class="form-row">
              <div class="input-wrapp">
                <label v-show="contactInfo.email.length" for="email" class="custom-l">Email</label>
                <q-input
                  :rules="[
                    val =>(val.trim() === '' || val.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g) !== null) || 'Please type a valid email address'
                  ]"
                  v-model="contactInfo.email"
                  type="email" outlined placeholder="Email" for="email"/>
              </div>
            </div>
            <div class="pupup-button">
              <q-btn @click="validate()" class="float-right q-btn--standard full-width" unelevated rounded
                     :loading="loading"
                     color="primary"
                     label="Save contact" aria-label="save contact" role="button"/>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import avatar from '@/assets/av.png'
import { mapActions, mapGetters } from 'vuex'
import Lock from '@/components/Utils/Lock'
import addLocks from '@/mixins/addLocks'
import removeLocks from '@/mixins/removeLocks'
import trapModalFocus from '@/mixins/trapModalFocus'
import focusLabel from '@/mixins/focusLabel'

const contact = {
  avatar: [],
  firstName: '',
  lastName: '',
  organization: '',
  phone: '',
  email: '',
  phoneNumbers: [],
  isMyContact: true,
  isQuickContact: false
}

export default {
  name: 'AddEditContact',
  components: { Lock },
  mixins: [addLocks, removeLocks, trapModalFocus, focusLabel],
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      modalOpen: false,
      avatar,
      uploadedAvatarImg: false,
      contactInfo: Object.assign({}, contact),
      isLocked: false
    }
  },
  computed: {
    ...mapGetters(['currentContact'])
  },
  watch: {
    value (value) {
      if (!value) {
        this.restData()
        this.isLocked = false
      }
      this.modalOpen = value
    },
    modalOpen (value) {
      if (!value) {
        this.restData()
        this.isLocked = false
      }
      this.$emit('input', value)
    },
    currentContact (currentContact) {
      if (currentContact) {
        this.uploadedAvatarImg = currentContact.avatar
        const editContact = Object.assign({}, currentContact)
        delete editContact.avatar
        this.contactInfo = editContact
      } else {
        this.uploadedAvatarImg = ''
        this.contactInfo = Object.assign({}, contact)
      }
    }
  },
  methods: {
    ...mapActions(['saveContact']),
    restData () {
      if (!this.currentContact) {
        this.contactInfo = Object.assign({}, contact)
        this.contactInfo.phoneNumbers = []
        this.uploadedAvatarImg = ''
      }
      this.modalOpen = false
      this.loading = false
    },
    getAvatarPreview (e) {
      try {
        const reader = new FileReader()
        reader.readAsDataURL(e)
        reader.onload = () => {
          this.uploadedAvatarImg = reader.result
        }
      } catch (e) {
        console.log(e)
      }
    },
    addInfo (type) {
      this.contactInfo[type].push('')
    },
    removeInfo (type, idx) {
      this.contactInfo[type].splice(idx, 1)
    },
    validate () {
      this.$refs.addContactForm.validate().then(success => {
        if (success) {
          this.loading = true
          const contactInfo = Object.assign({}, this.contactInfo)
          if (this.uploadedAvatarImg.length) {
            contactInfo.avatar = this.uploadedAvatarImg
          } else {
            if (typeof this.currentContact === 'object' && this.currentContact.avatar.length) {
              contactInfo.avatar = this.currentContact.avatar
            } else {
              contactInfo.avatar = ''
            }
          }
          this.saveContact(contactInfo).then(() => {
            this.restData()
          })
        }
      })
    }
  },
  mounted () {
    this.$root.$on('save:contact', this.validate)
  },
  updated () {
    if (document.querySelector('.q-file .q-file__filler')) {
      document.querySelector('.q-file .q-file__filler').setAttribute('aria-labelledby', 'photo')
    }
  },
  beforeDestroy () {
    this.$root.$off('save:contact', this.validate)
  }
}
</script>

<style scoped>

</style>
