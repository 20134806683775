<template>
  <div>
    <div class="contacts-holder">
      <div class="list-contacts-holder">
        <h2 v-if="label.length" class="list-title">{{ label }}</h2>
        <q-list separator v-for="(contactGroup, idx) in contacts" :key="'contactsGroup_' + idx">
          <q-bar>
            <span style="text-transform: uppercase">{{ contactGroup.group }}</span>
          </q-bar>
          <q-item :class="itemClassGenerator(contact)" :clickable="chevron" :v-ripple="chevron" v-for="(contact, idx) in contactGroup.children" :key="'contact_' + idx" @click="setCurrent(contact)">
            <q-item-section avatar>
              <q-avatar size="6.41vh">
                <img v-if="typeof contact.avatar === 'string' && contact.avatar.length" :src="contact.avatar" alt="avatar">
                <img v-else :src="avatar" alt="avatar">
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <p class="list-name">{{ contact.firstName }} {{ contact.lastName }}</p>
              <p class="list-text">{{ contact.mobile }}</p>
            </q-item-section>
            <slot v-bind:contact="{ ...contact, idx }"></slot>
            <q-item-section v-if="chevron && (contact.isMyCaseLoad || $route.name === 'Contacts' || $route.name === 'YpContacts')" side class="arrow-icon"><i class="fas fa-chevron-right"></i></q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from '../../assets/av.png'

export default {
  name: 'ListContacts',
  props: {
    contacts: {
      type: Array,
      default () {
        return []
      }
    },
    label: {
      type: String,
      default () {
        return ''
      }
    },
    itemClassGenerator: {
      type: Function,
      default () {
        return function (contact) { return '' }
      }
    },
    chevron: { // set the chevron to enable the set current function
      type: Boolean,
      default () {
        return true
      }
    },
    setCurrent: { // pass the set current function
      type: Function,
      default () {
        return function (contact) { }
      }
    }
  },
  data () {
    return {
      avatar
    }
  }
}
</script>
