<template>
  <div class="q-pa-md">
    <div v-if="sp.length" class="supporting-people">
      <q-card tabindex="0" v-for="(person, idx) in sp" :key="idx" class="text-center q-mb-md q-mr-md" style="width: calc(50% - 16px); float: left">
        <q-card-actions align="right">
        </q-card-actions>
        <q-card-section style="padding-top: 0;">
          <div class="card-avatar">
            <q-avatar size="6.41vh">
              <img v-if="typeof person.avatar === 'string' && person.avatar.length" :src="person.avatar" alt="avatar">
              <img v-else :src="avatar" alt="avatar">
            </q-avatar>
          </div>
          <div class="card-text details">
            <h2 :aria-label="person.first_name + ' ' + person.last_name">{{ person.first_name }} {{ person.last_name }}</h2>
            <p :aria-label="person.phone_number">{{ person.phone_number }}</p>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import avatar from '@/assets/av.png'

export default {
  name: 'PeopleSupporting',
  computed: {
    ...mapGetters(['currentYp', 'supportingPeople']),
    sp () {
      return this.currentYp ? this.currentYp.supporting_people : this.supportingPeople
    }
  },
  data () {
    return {
      avatar
    }
  }
}
</script>

<style scoped>

</style>
