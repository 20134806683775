<template>
  <div class="login hidden-header human-background">
    <vue-title title="Login"></vue-title>
    <q-img
      v-if="logo"
      :src="logo"
      spinner-color="white"
      style="max-width: 88px; margin: 0 auto; display: block"
      class="relative-position login-logo"
      alt="image"
    />
    <h1 v-if="title" class="logo-text text-center header-h1-custom" role="heading" aria-level="1">{{ title }}</h1>
    <q-form class="register-form">
      <div class="q-pa-md">
        <div class="q-gutter-y-md column">
          <label for="email">Email</label>
          <q-input v-model="email" type="email" outlined placeholder="Enter your email address" @keyup="resetErrors" for="email" :class="{ 'filled-input': email }"/>
          <span class="error" v-if="emailError">{{ emailError }}</span>
          <label for="password">Password</label>
          <q-input :class="['pass-field', { 'filled-input': password }]" v-model="password" :type="isPwd ? 'password' : 'text'" outlined placeholder="Enter password" @keyup="resetErrors" for="password">
            <template v-slot:append>
              <q-icon
                :class="isPwd ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
                @keyup.enter="isPwd = !isPwd"
                aria-label="toggle password"
                role="button"
                tabindex="0"
                :aria-hidden="false"
              />
            </template>
          </q-input>
          <div style="margin-top: 0" v-if="question" role="region" aria-label="security question">
            <label for="question">{{ question }}</label>
            <q-input v-model="answer" outlined placeholder="Answer security question" @keyup="resetErrors" for="question" :class="[{ 'filled-input': answer }]"/>
          </div>
          <q-btn @click="submitForm()" flat class="no-outline no-shadow q-btn--standard q-btn--rectangle bg-primary text-white" v-bind:class="{ disabled: isDisabled }" :disabled='isDisabled' rounded label="Log in" aria-label="Log in"/>
          <p class="forgot-text text-center"><router-link class="green form-text-link" :to="{ name: 'ForgotPassword' }" aria-label="Forgot your password?">Forgot your password?</router-link></p>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import focusLabel from '@/mixins/focusLabel'

export default {
  name: 'Login',
  mixins: [focusLabel],
  data () {
    return {
      title: window.title,
      logo: window.logo,
      password: '',
      isPwd: true,
      answer: '',
      email: '',
      emailError: '',
      question: ''
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isDisabled () {
      if (this.password.length && this.email.length && ((this.question.length && this.answer.length) || (!this.question.length && !this.answer.length))) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['loginPassword', 'login', 'setUser', 'setPathwayPlan']),
    checkForm () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(this.email)) {
        this.emailError = 'Invalid email'
        return false
      }
      return true
    },
    submitForm () {
      if (this.checkForm()) {
        this.$q.loading.show()
        if (this.answer) {
          this.login({
            email: this.email,
            password: this.password,
            security_answer: this.answer
          }).then((data) => {
            if (data.status === 200) {
              if (data.data.user_type === 'YP' && data.data.info_full.pathwayPlans.current.length && data.data.info_full.pathwayPlans.current[0].current_started === 'no') {
                const requestData = {
                  pathway_id: data.data.info_full.pathwayPlans.current[0].id,
                  token: data.data.user_token,
                  current_started: 'yes'
                }
                this.setPathwayPlan(requestData).then((data) => {})
              }
              data.data.user_email = this.email
              this.$q.loading.hide()
              this.$root.$emit('addToHomeScreen')
              this.$router.push({
                name: 'Home'
              })
              return this.$q.notify({
                message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                html: true,
                color: 'green',
                position: 'top-right'
              })
            }
            this.$q.loading.hide()
            return this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'red',
              position: 'top-right'
            })
          }, err => {
            this.$q.loading.hide()
            return this.$q.notify({
              message: err.message,
              color: 'red',
              position: 'top-right'
            })
          })
        } else {
          this.loginPassword({
            email: this.email,
            password: this.password
          }).then((data) => {
            this.$q.loading.hide()
            if (data.status === 200) {
              this.question = data.data.security_question
              return this.$q.notify({
                message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                html: true,
                color: 'green',
                position: 'top-right'
              })
            }
            return this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'red',
              position: 'top-right'
            })
          }, err => {
            this.$q.loading.hide()
            return this.$q.notify({
              message: err.message,
              color: 'red',
              position: 'top-right'
            })
          })
        }
      }
    },
    resetErrors () {
      this.emailError = ''
    }
  },
  mounted () {
    if (this.currentUser.active) {
      this.$router.push({
        name: 'YPStartPage' // this one should be changed based on user type
      })
    }
    if (document.querySelectorAll('.q-dialog--modal .q-select input').length) {
      document.querySelectorAll('.q-dialog--modal .q-select input').forEach(element => {
        element.removeAttribute('aria-multiselectable')
      })
    }
  }
}
</script>
<style>
  .register-form span.error {
    color: red;
  }
  .register-form .filled-input .q-field__control:before {
    border-color: #000000;
  }
  .pass-field .q-field__marginal {
    position: absolute !important;
    right: 16px
  }
  h1.header-h1-custom {
    font-style: normal;
    font-weight: bold;
    font-size: 2em;
    line-height: 4.8vh;
    text-align: center;
    color: #222222;
    margin: 2px 0 3vh;
  }
</style>
