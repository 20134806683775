import QMenu from 'quasar/src/components/menu/QMenu.js';

export default {
  extends: QMenu,
  name: 'QMenu',
  mounted () {
    const vm = this
    this.$on('show', () => {
      vm.$children[0].$children.forEach(c => {
        c.$el.setAttribute('tabindex', 0)
      })
      if (document.activeElement.tagName.toLowerCase() !== 'input') {
        vm.$children[0].$children[0].$el.focus()
      }
    })
  }
}
