<template>
  <div>
    <app-header-home></app-header-home>
    <app-header-contacts></app-header-contacts>
    <app-header-my-case-load></app-header-my-case-load>
    <app-header-pathway-plan></app-header-pathway-plan>
    <app-header-profile></app-header-profile>
    <app-header-tasks></app-header-tasks>
    <app-header-caseload-add-yp></app-header-caseload-add-yp>
    <app-header-caseload-all-yp></app-header-caseload-all-yp>
    <app-header-sw-profile></app-header-sw-profile>
    <app-header-yp-main-view></app-header-yp-main-view>
    <app-header-sw-yp-view></app-header-sw-yp-view>
    <app-header-document-viewer></app-header-document-viewer>
    <app-header-app-setting></app-header-app-setting>
    <app-header-my-options></app-header-my-options>
    <app-header-info></app-header-info>
  </div>
</template>

<script>

import AppHeaderContacts from '@/components/AppHeaders/HeaderContacts'
import AppHeaderMyCaseLoad from '@/components/AppHeaders/HeaderMyCaseload'
import AppHeaderPathwayPlan from '@/components/AppHeaders/HeaderPathwayPlan'
import AppHeaderProfile from '@/components/AppHeaders/HeaderProfile'
import AppHeaderTasks from '@/components/AppHeaders/HeaderTasks'
import AppHeaderCaseloadAddYp from '@/components/AppHeaders/HeaderCaseloadAddYp'
import AppHeaderHome from '@/components/AppHeaders/HeaderHome'
import AppHeaderCaseloadAllYp from '@/components/AppHeaders/HeaderCaseloadAllYp'
import AppHeaderSwProfile from '@/components/AppHeaders/HeaderSwProfile'
import AppHeaderYpMainView from '@/components/AppHeaders/HeaderProfileYpMainView'
import AppHeaderSwYpView from '@/components/AppHeaders/HeaderSwYpView'
import AppHeaderDocumentViewer from '@/components/AppHeaders/HeaderDocumentViewer'
import AppHeaderAppSetting from '@/components/AppHeaders/HeaderAppSetting'
import AppHeaderMyOptions from '@/components/AppHeaders/HeaderMyOptions'
import AppHeaderInfo from '@/components/AppHeaders/HeaderInfo'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderInfo,
    AppHeaderSwYpView,
    AppHeaderYpMainView,
    AppHeaderSwProfile,
    AppHeaderCaseloadAllYp,
    AppHeaderHome,
    AppHeaderCaseloadAddYp,
    AppHeaderTasks,
    AppHeaderProfile,
    AppHeaderPathwayPlan,
    AppHeaderMyCaseLoad,
    AppHeaderContacts,
    AppHeaderDocumentViewer,
    AppHeaderAppSetting,
    AppHeaderMyOptions
  }
}
</script>

<style scoped>

</style>
