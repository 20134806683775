<template>
  <div>
    <vue-title title="Contacts"></vue-title>
    <contact-info v-if="currentContact"></contact-info>
    <div v-else class="tasks-content contacts-content">
      <div>
        <notification section="contacts"></notification>
        <div v-if="search" class="search-holder">
          <search-bar></search-bar>
        </div>

        <q-tabs
          v-model="tab"
          dense
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
        >
          <q-tab name="all" label="All" aria-label="all contacts"/>
          <q-tab name="myContacts" label="My contacts" aria-label="my contacts" v-if="myContacts.length"/>
          <q-tab name="services" label="Services" aria-label="services"/>
        </q-tabs>

        <quick-contacts></quick-contacts>

        <q-tab-panels v-model="tab" animated class="q-pt-md">
          <q-tab-panel name="all" class="contacs-pannel">
            <list-contacts section="contacts" label="All contacts" aria-label="all contacts" :contacts="allContacts" :chevron="true" :set-current="goToContact">
              <template v-slot="{ contact }">
                <q-item-section side class="phone-icon">
                  <a @click.stop :href="'tel:' + contact.phone" aria-label="dial" role="button"><i class="fas fa-phone-alt"></i></a>
                </q-item-section>
              </template>
            </list-contacts>
          </q-tab-panel>

          <q-tab-panel name="myContacts" class="contacs-pannel" v-if="myContacts.length">
            <list-contacts section="contacts" label="My contacts" aria-label="my contacts" :contacts="myContacts" :chevron="true" :set-current="goToContact">
              <template v-slot="{ contact }">
                <q-item-section side class="phone-icon">
                  <a @click.stop :href="'tel:' + contact.phone" aria-label="dial" role="button"><i class="fas fa-phone-alt"></i></a>
                </q-item-section>
              </template>
            </list-contacts>
          </q-tab-panel>

          <q-tab-panel name="services" class="contacs-pannel">
            <services :contacts="services"></services>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
    <add-edit-contact v-model="addContact"></add-edit-contact>
    <q-dialog v-model="confirmDelete" role="region" aria-label="confirmation dialog" @hide="hideModal()" @show="showModal()">
      <q-card class="round-dialog">
        <q-card-section v-if="currentContact">
          <div class="text-h6 text-center">Are you sure you want to delete this contact?</div>
          <div class="text-center">
            <div class="q-avatar">
              <img v-if="typeof currentContact.avatar === 'string' && currentContact.avatar.length" :src="currentContact.avatar" alt="avatar">
              <img v-else :src="avatar" alt="avatar">
            </div>
          </div>
          <div class="text-h6 text-center">{{ currentContact.firstName }} {{ currentContact.lastName }}</div>
        </q-card-section>
        <q-card-actions class="text-center">
          <q-btn class="blue" flat label="Yes" v-close-popup @click="confirmedDelete()"/>
          <q-btn color="primary" rounded label="No" v-close-popup/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import ListContacts from '@/components/Utils/ListContacts'
import Services from '@/components/Contacts/Services'
import AddEditContact from '@/components/Contacts/AddEditContact'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import QuickContacts from '@/components/Contacts/QuickContacts'
import ContactInfo from '@/components/Contacts/ContactInfo'
import SearchBar from '@/components/Utils/SearchBar'
import Notification from '@/components/Utils/Notification'
import avatar from '../assets/av.png'
import trapModalFocus from '@/mixins/trapModalFocus'
let updateInterval = null

export default {
  name: 'Contacts',
  components: {
    Notification,
    SearchBar,
    QuickContacts,
    ListContacts,
    Services,
    AddEditContact,
    ContactInfo
  },
  mixins: [trapModalFocus],
  data () {
    return {
      tab: 'all',
      addContact: false,
      contact: false,
      confirmDelete: false,
      search: false,
      avatar
    }
  },
  computed: {
    ...mapGetters(['allContacts', 'myContacts', 'currentContact', 'services', 'currentUser', 'tasks', 'allContactsWithoutGroup'])
  },
  methods: {
    ...mapActions(['deleteContact', 'setContacts', 'clearYp']),
    ...mapMutations(['setCurrentContact', 'clearSearch']),
    eventAddEditContact () {
      this.addContact = true
    },
    eventDeleteContact () {
      this.confirmDelete = true
    },
    confirmedDelete () {
      this.$q.loading.show()
      this.deleteContact(this.currentContact).then(() => {
        this.$q.loading.hide()
        if (!this.myContacts.length) {
          this.tab = 'all'
        }
      })
    },
    showSearch () {
      this.search = !this.search
      if (!this.search) {
        this.clearSearch()
      }
    },
    toCurrentContact () {
      if (this.$route.query.id && this.allContactsWithoutGroup.length) {
        clearInterval(updateInterval)
        const contact = this.allContactsWithoutGroup.find(contact => contact.id === this.$route.query.id)
        if (contact) {
          this.setCurrentContact(contact)
        } else {
          this.setCurrentContact(false)
        }
      }
    },
    goToContact (contact) {
      if (['Contacts', 'YpContacts'].includes(this.$route.name)) {
        this.setCurrentContact(contact)
        this.$router.push({ name: this.$route.name, query: { id: contact.id } })
      } else {
        this.$router.push({ name: 'Contacts', query: { id: contact.id } })
      }
    }
  },
  mounted () {
    if (this.$route.name === 'Contacts') {
      this.clearYp()
    }
    this.setContacts().catch(e => {})
    let updateIntervalCounter = 0
    updateInterval = setInterval(() => {
      if (updateIntervalCounter > 20) {
        clearInterval(updateInterval)
      }
      this.toCurrentContact()
      updateIntervalCounter++
    }, 50)
    this.toCurrentContact()
    this.$root.$on('contact::add', this.eventAddEditContact)
    this.$root.$on('contact::edit', this.eventAddEditContact)
    this.$root.$on('contact::delete', this.eventDeleteContact)
    this.$root.$on('contact::showSearch', this.showSearch)
  },
  beforeDestroy () {
    this.$root.$off('contact::add', this.eventAddEditContact)
    this.$root.$off('contact::edit', this.eventAddEditContact)
    this.$root.$off('contact::delete', this.eventDeleteContact)
    this.$root.$on('contact::showSearch', this.showSearch)
    this.clearSearch()
  }
}
</script>
