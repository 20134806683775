<template>
  <q-dialog v-model="modalOpen" @hide="closeModal" role="region" aria-label="confirmation dialog">
    <q-card class="round-dialog">
      <q-card-section v-if="value">
        <div class="text-h6 text-center">Do you really want to {{ value.isMyCaseLoad ? 'remove from' : 'add to' }} my caseload</div>
        <div class="text-center">
          <div class="q-avatar">
            <img v-if="typeof value.avatar === 'string' && value.avatar.length" :src="value.avatar" alt="avatar">
            <img v-else :src="avatar" alt="avatar">
          </div>
        </div>
        <div class="text-h6 text-center">{{ value.firstName }} {{ value.lastName }}</div>
      </q-card-section>
      <q-card-actions class="text-center" v-if="value.isMyCaseLoad">
        <q-btn class="blue" flat label="Yes" v-close-popup @click="changeCaseload"/>
        <q-btn color="primary" rounded label="No" v-close-popup/>
      </q-card-actions>
      <q-card-actions class="text-center" v-else>
        <q-btn color="primary" rounded label="Yes" v-close-popup @click="changeCaseload"/>
        <q-btn class="blue" flat label="No" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import avatar from '../../assets/av.png'

export default {
  name: 'CaseloadConfirmation',
  props: {
    value: {
      default () {
        return true
      }
    }
  },
  data () {
    return {
      modalOpen: false,
      avatar
    }
  },
  computed: {
    ...mapGetters(['myCaseLoad'])
  },
  methods: {
    ...mapActions(['removeYpFromMyCaseLoad', 'addYpToMyCaseload']),
    changeCaseload () {
      if (this.value.isMyCaseLoad) {
        this.removeYpFromMyCaseLoad(this.value.id).then((data) => {
          this.modalOpen = false
          if (data.status === 200) {
            this.$root.$emit('caseload::notification', data.message)
            if (this.$route.name === 'MyCaseLoad') {
              if (data.data.caseloadLength === 0) {
                this.$router.push({ name: 'Home' })
              }
            }
          } else {
            this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'red',
              position: 'top-right'
            })
          }
          this.$emit('input', false)
          this.$emit('removed')
        })
      } else {
        this.addYpToMyCaseload([this.value.id]).then((data) => {
          this.$emit('input', false)
          this.$emit('added')
          if (data.status === 200) {
            this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'green',
              position: 'top-right'
            })
          } else {
            this.$q.notify({
              message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
              html: true,
              color: 'red',
              position: 'top-right'
            })
          }
        })
      }
    },
    closeModal () {
      this.modalOpen = false
      this.$emit('input', false)
    }
  },
  watch: {
    value (val) {
      this.modalOpen = typeof val === 'object'
    }
  }
}
</script>

<style scoped>

</style>
