<template>
  <q-input class="searchbox" ref="searchBox" title="searchbox" :value="searchText" outlined rounded placeholder="Search" @input="setSearch" @focus="fieldActivated = true" @blur="fieldActivated = false">
    <template v-slot:append>
      <i class="fas fa-times-circle" v-if="searchText || fieldActivated" @click="clearSearch()" tabindex="0" @keyup.enter="clearSearch()"></i>
      <q-icon size="md" name="search" v-else />
    </template>
  </q-input>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SearchBar',
  data () {
    return {
      fieldActivated: false
    }
  },
  computed: {
    ...mapGetters(['searchText'])
  },
  methods: {
    ...mapMutations(['setSearch']),
    clearSearch () {
      this.setSearch('')
      this.$refs.searchBox.focus()
    }
  }
}
</script>

<style scoped>

</style>
