import Vue from 'vue'

import './styles/quasar.scss'
import 'quasar/dist/quasar.ie.polyfills'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
// import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'

import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Quasar from 'quasar/src/vue-plugin.js';
import QMenuOverride from '@/overrides/QMenuOverride'

Vue.use(Quasar, {
  plugins: {
    Notify,
    Loading
  },
  components: {
    QMenu: Vue.component('QMenu', QMenuOverride)
  },
  iconSet: iconSet
})
