<template>
  <div class="register hidden-header">
    <vue-title title="Register"></vue-title>
    <q-img
      :src="logo"
      spinner-color="white"
      style="max-width: 88px; margin: 0 auto; display: block"
      class="relative-position login-logo"
      alt="logo"
    />
    <h4 class="logo-text text-center" role="heading" aria-level="1">Brent</h4>
    <q-form class="register-form">
      <div class="q-pa-md">
        <div class="q-gutter-y-md column">
          <label for="createPassword">Create Password</label>
          <q-input class="pass-field" v-model="password" :type="pwdType ? 'password' : 'text'" outlined placeholder="Enter password" @keyup="resetErrors" for="createPassword">
            <template v-slot:append>
              <q-icon
                :class="pwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="pwdType = !pwdType"
                @keyup.enter="pwdType = !pwdType"
                aria-label="toggle password"
                role="button"
                tabindex="0"
                :aria-hidden="false"
              />
            </template>
          </q-input>
          <span class="error" v-if="passwordError">{{ passwordError }}</span>
          <label for="confirmPassword">Confirm Password</label>
          <q-input class="pass-field" v-model="confirmPassword" :type="repeatPwdType ? 'password' : 'text'" outlined placeholder="Enter password" @keyup="resetErrors" for="confirmPassword">
            <template v-slot:append>
              <q-icon
                :class="repeatPwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="repeatPwdType = !repeatPwdType"
                @keyup.enter="repeatPwdType = !repeatPwdType"
                aria-label="toggle password"
                role="button"
                tabindex="0"
                :aria-hidden="false"
              />
            </template>
          </q-input>
          <span class="error" v-if="confirmPasswordError">{{ confirmPasswordError }}</span>
          <label for="securityQquestion">Define your own security question</label>
          <q-input outlined v-model="securityQuestion" placeholder="Who was my childhood hero?" @keyup="resetErrors" for="securityQquestion"/>
          <label for="securityAnswer">Answer to security question</label>
          <q-input outlined v-model="answer" placeholder="Enter the answer" @keyup="resetErrors" for="securityAnswer"/>
          <q-btn flat @click="submitForm()" class="no-outline no-shadow q-btn--standard q-btn--rectangle bg-primary text-white" v-bind:class="{ disabled: isDisabled }" :disabled='isDisabled' rounded label="Confirm" aria-label="Confirm"/>
          <p class="form-text text-center" tabindex="0">Already have an account? <router-link class="blue form-text-link" :to="{ name: 'Login' }">Sign in</router-link></p>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import logo from '../../assets/logo.png'
import { mapActions, mapGetters } from 'vuex'
import focusLabel from '@/mixins/focusLabel'

export default {
  name: 'Register',
  mixins: [focusLabel],
  data () {
    return {
      logo: logo,
      password: '',
      pwdType: true,
      repeatPwdType: true,
      confirmPassword: '',
      securityQuestion: '',
      answer: '',
      confirmPasswordError: '',
      passwordError: ''
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isDisabled () {
      if (this.password.length && this.confirmPassword.length && this.securityQuestion.length && this.answer.length) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['register', 'setUser', 'getUserInfo', 'setupUserData', 'check_link']),
    checkForm () {
      const re = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/
      if (!re.test(this.password)) {
        this.passwordError = 'Your password should be at least 6 characters and include letters and numbers'
        return false
      }
      if (this.password !== this.confirmPassword) {
        this.confirmPasswordError = 'Confirm password must be same'
        return false
      }
      return true
    },
    submitForm () {
      if (this.checkForm()) {
        this.$q.loading.show()
        this.register({
          hash: this.$route.params.hash ? this.$route.params.hash : null,
          password: this.password,
          security_question: this.securityQuestion,
          security_answer: this.answer
        }).then((data) => {
          if (data.status === 200) {
            this.setUser(data)
            return this.setupUserData().then(() => {
              this.$q.loading.hide()
              this.$q.notify({
                message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                html: true,
                color: 'green',
                position: 'top-right'
              })
              this.$root.$emit('addToHomeScreen')
              if (this.currentUser.userType === 'YP') {
                this.$router.push({
                  name: 'YPStartPage'
                })
              } else {
                this.$router.push({
                  name: 'SWPAStartPage'
                })
              }
            })
          }
          return this.$q.notify({
            message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
            html: true,
            color: 'red',
            position: 'top-right'
          })
        }, err => {
          this.$q.loading.hide()
          return this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        })
      }
    },
    resetErrors () {
      this.confirmPasswordError = ''
      this.passwordError = ''
    }
  },
  mounted () {
    if (!this.$route.params.hash) {
      return this.$router.push({
        name: 'Login'
      })
    }
    if (this.currentUser.active) {
      return this.$router.push({
        name: 'YPStartPage' // this one should be changed based on user type
      })
    }

    // Check if register link is valid
    this.$q.loading.show()
    this.check_link({ hash: this.$route.params.hash }).then((data) => {
      this.$q.loading.hide()
      if (data.status !== 200 || !data.data.status) {
        this.$q.notify({
          message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
          html: true,
          color: 'red',
          position: 'top-right'
        })
        return this.$router.push({
          name: 'Login'
        })
      }
    }, err => {
      this.$q.loading.hide()
      this.$q.notify({
        message: err.message,
        color: 'red',
        position: 'top-right'
      })
      return this.$router.push({
        name: 'Login'
      })
    })
  }
}
</script>

<style scoped>
  span.error {
    color: red;
  }
</style>
