import { render, staticRenderFns } from "./QuickContacts.vue?vue&type=template&id=a050f38a&scoped=true&"
import script from "./QuickContacts.vue?vue&type=script&lang=js&"
export * from "./QuickContacts.vue?vue&type=script&lang=js&"
import style0 from "./QuickContacts.vue?vue&type=style&index=0&id=a050f38a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a050f38a",
  null
  
)

export default component.exports
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QVirtualScroll,QCard,QCardActions,QCardSection,QAvatar});
