<template>
  <q-dialog v-model="modalOpen" position="bottom" class="brent-dialog" @hide="modalOpen = false">
    <q-card class="brent-dialog-content">
      <q-card-actions align="left" class="popup-header">
        <q-btn flat label="Cancel" v-close-popup/>
        <div class="text-h6 text-center" tabindex="0">
          <span v-if="!pp">Add Pathway Plan</span>
          <span v-if="pp && pp.opened === 'no'">Override Pathway Plan</span>
        </div>
      </q-card-actions>
      <q-card-section class="q-pt-none">
        <q-form ref="addPpForm">
          <div>
            <div class="form-row">
              <div class="row">
                <div class="col-3 text-left">
                  <q-avatar class="edit-profile-avatar" size="8.88vh">
                    <img v-if="assignedUser && typeof assignedUser.avatar === 'string' && assignedUser.avatar.length" :src="assignedUser.avatar">
                    <img v-else :src="someoneAvatar">
                  </q-avatar>
                </div>
                <div class="col-9">
                  <div class="input-wrapp">
                    <q-select
                      v-if="!currentYp"
                      use-input
                      v-model="assignUserName"
                      outlined
                      input-debounce="0"
                      placeholder="@Type Name"
                      :options="options"
                      @filter="filterFn"
                      :rules="[val => val !== null && val !== '' || 'Please select the assignee']"
                      :disable="loading"
                      behavior="menu"
                    >
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">
                            No results
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                    <div style="padding-left: 20px" v-else>{{ currentYp.firstName + ' ' + currentYp.lastName }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="row">
                <div class="col-6">
                  <div class="input-wrapp">
                    <label for="assignee" class="custom-l" tabindex="0">Due Date</label>
                    <modal-date
                      v-model="dueDate"
                      error-msg="Please select the due date"
                      :min-date="minDate" placeholder="DD/MM/YYYY"
                      class="date-input"
                      :disable="loading"
                    >
                    </modal-date>
                  </div>
                </div>
              </div>
            </div>
            <div class="pupup-button">
              <q-btn v-if="pp && pp.opened === 'no'" :disable="disableAssign" class="float-right q-btn--standard full-width" unelevated rounded :loading="loading" color="primary" label="Override Current Pathway Plan" @click="overrideNextPp"/>
              <q-btn v-if="pp === null" :disable="disableAssign" class="float-right q-btn--standard full-width" unelevated rounded :loading="loading" color="primary" label="Save Pathway Plan" @click="createNextPp()"/>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import ModalDate from '../Utils/ModalDate'
import moment from 'moment'
import avatar from '@/assets/av.png'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddPp',
  components: { ModalDate },
  props: {
    value: {
      default: false,
      type: Boolean
    },
    emit: {
      default: true,
      type: Boolean
    },
    pp: {
      default: null,
      type: Object
    },
    yp: {
      default: false
    }
  },
  data () {
    return {
      modalOpen: false,
      minDate: moment().format('YYYY/MM/DD'),
      someoneAvatar: avatar,
      assignUserName: null,
      options: [],
      dueDate: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'userGeneralInfo', 'assigned', 'currentYp', 'myCaseLoad']),
    disableAssign () {
      return this.dueDate.length === 0 || this.assignUserName === null
    },
    avatar () {
      return typeof this.userGeneralInfo.avatar === 'string' && this.userGeneralInfo.avatar.length ? this.userGeneralInfo.avatar : avatar
    },
    assignedUserNames () {
      if (['SW', 'PA'].includes(this.currentUser.userType)) {
        return this.myCaseLoad.map(user => (user.firstName + ' ' + user.lastName))
      } else {
        return this.assigned.map(user => user.user_name)
      }
    },
    assignedUser () {
      if (['SW', 'PA'].includes(this.currentUser.userType) && this.modalOpen) {
        return this.myCaseLoad.find(user => (user.firstName + ' ' + user.lastName) === this.assignUserName)
      }
      return null
    }
  },
  watch: {
    value (value) {
      this.modalOpen = value
    },
    modalOpen (value) {
      if (value) {
        if (this.currentYp) {
          this.assignUserName = this.currentYp.firstName + ' ' + this.currentYp.lastName
        } else {
          this.assignUserName = null
          this.dueDate = ''
        }
      }
      this.$emit('input', value)
    },
    pp: {
      deep: true,
      handler: function (val) {
        this.dueDate = val ? val.due_date : moment().add(6, 'week').format('DD/MM/YYYY')
      }
    }
  },
  methods: {
    ...mapActions(['saveTask', 'overridePp', 'getPpSwPa', 'syncYpPp', 'createPp']),
    filterFn (val, update, abort) {
      if (val.length < 1) {
        abort()
        return
      }
      if (val === '') {
        update(() => {
          this.options = this.assignedUserNames
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase().replace(/^@/g, '')
        this.options = this.assignedUserNames.filter(v => v.toLowerCase().indexOf(needle) > -1)
      })
    },
    addPp () {
      this.$refs.addPpForm.validate().then(success => {
        if (success) {
        }
      })
    },
    overrideNextPp () {
      this.loading = true
      this.overridePp({ due_date: this.dueDate, yp_id: this.pp.user_id }).then((success) => {
        console.log('success')
        this.getPpSwPa().then(() => {
          // this.syncYpPp()
          // this.$emit('input', false)
          this.$root.$emit('triggerPpDashboard')
          this.loading = false
        })
      })
    },
    createNextPp () {
      this.loading = true
      this.createPp({
        token: this.currentUser.user_token,
        user_id: this.assignedUser.id,
        due_date: this.dueDate
      }).then((data) => {
        if (data.data) {
          // this.$emit('input', false)
          this.$root.$emit('pp::notification', 'Next Pathway Plan for ' + this.assignedUser.firstName + ' ' + this.assignedUser.lastName + ' was successfully added')
          this.dueDate = ''
          this.assignUserName = null
        } else {
          this.$q.notify({
            message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
            html: true,
            color: 'red',
            position: 'top-right'
          })
        }
        this.loading = false
      }, err => {
        this.$q.notify({
          message: err.message,
          color: 'red',
          position: 'top-right'
        })
      })
    }
  },
  mounted () {
    this.$root.$on('pp::add', () => {
      this.modalOpen = true
      this.$emit('input', true)
    })
    if (this.pp) {
      this.dueDate = this.pp.due_date
    }
  },
  beforeDestroy () {
    this.$root.$off('pp::add')
  }
}
</script>

<style scoped>

</style>
