<template>
  <div class="money_color">
    <vue-title title="My money"></vue-title>
    <lock-dialog></lock-dialog>
    <div v-if="!viewPathwaySection && readOnly">
      <div class="planTitle text-center">
        <span>TASKS FOR THIS SECTION</span>
      </div>
      <div class="path_task_holder">
        <tasks :render-title="false" :show-task-assign-btn="false" :pp-section="'money'" :pp="pathwayPlan[$route.meta.tense]"></tasks>
      </div>
      <div class="current_path_footer money_footer">
        <q-btn @click="viewPathwaySection = !viewPathwaySection" class="tab-type-button" unelevated icon="savings" icon-right="arrow_forward_ios" label="Pathway Plan - my money"/>
      </div>
    </div>

    <step v-else @stepChanged="stepChanged" :tabs="tabs" @prev="prev" @validate="validate" :completed="completed"
          :change-tab-to="tab" :loading="loading" :read-only="readOnly" category="money">

      <template v-slot:1>
        <q-form class="my-money-one" ref="1">
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label class="strong-label" for="Name">How are you feeling about my money</label>
              <div class="feelings-box radio">
                <q-radio title="aw" class="feelings-item" name="feelings" v-if="readOnly ? money.step_1.moneyFeeling === '1' : true" v-model="money.step_1.moneyFeeling" val="1"
                        :class="[{ selected: money.step_1.moneyFeeling === '1' & !readOnly }]">
                  <span class="f-icon">😭</span>
                  <span class="f-text">awful</span>
                </q-radio>
                <q-radio title="bad" class="feelings-item" name="feelings" v-if="readOnly ? money.step_1.moneyFeeling === '2' : true" v-model="money.step_1.moneyFeeling" val="2"
                        :class="[{ selected: money.step_1.moneyFeeling === '2' & !readOnly }]">
                  <span class="f-icon">🙁</span>
                  <span class="f-text">bad</span>
                </q-radio>
                <q-radio title="so so" class="feelings-item" name="feelings" v-if="readOnly ? money.step_1.moneyFeeling === '3' : true" v-model="money.step_1.moneyFeeling" val="3"
                        :class="[{ selected: money.step_1.moneyFeeling === '3' & !readOnly }]">
                  <span class="f-icon">😐</span>
                  <span class="f-text">so so</span>
                </q-radio>
                <q-radio title="good" class="feelings-item" name="feelings" v-if="readOnly ? money.step_1.moneyFeeling === '4' : true" v-model="money.step_1.moneyFeeling" val="4"
                        :class="[{ selected: money.step_1.moneyFeeling === '4' & !readOnly }]">
                  <span class="f-icon">🙂</span>
                  <span class="f-text">good</span>
                </q-radio>
                <q-radio title="great" class="feelings-item" name="feelings" v-if="readOnly ? money.step_1.moneyFeeling === '5' : true" v-model="money.step_1.moneyFeeling" val="5"
                        :class="[{ selected: money.step_1.moneyFeeling === '5' & !readOnly }]">
                  <span class="f-icon">😀</span>
                  <span class="f-text">great</span>
                </q-radio>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="moneyWorkingWell">What's working well</label>
              <q-input
                v-model="money.step_1.moneyWorkingWell"
                outlined
                :placeholder="readOnly ? '' : 'Tell us what is working well'"
                :readonly="loading || isOffline || isLocked"
                for="moneyWorkingWell"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="moneyWorriedAbout">What are we worried about</label>
              <q-input
                v-model="money.step_1.moneyWorriedAbout"
                outlined
                :placeholder="readOnly ? '' : 'Add description'"
                :readonly="loading || isOffline || isLocked"
                for="moneyWorriedAbout"
              />
            </div>
          </div>
          <q-list class="pathList">
            <q-expansion-item
              group="somegroup"
              label="What needs to happen"
              icon="check_circle"
              default-opened
              :header-class="['pathListHeader', { compleated: needsToHappenCompleted }]"
            >
              <q-card>
                <q-card-section>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="moneyNextSteps">Next steps</label>
                      <q-input
                        v-model="money.step_1.moneyNextSteps"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="moneyNextSteps"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="moneyLongTermGoals">Long term goals</label>
                      <q-input
                        v-model="money.step_1.moneyLongTermGoals"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="moneyLongTermGoals"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="moneyContingency">Contingency if anything goes wrong</label>
                      <q-input
                        v-model="money.step_1.moneyContingency"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="moneyContingency"
                      />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-form>
      </template>

      <template v-slot:2>
        <div class="planTitle text-center">
          <span>TASKS FOR THIS SECTION</span>
        </div>
        <div v-if="pathwayPlanTasks[getTense].money.total.length" class="my-money-two">
          <div class="plan-task" v-for="(task, index) in pathwayPlanTasks[getTense].money.total" :key="index">
            <task :task="task" :read-only="readOnly ? false : true"></task>
          </div>
        </div>
        <div class="form-row" v-if="!readOnly">
          <q-btn :disable="isLocked || isOffline" @click="assignTaskModalOpen = true" class="add blue custom-add" flat><span class="plus-icon material-icons-outlined">add</span> Assign task</q-btn>
          <assign-task :section="'money'" :pp-id="getPpId" :emit="false"
                      v-model="assignTaskModalOpen"></assign-task>
        </div>
      </template>

    </step>
  </div>

</template>

<script>
import Step from '../components/Step.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import scrollToTarget from '../mixins/scrollToTarget'
import Task from '../components/Tasks/Task'
import AssignTask from '../components/Tasks/AssignTask'
import Tasks from './Tasks.vue'
import addLocks from '@/mixins/addLocks'
import removeLocks from '@/mixins/removeLocks'
import LockDialog from '@/components/Utils/LockDialog'
import showLock from '@/mixins/showLock'
import focusLabel from '@/mixins/focusLabel'
let updatePathwayPlanInterval = null

export default {
  name: 'MyMoney',
  components: {
    LockDialog,
    Step,
    Task,
    AssignTask,
    Tasks
  },
  mixins: [scrollToTarget, addLocks, removeLocks, showLock, focusLabel],
  data () {
    return {
      viewPathwaySection: false,
      readOnly: false,
      tab: 1,
      tabs: 2,
      loading: false,
      assignTaskModalOpen: false,
      money: {
        step_1: {
          moneyFeeling: '',
          moneyWorkingWell: '',
          moneyWorriedAbout: '',
          moneyNextSteps: '',
          moneyLongTermGoals: '',
          moneyContingency: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['tasks', 'pathwayPlan', 'pathwayPlanTasks', 'currentUser', 'isOffline']),
    completed () {
      const steps = []
      if (this.money.step_1.moneyFeeling && this.money.step_1.moneyWorkingWell && this.money.step_1.moneyWorriedAbout && this.money.step_1.moneyNextSteps && this.money.step_1.moneyLongTermGoals && this.money.step_1.moneyContingency) {
        steps.push(1)
      }
      return steps
    },
    needsToHappenCompleted () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        if (this.pathwayPlan[this.$route.meta.tense].money_next_steps && this.pathwayPlan[this.$route.meta.tense].money_next_steps !== null && this.pathwayPlan[this.$route.meta.tense].money_long_term_goals && this.pathwayPlan[this.$route.meta.tense].money_long_term_goals !== null && this.pathwayPlan[this.$route.meta.tense].money_contingency && this.pathwayPlan[this.$route.meta.tense].money_contingency !== null) {
          return true
        }
      }
      return false
    },
    getPpId () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        return this.pathwayPlan[this.$route.meta.tense].id
      }
      return null
    },
    getTense () {
      if (this.$route.meta.tense) {
        return this.$route.meta.tense
      }
      return 'next'
    }
  },
  methods: {
    ...mapActions(['setPathwayPlan']),
    ...mapMutations(['setppDueDate']),
    changeTab (tab) {
      this.tab = tab
    },
    prev () {
      this.tab = this.tab - 1
    },
    validate (goToNextStep = false) {
      if (this.$refs[this.tab]) {
        this.$refs[this.tab].validate().then(success => {
          if (success) {
            this.loading = true
            this.scroll(this.$refs.saveBtn)
            const userInfo = Object.assign({}, this.money['step_' + this.tab])
            let requestData = null
            if (this.tab === 1) {
              requestData = {
                money_feeling: userInfo.moneyFeeling,
                money_working_well: userInfo.moneyWorkingWell,
                money_worried_about: userInfo.moneyWorriedAbout,
                money_next_steps: userInfo.moneyNextSteps,
                money_long_term_goals: userInfo.moneyLongTermGoals,
                money_contingency: userInfo.moneyContingency
              }
            } else if (this.tab === 2 && goToNextStep) {
              return this.$router.push({
                name: 'PathwayPlan',
                query: {
                  tab: this.getTense
                }
              })
            }
            requestData.pathway_id = this.pathwayPlan[this.$route.meta.tense].id
            requestData.token = this.currentUser.user_token
            this.setPathwayPlan(requestData).then((data) => {
              this.loading = false
              if (data.status === 200) {
                if (goToNextStep && this.tab !== this.tabs) {
                  this.tab = this.tab + 1
                }
                if (data.message) {
                  return this.$q.notify({
                    message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                    html: true,
                    color: 'green',
                    position: 'top-right'
                  })
                }
              } else {
                return this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'red',
                  position: 'top-right'
                })
              }
            })
          }
        })
      } else {
        if (goToNextStep) {
          this.$router.push({
            name: 'PathwayPlan',
            query: {
              tab: this.getTense
            }
          })
        }
      }
    },
    stepChanged (e) {
      this.tab = e
      this.loading = false
    },
    updatePathwayData () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        clearInterval(updatePathwayPlanInterval)
        this.money.step_1.moneyFeeling = this.pathwayPlan[this.$route.meta.tense].money_feeling
        this.money.step_1.moneyWorkingWell = this.pathwayPlan[this.$route.meta.tense].money_working_well
        this.money.step_1.moneyWorriedAbout = this.pathwayPlan[this.$route.meta.tense].money_worried_about
        this.money.step_1.moneyNextSteps = this.pathwayPlan[this.$route.meta.tense].money_next_steps
        this.money.step_1.moneyLongTermGoals = this.pathwayPlan[this.$route.meta.tense].money_long_term_goals
        this.money.step_1.moneyContingency = this.pathwayPlan[this.$route.meta.tense].money_contingency
      }
    },
    goback (route) {
      if (this.viewPathwaySection) {
        this.viewPathwaySection = false
      } else {
        if (this.currentYp) {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab, ypId: this.currentYp.id } })
        } else {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab } })
        }
      }
    }
  },
  watch: {
    'allAboutMe.step_1.dob': function (newVal, oldVal) {
      this.setupStepTwoOptions()
    },
    pathwayPlan: function (newVal, oldVal) {
      if (this.$route.meta.tense === 'current') {
        if (this.pathwayPlan.current) {
          this.setppDueDate({
            dueDate: this.pathwayPlan.current.expiry_date,
            tense: 'current'
          })
        }
      }
    },
    'pathwayPlan.next': function (newVal, oldVal) {
      this.updatePathwayData()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$route.meta.edit === false) {
      this.loading = true
      this.readOnly = true
    }
    updatePathwayPlanInterval = setInterval(this.updatePathwayData, 500)
    this.$root.$on('save:pathwayPlanSection', this.validate)
    if (this.$route.meta.tense === 'current') {
      if (this.pathwayPlan.current) {
        this.setppDueDate({
          dueDate: this.pathwayPlan.current.expiry_date,
          tense: 'current'
        })
      }
    }
    this.$root.$on('ppSection:back', this.goback)
  },
  beforeDestroy () {
    this.$root.$off('save:pathwayPlanSection')
    this.$root.$off('ppSection:back', this.goback)
  }
}
</script>

<style>
.profile-tabs .q-focus-helper {
  display: none;
}
</style>
