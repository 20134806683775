<template>
  <div>
    <q-select filled v-model="test" :options="options" label="Standard" />
  </div>
</template>

<script>
export default {
  name: 'TestInput',
  data () {
    return {
      options: [
        'Google', 'Facebook', 'Twitter', 'Apple', 'Oracle'
      ],
      test: null
    }
  }
}
</script>

<style scoped>

</style>
