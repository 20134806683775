<template>
  <div>
    <div class="q-pa-md"  v-if="currentUser.userType === 'YP' && !pathwayPlan.next">
      <div class="empty-task">
        <p class="text-center">No next plans here yet</p>
      </div>
      <div class="set-next-illustration-bg2" style="margin-top: 30%"></div>
    </div>
    <div v-else>
      <no-next-pp :from-pp-dashboard="true" v-if="showNoPpPage"></no-next-pp>
      <div v-else>
        <div v-if="pathwayPlan.next">
          <div class="q-pa-md" style="padding-top: 0; padding-bottom: 0">
            <div class="ph-title-box">
              <h2>My next Pathway Plan</h2>
              <p>It needs signing by: {{ dueDate }}</p>
            </div>
            <pp-linear-progress :pp-calculation="pathwayPlan.next.ppCalculation"></pp-linear-progress>
          </div>

          <div v-if="pathwayPlan.next && pathwayPlan.next.ppCalculation && pathwayPlan.next.ppCalculation.totalPercent === 100">
            <div class="success-popup message-popup text-center" style="padding: 3vh 2vh 1.8vh" v-if="pathwayPlan.next.signed_by_yp === 'no'">
              <div>
                <div class="emoji">
                  <div class="emoji-box">
                    <img :src="emoji3" alt="icon">
                  </div>
                  <div class="emoji-box">
                    <img :src="emoji4" alt="icon">
                  </div>
                </div>
                <span v-if="currentUser.userType === 'YP'">Yay! You've completed your Pathway Plan.<br> Hit submit!</span>
                <span v-else>{{ pathwayPlan.next.first_name + ' ' + pathwayPlan.next.last_name }} have finished filling Pathway Plan. <br/> You can submit it for him/her!</span>
              </div>
              <div class="" style="padding-top: 0.5vh">
                <div class="sign-off-box">
                  <q-form ref="#">
                    <!--
                    <div class="terms-box">
                      <q-checkbox v-model="agreedToTc" aria-labelledby="agreedToTc"/>
                      <label id="agreedToTc">I have read the <router-link :to="{ name: 'Info', params: { pageSlug: 'terms-and-conditions' } }" class="blue"><b>Terms and Conditions!</b></router-link></label>
                    </div>
                    -->
                    <q-btn
                      class="no-outline no-shadow full-width q-btn--standard q-btn-full q-btn--rectangle bg-primary text-white" unelevated
                      rounded color="primary" style="margin-top: 2.7vh; margin-bottom: 3vh" label="Submit" @click="updatePp()"/>
                  </q-form>
                </div>
              </div>
            </div>
            <div class="success-popup message-popup text-center" v-if="currentUser.userType === 'YP' && pathwayPlan.next.signed_by_yp === 'yes' && pathwayPlan.next.signed_by_sw === 'no'">
              <span class="icon"><img :src="elipseIcon"></span>
              Your Social Worker or Personal Advisor will now sign-off your Pathway Plan. If you have any questions please contact them directly.
            </div>
            <div class="success-popup message-popup text-center" v-if="currentUser.userType !== 'YP' && pathwayPlan.next.signed_by_yp === 'yes' && pathwayPlan.next.signed_by_sw === 'no'">
              <div class="emoji">
                <div class="emoji-box">
                  <img :src="emoji3" alt="icon">
                </div>
                <div class="emoji-box">
                  <img :src="emoji4" alt="icon">
                </div>
              </div>
              <p>
                {{ pathwayPlan.next.first_name + ' ' + pathwayPlan.next.last_name }} has completed and signed the Pathway Plan.<br/> Approve and start the Pathway Plan below.
              </p>
              <div class="q-pa-md q-gutter-md" style="padding-top: 0">
                <div class="sign-off-box">
                  <q-form ref="#">
                    <q-btn
                      class="no-outline no-shadow full-width q-btn--standard q-btn-full q-btn--rectangle bg-primary text-white" unelevated
                      rounded color="primary" label="Approve and Start Pathway Plan" @click="updatePp(true)"/>
                  </q-form>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="success-popup message-popup text-center">
            <span class="icon"><img :src="elipseIcon"></span>
            <p>Your Pathway Plan has not yet been marked as complete by your Social Worker or Personal Advisor.</p>
          </div> -->
          <pathway-section-grid :previous-route="previousRoute" :show-circular-progress="true" :pathway-tense="'next'" :pp-calculation="pathwayPlan.next.ppCalculation"></pathway-section-grid>
        </div>
      </div>
      <!-- <div class="q-pa-md text-left" v-if="currentUser.userType !== 'YP' && pathwayPlan.next && pathwayPlan.next.started === 'yes'">
        <div style="padding: 1vh 1vh 4vh">
          <a class="delete-item" @click.prevent="deletePathwayPlan"><span class="delete-icon"></span> Delete Pathway Plan
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>

import PathwaySectionGrid from './PathwaySectionGrid'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import elipseIcon from '../../assets/elipse-icon.png'
import PpLinearProgress from '@/components/PathwayPlan/PpLinearProgress'
import NoNextPp from '@/components/PathwayPlan/NoNextPp'
import { Loading } from 'quasar'
import emoji3 from '../../assets/emoji3.png'
import emoji4 from '../../assets/emoji4.png'

export default {
  name: 'Next',
  components: {
    PathwaySectionGrid,
    PpLinearProgress,
    NoNextPp
  },
  props: {
    previousRoute: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      elipseIcon: elipseIcon,
      value: 81,
      agreedToTc: false,
      emoji3: emoji3,
      emoji4: emoji4
    }
  },
  computed: {
    ...mapGetters(['pathwayPlan', 'currentUser']),
    dueDate () {
      if (this.pathwayPlan.next) {
        return this.pathwayPlan.next.due_date ? moment(this.pathwayPlan.next.due_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
      }
      return ''
    },
    showNoPpPage () {
      if (this.currentUser.userType !== 'YP' && !this.pathwayPlan.next) {
        return true
      } else if (this.pathwayPlan.next) {
        return this.pathwayPlan.next.opened === 'no' || this.pathwayPlan.next.started === 'no'
      }
      return false
    }
  },
  methods: {
    ...mapActions(['setPathwayPlan', 'deletePp', 'getPpSwPa', 'syncYpPp']),
    updatePp (signedBySw = false) {
      Loading.show()
      if (this.pathwayPlan.next) {
        const requestData = {
          pathway_id: this.pathwayPlan.next.id,
          token: this.currentUser.user_token
        }
        if (this.currentUser.userType === 'YP') {
          requestData.signed_by_yp = 'yes'
        } else {
          if (signedBySw) {
            requestData.signed_by_sw = 'yes'
          } else {
            requestData.signed_by_yp = 'yes'

            if (typeof window.gtag === 'function') {
              window.gtag('event', 'PP_complete', {
                event_category: 'PathwayPlan',
                event_label: 'Pathway Plan complete'
              })
            }
          }
        }
        this.setPathwayPlan(requestData).then((data) => {
          if (['SW', 'PA'].includes(this.currentUser.userType)) {
            this.getPpSwPa().then(data => {
              // this.syncYpPp()
              Loading.hide()
              if (requestData.signed_by_sw === 'yes') {
                this.$route.query.tab = 'current'
              }
              this.$root.$emit('triggerPpDashboard')
            })
          } else {
            Loading.hide()
          }
        }, err => {
          Loading.hide()
          return this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        })
      }
    },
    deletePathwayPlan () {
      Loading.show()
      this.deletePp({
        token: this.currentUser.user_token,
        pathway_id: this.pathwayPlan.next.id
      }).then(() => {
        Loading.hide()
        this.$emit('input', false)
        this.$root.$emit('triggerPpDashboard')
      }, err => {
        Loading.hide()
        this.$q.notify({
          message: err.message,
          color: 'red',
          position: 'top-right'
        })
      })
    }
  },
  updated () {
    if (document.querySelectorAll('.q-checkbox input[type="checkbox"]').length) {
      document.querySelectorAll('.q-checkbox input[type="checkbox"]').forEach(element => {
        element.setAttribute('title', 'checkbox')
      })
    }
  }
}
</script>

<style scoped>

</style>
