<template>
  <div class="q-pa-md">
    <div class="row q-gutter-md custom-q-gutter-md" v-if="pathwayTense === 'current' || ppCalculation">
      <q-card tabindex="0" @keyup.enter="goToSection('AllAboutMe')" class="plan-card text-white about_color" v-ripple @click="goToSection('AllAboutMe')">
        <q-card-section>
          <div class="progress-holder text-center" v-if="this.showCircularProgress">
            <q-circular-progress
              show-value
              font-size="1.5vh"
              :value="ppCalculation.about.percentage"
              size="90px"
              :thickness="0.08"
              color="white"
              track-color="grey-3"
              :aria-label="ppCalculation.about.percentage === 100 ? 'done' : ppCalculation.about.percentage + '% completed'"
            >
              <span v-if="ppCalculation.about.percentage === 100" class="compleated-card-icon material-icons-outlined">done</span>
              <span v-else>
                <span class="ph-title">{{ ppCalculation.about.percentage }}%</span> completed
              </span>
            </q-circular-progress>
          </div>
          <q-icon class="plan-icon" name="account_circle"/>
          <div class="text-h4 on-left">
            All about me
          </div>
        </q-card-section>
      </q-card>
      <q-card tabindex="0" @keyup.enter="goToSection('OverallPlan')" class="plan-card text-white overall_color" v-ripple @click="goToSection('OverallPlan')">
        <q-card-section>
          <div class="progress-holder text-center" v-if="this.showCircularProgress">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="ppCalculation.overall.percentage"
              size="90px"
              :thickness="0.08"
              color="white"
              track-color="grey-3"
              :aria-label="ppCalculation.overall.percentage === 100 ? 'done' : ppCalculation.overall.percentage + '% completed'"
            >
              <span v-if="ppCalculation.overall.percentage === 100" class="compleated-card-icon material-icons-outlined">done</span>
              <span v-else>
                <span class="ph-title">{{ ppCalculation.overall.percentage }}%</span> completed
              </span>
            </q-circular-progress>
          </div>
          <q-icon class="plan-icon" name="stream"/>
          <div class="text-h4 on-left">
            Overall plan
          </div>
          <div class="card-footer">
            <span class="float-left">Tasks:</span>
            <span class="float-right">{{ pathwayPlanTasks[pathwayTense].overall.completed.length + '/' + pathwayPlanTasks[pathwayTense].overall.total.length}}</span>
            <div class="clearfix"></div>
          </div>
        </q-card-section>
      </q-card>
      <q-card tabindex="0" @keyup.enter="goToSection('EducationEmployment')" class="plan-card text-white education_color" v-ripple @click="goToSection('EducationEmployment')">
        <q-card-section>
          <div class="progress-holder text-center" v-if="this.showCircularProgress">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="ppCalculation.education.percentage"
              size="90px"
              :thickness="0.08"
              color="white"
              track-color="grey-3"
              :aria-label="ppCalculation.education.percentage === 100 ? 'done' : ppCalculation.education.percentage + '% completed'"
            >
              <span v-if="ppCalculation.education.percentage === 100" class="compleated-card-icon material-icons-outlined">done</span>
              <span v-else>
                <span class="ph-title">{{ ppCalculation.education.percentage }}% </span> completed
              </span>
            </q-circular-progress>
          </div>
          <q-icon class="plan-icon" name="school"/>
          <div class="text-h4 on-left">
            Education, employment<br> & training
          </div>
          <div class="card-footer">
            <span class="float-left">Tasks:</span>
            <span class="float-right">{{ pathwayPlanTasks[pathwayTense].education.completed.length + '/' + pathwayPlanTasks[pathwayTense].education.total.length}}</span>
            <div class="clearfix"></div>
          </div>
        </q-card-section>
      </q-card>
      <q-card tabindex="0" @keyup.enter="goToSection('ManagingLiving')" class="plan-card text-white managing_color" v-ripple @click="goToSection('ManagingLiving')">
        <q-card-section>
          <div class="progress-holder text-center" v-if="this.showCircularProgress">
              <q-circular-progress
                show-value
                font-size="12px"
                :value="ppCalculation.managing.percentage"
                size="90px"
                :thickness="0.08"
                color="white"
                track-color="grey-3"
                :aria-label="ppCalculation.managing.percentage === 100 ? 'done' : ppCalculation.managing.percentage + '% completed'"
              >
                <span v-if="ppCalculation.managing.percentage === 100" class="compleated-card-icon material-icons-outlined">done</span>
                <span v-else>
                  <span class="ph-title">{{ ppCalculation.managing.percentage }}% </span> completed
                </span>
              </q-circular-progress>
          </div>
          <q-icon class="plan-icon" name="maps_home_work"/>
          <div class="text-h4 on-left">
            Managing and living independently
          </div>
          <div class="card-footer">
            <span class="float-left">Tasks:</span>
            <span class="float-right">{{ pathwayPlanTasks[pathwayTense].managing.completed.length + '/' + pathwayPlanTasks[pathwayTense].managing.total.length}}</span>
            <div class="clearfix"></div>
          </div>
        </q-card-section>
      </q-card>
      <q-card tabindex="0" @keyup.enter="goToSection('HealthDevelopment')" class="plan-card text-white health_color" v-ripple @click="goToSection('HealthDevelopment')">
        <q-card-section>
          <div class="progress-holder text-center" v-if="this.showCircularProgress">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="ppCalculation.health.percentage"
              size="90px"
              :thickness="0.08"
              color="white"
              track-color="grey-3"
              :aria-label="ppCalculation.health.percentage === 100 ? 'done' : ppCalculation.health.percentage + '% completed'"
            >
              <span v-if="ppCalculation.health.percentage === 100" class="compleated-card-icon material-icons-outlined">done</span>
              <span v-else>
                <span class="ph-title">{{ ppCalculation.health.percentage }}% </span> completed
              </span>
            </q-circular-progress>
          </div>
          <q-icon class="plan-icon" name="self_improvement"/>
          <div class="text-h4 on-left">
            Health & development
          </div>
          <div class="card-footer">
            <span class="float-left">Tasks:</span>
            <span class="float-right">{{ pathwayPlanTasks[pathwayTense].health.completed.length + '/' + pathwayPlanTasks[pathwayTense].health.total.length}}</span>
            <div class="clearfix"></div>
          </div>
        </q-card-section>
      </q-card>
      <q-card tabindex="0" @keyup.enter="goToSection('MyMoney')" class="plan-card text-white money_color" v-ripple @click="goToSection('MyMoney')">
        <q-card-section>
          <div class="progress-holder text-center" v-if="this.showCircularProgress">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="ppCalculation.money.percentage"
              size="90px"
              :thickness="0.08"
              color="white"
              track-color="grey-3"
              :aria-label="ppCalculation.money.percentage === 100 ? 'done' : ppCalculation.money.percentage + '% completed'"
            >
              <span v-if="ppCalculation.money.percentage === 100" class="compleated-card-icon material-icons-outlined">done</span>
              <span v-else>
                <span class="ph-title">{{ ppCalculation.money.percentage }}% </span> completed
              </span>
            </q-circular-progress>
          </div>
          <q-icon class="plan-icon" name="savings"/>
          <div class="text-h4 on-left">
            My money
          </div>
          <div class="card-footer">
            <span class="float-left">Tasks:</span>
            <span class="float-right">{{ pathwayPlanTasks[pathwayTense].money.completed.length + '/' + pathwayPlanTasks[pathwayTense].money.total.length}}</span>
            <div class="clearfix"></div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'PathwaySectionGrid',
  props: {
    showCircularProgress: {
      type: Boolean,
      default () {
        return false
      }
    },
    pathwayTense: {
      type: String,
      default () {
        return 'current'
      }
    },
    ppCalculation: {
      type: Object,
      default () {
        return null
      }
    },
    previousRoute: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      progress: 0.4,
      right: false
    }
  },
  computed: {
    ...mapGetters(['pathwayPlanTasks', 'currentYp'])
  },
  methods: {
    goToSection (section) {
      if (this.currentYp) {
        this.$router.push({
          name: section + this.pathwayTense.charAt(0).toUpperCase() + this.pathwayTense.slice(1),
          params: { previousRoute: this.previousRoute },
          query: { ypId: this.currentYp.id }
        })
      } else {
        this.$router.push({
          name: section + this.pathwayTense.charAt(0).toUpperCase() + this.pathwayTense.slice(1),
          params: { previousRoute: this.previousRoute }
        })
      }
    }
  }
}
</script>
