import api from '@/api'
import Loading from 'quasar/src/plugins/Loading.js';

export default {
  setUpUser (userData, context, data) {
    userData = userData.data
    const user = Object.assign({}, data)
    const contacts = userData.contacts
    const myCaseLoad = userData.myCaseLoad

    let badges = []
    try {
      badges = userData.badges
    } catch (e) {
    }

    let tasks = []
    try {
      tasks = userData.tasks.tasks.map(task => {
        task.completed = task.completed === '1'
        task.dueDate = task.due_date
        return task
      })
    } catch (e) {
    }

    const pathwayPlans = userData.pathwayPlans

    try {
      user.supporting_people = userData.info.supporting_people
      user.generalInfo = {
        firstName: userData.first_name !== null ? userData.info.first_name : '',
        lastName: userData.last_name !== null ? userData.info.last_name : '',
        phone_number: userData.phone_number !== null ? userData.info.phone_number : '',
        dob: userData.dob !== null ? userData.info.dob : '',
        gender: userData.gender !== null ? userData.info.gender : '',
        showGenderIdentity: userData.info.gender_birth === 'yes',
        firstLanguage: userData.info.first_language !== null ? userData.info.first_language : '',
        languages: Array.isArray(userData.info.other_languages) ? userData.info.other_languages : [],
        avatar: userData.info.avatar !== null ? userData.info.avatar : null
      }
    } catch (e) {
      console.log(e)
    }
    try {
      if (Array.isArray(userData.info.phone_numbers)) {
        userData.info.phone_numbers = userData.info.phone_numbers.filter(number => number.length > 0)
      }
      if (Array.isArray(userData.info.emails)) {
        userData.info.emails = userData.info.emails.filter(email => email.length > 0)
      }
      user.contactDetails = {
        mainPhone: !['', null].includes(userData.info.mobile) ? userData.info.mobile : '',
        phoneNumbers: Array.isArray(userData.info.phone_numbers) ? userData.info.phone_numbers : [],
        email: userData.info.email ? userData.info.email : '',
        emails: Array.isArray(userData.info.emails) ? userData.info.emails : [],
        address: userData.info.address !== null ? userData.info.address : '',
        city: userData.info.city !== null ? userData.info.city : '',
        postcode: userData.info.postcode !== null ? userData.info.postcode : ''
      }
    } catch (e) {
      console.log(e)
    }
    try {
      userData.info.achievements = JSON.parse(userData.info.achievements)
    } catch (e) {
      userData.info.achievements = []
    }
    try {
      user.bio = {
        aboutMe: userData.info.about_me !== null ? userData.info.about_me : '',
        medical: userData.info.allergies !== null ? userData.info.allergies : '',
        achievements: userData.info.achievements
      }
    } catch (e) {
      console.log(e)
    }

    try {
      user.settings = {
        show_group_notification: !!userData.info.show_group_notification,
        notifications_email: !!userData.info.notifications_email,
        notifications_sms: !!userData.info.notifications_sms,
        notifications_push: !!userData.info.notifications_push,
        receive_remainder: !!userData.info.receive_remainder,
        inapp_vibrate: !!userData.info.inapp_vibrate,
        inapp_sounds: !!userData.info.inapp_sounds
      }
    } catch (e) {
      console.log(e)
    }

    if (data.isCaseload) {
      try {
        user.profileBadge = userData.info.badge
      } catch (e) {
        console.log(e)
      }
      context.commit('setYp', user)
      setTimeout(() => {
        context.commit('setYpBadges', badges)
        context.commit('setYpTasks', { tasks })
        context.commit('setYpPps', { pathwayPlan: pathwayPlans })
        context.commit('setRecentCaseload', user)
        context.commit('setYpContacts', contacts)
        context.commit('setYpAssignedUsers', user.supporting_people)
      }, 10)
    } else {
      context.commit('setUserGeneralInfo', user.generalInfo)
      context.commit('setUserContactDetails', user.contactDetails)
      context.commit('setUserBio', user.bio)
      context.commit('setAppSettings', user.settings)
      context.commit('setProfileBadge', userData.info.badge)
      try {
        if (window.wb) {
          navigator.serviceWorker.controller.postMessage({ settingsUpdate: user.settings })
        }
      } catch (e) {
      }
      context.dispatch('setWebPush')
      setTimeout(() => {
        if (data.userType === 'YP') {
          context.commit('setUserBadges', badges)
          context.commit('setSupportingPeople', user.supporting_people)
          context.commit('setAssignedUsers', user.supporting_people)
        } else {
          context.commit('setMyCaseLoad', myCaseLoad)
          context.dispatch('setYoungPersons')
        }
        context.commit('setUserTasks', { tasks })
        context.commit('setPathwayPlan', {
          pathwayPlan: pathwayPlans,
          currentUser: context.getters.currentUser,
          type: 'all'
        })
        context.commit('setContacts', { data: contacts })
      }, 10)
    }
    return user
  },
  setUserFull (context, data) {
    return new Promise((resolve, reject) => {
      if (!context.getters.isOffline) {
        const requestData = Object.assign({ token: context.getters.currentUser.user_token }, data)
        console.log('context.getters.skipInfoFullCall', context.getters.skipInfoFullCall)
        if (context.getters.skipInfoFullCall) {
          resolve(data)
        } else {
          return api.getUserInfoFull(requestData).then(userData => {
            if (userData.status === 200) {
              resolve(this.setUpUser(userData, context, data))
            }
          }).catch(e => {
            console.log(e)
            reject(e)
          })
        }
      } else {
        if (data.isCaseload) {
          Loading.hide()
          reject(Error('offline'))
        } else {
          Loading.hide()
          if (context.getters.currentUser) {
            resolve(data)
          } else {
            Loading.hide()
            reject(Error('offline'))
          }
        }
      }
    })
  }
}
