<template>
  <div class="add-yp-header">
    <q-header class="search-header bg-white text-black" v-if="['AddYoungPersons'].includes($route.name)">
      <div>
        <div class="row">
          <div class="col-3">
            <q-btn @click="cancel()" class="cancel-btn custom-add" color="blue" flat>Cancel</q-btn>
          </div>
          <div class="col-6 text-center">
            <span class="text-center h-title" role="heading" aria-level="1">Add young persons</span>
            <br/>
            <span class="text-center h-text">{{ cntSelected }} selected</span>
          </div>
          <div class="col-3 text-right header-right">
            <q-btn unelevated rounded color="primary" @click="$root.$emit('caseload::add')">Add</q-btn>
          </div>
        </div>
        <div class="row">
          <div class="col search-box">
            <search-input></search-input>
          </div>
        </div>
      </div>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SearchInput from '@/components/Utils/SearchBar'

export default {
  name: 'AppHeaderCaseloadAddYp',
  components: { SearchInput },
  computed: {
    ...mapGetters(['appDrawerOpen', 'cntSelected'])
  },
  methods: {
    ...mapMutations(['toggleAppDrawer', 'clearSearch']),
    cancel () {
      this.clearSearch()
      this.$router.go(-1)
    }
  }
}
</script>

<style>
.share-contacts-header .cancel-btn, .add-yp-header .cancel-btn, .document-viewer-header .done-btn {
  color: #036bdd !important;
  border-color: initial !important;
}
</style>
