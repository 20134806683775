<template>
  <div>
    <q-header class="tasks-header" v-if="['Tasks', 'YpTasks'].includes($route.name)">
      <q-toolbar>
        <div class="col-4">
          <q-btn
            flat
            dense
            round
            @click="toggleAppDrawer()"
            aria-label="Menu"
            icon="fas fa-bars"
            style="margin-left: -0.5vh"
          />
        </div>
        <div class="col-4 text-center">
          <q-toolbar-title class="text-large" role="heading" aria-level="1">Tasks</q-toolbar-title>
        </div>
        <div class="col-4 text-right header-right">
          <q-btn :disable="isOffline" @click="$root.$emit('task::assign')" flat dense icon="add" aria-label="add task"/>
          <q-btn @click="$root.$emit('task::showSearch')" flat dense icon="search" aria-label="search task"/>
        </div>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppHeaderTasks',
  computed: {
    ...mapGetters(['appDrawerOpen', 'isOffline'])
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>
