<template>
  <div>
    <notification :section="'pp'"></notification>
    <div class="tasks-content sw-pp-dashbaord-content">
      <div class="q-gutter-y-md">
        <q-tabs
          v-model="tab"
          dense
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
        >
          <q-tab role="tab" name="current" :label="'Current ' + (pathwayPlan.current ? pathwayPlan.current.length : '')"/>
          <q-tab role="tab" name="next" :label="'Next ' + (pathwayPlan.next ? pathwayPlan.next.length : '')"/>
          <q-tab role="tab" name="past" :label="'Past ' + pathwayPlan.past.length"/>
        </q-tabs>
        <q-tab-panels style="padding: 0" v-model="tab" animated>
          <q-tab-panel name="current" style="padding: 0">

            <q-list class="pp-list" v-if="pathwayPlan.current && pathwayPlan.current.length">
              <div class="q-item q-item-type row no-wrap" v-for="(pp, idx) in pathwayPlan.current" :key="idx" @click="goToYpPpDashboard(pp, 'current')" @keyup.enter="goToYpPpDashboard(pp, 'current')" tabindex="0" role="button">
                  <q-item-section avatar>
                    <q-avatar size="6.41vh">
                      <img v-if="pp.avatar && pp.avatar.length" :src="pp.avatar" :alt="pp.first_name + ' ' + pp.last_name">
                      <img v-else :src="avatar" :alt="pp.first_name + ' ' + pp.last_name">
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <p class="list-name">{{ pp.first_name + ' ' + pp.last_name }}</p>
                    <p class="list-text">expires on: {{ pp.expiry_date ? formatDate(pp.expiry_date, 'YYYY-MM-DD', 'DD/MM/YYYY') : '' }}</p>
                  </q-item-section>
                  <q-item-section side class="arrow-icon"><span class="material-icons-outlined">navigate_next</span></q-item-section>
              </div>
            </q-list>

            <div class="empty-task" v-else>
              <p class="text-center">All current plans will show here</p>
            </div>

          </q-tab-panel>

          <q-tab-panel name="next" style="padding: 0">

            <div class="q-pa-md q-gutter-md open-p open-p-next" style="padding-top: 0;" v-if="pathwayPlan.next && pathwayPlan.next.length">
              <div class="next-box-holder" v-for="pp in pathwayPlan.next" :key="pp.id" @click="goToYpPpDashboard(pp, 'next')" @keyup.enter="goToYpPpDashboard(pp, 'next')" tabindex="0" role="button">
                <div class="ph-title-box">
                  <div class="row">
                    <div class="col-2">
                      <div class="card-avatar">
                        <q-avatar size="52x">
                          <img v-if="pp.avatar && pp.avatar.length" :src="pp.avatar" :alt="pp.first_name + ' ' + pp.last_name">
                          <img v-else :src="avatar" :alt="pp.first_name + ' ' + pp.last_name">
                        </q-avatar>
                      </div>
                    </div>
                    <div class="col-10">
                      <h2>
                        {{ pp.first_name + ' ' + pp.last_name }}<span class="on-right material-icons-outlined">navigate_next</span>
                      </h2>
                      <p>It needs signing by: {{ pp.due_date ? formatDate(pp.due_date, 'YYYY-MM-DD', 'DD/MM/YYYY') : '' }}</p>
                    </div>
                  </div>
                </div>
                <pp-linear-progress :pp-calculation="pp.ppCalculation"></pp-linear-progress>
              </div>
            </div>

            <div class="empty-task" v-else>
              <p class="text-center">Here will be all next plans</p>
            </div>

          </q-tab-panel>

          <q-tab-panel name="past" style="padding: 0">

            <div class="doc-list" v-if="pathwayPlan.past.length">
              <div class="doc-row" v-for="(pp, key) in pathwayPlan.past" :key="key">
                <div class="doc">
                  <div class="row">
                    <div class="col-3">
                      <img :src="docImgPrev" alt="document image">
                    </div>
                    <div class="col-9 position-relative">
                      <div class="doc-details">
                        <p class="title">{{ pp.first_name }} {{ pp.last_name }}</p>
                        <p class="title">{{ pp.fileTitle }}</p>
                        <p class="list-date">{{ pp.fileDate }}</p>
                        <p class="size">{{ pp.file.size }}</p>
                        <p class="action" v-if="!isOffline">
                          <a target="_blank" @click="downloadPP" :download="pp.fileTitle" :href="pp.file.fileurl" style="margin-left: 10px" class="add blue custom-add"><q-icon name="get_app"></q-icon> Download</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="empty-task" v-else>
              <p class="text-center">Here will be all past plans</p>
            </div>

          </q-tab-panel>
        </q-tab-panels>

      </div>
    </div>
    <add-pp v-model="openPpAdd" :emit="false"></add-pp>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Loading } from 'quasar'
import moment from 'moment'
import avatar from '../../assets/av.png'
import PpLinearProgress from '@/components/PathwayPlan/PpLinearProgress'
import docImgPrev from '../../assets/doc_prev.png'
import AddPp from '@/components/PathwayPlan/AddPp'
import Notification from '@/components/Utils/Notification'

export default {
  name: 'SwPpDashboard',
  components: {
    PpLinearProgress,
    AddPp,
    Notification
  },
  data () {
    return {
      tab: 'current',
      avatar: avatar,
      docImgPrev: docImgPrev,
      openPpAdd: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'pathwayPlan', 'myCaseLoad', 'isOffline'])
  },
  methods: {
    ...mapActions(['getPpSwPa', 'setCurrentYp', 'getAssignedUsers']),
    ...mapMutations(['setYp', 'clearYp']),
    formatDate (date, fromFormat, toFormat) {
      return moment(date, fromFormat).format(toFormat)
    },
    goToYpPpDashboard (pp, tab) {
      if (this.myCaseLoad && pp) {
        const yp = this.myCaseLoad.find(yp => yp.id === pp.user_id)
        if (yp) {
          this.setYp(yp)
          this.$router.push({ name: 'PathwayPlan', query: { tab: tab, ypId: yp.id } })
        }
      }
    },
    downloadPP () {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'download_pp', {
          event_category: 'PathwayPlan',
          event_label: 'Download Pathway Plan'
        })
      }
    }
  },
  mounted () {
    this.clearYp()
    Loading.show()
    this.getPpSwPa().then(() => {
      Loading.hide()

      if (this.pathwayPlan) {
        if (this.pathwayPlan.past && this.pathwayPlan.past.length) {
          this.tab = 'past'
        }
        if (this.pathwayPlan.past && this.pathwayPlan.next.length) {
          this.tab = 'next'
        }
        if (this.pathwayPlan.past && this.pathwayPlan.current.length) {
          this.tab = 'current'
        }
      }
    }, err => {
      if (err.message !== 'offline') {
        Loading.hide()
        this.$q.notify({
          message: err.message,
          color: 'red',
          position: 'top-right'
        })
      }
    })
  }
}
</script>

<style scoped>
  .sw-pp-dashbaord-content .list-name {
    font-weight: 600;
  }
</style>
