<template>
  <q-card class="text-center q-mb-md q-mr-md card" style="width: calc(50% - 16px); float: left" :class="{ offline: isOffline }" tabindex="0" @keyup.enter="goToYoungPerson(youngPerson)" @click="goToYoungPerson(youngPerson)">
    <q-card-actions align="right">
      <div class="bookmark-icon" tabindex="0" aria-label="bookmark" role="button" @keyup.enter="toggleBookmark()" @click.stop="toggleBookmark()">
        <i class="fas fa-bookmark"></i>
      </div>
    </q-card-actions>
    <q-card-section style="padding-top: 0;">
      <div class="card-avatar">
        <q-avatar size="6.41vh">
          <img v-if="typeof youngPerson.avatar === 'string' && youngPerson.avatar.length" :src="youngPerson.avatar" alt="avatar">
          <img v-else :src="avatar" alt="avatar">
        </q-avatar>
      </div>
      <div class="card-text">
        <h2>{{ youngPerson.firstName }} {{ youngPerson.lastName }}</h2>
        <p v-html="youngPerson.status"></p>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import avatar from '../../assets/av.png'
import { mapActions, mapGetters } from 'vuex'
import { Loading } from 'quasar'

export default {
  name: 'CaseLoadYp',
  props: {
    youngPerson: {
      type: Object
    }
  },
  data () {
    return {
      avatar
    }
  },
  computed: {
    ...mapGetters(['isOffline'])
  },
  methods: {
    ...mapActions(['setCurrentYp']),
    toggleBookmark () {
      !this.isOffline && this.$emit('caseloadRemoveYp')
    },
    goToYoungPerson (yp) {
      Loading.show()
      this.setCurrentYp(yp).then(success => {
        if (success) {
          this.$router.push({ name: 'YpMainView', params: { previousRoute: this.$route.name } })
        } else {
          Loading.hide()
        }
      }).catch(e => {
        // console.log(e)
      })
    }
  }
}
</script>

<style scoped>
.offline {
  background-color: #f9f9f9;
}
</style>
