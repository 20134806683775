<template>
  <div>
    <div class="text-center">
      <q-btn :disable="isOffline" @click="modalOpen = true" class="notify-btn" color="primary" outline rounded icon="notifications">Send
        group notification
      </q-btn>
    </div>
    <q-dialog @before-hide="resetData" v-model="modalOpen" position="bottom" class="brent-dialog" role="region" aria-label="Notification" @hide="hideModal()" @show="openModal()">
      <q-card :class="['date-card brent-dialog-content case-dialog caseLoadContent send-grp-notification-dialog', { 'hide': hide }]">
        <q-card-actions align="left" class="popup-header">
          <q-btn flat label="Cancel" v-close-popup/>
          <div class="text-h6 text-center">Notification</div>
        </q-card-actions>
        <q-card-section class="q-pt-none">
          <q-form ref="sendGroupNotification">
            <div class="form-row">
              <div class="q-gutter-y-md column">
                <label for="description" style="display: none">Description</label>
                <q-input
                  type="textarea"
                  outlined
                  placeholder="Add description"
                  :rules="[val => val.trim() !== null && val.trim() !== '' || 'Please add a description']"
                  v-model="formData.description"
                  title="description"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="q-gutter-y-md column">
                <div class="input-wrapp">
                  <div class="row">
                    <div class="col-6">
                      <modal-date labelId="eventDate" :min-date="minDate" class="date-input" v-model="formData.eventDate" @triggerBehindModalShow="triggerBehindModalShow"></modal-date>
                    </div>
                    <div class="col-6 text-center">
                      <q-btn class="blue custom-add" flat @click="formData.eventDate = ''">Clear</q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="input-wrapp">
                <label class="custom-l custom-l-2" for="assignee">Notify</label>
                <q-select
                  outlined
                  v-model="formData.notifyYp"
                  :options="options"
                  use-chips
                  stack-label
                  multiple
                  ref="notificationYpSelector"
                  class="chips-select"
                  behavior="menu"
                  :option-label="(item) => item === null ? 'Null value' : item.name"
                  :rules="[val => val.length || 'Please select young person']"
                  for="assignee"
                  @input="input"
                  @keyup.enter="triggerDropdown"
                  @keydown.tab="switchFocusedToMenu"
                  @keyup.tab="switchFocusedToMenu"
                  @add="switchFocused"
                  @remove="switchFocused"
                  id="assignee"
                >
                  <template #before-options>
                    <q-item>
                      <q-item-section>
                        <q-item-label>Select All</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-checkbox v-model="selectedAllYp" @input="selectAllYp"></q-checkbox>
                      </q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:selected-item="scope">
                    <q-chip
                      removable
                      dense
                      @remove="scope.removeAtIndex(scope.index)"
                      :tabindex="scope.tabindex"
                      color="white"
                      text-color="secondary"
                      class="q-ma-none"
                    >
                      <q-avatar v-if="scope.opt.avatar.length" color="secondary" text-color="white">
                        <img :src="scope.opt.avatar" alt="avatar">
                      </q-avatar>
                      <q-avatar v-else color="primary" text-color="white" icon="person"/>
                      {{ scope.opt.name }}
                    </q-chip>
                  </template>
                  <template v-slot:option="scope">
                    <q-item v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                            @keyup.esc="closeMenu"
                    >
                      <q-item-section>{{ scope.opt.name }}</q-item-section>
                      <q-item-section avatar>
                        <q-icon v-if="scope.selected" name="checked"></q-icon>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
            <q-file @input="getAttachedFile" class="file-attach-notification blue2" borderless v-model="formData.attachedFile" label="Attach file (pdf)" title="file" for="file">
              <template v-slot:before>
                <q-icon name="attach_file"/>
              </template>
            </q-file>
            <div class="pupup-button">
              <q-btn style="margin-top: 1vh" @click="validate()" class="float-right q-btn--standard q-btn-full full-width" unelevated rounded
                     color="primary"
                     :label="'Send ' + (formData.notifyYp.length ? ' to ' + formData.notifyYp.length : '') "/>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import ModalDate from '@/components/Utils/ModalDate'
import { mapGetters } from 'vuex'
import avatar from '../../assets/avatar.png'
import moment from 'moment'
import api from '@/api'
import trapModalFocus from '@/mixins/trapModalFocus'
import focusLabel from '@/mixins/focusLabel'

const formData = {
  description: '',
  eventDate: moment().format('DD/MM/YYYY'),
  notifyYp: [],
  attachedFile: [],
  file: '',
  file_name: ''
}

export default {
  name: 'SendGroupNotifications',
  components: { ModalDate },
  mixins: [trapModalFocus, focusLabel],
  data () {
    return {
      modalOpen: false,
      selectedFile: [],
      avatar,
      formData: Object.assign({}, formData),
      minDate: moment().format('YYYY/MM/DD'),
      selectedAllYp: false,
      hide: false
    }
  },
  computed: {
    ...mapGetters(['myCaseLoad', 'currentUser', 'isOffline']),
    options () {
      return this.myCaseLoad.map(yp => {
        return {
          id: yp.id,
          name: yp.firstName + ' ' + yp.lastName,
          avatar: yp.avatar
        }
      })
    }
  },
  methods: {
    validate () {
      this.$refs.sendGroupNotification.validate().then(success => {
        if (success) {
          this.$q.loading.show()
          const data = {
            token: this.currentUser.user_token,
            due_date: moment(this.formData.eventDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            file: this.formData.file,
            file_name: this.formData.file_name,
            yp_ids: this.formData.notifyYp.map(yp => yp.id),
            description: this.formData.description
          }
          api.sendGroupNotification(data).then((data) => {
            this.$q.loading.hide()
            if (data.status === 200) {
              this.modalOpen = false
              this.resetData()
              this.$root.$emit('caseload::notification', 'The Group notification was successfully assigned')
            } else {
              this.$q.notify({
                message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                html: true,
                color: 'red',
                position: 'top-right'
              })
            }
          })
        }
      })
    },
    resetData () {
      this.formData = Object.assign({}, formData)
    },
    getAttachedFile (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = () => {
        this.formData.file = reader.result
        this.formData.file_name = e.name
      }
    },
    input () {
      if (this.options.length === this.formData.notifyYp.length) {
        this.selectedAllYp = true
      } else {
        this.selectedAllYp = false
      }
    },
    selectAllYp (value) {
      if (value) {
        this.formData.notifyYp = JSON.parse(JSON.stringify(this.options))
      } else {
        this.formData.notifyYp = []
      }
    },
    triggerDropdown () {
      if (this.$refs.notificationYpSelector.menu) {
        this.$refs.notificationYpSelector.showPopup()
        this.$refs.notificationYpSelector.$refs.content.setAttribute('tabindex', 0)
      } else {
        this.$refs.notificationYpSelector.hidePopup()
      }
    },
    switchFocused (item) {
      try {
        if (this.$refs.notificationYpSelector.$refs.content) {
          const idx = this.myCaseLoad.map(o => o.id).indexOf(item.value.id)
          if (this.$refs.notificationYpSelector.$refs.content.children) {
            this.$refs.notificationYpSelector.$refs.content.children.item(idx).focus()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    switchFocusedToMenu (e) {
      try {
        if (document.activeElement.tagName.toLowerCase() === 'input' && document.activeElement.value.length) {
          e.preventDefault()
          this.$refs.notificationYpSelector.showPopup()
          if (this.$refs.notificationYpSelector.$refs.content.children) {
            this.$refs.notificationYpSelector.$refs.content.children.item(0).focus()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    closeMenu () {
      this.$refs.notificationYpSelector.focus()
      this.$refs.notificationYpSelector.hidePopup()
    },
    triggerBehindModalShow (frontModalVisible) {
      this.hide = frontModalVisible
    },
    openModal () {
      this.selectedAllYp = false
      this.showModal()
    }
  },
  updated () {
    if (document.querySelectorAll('.q-dialog--modal .q-select [role="combobox"]').length) {
      document.querySelectorAll('.q-dialog--modal .q-select [role="combobox"]').forEach(element => {
        element.removeAttribute('aria-multiselectable')
        element.setAttribute('aria-label', 'Notify')
      })
    }
  }
}
</script>

<style>
  .q-field--auto-height.q-field--labeled .q-field__control-container {
    padding-top: 12px !important;
  }
  .send-grp-notification-dialog .q-select .q-field__control {
    padding: 1vh;
  }
</style>
