<template>
  <div>
    <vue-title title="Home page"></vue-title>
    <div v-if="doneLoading">
      <y-p-home v-if="currentUser.userType === 'YP'"></y-p-home>
      <s-w-p-a-home v-if="['SW', 'PA'].includes(currentUser.userType)"></s-w-p-a-home>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import YPHome from '@/views/YP/YPHome'
import SWPAHome from '@/views/SWPA/SWPAStartPage'
import { Loading } from 'quasar'

export default {
  name: 'Home',
  components: {
    YPHome,
    SWPAHome
  },
  data () {
    return {
      doneLoading: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'pathwayPlan'])
  },
  methods: {
    ...mapActions(['getUserTasks', 'setAppBadges', 'setupUserData', 'setPathwayPlan']),
    ...mapMutations(['setSkipInfoFullCall'])
  },
  mounted () {
    this.doneLoading = true
    this.setAppBadges()
    // Loading.show()
    this.setupUserData().then(() => {
      this.setSkipInfoFullCall()
      this.doneLoading = true
      Loading.hide()
      if (this.currentUser.userType === 'YP' && this.pathwayPlan.current && this.pathwayPlan.current.current_started === 'no') {
        const requestData = {
          pathway_id: this.pathwayPlan.current.id,
          token: this.currentUser.user_token,
          current_started: 'yes'
        }
        this.setPathwayPlan(requestData).then((data) => {})
      }
    })
  }
}
</script>
