<template>
  <div>
    <div v-if="myCaseLoad && myCaseLoad.length === 0">
      <div class="text-center q-pa-md home-page-text">
        <p aria-label="Start adding Young Persons to My Caseload" tabindex="0">Start adding Young Persons<br> to "My Caseload"</p>
      </div>
      <div class="text-center" style="position:relative; z-index: 999">
        <q-btn class="q-btn--standard" unelevated rounded style="width: 70%"
               color="primary" :to="{ name: 'AddYoungPersons' }" aria-label="Start Adding caseload" role="button">Start Adding
        </q-btn>
      </div>
      <div class="text-center home-page-background">
        <img :src="backgroundImage">
      </div>
    </div>
   <s-w-p-a-home v-else></s-w-p-a-home>
  </div>
</template>

<script>
import avatar from '../../assets/av.png'
import backgroundImage from '../../assets/caseload-bg.png'
import { mapGetters, mapActions } from 'vuex'
import SWPAHome from '@/views/SWPA/SWPAHome'

export default {
  name: 'SWPAStartPage',
  data () {
    return {
      backgroundImage: backgroundImage
    }
  },
  components: {
    SWPAHome
  },
  computed: {
    ...mapGetters(['userGeneralInfo', 'myCaseLoad']),
    username () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.firstName : 'User'
    },
    avatar () {
      return typeof this.userGeneralInfo.avatar === 'string' && this.userGeneralInfo.avatar.length ? this.userGeneralInfo.avatar : avatar
    }
  },
  methods: {
    ...mapActions(['setYoungPersons'])
  }
}
</script>

<style scoped>

</style>
