<template>
  <div v-if="!currentYp">
    <q-header class="edit-profile-header" v-if="['Profile'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="back back-profile" aria-label="back" role="button" :to="{ name: 'ViewProfile' }" flat round dense icon="arrow_back_ios"/>
        </div>
        <div class="col-7 text-left">
          <q-toolbar-title role="heading" aria-level="1">Editing my profile</q-toolbar-title>
        </div>
        <div class="col-3 text-right">
          <q-btn :disable="isOffline" @click="$root.$emit('save:profile', false)" outline rounded label="Save" aria-label="save"/>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="view-profile-header" v-if="['ViewProfile'].includes($route.name)">
        <q-toolbar class="items-start">
          <div class="col-4">
            <q-btn
              flat
              dense
              round
              @click="toggleAppDrawer()"
              aria-label="Menu"
              icon="fas fa-bars"
              style="margin-left: -0.5vh"
            />
          </div>
          <div class="col-4">
            <q-avatar @click="$router.push({ name: 'Profile' })" @keyup.enter="$router.push({ name: 'Profile' })" class="edit-profile-avatar" size="8.87vh" tabindex="0">
              <img :src="avatar" alt="avatar">
            </q-avatar>
          </div>
          <div class="col-4 text-right">
            <q-btn :to="{ name: 'Profile' }" class="edit-btn vertical-top" outline rounded icon="edit" label="Edit" aria-label="edit"/>
          </div>
        </q-toolbar>
        <div class="avatar-details">
          <div class="avatar-text" tabindex="0" @click="$router.push({ name: 'Profile' })" @keyup.enter="$router.push({ name: 'Profile' })" role="heading" aria-level="1">{{ username }}</div>
          <p>{{ gender ? gender : '' }} <span class="text-divider"></span> {{ age ? age : '' }} {{ dob ? '( ' + dob + ' )': '' }} </p>
          <p>{{ firstLanguage ? firstLanguage + ' speaker' : '' }}<!-- <span class="text-divider"></span> Unaccompanied minor </p>-->
          <div class="avatar-badge" v-if="profileBadge"><i class="av-icon"><img :src="profileBadge.icon" :alt="profileBadge.title"></i>{{ profileBadge.title }}</div>
        </div>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import avatar from '../../assets/av.png'

export default {
  name: 'AppHeaderProfile',
  computed: {
    ...mapGetters(['appDrawerOpen', 'userGeneralInfo', 'currentContact', 'currentUser', 'currentYp', 'isOffline', 'profileBadge']),
    username () {
      return (this.userGeneralInfo.firstName !== '' || this.userGeneralInfo.lastName) ? this.userGeneralInfo.firstName + ' ' + this.userGeneralInfo.lastName : 'User'
    },
    avatar () {
      return typeof this.userGeneralInfo.avatar === 'string' && this.userGeneralInfo.avatar.length ? this.userGeneralInfo.avatar : avatar
    },
    gender () {
      let gender = false
      switch (this.userGeneralInfo.gender) {
        case 'm':
          gender = 'Male'
          break
        case 'f':
          gender = 'Female'
          break
      }
      return gender
    },
    age () {
      if (moment(this.userGeneralInfo.dob, 'DD/MM/YYYY').isValid()) {
        return moment().diff(moment(this.userGeneralInfo.dob, 'DD/MM/YYYY'), 'years', false)
      }
      return false
    },
    dob () {
      if (moment(this.userGeneralInfo.dob, 'DD/MM/YYYY').isValid()) {
        return moment(this.userGeneralInfo.dob, 'DD/MM/YYYY').format('Do MMMM YYYY')
      }
      return false
    },
    firstLanguage () {
      if (typeof this.userGeneralInfo.firstLanguage === 'string' && this.userGeneralInfo.firstLanguage.length) {
        return this.userGeneralInfo.firstLanguage
      }
      return false
    },
    userType () {
      return this.currentUser.userType
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>
