<template>
  <div v-if="currentContact && typeof currentContact === 'object'">
    <div class="contact-info-holder">
      <div class="q-pa-md">
        <div class="row q-col-gutter-sm">
          <div class="col-3 custom-grid">
            <q-item-section avatar>
              <q-avatar size="6.41vh">
                <img v-if="typeof currentContact.avatar === 'string' && currentContact.avatar.length"
                     :src="currentContact.avatar" alt="avatar">
                <img v-else :src="avatar" alt="avatar">
              </q-avatar>
            </q-item-section>
          </div>
          <div class="col-8 custom-grid">
            <h2 v-if="currentContact.firstName || currentContact.lastName">{{ currentContact.firstName }} {{ currentContact.lastName }}</h2>
            <!-- <p>www.brent.gov.uk <q-btn class="new-tab" flat round dense icon="fas fa-indent"/></p> -->
            <p>{{ currentContact.phone }}</p>
            <p v-for="(phone, idx) in currentContact.phoneNumbers" :key="'phone' + idx">
              {{ phone }}
            </p>
            <p v-if="currentContact.type === 'service'">
              {{ currentContact.website }} <a style="color: #027646" target="_blank"
                                              :href="'https://' + currentContact.website.replace(/https:\/\/|http:\/\//g, '')">
              <span style="margin-left: 1.5vh; font-size: 2.8vh" class="material-icons-outlined">launch</span></a>
            </p>
            <p class="report" v-if="currentContact.type === 'service'">
              <q-btn :disable="isOffline" @click="reportError = true" icon="edit" class="add blue custom-add" flat label="Report error" aria-label="Report error" role="button"/>
            </p>
          </div>
          <div class="col-1 contactInfoBookmark">
            <div @click="!isOffline && toggleQuickContact()" class="bookmark-icon" tabindex="0" role="button" aria-label="toogle bookmark" @keyup.enter="!isOffline && toggleQuickContact()">
              <i :class="[currentContact.isQuickContact ? 'fas fa-bookmark' : 'far fa-bookmark']"></i>
            </div>
          </div>
        </div>
      </div>
      <div :class="['contact-actions', { 'text-center': !(['SW', 'PA'].includes(currentUser.userType) && currentContact.type !== 'service' && !currentYp) }]">
        <q-btn v-if="currentContact.phone" type="a" round outline icon="call" :href="'tel:' + currentContact.phone" aria-label="dial" role="button"/>
        <q-btn v-if="currentContact.email" type="a" round outline icon="email" :href="'mailto:' + currentContact.email" aria-label="email" role="button"/>
        <q-btn v-if="currentContact.phone" type="a" round outline icon="chat" :href="'sms:' + currentContact.phone" aria-label="sms" role="button"/>
        <share-contact style="float: right" v-if="['SW', 'PA'].includes(currentUser.userType) && currentContact.type !== 'service' && !currentYp"></share-contact>
      </div>
    </div>
    <div class="tasks-content tabs-content" v-if="currentContact.type === 'service' && (currentContact.aboutService.trim().length || (Array.isArray(currentContact.serviceDocuments) && currentContact.serviceDocuments.length))">
      <div class="q-gutter-y-md">
        <q-tabs
          v-model="tab"
          dense
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
          role="tablist"
        >
          <q-tab role="tab" name="about" label="About Services" v-if="currentContact.aboutService.trim().length" aria-label="about services"/>
          <q-tab role="tab" name="documents" label="Documents" v-if="Array.isArray(currentContact.serviceDocuments) && currentContact.serviceDocuments.length" aria-label="documents"/>
        </q-tabs>

        <q-tab-panels v-model="tab" animated v-if="currentContact.type === 'service'">
          <q-tab-panel name="about" class="contacs-pannel">
            <div v-html="currentContact.aboutService" tabindex="0"></div>
          </q-tab-panel>

          <q-tab-panel name="documents" class="contacs-pannel" v-if="Array.isArray(currentContact.serviceDocuments) && currentContact.serviceDocuments.length">
            <div class="doc">
              <div class="row" v-for="(document, idx) in currentContact.serviceDocuments" :key="idx">
                <div class="col-3">
                  <a target="_blank" :href="document.file">
                    <img :src="docImg" alt="document image">
                  </a>
                </div>
                <div class="col-9 position-relative">
                  <div class="doc-details">
                    <a target="_blank" :href="document.file" tabindex="0" :download="document.file">
                      <p class="title">{{ document.filename }}</p>
                    </a>
                    <p class="size">{{ document.size }}</p>
                    <p class="date">{{ document.date }}</p>
                  </div>
                </div>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
    <q-dialog v-model="reportError" position="bottom" class="brent-dialog" role="region" aria-label="Report error">
      <q-card class="brent-dialog-content">
        <q-card-actions align="left" class="popup-header">
          <q-btn flat label="Cancel" v-close-popup/>
          <div class="text-h6 text-center" role="heading" aria-level="2">Report error</div>
        </q-card-actions>
        <q-card-section class="q-pt-none">
          <q-form ref="reportError">
            <div>
              <div class="form-row">
                <div class="input-wrapp">
                  <q-input
                    :rules="[
                    val => val !== null && val.trim() !== '' || 'Please type the error description',
                    ]"
                    v-model="errorReport"
                    :disable="loading"
                    type="textarea"
                    outlined placeholder="Describe the error" role="textbox"/>
                </div>
              </div>
              <div class="pupup-button">
                <q-btn @click="validate()" class="float-right q-btn--standard full-width" unelevated rounded
                       color="primary"
                       :loading="loading"
                       label="Report" aria-label="report" role="button"/>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import docImg from '../../assets/doc.png'
import { mapActions, mapGetters } from 'vuex'
import avatar from '@/assets/av.png'
import api from '@/api'
import moment from 'moment'
import ShareContact from '@/components/Contacts/ShareContact'

export default {
  name: 'ContactInfo',
  components: { ShareContact },
  data () {
    return {
      tab: 'about',
      docImg: docImg,
      avatar: avatar,
      reportError: false,
      errorReport: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters(['currentContact', 'currentUser', 'currentYp', 'isOffline', 'backgroundLoading', 'allContactsWithoutGroup'])
  },
  methods: {
    ...mapActions(['toggleQuickContact', 'setCurrentContact']),
    validate () {
      this.$refs.reportError.validate().then(success => {
        if (success) {
          this.loading = true
          api.reportError({
            token: this.currentUser.user_token,
            service_id: this.currentContact.id,
            message: this.errorReport,
            date: moment().format('YYYY-MM-DD')
          }).then((data) => {
            this.loading = false
            if (data.status === 200) {
              this.reportError = false
              this.errorReport = ''
              this.$q.notify({
                message: 'Thank you for sending your error report!',
                color: 'green'
              })
            } else {
              this.reportError = false
              this.errorReport = ''
              this.$q.notify({
                message: 'There was an error while trying to send your error report!',
                color: 'red'
              })
            }
          }).catch(() => {
            this.reportError = false
            this.errorReport = ''
            this.$q.notify({
              message: 'There was an error while trying to send your error report!',
              color: 'red'
            })
          })
        }
      })
    }
  },
  watch: {
    backgroundLoading (state) {
      if (!state) {
        const contact = this.allContactsWithoutGroup.find(contact => contact.id === this.$route.query.id)
        if (contact) {
          this.setCurrentContact(contact)
        } else {
          this.setCurrentContact(false)
        }
      }
    }
  }
}
</script>

<style>
.contactInfoBookmark .far.fa-bookmark {
  color: #007AFF;
}

.contactInfoBookmark .fas.fa-bookmark {
  color: #007AFF;
}
</style>
