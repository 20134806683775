<template>
  <div class="contacts-holder">
    <div class="accordion-contacts-holder services-contacts-holder">
      <q-list separator>
        <q-expansion-item v-for="(serviceGroup, idx) in contacts" :key="idx + '_serviceGroup'"
          :group="serviceGroup.group"
          :label="serviceGroup.group"
          :default-opened="idx === 0"
          header-class="text-primary"
        >
          <template v-slot:header class="serv-header">
              <q-item-section avatar>
                  <q-icon class="cat-icon" v-if="serviceGroup.groupIcon.length" :name="serviceGroup.groupIcon"/>
              </q-item-section>

              <q-item-section class="serv-title">
                {{ serviceGroup.group }}
              </q-item-section>
          </template>

          <q-card>
            <q-card-section class="no-padding">
              <div class="list-contacts-holder">
                <q-list separator>
                  <q-item clickable v-ripple v-for="(contact, idx) in serviceGroup.children" :key="idx + '_contact'" @click="goToContact(contact)">
                    <q-item-section avatar>
                      <q-avatar size="6.41vh">
                        <img v-if="typeof contact.avatar === 'string' && contact.avatar.length" :src="contact.avatar" :alt="contact.firstName + ' ' + contact.lastName">
                        <img v-else :src="avatar" :alt="contact.firstName + ' ' + contact.lastName">
                      </q-avatar>
                    </q-item-section>
                    <q-item-section>
                    <p class="list-name" :aria-label="contact.firstName + ' ' + contact.lastName">{{ contact.firstName }} {{ contact.lastName }}</p>
                    <p class="list-text" :aria-label="contact.phone">{{ contact.phone }}</p>
                    </q-item-section>
                    <q-item-section side class="phone-icon">
                      <a @click.stop :href="'tel:' + contact.phone" aria-label="dial" role="button"><i class="fas fa-phone-alt"></i></a>
                    </q-item-section>
                    <q-item-section side class="arrow-icon"><i class="fas fa-chevron-right"></i></q-item-section>
                  </q-item>
                </q-list>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-list>
    </div>
  </div>
</template>

<script>
import avatar from '../../assets/av.png'
import { mapMutations } from 'vuex'
export default {
  name: 'ListServices',
  props: {
    contacts: {
      type: Array,
      default () {
        return []
      }
    },
    label: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      avatar
    }
  },
  methods: {
    ...mapMutations(['setCurrentContact']),
    goToContact (contact) {
      if (['Contacts', 'YpContacts'].includes(this.$route.name)) {
        this.setCurrentContact(contact)
        this.$router.push({ name: this.$route.name, query: { id: contact.id } })
      } else {
        this.$router.push({ name: 'Contacts', query: { id: contact.id } })
      }
    }
  }
}
</script>
