<template>
  <div id="assignBadgeComponent">
    <div class="selected-badges">
      <div class="badges-list">
        <span class="badge-item" v-for="(badge, idx) in (staticBadges.length ? staticBadges : assignedBadges)" :key="'assigned_' + idx" :aria-label="badge.title">
          <span v-if="!disabled" class="material-icons-outlined remove-badge" @click="removeBadge(badge)" @keyup.enter="removeBadge(badge)" tabindex="0" aria-label="close" role="button">close</span>
          <img :src="badge.icon" :alt="badge.title">
        </span>
      </div>
    </div>
    <div v-if="!(single && assignedBadges.length >= 1)">
      <q-btn id="addBadgeBtn" v-if="!disabled && assignedBadges.length !== badges.length" @click="modalOpen = true" class="add blue custom-add" flat><span class="plus-icon material-icons-outlined">add</span> Add badge</q-btn>
    </div>
    <q-dialog v-model="modalOpen" position="bottom" class="brent-dialog" role="region" aria-label="select badge" @show="showModal" @hide="hideModal" id="assignBadgeModal">
      <q-card class="date-card">
        <q-card-actions align="left" class="popup-header">
          <q-btn flat label="Cancel" v-close-popup/>
          <div class="text-h6 text-center">Select badge</div>
        </q-card-actions>
        <q-card-section class="q-pt-none">
          <div class="badges-list">
            <span class="task-badge" :class="{ last: (idx + 1) % 4 === 0 }" v-for="(badge, idx) in availableBadges"
                  :key="idx" @click="addBadge(badge)" @keyup.enter="addBadge(badge)" tabindex="0" :aria-label="badge.title">
              <img :src="badge.icon" :alt="badge.title">
            </span>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AssignBadges',
  computed: {
    ...mapGetters(['badges', 'ypAssignedBadges']),
    availableBadges () {
      return this.badges.filter(badge => !this.assigned.includes(badge.id))
    },
    assignedBadges () {
      return this.badges.filter(badge => this.assigned.includes(badge.id))
    }
  },
  data () {
    return {
      modalOpen: false,
      assigned: []
    }
  },
  props: {
    emit: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    single: {
      default: false,
      type: Boolean
    },
    value: {
      default () {
        return []
      }
    },
    staticBadges: {
      default () {
        return []
      }
    }
  },
  methods: {
    ...mapActions(['assignBadge', 'removeAssignedBadge']),
    addBadge (badge) {
      const assign = () => {
        this.assigned.push(badge.id)
        this.modalOpen = false
      }
      if (!this.assigned.includes(badge.id)) {
        if (this.emit) {
          assign()
          this.$emit('input', this.assigned)
        } else {
          this.assignBadge(badge.id).then(success => {
            if (success) {
              assign()
            }
          })
        }
      }
    },
    removeBadge (removedBadge) {
      const remove = () => {
        this.assigned = this.assigned.filter(badge => removedBadge.id !== badge)
        this.$emit('input', this.assigned)
      }
      if (this.emit) {
        remove()
        this.$emit('assignedBadges', this.assigned)
      } else {
        this.removeAssignedBadge(removedBadge.id).then(success => {
          if (success) {
            remove()
          }
        })
      }
    },
    handleKey (e) {
      if (e.keyCode === 9) {
        if (document.querySelector('#assignBadgeModal')) {
          const focusable = document.querySelector('#assignBadgeModal').querySelectorAll('input,button,select,textarea,span[tabindex="0"]')
          if (focusable.length) {
            const first = focusable[0]
            const last = focusable[focusable.length - 1]
            const shift = e.shiftKey
            if (shift) {
              if (e.target === first) { // shift-tab pressed on first input in dialog
                last.focus()
                e.preventDefault()
              }
            } else {
              if (e.target === last) { // tab pressed on last input in dialog
                first.focus()
                e.preventDefault()
              }
            }
          }
        }
      }
    },
    showModal () {
      document.querySelector('#assignBadgeModal').addEventListener('keydown', this.handleKey)
    },
    hideModal () {
      if (document.querySelector('#assignBadgeModal')) {
        document.querySelector('#assignBadgeModal').removeEventListener('keydown', this.handleKey)
      }
      if (document.querySelector('#assignBadgeComponent span[tabindex="0"]')) {
        document.querySelector('#assignBadgeComponent span[tabindex="0"]').focus()
      } else if (document.querySelector('#addBadgeBtn')) {
        document.querySelector('#addBadgeBtn').focus()
      }
    }
  },
  watch: {
    value (value) {
      this.assigned = value
      this.$forceUpdate()
    },
    ypAssignedBadges () {
      if (!this.emit) {
        this.assigned = this.ypAssignedBadges
      }
    },
    modalOpen (value) {
      this.$emit('triggerBehindModalShow', value)
    }
  },
  mounted () {
    if (!this.emit) {
      this.assigned = this.ypAssignedBadges
    } else {
      this.assigned = this.value
    }
  }
}
</script>

<style scoped>

</style>
