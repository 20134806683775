<template>
  <div>
    <q-header class="tasks-header" v-if="['AllYoungPersons'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn
            flat
            dense
            round
            @click="toggleAppDrawer()"
            aria-label="Menu"
            icon="fas fa-bars"
            style="margin-left: -0.5vh"
          />
        </div>
        <div class="col-8 text-center">
          <q-toolbar-title class="text-large" role="heading" aria-level="1">All young people</q-toolbar-title>
        </div>
        <div class="col-2">
        </div>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppHeaderAllYoungPeople',
  computed: {
    ...mapGetters(['appDrawerOpen'])
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>
