<template>
  <div class="health_color">
    <vue-title title="Health & development"></vue-title>
    <lock-dialog></lock-dialog>
    <div v-if="!viewPathwaySection && readOnly">
      <div class="planTitle text-center">
        <span>TASKS FOR THIS SECTION</span>
      </div>
      <div class="path_task_holder">
        <tasks :render-title="false" :show-task-assign-btn="false" :pp-section="'health'" :pp="pathwayPlan[$route.meta.tense]"></tasks>
      </div>
      <div class="current_path_footer health_footer">
        <q-btn @click="viewPathwaySection = !viewPathwaySection" class="tab-type-button" unelevated icon="self_improvement" icon-right="arrow_forward_ios"  label="Pathway Plan - health & development"/>
      </div>
    </div>

    <step v-else @stepChanged="stepChanged" :tabs="tabs" @prev="prev" @validate="validate" :completed="completed"
          :change-tab-to="tab" :loading="loading" :read-only="readOnly" category="health">

      <template v-slot:1>
        <q-form class="health-development-one" ref="1">
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label class="strong-label" for="Name">How are you feeling about health & development</label>
              <div class="feelings-box radio">
                <q-radio title="awful" class="feelings-item" name="feelings" v-if="readOnly ? health.step_1.healthFeeling === '1' : true" v-model="health.step_1.healthFeeling" val="1"
                        :class="[{ selected: health.step_1.healthFeeling === '1' & !readOnly }]">
                  <span class="f-icon">😭</span>
                  <span class="f-text">awful</span>
                </q-radio>
                <q-radio title="bad" class="feelings-item" name="feelings" v-if="readOnly ? health.step_1.healthFeeling === '2' : true" v-model="health.step_1.healthFeeling" val="2"
                        :class="[{ selected: health.step_1.healthFeeling === '2' & !readOnly }]">
                  <span class="f-icon">🙁</span>
                  <span class="f-text">bad</span>
                </q-radio>
                <q-radio title="so so" class="feelings-item" name="feelings" v-if="readOnly ? health.step_1.healthFeeling === '3' : true" v-model="health.step_1.healthFeeling" val="3"
                        :class="[{ selected: health.step_1.healthFeeling === '3' & !readOnly }]">
                  <span class="f-icon">😐</span>
                  <span class="f-text">so so</span>
                </q-radio>
                <q-radio title="good" class="feelings-item" name="feelings" v-if="readOnly ? health.step_1.healthFeeling === '4' : true" v-model="health.step_1.healthFeeling" val="4"
                        :class="[{ selected: health.step_1.healthFeeling === '4' & !readOnly }]">
                  <span class="f-icon">🙂</span>
                  <span class="f-text">good</span>
                </q-radio>
                <q-radio title="great" class="feelings-item" name="feelings" v-if="readOnly ? health.step_1.healthFeeling === '5' : true" v-model="health.step_1.healthFeeling" val="5"
                        :class="[{ selected: health.step_1.healthFeeling === '5' & !readOnly }]">
                  <span class="f-icon">😀</span>
                  <span class="f-text">great</span>
                </q-radio>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="healthWorkingWell">What's working well</label>
              <q-input
                v-model="health.step_1.healthWorkingWell"
                outlined
                :placeholder="readOnly ? '' : 'Tell us what is working well'"
                :readonly="loading || isOffline || isLocked"
                for="healthWorkingWell"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="healthWorriedAbout">What are we worried about</label>
              <q-input
                v-model="health.step_1.healthWorriedAbout"
                outlined
                :placeholder="readOnly ? '' : 'Add description'"
                :readonly="loading || isOffline || isLocked"
                for="healthWorriedAbout"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="healthAllergies">Known allergies and / or medical conditions</label>
              <q-input
                v-model="health.step_1.healthAllergies"
                outlined
                :placeholder="readOnly ? '' : 'Add description'"
                :readonly="loading || isOffline || isLocked"
                for="healthAllergies"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="mentalHealth">How do you feel about your mental health and wellbeing currently</label>
              <q-input
                v-model="health.step_1.mentalHealth"
                outlined
                :placeholder="readOnly ? '' : 'Add description'"
                :readonly="loading || isOffline || isLocked"
                for="mentalHealth"
              />
            </div>
          </div>
          <q-list class="pathList">
            <q-expansion-item
              group="somegroup"
              label="What needs to happen"
              icon="check_circle"
              default-opened
              :header-class="['pathListHeader', { compleated: needsToHappenCompleted }]"
            >
              <q-card>
                <q-card-section>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="healthNextSteps">Next steps</label>
                      <q-input
                        v-model="health.step_1.healthNextSteps"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="healthNextSteps"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="healthLongTermGoals">Long term goals</label>
                      <q-input
                        v-model="health.step_1.healthLongTermGoals"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="healthLongTermGoals"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="q-gutter-y-md column">
                      <label for="healthContingency">Contingency if anything goes wrong</label>
                      <q-input
                        v-model="health.step_1.healthContingency"
                        outlined
                        :placeholder="readOnly ? '' : 'Add description'"
                        :readonly="loading || isOffline || isLocked"
                        for="healthContingency"
                      />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-form>
      </template>

      <template v-slot:2>
        <div class="planTitle text-center">
          <span>TASKS FOR THIS SECTION</span>
        </div>
        <div v-if="pathwayPlanTasks[getTense].health.total.length" class="health-development-two">
          <div class="plan-task" v-for="(task, index) in pathwayPlanTasks[getTense].health.total" :key="index">
            <task :task="task" :read-only="readOnly ? false : true"></task>
          </div>
        </div>
        <div class="form-row" v-if="!readOnly">
          <q-btn :disable="isLocked || isOffline" @click="assignTaskModalOpen = true" class="add blue custom-add" flat><span class="plus-icon material-icons-outlined">add</span> Assign task</q-btn>
          <assign-task :section="'health'" :pp-id="getPpId" :emit="false"
                      v-model="assignTaskModalOpen"></assign-task>
        </div>
      </template>

    </step>
  </div>

</template>

<script>
import Step from '../components/Step.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import scrollToTarget from '../mixins/scrollToTarget'
import Task from '../components/Tasks/Task'
import AssignTask from '../components/Tasks/AssignTask'
import Tasks from './Tasks.vue'
import addLocks from '@/mixins/addLocks'
import removeLocks from '@/mixins/removeLocks'
import LockDialog from '@/components/Utils/LockDialog'
import showLock from '@/mixins/showLock'
import focusLabel from '@/mixins/focusLabel'
let updatePathwayPlanInterval = null

export default {
  name: 'HealthDevelopment',
  components: {
    LockDialog,
    Step,
    Task,
    AssignTask,
    Tasks
  },
  mixins: [scrollToTarget, addLocks, removeLocks, showLock, focusLabel],
  data () {
    return {
      viewPathwaySection: false,
      readOnly: false,
      tab: 1,
      tabs: 2,
      loading: false,
      assignTaskModalOpen: false,
      health: {
        step_1: {
          healthFeeling: '',
          healthWorkingWell: '',
          healthWorriedAbout: '',
          healthAllergies: '',
          mentalHealth: '',
          healthNextSteps: '',
          healthLongTermGoals: '',
          healthContingency: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['tasks', 'pathwayPlan', 'pathwayPlanTasks', 'currentUser', 'isOffline']),
    completed () {
      const steps = []
      if (this.health.step_1.healthFeeling && this.health.step_1.healthWorkingWell && this.health.step_1.healthWorriedAbout && this.health.step_1.mentalHealth && this.health.step_1.healthNextSteps && this.health.step_1.healthLongTermGoals && this.health.step_1.healthContingency) {
        steps.push(1)
      }
      return steps
    },
    needsToHappenCompleted () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        if (this.pathwayPlan[this.$route.meta.tense].health_next_steps && this.pathwayPlan[this.$route.meta.tense].health_next_steps !== null && this.pathwayPlan[this.$route.meta.tense].health_long_term_goals && this.pathwayPlan[this.$route.meta.tense].health_long_term_goals !== null && this.pathwayPlan[this.$route.meta.tense].health_contingency && this.pathwayPlan[this.$route.meta.tense].health_contingency !== null) {
          return true
        }
      }
      return false
    },
    getPpId () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        return this.pathwayPlan[this.$route.meta.tense].id
      }
      return null
    },
    getTense () {
      if (this.$route.meta.tense) {
        return this.$route.meta.tense
      }
      return 'next'
    }
  },
  methods: {
    ...mapActions(['setPathwayPlan']),
    ...mapMutations(['setppDueDate']),
    changeTab (tab) {
      this.tab = tab
    },
    prev () {
      this.tab = this.tab - 1
    },
    validate (goToNextStep = false) {
      if (this.$refs[this.tab]) {
        this.$refs[this.tab].validate().then(success => {
          if (success) {
            this.loading = true
            this.scroll(this.$refs.saveBtn)
            const userInfo = Object.assign({}, this.health['step_' + this.tab])
            let requestData = null
            if (this.tab === 1) {
              requestData = {
                health_feeling: userInfo.healthFeeling,
                health_working_well: userInfo.healthWorkingWell,
                health_worried_about: userInfo.healthWorriedAbout,
                health_allergies: userInfo.healthAllergies,
                mental_health: userInfo.mentalHealth,
                health_next_steps: userInfo.healthNextSteps,
                health_long_term_goals: userInfo.healthLongTermGoals,
                health_contingency: userInfo.healthContingency
              }
            } else if (this.tab === 2 && goToNextStep) {
              return this.$router.push({
                name: 'PathwayPlan',
                query: {
                  tab: this.getTense
                }
              })
            }
            requestData.pathway_id = this.pathwayPlan[this.$route.meta.tense].id
            requestData.token = this.currentUser.user_token
            this.setPathwayPlan(requestData).then((data) => {
              this.loading = false
              if (data.status === 200) {
                if (goToNextStep && this.tab !== this.tabs) {
                  this.tab = this.tab + 1
                }
                if (data.message) {
                  return this.$q.notify({
                    message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                    html: true,
                    color: 'green',
                    position: 'top-right'
                  })
                }
              } else {
                return this.$q.notify({
                  message: '<span role="region" aria-label="' + data.message + '">' + data.message + '</span>',
                  html: true,
                  color: 'red',
                  position: 'top-right'
                })
              }
            })
          }
        })
      } else {
        if (goToNextStep) {
          this.$router.push({
            name: 'PathwayPlan',
            query: {
              tab: this.getTense
            }
          })
        }
      }
    },
    stepChanged (e) {
      this.tab = e
      this.loading = false
    },
    updatePathwayData () {
      if (this.pathwayPlan[this.$route.meta.tense]) {
        clearInterval(updatePathwayPlanInterval)
        this.health.step_1.healthFeeling = this.pathwayPlan[this.$route.meta.tense].health_feeling
        this.health.step_1.healthWorkingWell = this.pathwayPlan[this.$route.meta.tense].health_working_well
        this.health.step_1.healthAllergies = this.pathwayPlan[this.$route.meta.tense].health_allergies
        this.health.step_1.mentalHealth = this.pathwayPlan[this.$route.meta.tense].mental_health
        this.health.step_1.healthWorriedAbout = this.pathwayPlan[this.$route.meta.tense].health_worried_about
        this.health.step_1.healthNextSteps = this.pathwayPlan[this.$route.meta.tense].health_next_steps
        this.health.step_1.healthLongTermGoals = this.pathwayPlan[this.$route.meta.tense].health_long_term_goals
        this.health.step_1.healthContingency = this.pathwayPlan[this.$route.meta.tense].health_contingency
      }
    },
    goback (route) {
      if (this.viewPathwaySection) {
        this.viewPathwaySection = false
      } else {
        if (this.currentYp) {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab, ypId: this.currentYp.id } })
        } else {
          this.$router.push({ name: 'PathwayPlan', params: { previousRoute: route.previousRoute }, query: { tab: route.tab } })
        }
      }
    }
  },
  watch: {
    'allAboutMe.step_1.dob': function (newVal, oldVal) {
      this.setupStepTwoOptions()
    },
    pathwayPlan: function (newVal, oldVal) {
      if (this.$route.meta.tense === 'current') {
        if (this.pathwayPlan.current) {
          this.setppDueDate({
            dueDate: this.pathwayPlan.current.expiry_date,
            tense: 'current'
          })
        }
      }
    },
    'pathwayPlan.next': function (newVal, oldVal) {
      this.updatePathwayData()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$route.meta.edit === false) {
      this.loading = true
      this.readOnly = true
    }
    updatePathwayPlanInterval = setInterval(this.updatePathwayData, 500)
    this.$root.$on('save:pathwayPlanSection', this.validate)
    if (this.$route.meta.tense === 'current') {
      if (this.pathwayPlan.current) {
        this.setppDueDate({
          dueDate: this.pathwayPlan.current.expiry_date,
          tense: 'current'
        })
      }
    }
    this.$root.$on('ppSection:back', this.goback)
  },
  beforeDestroy () {
    this.$root.$off('save:pathwayPlanSection')
    this.$root.$off('ppSection:back', this.goback)
  }
}
</script>

<style>
.profile-tabs .q-focus-helper {
  display: none;
}
</style>
