<template>
  <div v-if="currentUser && backgroundLoading">
      <q-linear-progress query color="secondary" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BackgroundLoading',
  computed: {
    ...mapGetters(['currentUser', 'backgroundLoading'])
  }
}
</script>
