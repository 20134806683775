import scroll from 'quasar/src/utils/scroll.js';
const { getScrollTarget, setScrollPosition } = scroll

export default {
  methods: {
    scroll (el) {
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    scrollQ (el) {
      setTimeout(() => {
        setScrollPosition(getScrollTarget(el), 1, 500)
      }, 300)
    }
  }
}
