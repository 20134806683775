<template>
  <div>
    <div class="filter-header row">
      <div class="col-6">
        <q-btn @click="filterOpen = !filterOpen" outline rounded icon="tune" class="filter-btn" color="primary" aria-label="Filter">Filter</q-btn>
      </div>
      <div class="col-6 text-right">
        <span class="filter-count">{{ tasks.length }} Tasks</span>
      </div>
    </div>
    <div class="filter-results">
      <div v-if="yps.length">
        <div class="filter-result-item" v-for="(yp, idx) in yps" :key="idx">
          <span class="avatar">
            <img v-if="yp.avatar && yp.avatar.length" :src="yp.avatar" :alt="yp.name">
            <img v-else :src="avatar" :alt="yp.name">
          </span>
          <span class="text">{{ yp.name }}</span>
          <span class="material-icons-outlined" @click="removeYp(yp)" @keyup.enter="removeYp(yp)" tabindex="0" role="button">close</span>
        </div>
      </div>
      <div class="filter-result-item" v-if="status && status !== 'all'">
        <span class="text">{{ status }}</span>
        <span class="material-icons-outlined" @click="removeStatus()" @keyup.enter="removeStatus(yp)" tabindex="0" role="button">close</span>
      </div>
      <div class="filter-result-item" v-if="sortby && sortby !== 'Default'">
        <span class="text">{{ sortby }}</span>
        <span class="material-icons-outlined" @click="removeSorting()" @keyup.enter="removeSorting(yp)" tabindex="0" role="button">close</span>
      </div>
      <div class="reset-box" v-if="yps.length || (status && status !== 'all') || (sortby && sortby !== 'Default')">
        <q-btn class="add blue custom-add" outline flat color="primary" label="Reset Filters" @click="resetFilter"/>
      </div>
    </div>
    <div class="taskFilter" v-if="filterOpen">
      <div class="fiter-text">Filter by</div>
      <div class="filter-form">
        <q-form class="filter">
          <div class="form-row">
            <div class="q-gutter-y-md column">
              <label for="youngPerson">Young persons</label>
              <yp-multi-select v-model="yps" label-id="Young persons"></yp-multi-select>
            </div>
          </div>
          <div class="form-row status-filter">
            <div class="q-gutter-y-md column">
              <label for="status" aria-label="Status">Status</label>
              <q-select
                outlined
                color="primary"
                placeholder="Select status"
                behavior="menu"
                v-model="status"
                :options="statusOptions"
              />
            </div>
          </div>
          <div class="form-row sortby-filter">
            <div class="q-gutter-y-md column">
              <label for="sortBy" aria-label="Sort by">Sort by</label>
              <q-select
                outlined
                color="primary"
                placeholder="Select status"
                behavior="menu"
                v-model="sortby"
                :options="sortbyOptions"
                for="sortBy"
                />
            </div>
          </div>
          <div class="row saveBtn">
            <div class="col-6">
              <q-btn class="float-left q-btn--standard green" outline rounded label="Cancel" @click="filterOpen = false" aria-label="cancel"/>
            </div>
            <div class="col-6">
              <q-btn class="float-right q-btn--standard text-white " unelevated rounded color="primary" label="Apply" @click="applyFilter" aria-label="apply"/>
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <div class="list-filter-results">
      <q-form class="general-info-form">
        <div v-for="(task, idx) in tasks" :key="idx">
          <task :task="task" :show-assigned-from="showAssignedFrom" :show-assigned-to="showAssignedTo" :show-status-tag="true"></task>
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import avatar from '@/assets/av.png'
import Task from '@/components/Tasks/Task'
import YpMultiSelect from '@/components/Utils/YpMultiSelect'

export default {
  name: 'FilterTasks',
  components: {
    Task,
    YpMultiSelect
  },
  props: {
    tasks: {
      default () {
        return []
      },
      type: Array
    },
    showAssignedFrom: {
      type: Boolean,
      default () {
        return true
      }
    },
    showAssignedTo: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      filterOpen: false,
      avatar: avatar,
      right: false,
      status: 'all',
      sortby: 'Default',
      yps: [],
      statusOptions: [
        'all',
        'overdue',
        'upcoming',
        'completed'
      ],
      sortbyOptions: [
        'Default',
        'Newest first',
        'Oldest first'
      ]
    }
  },
  methods: {
    applyFilter () {
      this.filterOpen = false
      this.$emit('filterTasks', { status: this.status, sortby: this.sortby, yps: this.yps.map(yp => yp.id) })
    },
    removeYp (yp) {
      this.yps = this.yps.filter(youngPerson => youngPerson.id !== yp.id)
      this.applyFilter()
    },
    resetFilter () {
      this.$emit('filterTasks', false)
      this.filterOpen = false
      this.status = 'all'
      this.sortby = 'Default'
      this.yps = []
    },
    removeStatus () {
      this.status = 'all'
      this.applyFilter()
    },
    removeSorting () {
      this.sortby = 'Default'
      this.applyFilter()
    }
  },
  updated () {
    if (document.querySelectorAll('.q-select [role="combobox"]').length) {
      document.querySelectorAll('.q-select [role="combobox"]').forEach(element => {
        element.removeAttribute('aria-multiselectable')
      })
    }
    if (document.querySelector('.status-filter .q-select [role="combobox"]')) {
      document.querySelector('.status-filter .q-select [role="combobox"]').setAttribute('aria-label', 'Status')
      document.querySelector('.sortby-filter .q-select [role="combobox"]').setAttribute('aria-label', 'Sort by')
    }
  }
}
</script>
