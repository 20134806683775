<template>
  <div>
    <q-header v-if="['Info'].includes($route.name)">
      <q-toolbar>
        <div class="col-2">
          <q-btn class="done-btn" flat round dense @click="$router.go(-1)" icon="arrow_back_ios"></q-btn>
        </div>
        <div class="col-10">
          <span>{{ documentTitle }}</span>
        </div>
      </q-toolbar>
    </q-header>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'AppHeaderInfo',
  computed: {
    ...mapGetters(['documentTitle'])
  }
}
</script>
