<template>
  <div>
    <list-contacts :contacts="groupedYoungPersons" :item-class-generator="isSelectedCase" :chevron="false">
      <template v-slot="{ contact }">
        <q-item-section side>
          <div v-if="!contact.hasSW">
            <div class="bookmark-icon" v-if="!contact.isMyCaseLoad" @click.stop="addToMyCaseload(contact)" @keyup.enter.stop="addToMyCaseload(contact)" tabindex="0">
              <i :class="{ fas: selectedYps.includes(contact.id), far: !selectedYps.includes(contact.id) }"
                 class="fa-bookmark"></i>
            </div>
            <div v-else class="bookmark-icon bookmark-icon-grey">
              <i class="fas fa-bookmark"></i>
            </div>
          </div>
          <div class="leadByText text-right" v-if="contact.hasSW">
            allocated to: <br/>
            {{ contact.leadBy }}
          </div>
        </q-item-section>
      </template>
    </list-contacts>
  </div>
</template>

<script>
import ListContacts from '@/components/Utils/ListContacts'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'AddYoungPersons',
  components: { ListContacts },
  data () {
    return {
      selectedCaseload: []
    }
  },
  computed: {
    ...mapGetters(['groupedYoungPersons']),
    selectedYps () {
      return this.selectedCaseload.map(yp => yp.id)
    }
  },
  methods: {
    ...mapMutations(['setCntSelected', 'clearSearch']),
    ...mapActions(['addYpToMyCaseload', 'setYoungPersons']),
    addToMyCaseload (contact) {
      if (!contact.isMyCaseLoad && !contact.hasSW) {
        if (this.selectedYps.includes(contact.id)) {
          this.selectedCaseload.splice(this.selectedCaseload.indexOf(contact), 1)
        } else {
          this.selectedCaseload.push(contact)
        }
      }
    },
    addSelected () {
      if (this.selectedCaseload.length) {
        this.clearSearch()
        this.addYpToMyCaseload(this.selectedCaseload.map(yp => yp.id)).then(() => {
          this.$router.push({ name: 'MyCaseLoad' })
          setTimeout(() => {
            if (this.selectedCaseload.length === 1) {
              this.$root.$emit('caseload::notification', 'New Young Person! <br />' + this.selectedCaseload[0].firstName + ' ' + this.selectedCaseload[0].lastName + ' was added to caseload!')
            } else {
              this.$root.$emit('caseload::notification', 'New Young Persons were added to your caseload!')
            }
          }, 100)
        })
      }
    },
    isSelectedCase (contact) {
      return this.selectedYps.includes(contact.id) ? 'yp-isMyCaseload' : ''
    }
  },
  mounted () {
    this.$root.$on('caseload::add', this.addSelected)
    this.setYoungPersons()
  },
  watch: {
    selectedCaseload (selected) {
      this.setCntSelected(selected.length)
    }
  },
  beforeDestroy () {
    this.$root.$off('caseload::add', this.addSelected)
    this.setCntSelected(0)
    this.clearSearch()
  }
}
</script>

<style>
.yp-isMyCaseload .q-avatar {
  opacity: 0.6;
}

.bookmark-icon-grey {
  color: #A2ADB9 !important;
}
</style>
