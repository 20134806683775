import { render, staticRenderFns } from "./ViewProfile.vue?vue&type=template&id=068f5a9e&scoped=true&"
import script from "./ViewProfile.vue?vue&type=script&lang=js&"
export * from "./ViewProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068f5a9e",
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QExpansionItem,QCard,QCardSection,QSeparator,QMarkupTable});
