// global socket access
import { io } from 'socket.io-client'
import updateActions from '@/store/utils/mapUpdateActions'
import Notify from 'quasar/src/plugins/Notify.js';
let setSocketInterval = null
let socket = null
let lockUpdateInterval = null

const state = {
  locks: [],
  lastAction: null
}

const getters = {
  locks (state) {
    return state.locks
  },
  lastAction (state) {
    return state.lastAction
  }
}

const actions = {
  setSocket (context) {
    const trySetSocket = () => {
      if (context.getters.currentUser.active && socket === null) {
        const auth = {
          token: context.getters.currentUser.user_token,
          id: context.getters.currentUser.user_id
        }

        socket = io(process.env.VUE_APP_SOCKET_SERVER, {
          auth
        })

        socket.on('data', data => {
          try {
            const actionParams = JSON.parse(data)
            context.commit('setBackgroundLoading', true)
            Promise.all(updateActions[actionParams.action].map(action => context.dispatch(action))).then(() => {
              if (actionParams.message.length && context.getters.appSettings && context.getters.appSettings.notifications_push) {
                Notify.create({
                  message: actionParams.message,
                  color: 'green',
                  position: 'top-right'
                })
              }
              context.commit('setBackgroundLoading', false)
            })
          } catch (e) {
            context.commit('setBackgroundLoading', false)
          }
        })

        socket.on('ck', data => {
          if (data.lockedBy === undefined) {
            context.commit('removeLock', data)
          } else {
            context.commit('setLocks', data)
          }
        })

        context.commit('setActiveLock', false)
        clearInterval(setSocketInterval)
        clearInterval(lockUpdateInterval)
        lockUpdateInterval = setInterval(() => context.dispatch('updateLock'), 1000 * 30)
      } else {
        socket = null
      }
    }

    trySetSocket()
    if (socket === null) {
      setSocketInterval = setInterval(() => {
        trySetSocket()
      }, 1000)
    }
  },
  removeListeners (context) {
    if (socket) {
      socket.off('data')
    }
  },
  clearSocket (context, id) {
    if (socket) {
      socket.emit('rs', {
        sid: socket.id,
        id: id
      })
      socket = null
    }
  },
  handleCredentialsChange (context, userId) {
    if (context.getters.currentUser.active) {
      context.dispatch('removeListeners')
      context.dispatch('setSocket')
    } else {
      clearInterval(lockUpdateInterval)
      context.dispatch('removeListeners')
      context.dispatch('clearSocket', userId)
    }
  },
  addLock (context, data) {
    if (socket) {
      const lockData = {
        id: data.id,
        resourceId: data.resourceId,
        section: data.section,
        userType: context.getters.currentUser.userType
      }
      socket.emit('sl', lockData)
      context.commit('setActiveLock', lockData)
    }
  },
  removeLock (context, data) {
    if (socket) {
      socket.emit('rl', {
        id: context.getters.currentUser.user_id,
        resourceId: data.resourceId,
        section: data.section
      })
      context.commit('setActiveLock', false)
    }
  },
  checkLock (context, data) {
    if (socket && context.getters.currentUser && data) {
      socket.emit('ck', data)
    }
  },
  setLock (context, data) {
    if (typeof data === 'object' && data.resourceId && data.section) {
      if (!context.state.locks.filter(lk => lk.resourceId === data.resourceId && lk.section === data.section).length) {
        context.commit('setLock', data)
      }
    }
  },
  switchLock (context, data) {
    if (socket) {
      const lockData = {
        id: context.getters.currentUser.user_id,
        resourceId: data.resourceId,
        section: data.section,
        userType: context.getters.currentUser.userType
      }
      socket.emit('swl', lockData)
      context.commit('setActiveLock', lockData)
    }
  },
  updateLock (context) {
    if (context.getters.activeLock) {
      socket.emit('ul', context.getters.activeLock)
    }
  }
}

const mutations = {
  removeLock (state, data) {
    state.locks = state.locks.filter(lk => lk.resourceId !== data.resourceId && lk.section !== data.section)
  },
  setLocks (state, data) {
    if (state.locks.filter(lk => lk.resourceId === data.resourceId && lk.section === data.section).length) {
      state.locks = state.locks.map(lk => lk.resourceId === data.resourceId && lk.section === data.section ? data : lk)
    } else {
      state.locks.push(data)
    }
  },
  setLastAction (state, data) {
    state.lastAction = data
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}

export { socket }
