import { render, staticRenderFns } from "./ContactDetails.vue?vue&type=template&id=57ccc366&scoped=true&"
import script from "./ContactDetails.vue?vue&type=script&lang=js&"
export * from "./ContactDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ccc366",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QInput,QBtn});
