// add locks on navigation

import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions(['addLock']),
    setLocks (params) {
      this.addLock({
        id: this.currentUser.user_id,
        resourceId: params.resourceId,
        section: params.section,
        role: this.currentUser.userType
      })
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentYp', 'locks', 'pathwayPlan', 'selectedTask', 'currentContact']),
    lockParams () {
      let section = false
      if (['AllAboutMeNext', 'OverallPlanNext', 'EducationEmploymentNext', 'ManagingLivingNext', 'HealthDevelopmentNext', 'MyMoneyNext'].includes(this.$route.name)) {
        if (this.$route.meta.edit && this.$route.meta.tense) {
          if (this.pathwayPlan && this.pathwayPlan[this.$route.meta.tense]) {
            section = 'pp'
            return {
              resourceId: this.pathwayPlan[this.$route.meta.tense].id,
              section
            }
          }
        }
      }

      if (['Profile'].includes(this.$route.name)) {
        section = 'profile'
      }

      if (section) {
        if (['SW', 'PA'].includes(this.currentUser.userType) && this.currentYp) {
          return {
            resourceId: this.currentYp.id,
            section: section
          }
        }
        if (this.currentUser.userType === 'YP') {
          return {
            resourceId: this.currentUser.user_id,
            section: section
          }
        }
      }
      return false
    },
    taskLockParams () {
      if (this.$options.name === 'AssignTask' && this.selectedTask && this.editMode) {
        return {
          section: 'task',
          resourceId: this.selectedTask.id
        }
      }
      return false
    },
    contactLockParams () {
      if (this.$options.name === 'AddEditContact' && this.currentContact && this.modalOpen) {
        return {
          section: 'contact',
          resourceId: this.currentContact.id
        }
      }
      return false
    }
  },
  mounted () {
    if (this.lockParams) {
      console.log('should set the locks', this.lockParams)
      this.setLocks(this.lockParams)
    }
  },
  watch: {
    taskLockParams (lkParams) {
      if (lkParams) {
        this.setLocks(lkParams)
      }
    },
    contactLockParams (lkParams) {
      if (lkParams) {
        this.setLocks(lkParams)
      }
    }
  }
}
